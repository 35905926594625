<template>
<div>
    <div v-if="!showManualEntry">
        <div class="grid grid-cols-3 col-span-3 items-center">
            <TextInput
                :id="`${id}_search`"
                label="Address Search"
                placeholder="Enter first line of address or postcode..."
                :isEditing="true"
                :value="searchValue"
                :required="false"
                @update:value="v => (searchValue = v)"
            />
        </div>
        <div class="text-red-600 text-xs mt-2">{{generalError}}</div>
        <div v-if="searchComplete && addresses?.length > 0" >
            <div class="border border-gray-300 rounded-md col-span-6 p-2 overflow-y-scroll max-h-52">
                <ul>
                    <li v-for="address in addresses" class="p-2 hover:bg-gray-200 dark:hover:bg-gray-800 cursor-pointer" @click="selectAddress(address.id)">{{address.value}}</li>
                </ul>
            </div>
        </div>
        <div v-else-if="searchComplete" class="text-center text-sm">
            No addresses found
        </div>
        <div class="text-center mt-2 mb-2" v-if="showManualEntryButton">
            <Button variety="secondary" @click.stop="switchToManual">
                Enter address manually
            </Button>
        </div>
        <div class="col-span-2"></div>
    </div>
    <div v-if="showManualEntry || !showManualEntryButton" class="grid grid-cols-3 col-span-3 space-y-2 items-center mb-2">
            <TextInput
                :id="`${id}_address_line_1`"
                label="Address Line 1"
                placeholder=""
                :isEditing="true"
                :value="addressLine1"
                :errors="errors['address_line_1']"
                :required="false"
                @update:value="v => (addressLine1 = v)"
            />

            <TextInput
                :id="`${id}_address_line_2`"
                label="Address Line 2"
                placeholder=""
                :isEditing="true"
                :value="addressLine2"
                :errors="errors['address_line_2']"
                :required="false"
                @update:value="v => (addressLine2 = v)"
            />

            <TextInput
                :id="`${id}_address_line_3`"
                label="Address Line 3"
                placeholder=""
                :isEditing="true"
                :value="addressLine3"
                :errors="errors['address_line_3']"
                :required="false"
                @update:value="v => (addressLine3 = v)"
            />

            <TextInput
                :id="`${id}_city`"
                label="City"
                placeholder=""
                :isEditing="true"
                :value="city"
                :errors="errors['city']"
                :required="false"
                @update:value="v => (city = v)"
            />

            <TextInput
                :id="`${id}_county`"
                label="County"
                placeholder=""
                :isEditing="true"
                :value="county"
                :errors="errors['county']"
                :required="false"
                @update:value="v => (county = v)"
            />

            <TextInput
                :id="`${id}_zip_code`"
                label="Postcode"
                placeholder=""
                :isEditing="true"
                :value="zipCode"
                :errors="errors['zip_code']"
                :required="false"
                @update:value="v => (zipCode = v)"
            />

            <TextInput
                :id="`${id}_country_id`"
                label="Country"
                placeholder=""
                :isEditing="false"
                :value="countryId"
                :errors="errors['country_id']"
                :required="true"
                @update:value="v => (countryId = v)"
            />
    </div>
</div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, Ref, ref, watch} from "vue";
import DropdownSelector from "./DropdownSelector.vue";
import useMiscInformationStore from "../../utils/useMiscInformationStore";
import AddressApiClient from "../../api/AddressApiClient";
import AddressLookupItem from "../../types/AddressLookupItem";
import FormComponent from "./FormComponent.vue";
import AddressAutocompleteItem from "../../types/AddressAutocompleteItem";
import _ from "lodash";

export default defineComponent({
    name: "AddressLookup",
    components: {FormComponent, DropdownSelector},
    props:{
        errors:{
            required: false,
            type: Object,
        },
        generalErrorText:{
            required: false,
            type: String,
        },
        id:{
            required: true,
            type: String
        },
        expand: {
            required: false,
            type: Boolean,
            default: false
        },
        hasTopPadding: {
            type: Boolean,
            required: false,
            default: true
        },
        hasBottomPadding: {
            type: Boolean,
            required: false,
            default: true
        },
        showManualEntryButton: {
            type: Boolean,
            required: false,
            default: true
        },
        address: {
            type: Object,
            required: false
        }
    },
    emits:[
        'update:value'
    ],
    setup(props,context){

        const searchValue = ref("");

        const searchComplete = ref(false);

        const addressLine1 = ref(undefined as unknown as string);
        const addressLine2 = ref(undefined as unknown as string);
        const addressLine3 = ref(undefined as unknown as string);
        const city = ref(undefined as unknown as string);
        const county = ref(undefined as unknown as string);
        const zipCode = ref(undefined as unknown as string);
        const countryId = ref(undefined as unknown as string);

        const showManualEntry = ref(false);
        const showSearchValueError = ref(false);

        const columns = computed(() => showManualEntry.value ? 3 : 6);

        const generalError = computed(() => !showManualEntry.value && props.errors && Object.keys(props.errors).length > 0 ? props.generalErrorText : '');

        const miscInformationStore = useMiscInformationStore();

        const countries = miscInformationStore.countries;

        const addresses: Ref<AddressAutocompleteItem[] | null | undefined>= ref([]);

        const onSubmit = function() {

        }

        let latestSearch = '';

        const performAddressLookup = _.debounce(async function () {
                if(!searchValue.value) return;
                if(searchValue.value.length < 2) {
                    searchComplete.value = false;
                    return;
                }

                latestSearch = searchValue.value.toString();

                let autocompleteResponse = await AddressApiClient.autocomplete(searchValue.value);

                if(autocompleteResponse.success && searchValue.value === latestSearch) {
                    addresses.value = autocompleteResponse.data?.items
                    searchComplete.value = true;
                }
            }, 1000)


        const switchToManual = function () {
            showManualEntry.value = true;
            countryId.value = "GBR"
        }

        const selectAddress = async function(addressLookupId: string) {
            let lookupResponse = await AddressApiClient.lookup(addressLookupId);

            if(!lookupResponse.success || !lookupResponse.data) {
                return;
            }

            addressLine1.value = lookupResponse.data.address.address_line_1;
            addressLine2.value = lookupResponse.data.address.address_line_2 ?? "";
            addressLine3.value = lookupResponse.data.address.address_line_3 ?? "";
            city.value = lookupResponse.data.address.city;
            county.value = lookupResponse.data.address.county ?? "";
            zipCode.value = lookupResponse.data.address.zip_code
            countryId.value = lookupResponse.data.address.country_id;

            showManualEntry.value = true;
            searchComplete.value = false;
            searchValue.value = "";
        }

        watch(
            [addressLine1, addressLine2, addressLine3, city, county, zipCode, countryId],
            () => {
                try{
                    let selectedAddress = {
                        address_line_1: addressLine1.value,
                        address_line_2: addressLine2.value,
                        address_line_3: addressLine3.value,
                        city: city.value,
                        county: county.value,
                        zip_code: zipCode.value,
                        country_id: countryId.value
                    }
                    context.emit('update:value', selectedAddress);
                }
                catch (e){
                    console.error(e);
                }
            },
            { immediate: true }
        );

        watch(
            [searchValue],
            () => {
                try{
                    performAddressLookup();
                }
                catch (e){
                    console.error(e);
                }
            },
            { immediate: true }
        );

        onMounted(() => {
            if(props.address){
                addressLine1.value = props.address.addressLine1;
                addressLine2.value = props.address.addressLine2;
                addressLine3.value = props.address.addressLine3;
                city.value = props.address.city;
                county.value = props.address.county;
                zipCode.value = props.address.zipCode
                countryId.value = props.address.countryId;
            }
        })

        return {
            searchValue,
            searchComplete,
            addresses,
            performAddressLookup,
            showManualEntry,
            showSearchValueError,
            columns,
            generalError,
            switchToManual,
            selectAddress,
            onSubmit,
            addressLine1,
            addressLine2,
            addressLine3,
            city,
            county,
            zipCode,
            countryId,
            countries
        }
    }
})
</script>

<style scoped>

</style>
