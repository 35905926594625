import axios from "axios";
import {stringify} from "query-string";
import CreatedInvoice from "../modules/invoices/types/CreatedInvoice";
import Invoice from "../modules/invoices/types/Invoice";
import InvoiceStatus from "../modules/invoices/types/InvoiceStatus";
import SlimlineInvoice from "../modules/invoices/types/SlimlineInvoice";
import InvoiceUtils from "../modules/invoices/utils/InvoiceUtils";
import PaginatedResponse from "../types/PaginatedResponse";
import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper";

async function handleInvoiceResponse(
    invoiceResponse: Promise<ApiResponseWrapper<Invoice>>
): Promise<ApiResponseWrapper<Invoice>> {
    const r = await invoiceResponse;
    if (!r.success || !r.data?.lines) {
        return r;
    }

    InvoiceUtils.deApify(r.data);

    return r;
}

function listInvoices(
    invoiceStatuses?: InvoiceStatus[],
    customer?: number | undefined,
    paginationLink?: string | undefined,
    sortToUse?: string | undefined,
    showDeleted?: number | undefined
): Promise<ApiResponseWrapper<PaginatedResponse<SlimlineInvoice[]>>> {
    let url = (paginationLink ? `${paginationLink}&` : "/invoices?") + stringify({
        status: invoiceStatuses,
        customerIds: [customer],
        sort: sortToUse,
        showDeleted: showDeleted
    }, {arrayFormat: "bracket"});

    return ApiAsyncWrapper(axios.get(url));
}

function createInvoice(invoice: CreatedInvoice): Promise<ApiResponseWrapper<Invoice>> {
    return handleInvoiceResponse(
        ApiAsyncWrapper(axios.post(`/invoices/${invoice.customer_id}`, InvoiceUtils.apify(invoice as any as Invoice)))
    );
}

function getInvoice(invoiceId: number): Promise<ApiResponseWrapper<Invoice>> {
    return handleInvoiceResponse(
        ApiAsyncWrapper(axios.get(`/invoices/${invoiceId}`))
    );
}

function updateInvoice(invoiceId: number, invoice: Omit<Invoice, "id">): Promise<ApiResponseWrapper<Invoice>> {
    return handleInvoiceResponse(
        ApiAsyncWrapper(axios.put(`/invoices/${invoiceId}`, InvoiceUtils.apify(invoice as Invoice)))
    );
}

function deleteInvoice(invoiceId: number): Promise<ApiResponseWrapper<{}>> {
    return handleInvoiceResponse(
        ApiAsyncWrapper(axios.delete(`/invoices/${invoiceId}`))
    );
}

function unDeleteInvoice(invoiceId: number): Promise<ApiResponseWrapper<{}>> {
    return handleInvoiceResponse(
        ApiAsyncWrapper(axios.put(`/invoices/${invoiceId}/undelete`))
    );
}

function sendReminder(invoiceId: number): Promise<ApiResponseWrapper<Invoice>> {
    return handleInvoiceResponse(
        ApiAsyncWrapper(axios.post(`/invoices/${invoiceId}/reminder`))
    );
}


export default {
    listInvoices,
    createInvoice,
    getInvoice,
    updateInvoice,
    deleteInvoice,
    unDeleteInvoice,
    sendReminder
}
