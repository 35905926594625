<template>
    <div class="wizard-intro h-full flex flex-col justify-between">
        <div class=""></div>
        <div class="wizard-logo">
            <img class="w-full" alt="WalkBuddy logo" :src="logo" style="max-width:500px" />
            <div class="font-bold text-xl text-center hidden lg:block">Join now and start managing your dog walking business today</div>
        </div>
        <div class="wizard-image self-center hidden lg:block">
            <img class="w-full ml-12" alt="Lady walking a dog" :src="walker" />
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted} from "vue";
import logo from "../../../assets/logo-coloured-bg.png";
import walker from "../../../assets/walker.png";

export default defineComponent({
    name: "WizardIntro",
    setup(){
        return {
            logo,
            walker
        }
    }
})
</script>

<style scoped>
.wizard-logo {
    padding: 30px;
}

.wizard-logo img {
    margin-left: auto;
    margin-right: auto;
}
</style>
