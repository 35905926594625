<template>
    <div class="text-lg font-bold p-2">
        <div class="embed-youtube">
            <iframe v-if="showVideo" class="rounded-lg border-none" :src='data.video_uri' allowfullscreen></iframe>
            <img v-else class="rounded-lg border-none cursor-pointer" style="width: 100%;" @click="toggleVideo"  :alt="data.title" :src="data.image_uri" />
        </div>
        <h3>{{ data.title }}</h3>
    </div>
</template>

<script>
import {defineComponent, ref} from "vue";

export default defineComponent({
    name: "HelpVideo",
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    setup(){
        const showVideo = ref(false);

        const toggleVideo = function() {
            showVideo.value = !showVideo.value
        }

        return {
            showVideo,
            toggleVideo
        }
    }
})
</script>

<style scoped>

.embed-youtube {
    position: relative;
    padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
    /* padding-bottom: 62.5%; - 16:10 aspect ratio */
    /* padding-bottom: 75%; - 4:3 aspect ratio */
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.embed-youtube iframe,
.embed-youtube object,
.embed-youtube embed {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
