import axios from "axios";
import { ApiAsyncWrapper, ApiResponseWrapper } from "./utils/ApiAsyncWrapper";
import VoteType from "../modules/voting/types/VoteType";
import VotingFeature from "../modules/voting/types/VotingFeature";
import FeatureSuggestion from "../modules/voting/types/FeatureSuggestion";

function listVotingFeatures(paginationUrl?: string): Promise<ApiResponseWrapper<VotingFeature[]>> {
    return ApiAsyncWrapper(axios.get(paginationUrl ? paginationUrl : '/features'));
}

function getFeature(id: number): Promise<ApiResponseWrapper<VotingFeature>> {
    return ApiAsyncWrapper(axios.get(`/features/${id}`));
}

function suggestFeature(feature: FeatureSuggestion): Promise<ApiResponseWrapper<VotingFeature>> {
    return ApiAsyncWrapper(axios.post(`/features`, feature));
}

function voteForFeature(id: number, voteType: VoteType): Promise<ApiResponseWrapper<VotingFeature>> {
    return ApiAsyncWrapper(axios.post(`/features/${id}/vote/${voteType}`, {
        featureId: id,
        voteType: voteType
    }));
}

export default {
    listVotingFeatures,
    getFeature,
    suggestFeature,
    voteForFeature
}
