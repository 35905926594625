<template>
    <Form>
        <FormComponent :hasTopPadding="false">
            <TextInput
                id="user-name"
                label="Name"
                placeholder="Joe Bloggs"
                :required="true"
                :isEditing="isEditing"
                :value="name"
                :errors="nameError"
                @update:value="(value) => (name = value)"
            />
        </FormComponent> 

        <FormComponent>
            <TextInput
                id="user-telephone"
                label="Telephone"
                placeholder="01487 523654"
                type="tel"
                :value="telephone"
                :errors="telephoneError"
                :isEditing="isEditing"
                :required="true"
                @update:value="(value) => (telephone = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="user-mobile"
                label="Mobile"
                placeholder="07541236665"
                type="tel"
                :value="mobile"
                :errors="mobileError"
                :isEditing="isEditing"
                :required="true"
                @update:value="(value) => (mobile = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="user-address-1"
                label="Address Line 1"
                placeholder="32 Long Avenue"
                :value="addressLine1"
                :errors="addressLine1Error"
                :isEditing="isEditing"
                :required="true"
                @update:value="(value) => (addressLine1 = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="user-address-2"
                label="Address Line 2"
                placeholder=""
                :value="addressLine2"
                :errors="addressLine2Error"
                :isEditing="isEditing"
                @update:value="(value) => (addressLine2 = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="user-address-3"
                label="Address Line 3"
                placeholder=""
                :value="addressLine3"
                :errors="addressLine3Error"
                :isEditing="isEditing"
                @update:value="(value) => (addressLine3 = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="user-city"
                label="City"
                placeholder=""
                :value="city"
                :errors="cityError"
                :isEditing="isEditing"
                :required="true"
                @update:value="(value) => (city = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="user-county"
                label="County"
                placeholder=""
                :value="county"
                :errors="countyError"
                :isEditing="isEditing"
                @update:value="(value) => (county = value)"
            />
        </FormComponent>

        <FormComponent :hasBottomPadding="false">
            <TextInput
                id="user-post-code"
                label="Post Code"
                placeholder=""
                :value="zipCode"
                :errors="zipCodeError"
                :isEditing="isEditing"
                :required="true"
                @update:value="(value) => (zipCode = value)"
            />
        </FormComponent>
    </Form>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, PropType, ref, watch} from "vue";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import useMiscInformationStore from "../../../utils/useMiscInformationStore";
import User from "../types/User";

export default defineComponent({
    props: {
        isEditing: {
            type: Boolean,
            required: true
        },
        user: {
            type: Object as PropType<User>,
            required: true,
        }
    },
    setup(props, context) {
        const miscInformationStore = useMiscInformationStore();
        const isEditing = computed(() => props.isEditing);

        const schema = yup.object({
            name: yup.string().required('Name is required.').max(255),
            telephone: yup.string().required('Telephone is required.').min(6, 'Telephone needs to be a valid number.').max(255),
            mobile:  yup.string().min(6, 'Mobile needs to be a valid number.').nullable().max(255),
            address_line_1: yup.string().required('Address line 1 is required.').min(5, 'Address line 1 needs to be a minimum of 5 characters.').max(255),
            address_line_2: yup.string().nullable().max(255),
            address_line_3: yup.string().nullable().max(255),
            city: yup.string().required('City is required.').max(255),
            county: yup.string().nullable().max(255),
            country_id: yup.string().required('Country is required.'),
            zip_code: yup.string().required('Post Code is required.').max(255)
        });

        // Form context
        const {handleSubmit, resetForm} = useForm({
            validationSchema: schema,
        })

        // Form fields.
        const { value: name, errorMessage: nameError } = useField('name');
        const { value: telephone, errorMessage: telephoneError } = useField('telephone');
        const { value: mobile, errorMessage: mobileError } = useField('mobile');
        const { value: addressLine1, errorMessage: addressLine1Error } = useField('address_line_1');
        const { value: addressLine2, errorMessage: addressLine2Error } = useField('address_line_2');
        const { value: addressLine3, errorMessage: addressLine3Error } = useField('address_line_3');
        const { value: city, errorMessage: cityError } = useField('city');
        const { value: county, errorMessage: countyError } = useField('county');
        const { value: countryId, errorMessage: countryIdError } = useField('country_id');
        const { value: zipCode, errorMessage: zipCodeError } = useField('zip_code');

        watch(
            isEditing, 
            () => {
                resetForm();
                if (props.user) {
                    name.value = props.user.name ?? "";
                    telephone.value = props.user.telephone ?? "";
                    mobile.value = props.user.mobile ?? "";
                    addressLine1.value = props.user.address_line_1 ?? "";
                    addressLine2.value = props.user.address_line_2 ?? "";
                    addressLine3.value = props.user.address_line_3 ?? "";
                    city.value = props.user.city ?? "";
                    county.value = props.user.county ?? "";
                    countryId.value = props.user.country_id ?? "GBR";
                    zipCode.value = props.user.zip_code ?? "";
                } else {
                    countryId.value = "GBR";
                }
            }, 
            { immediate: true }
        )

        const onSubmit = handleSubmit(values => {
            context.emit('onSubmit', values);
        });

        return {
            countries: miscInformationStore.countries,

            onSubmit,

            name,
            nameError,
            
            telephone,
            telephoneError,

            mobile,
            mobileError,
            
            addressLine1,
            addressLine1Error,

            addressLine2,
            addressLine2Error,

            addressLine3,
            addressLine3Error,

            city,
            cityError,

            county,
            countyError,

            countryId,
            countryIdError,

            zipCode,
            zipCodeError
        }
    },
})
</script>
