<template>
    <tr @click="viewCustomer" class="hover:bg-gray-100 dark:hover:bg-gray-500 dark:hover:text-gray-900 cursor-pointer">
        <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
                <div class="flex-shrink-0 flex items-center justify-center w-8 h-8 bg-gray-600 text-white dark:text-gray-100 font-medium rounded-full">
                    {{customer.name.split(' ')[0]?.charAt(0)}}{{customer.name.split(' ')[1]?.charAt(0)}}
                </div>
                <div class="ml-4">
                    <div class="font-medium text-gray-900 dark:text-gray-100">
                        {{ customer.name }}
                    </div>
                </div>
            </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-gray-900 dark:text-gray-100">{{ customer.email }}</div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-gray-900 dark:text-gray-100">{{ customer.telephone ?? customer.mobile }}</div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-gray-500 dark:text-gray-100">
            {{ dogNames(customer) }}
        </td>
    </tr>
</template>
<script lang="ts">
import {defineComponent, PropType} from "vue";
import {useRouter} from "vue-router";
import SlimCustomer from "../types/SlimCustomer";

export default defineComponent({
    name: 'CustomerTableRow',
    props:{
        customer: {
            type: Object as PropType<SlimCustomer>,
            required: true
        }
    },
    setup(props){
        const router = useRouter();

        function viewCustomer(){
            router.push(`/customers/${props.customer!.id}`);
        }

        function dogNames(customer: SlimCustomer) {
            if (!customer.dogs) {
                return "";
            }

            if (customer.dogs.length <= 3) {
                return customer.dogs.join(", ");
            }

            return `${customer.dogs.slice(0, 3).join(", ")} (${customer.dogs.length - 3} more...)`;
        }

        return{
            viewCustomer,
            dogNames
        }
    }
})
</script>
