<template>
    <div
        :class="[isHorizontal? 'pl-4 pr-4' : '']"
        class="flex flex-row mt-4 mb-4 items-center relative" >
        <div v-if="!isLastStep && !isHorizontal" :class="[isComplete ? 'bg-white' : 'bg-gray-200']" style="position:absolute; bottom:-34px; left: 24px; width: 3px; height: 34px;" />
        <div style="min-width: 50px; min-height: 50px;"
             :class="[isCurrent ? 'bg-white' : '', isComplete ? 'border-white border-2' : '', !isCurrent && !isComplete ? 'bg-gray-200' : '' ]"
             class="flex text-gray-900 justify-center items-center rounded-full">
            <TickIcon style="width: 30px; height: 30px" v-if="isComplete" class="text-white" />
            <div v-else>
                <BusinessFolderIcon v-if="step === 2" style="width: 30px; height: 30px"/>
                <CalenderIcon v-if="step === 3" style="width: 30px; height: 30px" />
                <ChartIcon v-if="step === 4" style="width: 30px; height: 30px" />
            </div>
        </div>
        <div v-if="!isHorizontal" class="ml-4 text-lg" :class="[isComplete ? 'text-white' : 'text-gray-200', isCurrent ? 'text-gray-900' : '']">{{ stepText }}</div>
        <div v-if="isHorizontal && isCurrent" class="ml-4 text-lg" :class="[isComplete ? 'text-white' : 'text-gray-200', isCurrent ? 'text-gray-900' : '']">{{ stepText }}</div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted} from "vue";
import BusinessFolderIcon from "../../../components/icons/BusinessFolderIcon.vue";
import CalenderIcon from "../../../components/icons/CalenderIcon.vue";
import ChartIcon from "../../../components/icons/ChartIcon.vue";
import TickIcon from "../../../components/icons/TickIcon.vue";

export default defineComponent({
    name: "OnboardingStepperStep",
    components: {TickIcon, ChartIcon, CalenderIcon, BusinessFolderIcon},
    props:{
        step: {
            type: Number,
            required: true
        },
        stepText: {
            type: String,
            required: true
        },
        currentStep: {
            type: Number,
            required: true
        },
        isLastStep: {
            type: Boolean,
            required: false,
            default: false
        },
        isHorizontal: {
            type: Boolean,
            required: false
        }
    },
    setup(props){
        const isCurrent = computed(() => props.currentStep === props.step);
        const isComplete = computed(() => props.currentStep > props.step);

        return {
            isCurrent,
            isComplete
        }
    }
})
</script>

<style scoped>
</style>
