import axios from "axios"
import DogCategory from "../modules/business/types/DogCategory";
import SlimBusinessUser from "../modules/business/types/SlimBusinessUser";
import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper"
import Business from "../modules/business/types/Business";
import WalkCategory from "../modules/business/types/WalkCategory";
import Surcharge from "../modules/business/types/Surcharge";

// SURCHARGES
async function listSurchargesForBusiness(): Promise<ApiResponseWrapper<Surcharge[]>> {
    return await ApiAsyncWrapper(axios.get("/business/surcharges"));
}

async function updateSurcharge(cat: Surcharge): Promise<ApiResponseWrapper<Surcharge>> {
    return await ApiAsyncWrapper(axios.put(`business/surcharges/${cat.id}`, cat));
}

async function addSurcharge(cat: Surcharge): Promise<ApiResponseWrapper<Surcharge>> {
    return await ApiAsyncWrapper(axios.post(`business/surcharges`, cat));
}

async function deleteSurcharge(cat: Surcharge): Promise<ApiResponseWrapper<Surcharge>> {
    return await ApiAsyncWrapper(axios.delete(`business/surcharges/${cat.id}`));
}

// DOG CATEGORIES
async function listDogCategoriesForBusiness(): Promise<ApiResponseWrapper<DogCategory[]>> {
    return await ApiAsyncWrapper(axios.get("/business/dog-categories"));
}

async function updateDogCategory(cat: DogCategory): Promise<ApiResponseWrapper<DogCategory>> {
    return await ApiAsyncWrapper(axios.put(`business/dog-categories/${cat.id}`, cat));
}

async function addDogCategory(cat: DogCategory): Promise<ApiResponseWrapper<DogCategory>> {
    return await ApiAsyncWrapper(axios.post(`business/dog-categories`, cat));
}

async function deleteDogCategory(cat: DogCategory): Promise<ApiResponseWrapper<DogCategory>> {
    return await ApiAsyncWrapper(axios.delete(`business/dog-categories/${cat.id}`));
}

// WALK CATEGORIES
async function listWalkCategoriesForBusiness(): Promise<ApiResponseWrapper<WalkCategory[]>> {
    return await ApiAsyncWrapper(axios.get("/business/walk-categories"));
}

async function updateWalkCategory(cat: WalkCategory): Promise<ApiResponseWrapper<WalkCategory>> {
    return await ApiAsyncWrapper(axios.put(`business/walk-categories/${cat.id}`, cat));
}

async function addWalkCategory(cat: WalkCategory): Promise<ApiResponseWrapper<WalkCategory>> {
    return await ApiAsyncWrapper(axios.post(`business/walk-categories`, cat));
}

async function deleteWalkCategory(cat: WalkCategory): Promise<ApiResponseWrapper<WalkCategory>> {
    return await ApiAsyncWrapper(axios.delete(`business/walk-categories/${cat.id}`));
}


async function listUsersForBusiness(): Promise<ApiResponseWrapper<SlimBusinessUser[]>> {
    return await ApiAsyncWrapper(axios.get("/business/users"));
}

async function businessInfo(): Promise<ApiResponseWrapper<Business>> {
    return await ApiAsyncWrapper(axios.get("/business"));
}

async function updateBusinessInfo(newBusinessData: Business): Promise<ApiResponseWrapper<Business>> {
    return await ApiAsyncWrapper(axios.put("/business", newBusinessData));
}

async function updateBusinessAvatar(imageData: any): Promise<ApiResponseWrapper<any>> {
    const data = new FormData();
    data.append('image', imageData);
    return await ApiAsyncWrapper<any>(axios.post(`/business/image`, data));
}

async function deleteBusinessAvatar(): Promise<ApiResponseWrapper<Business>> {
    return await ApiAsyncWrapper(axios.delete("/business/image"));
}

export default {
    // Surcharges
    listSurchargesForBusiness,
    updateSurcharge,
    addSurcharge,
    deleteSurcharge,
    // Dog Categories
    listDogCategoriesForBusiness,
    updateDogCategory,
    addDogCategory,
    deleteDogCategory,
    // Walk Categories
    listWalkCategoriesForBusiness,
    updateWalkCategory,
    addWalkCategory,
    deleteWalkCategory,
    businessInfo,
    updateBusinessInfo,
    listUsersForBusiness,
    updateBusinessAvatar,
    deleteBusinessAvatar
}
