<template>
    <div class="bg-white dark:bg-gray-800 mr-8 p-4 w-full shadow" style="overflow: auto;">
        <div class="flex justify-between" v-if="!slimMode">
            <div class="text-2xl font-extrabold text-gray-900 dark:text-gray-100 mb-4">Statistics</div>
        </div>

        <div class="flex items-center justify-center" v-if="dashboardMetricsLoading">
            <LoadingSpinner />
        </div>
        <div v-else class="grid gap-1 md:gap-4" :class="[slimMode ? 'grid-cols-1' : 'grid-cols-2']">
            <div v-for="metric in metricsData" class="border-2 border-gray-200 dark:border-gray-500 grid grid-cols-3 md:grid-cols-2 p-0 md:p-2 content-start">
                <div class="col-span-1 md:col-span-2 my-auto">
                    <div class="bg-sky-blue-300 dark:bg-gray-700 text-xl md:text-2xl font-extrabold text-gray-900 dark:text-gray-100 text-center p-1 md:p-2">{{ metric.amount }}</div>
                </div>
                <div class="text-sm md:text-lg text-gray-900 dark:text-gray-100 text-center col-span-2 my-auto mt-2 items-center">{{ metric.label }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured} from "vue";
import useDashboardStore from "../useDashboardStore";

export default defineComponent({
    name: "MetricsWidget",
    props: {
        slimMode: {
            type: Boolean,
            required: false
        }
    },
    setup(){
        const dashboardStore = useDashboardStore();

        const dashboardMetricsLoading = computed(() => dashboardStore.dashboardMetricsLoading);

        const metricsData = computed(() => [
            { label: "Expected Monthly Income", amount: dashboardStore.estimatedMonthlyIncome()},
            { label: "Total Dog Walks This Month", amount: dashboardStore.scheduledWalks()},
            { label: "Outstanding Invoices", amount: dashboardStore.invoicesOutstanding()},
        ]);

        return {
            dashboardMetricsLoading,
            metricsData
        }
    }
})
</script>

<style scoped>
</style>
