<template>
    <div class="flex flex-col space-y-2 pl-2">
        <strong>Surcharges</strong>
        <ToggleComponent
            v-for="surcharge in chosenSurcharges"
            :is-toggled-on="surcharge.applied"
            :id="scheduleLineId+'-'+surcharge.id"
            :label-text="getSurchargeLabelText(surcharge)"
            @on-toggle-change="(value) => handleToggleChange(surcharge.id, value.target.checked)"
            />

        <MultiSelectDropdown
            :id="scheduleLineId+'-surcharge-list'"
            :selectors="options"
            valueProp="id"
            :value="-1"
            track-by="name"
            label="name"
            placeholder="Add a surcharge..."
            :clear-on-select="true"
            @change="(value) => handleSurchargeSelection(value)"
        />
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue'
import ToggleComponent from "../../../components/ToggleComponent.vue";
import MultiSelectDropdown from "../../../components/forms/MultiSelectDropdown.vue";
import Surcharge from "../../business/types/Surcharge";
import MoneyUtils from "../../../utils/MoneyUtils";

export default defineComponent({
    name: "ScheduleLineSurchargeList",
    components: {
        ToggleComponent,
        MultiSelectDropdown
    },
    props: {
        scheduleLineId: {
            type: Number,
            required: true
        },
        defaultSurcharges: {
            required: false,
            type: Array,
            default: []
        },
        surchargeList: {
            required: true,
            type: Array,
        }
    },
    emits: [
        "onSurchargeSelect"
    ],
    setup(props, context) {
        const chosenSurcharges: Surcharge[] = computed(() => props.defaultSurcharges);
        const options = computed(() => props.surchargeList.map(function(s){
            const found = props.defaultSurcharges.find(x => x.id == s.id);
            if(found){
                return {
                    ...s,
                    disabled: true,
                    applied: true
                }
            }
            return s;
        }));

        const handleToggleChange = (surchargeId: number, value: Boolean) => {
            let found = props.surchargeList.find(s => s.id == surchargeId) as Surcharge | undefined;
            if(found){
                context.emit('onSurchargeSelect', surchargeId,
                {
                    ...found,
                    disabled: true,
                    applied: value
                });
            }
        }

        const handleSurchargeSelection = (value: number) => {
            if(value){
                let found = props.surchargeList.find(s => s.id == value) as Surcharge | undefined;
                if(found) {
                    context.emit('onSurchargeSelect', props.scheduleLineId,
                        {
                            ...found,
                            disabled: true,
                            applied: true
                        });
                }
            }
        }

        const getSurchargeLabelText = (surcharge: Surcharge) => {
            const surchargePriceDisplayValue = MoneyUtils.getDisplayValue(surcharge.price_per_minute,"+");

            return `${surcharge.name} (${surchargePriceDisplayValue})`
        }

        return {
            options,
            chosenSurcharges,
            handleSurchargeSelection,
            handleToggleChange,
            getSurchargeLabelText
        }
    },
});
</script>