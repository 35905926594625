<template>
    <div v-if="visible" class="fixed modal-z inset-0 md:overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex h-full text-center p-0 md:items-center md:justify-center md:px-4 md:pb-20">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <div
                class="flex flex-col w-full h-full md:h-auto max-h-full bg-white dark:bg-gray-700 dark:text-gray-100 md:rounded-lg md:px-4 md:p-6 pb-4 md:my-8 text-left shadow-xl transform transition-all divide-y divide-gray-200"
                :class="{
                    'sm:max-w-md': size === 'medium',
                    'sm:max-w-lg': size === 'large',
                    'sm:max-w-2xl': size === 'xlarge'
                }"
            >
                <div class="flex flex-row items-center px-4 md:px-0 pt-4 md:pt-0 pb-4 bg-sky-blue-500 md:bg-white dark:bg-gray-700 dark:text-gray-100">
                    <slot name="icon"></slot>
                    <h1 v-if="title" class="ml-2 text-xl flex-1 truncate">{{ title }}</h1>
                    <div class="justify-end gap-2 flex flex-row gap-2">
                        <slot name="buttons"></slot>
                    </div>
                </div>
                <perfect-scrollbar style="height: 100%">
                    <div class="flex-grow px-4 md:px-0">
                        <div :class="{
                        'pt-6 sm:pt-8': title
                    }">
                            <slot></slot>
                        </div>
                    </div>
                </perfect-scrollbar>
                <div class="flex justify-end border-t border-gray-200 mt-4 pt-4 px-4 md:px-0 gap-2" v-if="$slots['footer-buttons']">
                    <slot name="footer-buttons"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onBeforeUnmount, onMounted, PropType, ref, watchEffect} from 'vue'

export default defineComponent({
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        size: {
            type: String as PropType<"small" | "medium" | "large" | "xlarge">,
            required: true
        },
        title: {
            type: String,
            required: false,
            default: undefined
        }
    },
    setup(props){

        // This watcher is needed to disable the perfect scroll on the sites main area while the modal is open.
        watchEffect(() => {
            const foundElement = document.getElementById('scroll-area');
            if (props.visible) {
                if(foundElement) {
                    foundElement.classList.remove('ps');
                    foundElement.classList.remove('ps--active-y');
                }
            } else {
                if(foundElement) {
                    foundElement.classList.add('ps');
                    foundElement.classList.add('ps--active-y');
                }
            }
        })
    }
})
</script>
