<template>

    <!-- see https://github.com/vueform/multiselect/issues/99 apparently fixed in 2.1.1 but doesn't seem to be -->
    <!-- had to add a hack to clear the search on select -->
    <label :for="id" class="block font-medium text-gray-700 dark:text-gray-100">
        {{ label }}{{ required ? "*" : "" }}
    </label>
    <div v-if="isEditing" class="col-span-2">
        <Multiselect
            :classes="multiSelectClasses"
            style="width: 100%"
            placeholder="Select.."
            mode="single"
            valueProp="id"
            trackBy="name"
            label="name"
            v-model="currentValue"
            :filterResults="true"
            :options="breeds"
            :minChars="1"
            :searchable="true"
            @change="handleOnChange"
        />
    </div>
    <label v-else-if="currentBreed" class="block text-gray-700 dark:text-gray-100">
        {{ currentBreed.name }}
    </label>
    <label v-else class="block font-normal text-gray-400 dark:text-gray-100">
        Unknown
    </label>
    <span v-if="errors" class="text-red-600 text-xs sm:col-span-2">{{errors}}</span>
</template>

<script lang="ts">
import {computed, defineComponent, ref, toRefs, watch} from 'vue'
import useMiscInformationStore from "../../../utils/useMiscInformationStore";

export default defineComponent({
    props:{
        id: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            required: false
        },
        label: {
            type: String,
            required: false
        },
        value: {
            type: Number,
            required: false
        },
        errors: {
            type: String,
            required: false
        },
        isEditing: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props, context) {
        const miscInformationStore = useMiscInformationStore();
        const breeds = miscInformationStore.breeds.map(function(x){
            return {
                id: x.id,
                name: x.name
            }
        });

        let currentValue = ref();

        let currentBreed = computed(() => breeds.find(b => b.id == props.value));

        let handleOnChange = (value: number) => {
            context.emit('update:value', value);
        }

        const { value } = toRefs(props);
        watch(value, val => {
            if(val) currentValue.value = val;
        }, { immediate: true })

        const multiSelectClasses = {
            container: 'relative mx-auto w-full flex items-center justify-end cursor-pointer bg-white dark:bg-gray-500 text-base leading-snug outline-none',
            containerDisabled: 'cursor-default bg-gray-100 dark:bg-gray-800',
            containerOpen: 'rounded-b-none',
            containerOpenTop: 'rounded-t-none',
            containerActive: 'ring ring-sky-blue-500 ring-opacity-30',
            singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
            singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
            multipleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
            search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white dark:bg-gray-500 rounded pl-3.5 rtl:pl-0 rtl:pr-3.5',
            tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 rtl:pl-0 rtl:pr-2',
            tag: 'bg-sky-blue-500 text-white dark:text-gray-100 text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
            tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
            tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
            tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
            tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
            tagsSearch: 'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full',
            tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
            placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
            caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
            caretOpen: 'rotate-180 pointer-events-auto',
            clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
            clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
            spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
            inifite: 'flex items-center justify-center w-full',
            inifiteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
            dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white dark:bg-gray-500 flex flex-col rounded-b',
            dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
            dropdownHidden: 'hidden',
            options: 'flex flex-col p-0 m-0 list-none',
            optionsTop: '',
            group: 'p-0 m-0',
            groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
            groupLabelPointable: 'cursor-pointer',
            groupLabelPointed: 'bg-gray-300 text-gray-700',
            groupLabelSelected: 'bg-sky-blue-600 text-white',
            groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
            groupLabelSelectedPointed: 'bg-sky-blue-600 text-white opacity-90',
            groupLabelSelectedDisabled: 'text-sky-blue-100 bg-sky-blue-600 bg-opacity-50 cursor-not-allowed',
            groupOptions: 'p-0 m-0',
            option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
            optionPointed: 'text-gray-800 dark:text-gray-800 bg-gray-100 dark:bg-gray-400',
            optionSelected: 'text-white bg-sky-blue-500',
            optionDisabled: 'text-gray-300 dark:text-gray-400 cursor-not-allowed',
            optionSelectedPointed: 'text-white bg-sky-blue-500 opacity-90',
            optionSelectedDisabled: 'text-sky-blue-100 bg-sky-blue-500 bg-opacity-50 cursor-not-allowed',
            noOptions: 'py-2 px-3 text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-500 text-left',
            noResults: 'py-2 px-3 text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-500 text-left',
            fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
            spacer: 'h-9 py-px box-content',
        }

        return {
            handleOnChange,
            currentValue,
            currentBreed,
            breeds,
            multiSelectClasses
        }
    }
})
</script>
