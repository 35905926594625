<template>
    <div class="flex flex-col justify-center spacing h-full flex overflow-hidden bg-sky-blue-400 p-4">
        <img style="margin-left: auto; margin-right: auto;" alt="loading Image of a dog" :src="logo">
        <div class="text-center text-xl mt-2">There has been a problem connecting...</div>
        <img class="w-80" style="margin-left: auto; margin-right: auto;" alt="loading Image of a dog" :src="failDog">
        <Button variety="plain" @click="tryAgain" class="mt-4 w-64 ml-auto mr-auto"> Retry</Button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter} from "vue-router";
import LoadingSpinner from "./components/LoadingSpinner.vue";
import logo from "./assets/logo-coloured-bg.png";
import failDog from "./assets/gone-wrong.png";

export default defineComponent({
    name: 'App',
    components: {
        LoadingSpinner,
    },
    setup(){
        const router = useRouter();
        let tryAgain = () => {
            location.href = "/";
        }
        return {
            tryAgain,
            logo,
            failDog
        }
    }
})
</script>

<style>
</style>
<style src="@vueform/multiselect/themes/default.css"></style>

