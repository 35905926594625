import { ContactType } from "../types/ContactType";

/**
 * Given a contact type, retrieves a human readable name.
 * 
 * @param type The type to retrieve a human readable name from.
 */
export function humanReadableNameFromContactType(type: ContactType) {
    switch (type) {
        case ContactType.STANDARD:
            return "Standard";
        case ContactType.EMERGENCY:
            return "Emergency";
        case ContactType.ALTERNATIVE_PICKUP:
            return "Alternative Pickup";
    }
}
