import { defineStore } from "pinia";
import DashboardMetrics from "./types/DashboardMetrics";
import DashboardApiClient from "../../api/DashboardApiClient";
import {PermissionGroup} from "../user/enums/PermissionGroup";
import FormattingUtils from "../../utils/FormattingUtils";

const useDashboardStore = defineStore({
    id: "dashboard",
    state: () => ({
        dashboardMetricsLoading: true,
        dashboardMetrics: undefined as DashboardMetrics | undefined,
    }),
    actions: {
        async refreshDashboardMetrics() {
            try {
                this.dashboardMetricsLoading = true;
                const response = await DashboardApiClient.getDashboardMetrics();
                if (response.success) {
                    this.dashboardMetrics = response.data!;
                }
            } finally {
                this.dashboardMetricsLoading = false;
            }
        }
    },
    getters: {
        actualMonthlyIncome: (state) => (): string => {
            return FormattingUtils.toCurrencyString(state?.dashboardMetrics?.actual_monthly_income as unknown as number, true);
        },
        estimatedMonthlyIncome: (state) => (): string => {
            return FormattingUtils.toCurrencyString(state?.dashboardMetrics?.estimated_monthly_income as unknown as number, true);
        },
        scheduledWalks: (state) => (): number => {
            return state?.dashboardMetrics?.scheduled_walks_for_month as unknown as number ?? 0;
        },
        invoicesOutstanding: (state) => (): number => {
            return state?.dashboardMetrics?.invoices_outstanding as unknown as number ?? 0;
        }
    }
});

export default useDashboardStore;
