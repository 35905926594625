<template>
    <label class="block font-medium text-gray-700 dark:text-gray-100">
        {{ label }}{{ required && isEditing ? "*" : "" }}
    </label>
    <div v-if="isEditing" class="mt-1 sm:mt-0 sm:col-span-2">
        <Money3Component
            v-model.number="internalValue"
            :name="label"
            :disabled="disabled"
            :precision="precision"
            :max="maxValue"
            class="max-w-lg block w-full shadow-sm focus:ring-sky-blue-500 focus:border-sky-blue-500 sm:max-w-xs border-gray-300 dark:border-gray-800 rounded-md text-sm dark:text-gray-100 dark:bg-gray-500"
        />
        <span class="text-red-600 text-xs">{{errors}}</span>
    </div>
    <label v-else-if="displayValue || value" class="block font-medium text-gray-700 dark:text-gray-100">
        {{ displayValue ?? MoneyUtils.getDisplayValue(value) }}
    </label>
    <label v-else class="block font-normal text-gray-700 dark:text-gray-100">
        Not Specified
    </label>
</template>


<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
// @ts-ignore
import { Money3Component } from "v-money3";
import MoneyUtils from "../../utils/MoneyUtils";

export default defineComponent({
    name: "MoneyInput",
    components: { Money3Component },
    props:{
        label:{
            required: false,
            type: String,
        },

        value:{
            required: false,
            type: Number,
        },

        errors:{
            required: false,
            type: String,
        },

        isEditing: {
            required: false,
            type: Boolean,
            default: false,
        },

        required: {
            required: false,
            type: Boolean,
            default: false
        },

        disabled: {
            required: false,
            type: Boolean,
            default: false
        },

        displayValue: {
            required: false,
            type: [String, Number]
        },

        precision: {
            type: Number,
            required: false,
            default: 2
        },
        maxValue: {
            type: Number,
            required: false,
            default: Number.MAX_SAFE_INTEGER
        }
    },
    emits:[
        'update:value'
    ],
    setup(props,context){
        const internalValue = computed({
            get: () => {
                return props.value;
            },
            set: (newValue) => {
                context.emit('update:value', newValue);
            }
        });

        return {
            internalValue,
            MoneyUtils
        }
    }
})
</script>
