import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import _ from "lodash";

interface IMediaQueryConfig {
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number,
    xxl: number,
}

interface Modifiers {
    '>': string,
    '>=': string,
    '<': string,
    '<=': string,
}

interface IMediaQueryConfigCollection {
    [configName: string] : IMediaQueryConfig
}

const configs = {
    tailwind: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1536,
    }
} as IMediaQueryConfigCollection;

export const useMediaQuery = (configName: string) => {
    const current =  ref(0)
    const currentSize = ref("md");

    const config = _.findKey(configs, () => configName) ? configs[configName] as object : null;
    if(!config) {
        throw new Error(`useMediaQuery: config ${configName} not found. Options are 'tailwind'`);
    }

    watch(current, (c) => {
        const values = _.values(config);
        for(let i = 0; i < values.length - 1; i++) {
            if(current.value > Number(values[i]) && current.value < Number(values[i+1])) {
                currentSize.value = _.keys(config)[_.indexOf(values, values[i])];
                return;
            }
        }
    })

    // initialize
    current.value = window.innerWidth;

    const onResize = (ev: UIEvent) => {
        current.value = (ev.target as Window).innerWidth;
    }

    const mediaQuery = (query: keyof IMediaQueryConfig, modifier?: keyof Modifiers) => {
        if(modifier) {
            const idxOfTgt = _.keys(config).indexOf(query);
            const idxOfCurr = _.keys(config).indexOf(currentSize.value);
            if(modifier === '<=') {
                return idxOfCurr <= idxOfTgt;
            } else if(modifier === '<') {
                return idxOfCurr < idxOfTgt;
            } else if(modifier === '>=') {
                return idxOfCurr >= idxOfTgt;
            } else if(modifier === '>') {
                return idxOfCurr < idxOfTgt;
            }
        } else {
            return query === currentSize.value;
        }
    }

    // Helpers
    const smLessThanOrEq = computed( () => {
        return mediaQuery("sm", "<=");
    });

    const mdGrThanOrEq = computed( () => {
        return mediaQuery("md", ">=");
    });

    onMounted(() => {
        window.addEventListener('resize', onResize);
    })

    onBeforeUnmount(() => {
        window.removeEventListener('resize', onResize)
    })

    return {
        currentSize,
        mediaQuery,
        mediaFlags: reactive({
            sm: {
                grThanOrEq: computed( () => { return mediaQuery("sm", ">="); }),
                lessThanOrEq: computed( () => { return mediaQuery("sm", "<="); }),
                eq: computed( () => { return mediaQuery("sm"); }),
            },
            md: {
                grThanOrEq: computed( () => { return mediaQuery("md", ">="); }),
                lessThanOrEq: computed( () => { return mediaQuery("md", "<="); }),
                eq: computed( () => { return mediaQuery("md"); }),
            }
        })
    };
}

export default {
    useMediaQuery
}
