<template>
    <div aria-live="assertive" class="fixed notification-z inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
            <Notification v-for="notification in notifications"
                          :type="notification.type"
                          :title="notification.title"
                          :body="notification.body"
                          :debug="notification.debug"

            />
        </div>
    </div>
</template>

<script>
import {inject, ref} from 'vue'
import {CheckCircleIcon, ChipIcon, ExclamationIcon, ShieldCheckIcon, XCircleIcon} from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/solid'
import NotificationType from "../types/NotificationType";
import Notification from "./Notification.vue";

export default {
    components: {
        Notification,
        CheckCircleIcon,
        ChipIcon,
        ExclamationIcon,
        ShieldCheckIcon,
        XCircleIcon,
        XIcon,
    },
    props:{
    },
    setup() {
        const notifications = ref([])

        const emitter = inject("emitter");

        emitter.on('show-notification', event => {
            notifications.value.push({
                type: event.type,
                title: event.title,
                body: event.body,
                debug: event.debug
            })
        })

        return {
            notifications,
            NotificationType
        }
    },
}
</script>
