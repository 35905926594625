<template>
    <p class="mb-5 text-gray-400">
        If you wish to expand on your feature request in more detail, and perhaps upload images to better explain it - please e-mail
        <a class="text-blue-500" title="E-mail WalkBuddy" href="mailto:contact@walkbuddy.net">contact@walkbuddy.net</a>
    </p>
    <Form @submit="onSubmit">
            <div class="grid grid-cols-3 gap-4">
                <div class="col-span-3 divide divide-y">
                    <FormComponent :hasTopPadding="false">
                        <TextInput
                            id="feature-title"
                            label="Title"
                            placeholder="Feature Title"
                            :isEditing="true"
                            :value="title"
                            :errors="titleError"
                            :required="true"
                            @update:value="v => (title = v)"
                        />
                    </FormComponent>
                    <FormComponent :hasTopPadding="true">
                        <TextInput
                            id="feature-description"
                            label="Description"
                            placeholder="Feature description"
                            :isEditing="true"
                            :value="description"
                            :errors="descriptionError"
                            :required="true"
                            @update:value="v => (description = v)"
                        />
                    </FormComponent>
                </div>

            </div>

            <template #buttons>
                <Button
                    variety="plain"
                    @click.stop="$emit('on-cancel')"
                >
                    Close
                </Button>
                <Button
                    variety="primary"
                    @click.stop="onSubmit"
                >
                    Request
                </Button>
            </template>
        </Form>
</template>

<script lang="ts">
import {defineComponent, inject, onErrorCaptured} from "vue";
import * as yup from "yup";
import {useField, useForm} from "vee-validate";

export default defineComponent({
    name: "FeatureSubmitForm",
    setup(props, context) {
        const schema = yup.object({
            title: yup.string().required("A title for the feature is required").max(255),
            description: yup.string().required("Please enter a description of the feature you are requesting")
        });

        const { handleSubmit } = useForm({
            validationSchema: schema,
        })

        const { value: title, errorMessage: titleError } = useField("title");
        const { value: description, errorMessage: descriptionError } = useField("description");

        const onSubmit = handleSubmit(values => {
            context.emit("on-submit", values);
        });

        return {
            title,
            titleError,

            description,
            descriptionError,

            onSubmit
        }

    }
})
</script>

<style scoped>

</style>
