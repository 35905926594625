<template>
    <SendInvoiceModal
        v-if="statusChangeAttempt != null"
        :customer-name="invoice.customer.name"
        :customer-email="invoice.customer.email"
        :status-change-attempt="statusChangeAttempt"
        @cancel="statusChangeAttempt = null"
        @status-selected="statusSelected"
    />
    <div v-if="invoice.status === InvoiceStatus.DRAFT" class="flex gap-2">
        <Button
            :disabled="isEditingAnything"
            variety="primary"
            @click.stop="statusChangeAttempt = InvoiceStatus.SENT"
        >
            Send
        </Button>

        <Button
            :disabled="isEditingAnything"
            variety="plain"
            @click.stop="statusChangeAttempt = InvoiceStatus.MANUALLY_SENT"
        >
            Manually Send
        </Button>

        <Button
            :disabled="isEditingAnything"
            variety="plain"
            @click.stop="statusSelected(InvoiceStatus.CANCELLED)"
        >
            Cancel
        </Button>
    </div>

    <div v-else-if="invoice.status === InvoiceStatus.SENT || invoice.status === InvoiceStatus.MANUALLY_SENT" class="flex gap-2">
        <Button
            :disabled="isEditingAnything"
            variety="primary"
            @click.stop="statusSelected(InvoiceStatus.PAID)"
        >
            Paid
        </Button>

        <Button
            :disabled="isEditingAnything"
            variety="plain"
            @click.stop="statusSelected(InvoiceStatus.DRAFT)"
        >
            Draft
        </Button>

        <Button
            :disabled="isEditingAnything"
            variety="plain"
            @click.stop="statusSelected(InvoiceStatus.CANCELLED)"
        >
            Cancelled
        </Button>
    </div>

    <div v-else-if="invoice.status === InvoiceStatus.CANCELLED">
        <Button
            :disabled="isEditingAnything"
            variety="primary"
            @click.stop="statusSelected(InvoiceStatus.DRAFT)"
        >
            Draft
        </Button>
    </div>

    <div v-else-if="invoice.status === InvoiceStatus.PAID">
        <Button
            :disabled="isEditingAnything"
            variety="plain"
            @click.stop="statusSelected(InvoiceStatus.CANCELLED)"
        >
            Cancelled
        </Button>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from 'vue'
import Invoice from '../types/Invoice'
import InvoiceStatus from '../types/InvoiceStatus'
import SendInvoiceModal from "../components/SendInvoiceModal.vue";

export default defineComponent({
    components: {
        SendInvoiceModal,
    },
    props: {
        invoice: {
            type: Object as PropType<Invoice>,
            required: true
        },
        isEditingAnything: {
            type: Boolean,
            required: true
        }
    },
    setup(props, context) {
        let statusChangeAttempt = ref<InvoiceStatus | null>();

        function statusSelected(status: InvoiceStatus) {
            statusChangeAttempt.value = null;
            context.emit("status-selected", status);
        }

        return {
            statusSelected,
            statusChangeAttempt,
            InvoiceStatus
        }
    },
})
</script>
