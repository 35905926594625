<template>
    <div class="col-span-6 mt-3 pr-2">
        <label class="block text-2xl text-gray-700 dark:text-gray-50 pb-4">
            {{ title }}
            <div class="text-base text-red-800" v-if="!isDeletable">Note: some lines have been invoiced and cannot be changed/removed.</div>
            <div class="text-base text-gray-500 dark:text-gray-100 mt-2"><p>
              Note: prices are automatically calculated based on the choice of Walk type and any Surcharges selected.<br />
              To set a manual price, edit the "Cost" value before saving.
            </p></div>
        </label>
        <div v-if="lines && lines.length && !mediaFlags.sm.lessThanOrEq"
             class="grid font-bold text-gray-600 dark:text-gray-400 mt-2"
             :class="hasVAT? 'grid-cols-10' : 'grid-cols-8'"
        >
            <div class="col-span-3 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-800 border-2 border-b-0">Walk Type</div>
            <div class="col-span-3 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-800 border-2 border-b-0 border-l-0">Dog</div>
            <div class="col-span-1 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-800 border-2 border-b-0 border-l-0">Cost</div>
            <div class="col-span-1 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-800 border-2 border-b-0 border-l-0" v-if="hasVAT">VAT</div>
            <div class="col-span-1 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-800 border-2 border-b-0 border-l-0" v-if="hasVAT">Price</div>
        </div>
        <ScheduleBlockLineEditor
            v-for="(line, i) in lines"
            :key="line"
            :line="line"
            :index="i"
            :errors="errors"
            :is-editing="!line.invoice && isEditing"
            :has-vat="hasVAT"
            :allow-add="i === (lines.length - 1)"
            :allow-remove="(!line.invoice && (i !== 0 || lines.length > 1))"
            :dog-list="customerDogs"
            :surcharges-list="surchargeList"
            @on-remove-line="handleRemoveLine(i)"
            @on-add-line="handleAddLine()"
            @on-dog-select="handleDogSelect"
        />
        <div class="text-red-500">
            {{ errors[`lines`] }}
        </div>
        <div :class="[isEditing? 'p-0' : 'p-1', !mediaFlags.sm.lessThanOrEq? 'col-span-1 flex pl-1' : 'pt-2 flex']" v-if="isEditing">

            <Button v-if="isEditing" @click.prevent="handleAddLine()" :class="['mt-2', !mediaFlags.sm.lessThanOrEq? '' : 'mt-5 flex-grow']" variety="primary">
                Add
            </Button>
        </div>
    </div>
</template>
<script lang="ts">
import {computed, defineComponent, PropType} from "vue";
import ScheduleBlockLineEditor from "./ScheduleBlockLineEditor.vue";
import {ScheduleBlockLine} from "../types/ScheduleBlockLine";
import useBusinessStore from "../../business/useBusinessStore";
import CustomerDog from "../../customer/types/CustomerDog";
import useCustomerStore from "../../customer/useCustomerStore";
import {useMediaQuery} from "../../core/useMediaQuery";
import AddIcon from "../../../components/icons/AddIcon.vue";
import Button from "../../../components/forms/Button.vue";
import CustomerDogStatus from "../../customer/types/CustomerDogStatus";

export default defineComponent({
    components: {
        ScheduleBlockLineEditor,
        AddIcon,
        Button
    },
    props:{
        title: {
            type: String,
            required: true
        },
        lines: {
            type: Object as PropType<ScheduleBlockLine[]>,
            required: true,
        },
        errors:{
            required: false,
            type: Object,
        },
        isEditing: {
            type: Boolean,
            required: false,
            default: false
        },
        duration: {
            type: Number,
            required: true
        },
        isDeletable:{
            required: true,
            type: Boolean
        }
    },
    setup: function (props) {

        const customerStore = useCustomerStore();
        const buisnessStore = useBusinessStore();
        const { mediaFlags } = useMediaQuery("tailwind");
        let customerDogs : CustomerDog[] = [];

        const businessStore = useBusinessStore();
        const hasVAT = computed(() => businessStore.activeBusiness.vat_number?.length > 0);

        const surchargeList = computed(() => businessStore.surcharges);

        function buildCustomerDogsList(){
            customerDogs = customerStore.availableDogs.map(function(x) {
                x.disabled = !!(props.lines.find(y => y.dog?.id == x.id)) || x.status == CustomerDogStatus.STATUS_DISABLED;
                return x;
            });
        }

        function handleRemoveLine(index: number) {
            props.lines.splice(index, 1);
            buildCustomerDogsList();
        }

        function handleAddLine() {
            let newLine = {} as ScheduleBlockLine;
            if(props.lines.length>0){
                newLine.walk_category_id = props.lines[(props.lines.length-1)]?.walk_category_id
            }
            props.lines.push(newLine)
            buildCustomerDogsList();
        }

        function handleDogSelect(oldDogId: number|null, newDogId: number|null) {
            if(oldDogId){
                let oldDog = customerDogs.find(x => x.id === oldDogId);
                if(oldDog){
                    oldDog.disabled = false;
                }
            }
            if(newDogId){
                let newDog = customerDogs.find(x => x.id === newDogId);
                if(newDog){
                    newDog.disabled = true;
                }
            }
        }

        buildCustomerDogsList();

        return {
            customerDogs,
            surchargeList,
            handleRemoveLine,
            handleAddLine,
            handleDogSelect,
            hasVAT,
            mediaFlags
        }
    },
})
</script>
