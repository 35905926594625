<template>
    <button
        class="inline-flex items-center border dark:border-gray-600 rounded-md shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 justify-center"
        :class="{
            'px-4 py-2' : !isSmallButton,
            'px-2 py-1' : isSmallButton,
             // Primary
            'text-white dark:text-black bg-sky-blue-500 hover:bg-sky-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-blue-500': variety === 'primary' && !disabled,
            'text-gray-400 bg-gray-100 cursor-not-allowed': variety === 'primary' && disabled,
            // Secondary
            'text-sky-blue-500 hover:text-white border border-gray-200 bg-transparent hover:bg-sky-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-blue-500': variety === 'secondary' && !disabled,
            'text-gray-400 bg-gray-100 cursor-not-allowed': variety === 'secondary' && disabled,
            // Warning
            'text-white dark:text-white border-0 bg-sky-red-500 hover:bg-sky-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-red-500': variety === 'warning' && !disabled,
            'text-gray-400 bg-gray-100 cursor-not-allowed': variety === 'warning' && disabled,
            // Confirm
            'text-white dark:text-white border-0 bg-sky-green-500 hover:bg-sky-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-green-500': variety === 'confirm' && !disabled,
            'text-gray-400 bg-gray-100 cursor-not-allowed': variety === 'confirm' && disabled,
        }"
        :disabled="disabled"
    >
        <slot></slot>
    </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    props: {
        type: {
            type: String,
            required: false,
            default: 'button'
        },
        variety: {
            type: String as PropType<'primary' | 'secondary' | 'warning' | 'confirm'>,
            required: false,
            default: 'primary'
        },
        disabled: {
            type: Boolean,
            required: false
        },
        isSmallButton: {
            type:Boolean,
            required: false,
            default: false
        }
    },
    setup(props, context) {
        return {
            context,
            variety: props.variety
        }
    },
})
</script>
