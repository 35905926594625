<template>
    <div class="flex flex-row gap-4 items-center">
        <DateTimePicker
            label="From Date"
            mode="date"
            :isEditing="true"
            :value="fromDate"
            @update:value="$emit('fromDateChanged', $event)"
        />

        <DateTimePicker
            label="To Date"
            mode="date"
            :isEditing="true"
            :value="toDate"
            @update:value="$emit('toDateChanged', $event)"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        toDate: {
            type: String,
            required: true,
        },
        fromDate: {
            type: String,
            required: true
        }
    },
    setup() {

    },
})
</script>
