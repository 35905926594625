import moment from "moment";
import Invoice from "../types/Invoice";
import SlimlineInvoice from "../types/SlimlineInvoice";

function deApify(invoice: Invoice): Invoice {
    if (!invoice?.lines) {
        return invoice;
    }

    if (invoice.total_cost !== 0) {
        invoice.total_cost = invoice.total_cost / 100;
    }

    if (invoice.total_vat !== 0) {
        invoice.total_vat = invoice.total_vat / 100;
    }

    if (invoice.total_price !== 0) {
        invoice.total_price = invoice.total_price / 100;
    }

    invoice.lines.forEach(line => {
        line.line_cost = Number((line.line_cost / 100).toFixed(2));
        line.line_price = Number((line.line_price! / 100).toFixed(2));

        if (line.line_vat !== 0) {
            line.line_vat = Number((line.line_vat / 100).toFixed(2));
        }
    });

    return invoice;
}

function apify(invoice: Invoice): Invoice {
    if (!invoice?.lines) {
        return invoice;
    }

    invoice.lines.forEach(line => {
        line.line_cost = line.line_cost * 100;
        line.line_vat = line.line_vat * 100;
        delete line["line_price"];
    });
    return invoice;
}

function prettyPrintInvoiceDate(date: string): string | undefined {
    if (!date) {
        return undefined;
    }
    return moment(date).format("DD/MM/yyyy");
}

function prettyPrintInvoiceDateWithTime(date: string): string | undefined {
    if (!date) {
        return undefined;
    }

    return moment(date).format("DD/MM/yyyy HH:mm");
}

function prettyPrintInvoiceDateWithTimeAndDay(date: string): string | undefined {
    if (!date) {
        return undefined;
    }

    return moment(date).format("ddd DD/MM/yyyy HH:mm");
}

function prettyPrintInvoicePrice(invoice: Invoice): string {
    return invoice.total_price ? invoice.total_price.toFixed(2) : "0.00";
}

function prettyPrintSlimlineInvoicePrice(invoice: SlimlineInvoice): string {
    return invoice?.total_price ? (invoice.total_price / 100).toFixed(2) : "0.00";
}

export default {
    deApify,
    apify,
    prettyPrintInvoiceDate,
    prettyPrintInvoicePrice,
    prettyPrintSlimlineInvoicePrice,
    prettyPrintInvoiceDateWithTime,
    prettyPrintInvoiceDateWithTimeAndDay
};
