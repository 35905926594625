const preferenceTypes = {
    SHOW_CALENDAR_LIST_VIEW: "showcalendarlistview",
    SHOW_CALENDAR_OOO: "showcalendarooo",
    SHOW_CALENDAR_CANCELLED: "showcalendarcancelled",
}

export {
    preferenceTypes,
}


