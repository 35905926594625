<template>
    <Modal :visible="showConfirmationModal" size="small" title="Confirm Subscription Changes">
        <template #buttons>
            <div class="flex flex-row gap-2">
                <Button variety="warning" @click="cancelSubscription">Cancel Subscription</Button>
                <Button variety="primary" @click="showConfirmationModal = false">Continue Subscription</Button>
            </div>
        </template>
        <div class="flex flex-col">
            <span class="font-bold">Are you sure you wish to cancel your subscription?</span>
            <span class="text-sm mt-2">Note: your subscription will become inactive at the end of the current billing period, you will
            still be able to access premium features until this time.</span>
        </div>
    </Modal>

    <Card title="Subscription">
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
            </svg>
        </template>

        <template #buttons>
            <Button
                variety="warning"
                v-if="subscription.is_subscribed && !subscription.is_on_grace_period"
                @click="showConfirmationModal = true"
            >
                Cancel
            </Button>
            <Button
                variety="primary"
                v-if="subscription.is_subscribed && !subscription.is_on_grace_period"
                @click="goToBillingPortal"
            >
                Billing Portal
            </Button>
            <Button
                variety="primary"
                v-if="subscription.is_subscribed && subscription.is_on_grace_period"
                @click="resumeSubscription"
            >
                Resume
            </Button>
            <Button
                variety="primary"
                v-if="!subscription.is_subscribed"
                @click="goToSubscriptionPage"
            >
                Subscribe
            </Button>
        </template>
        <div class="flex flex-col gap-4 dark:text-gray-100">
            <div class="grid grid-cols-3 gap-4">
                <span class="">Status: </span>
                <span class="text-green-500" v-if="subscription.is_subscribed && !subscription.is_on_grace_period">Active</span>
                <span class="text-orange-500" v-if="subscription.is_subscribed && subscription.is_on_grace_period">Ending Soon</span>
                <span class="text-red-500" v-if="!subscription.is_subscribed">Inactive</span>
            </div>
            <div v-if="subscription.is_subscribed || subscription.is_on_grace_period" class="grid grid-cols-3 gap-4">
                <span class="">Active Since: </span>
                <span class="font-bold">{{DateTime.fromISO(subscription.created_at).toLocaleString()}}</span>
            </div>
            <div v-if="!subscription.is_subscribed && subscription.ends_at" class="grid grid-cols-3 gap-4">
                <span class="">End Date: </span>
                <span class="font-bold">{{DateTime.fromISO(subscription.ends_at).toLocaleString()}}</span>
            </div>
        </div>
    </Card>


</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import {DateTime} from "luxon";
import Button from "../../../components/forms/Button.vue";
import Modal from "../../../components/Modal.vue";
import router from "../../../router";

export default defineComponent({
    name: "SubscriptionsCard",
    components: {
        Modal,
        Button
    },
    props:{
      subscription :{
          type: Object,

      }
    },
    emits:[
        'onCancel',
        'onResume'
    ],
    setup(props, context){

        const showConfirmationModal = ref(false);

        const cancelSubscription = () => {
            context.emit('onCancel');
        }

        const resumeSubscription = () => {
            context.emit('onResume');
        }

        const goToSubscriptionPage = async () => {
            await router.push('/business/subscribe');
        }

        const goToBillingPortal = async () => {
            window.location.href = props.subscription.billing_portal_url
        }

        return {
            DateTime,
            showConfirmationModal,
            cancelSubscription,
            resumeSubscription,
            goToSubscriptionPage,
            goToBillingPortal
        }
    }
})
</script>

<style scoped>

</style>
