<template>
    <Card 
        v-if="pdfUrl"
        title="Invoice PDF"
    >
        <template #icon>
            <DownloadIcon />
        </template>

        <template #buttons>
            <Button
                v-if="pdfUrl"
                variety="primary"
                @click.stop="invoicePdfOpened"
            >
                Download
            </Button>
        </template>

        <object :data="pdfUrl" type="application/pdf" width="100%" height="100%">
            You do not have a PDF plugin for this browser so we cannot display a preview.
        </object>
    </Card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DownloadIcon from "../../../components/icons/DownloadIcon.vue";

export default defineComponent({
    components: {
        DownloadIcon
    },
    props: {
        pdfUrl: {
            type: String,
            required: true
        }
    },
    setup(props) {
        function invoicePdfOpened() {
            window.open(props.pdfUrl, "_blank");
        }

        return {
            invoicePdfOpened
        }
    },
})
</script>
