<template>
    <Form @submit="handleSubmit">
        <div>
            <FormComponent>
                <TextInput
                    type="email"
                    id="user-email"
                    label="Email"
                    placeholder="joe@bloggs.com"
                    :required="true"
                    :isEditing="isEditing"
                    :value="email"
                    :errors="emailError"
                    @update:value="(value) => (email = value)"
                />
            </FormComponent>

            <FormComponent v-if="isEditing">
                <TextInput
                    type="password"
                    id="user-new-password"
                    label="New Password"
                    :required="true"
                    :isEditing="isEditing"
                    :value="password"
                    :errors="passwordError"
                    @update:value="(value) => (password = value)"
                />
            </FormComponent>
        </div>
        <div class="pt-4">
            <span class="text-gray-500" v-if="isEditing">For security reasons you must confirm your <em>CURRENT</em> password before updating any security details.</span>
            <FormComponent>
                <TextInput
                    type="password"
                    id="user-current-password"
                    :label="isEditing ? 'Current Password' : 'Password'"
                    displayValue="***************"
                    :required="true"
                    :isEditing="isEditing"
                    :value="currentPassword"
                    :errors="currentPasswordError"
                    @update:value="(value) => (currentPassword = value)"
                />
            </FormComponent>
        </div>
    </Form>
</template>

<script lang="ts">
import {defineComponent, PropType, watch} from "vue";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import User from "../types/User";

export default defineComponent({
    props: {
        isEditing: {
            type: Boolean,
            required: true
        },
        user: {
            type: Object as PropType<User>,
            required: true
        }
    },
    setup(props, context) {

        const schema = yup.object({
            email: yup.string().required("Email is required.").email("Email needs to be a valid email.").max(255),
            current_password: yup.string().when("is_passwordless", (is_passwordless) => {
                if(!props.user.is_passwordless)
                    return yup.string().required("Your current password is required to update security details.").max(255)
            }),
            new_password: yup.string().required("Your new password is required to update security details.").min(8, "Your new password needs to be a minimum of 8 characters.").max(255)
        });

        const {handleSubmit, resetForm} = useForm({
            validationSchema: schema,
        });

        const onSubmit = handleSubmit(values => {
            context.emit('onSubmit', values);
        });

        const { value: email, errorMessage: emailError } = useField("email");
        const { value: currentPassword, errorMessage: currentPasswordError } = useField("current_password");
        const { value: password, errorMessage: passwordError } = useField("new_password");

        watch(props, () => {
            resetForm();
            email.value = props.user.email;
        },
        {
            deep: true
        })

        email.value = props.user.email;
        password.value = undefined;
        currentPassword.value = props.user.is_passwordless ? "" : undefined;

        return {
            handleSubmit,
            onSubmit,

            email,
            emailError,

            currentPassword,
            currentPasswordError,

            password,
            passwordError
        };
    } 
});
</script>
