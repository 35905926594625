import {DateTime, Interval} from "luxon";
import {computed, ref} from "vue";
import SchedulerUtils from "../utils/scheduler-utils";
import EmptyScheduleBlockSpace from "../types/EmptyScheduleBlockSpace";

export const useSchedulerInfo = (props: any, businessInfo: any) => {

    const startHours = 0;
    const endHours = 24;

    const scheduleDays = computed( () => SchedulerUtils.getScheduleDays(
        props.selectedDate,
        props.days
    ));

    const scheduleTimes = computed( () => SchedulerUtils.getDailyHours(
        startHours,
        endHours,
        30
    ));

    const scheduleBlockEmptySpaces = computed(() => {
        const blockSpaces : EmptyScheduleBlockSpace[] = [];
        scheduleDays.value.forEach((day, dayIndex) => {
            scheduleTimes.value.forEach((time, timeIndex) => {
                blockSpaces.push({
                    day,
                    time,
                    dayIndex: dayIndex + 1,
                    timeIndex: timeIndex + 1,
                    dayTime: SchedulerUtils.getCombinedDayAndTime(day, time),
                    intervalMinutes: props.intervalMinutes
                });
            });
        })
        return blockSpaces;
    });


    return {
        startHours,
        endHours,
        weekdays: SchedulerUtils.getWeekdays(),
        scheduleDays,
        scheduleTimes,
        scheduleBlockEmptySpaces
    }
}

