<template>
    <div class="text-2xl font-extrabold text-gray-900 dark:text-gray-50">
        {{ timeDisplay }}
    </div>
</template>

<script>
import {defineComponent, ref} from "vue";

export default defineComponent({
    name: "Clock",
    setup(){

        const timeDisplay = ref(new Date().toLocaleTimeString());

        setInterval(
            () => timeDisplay.value = new Date().toLocaleTimeString(),
            1000
        );

        return {
            timeDisplay
        }
    }
})
</script>

<style scoped>

</style>
