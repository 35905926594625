import {DateTime, Duration, Info} from "luxon";
import DateRange from "../../../types/DateRange";
import ScheduleBlock from "../types/ScheduleBlock";
import ScheduleBlockStatus from "../types/ScheduleBlockStatus";
import SlimlineScheduleBlock from "../types/SlimlineScheduleBlock";

/**
 * isInThePast - returns ifa DateTime is in the past of current date & time.
 */
function getIsInThePast (dateToCheck : DateTime) : boolean {
    const todaysDate = DateTime.now();
    return dateToCheck < todaysDate;
}

/**
 * isToday - returns if a DateTime is the same day as today
 */
function getIsToday (currentDate : DateTime) : boolean {
    return currentDate.day == DateTime.utc().day && currentDate.month == DateTime.utc().month;
}

/**
 * dayName - returns if a DateTime's day name
 */
function getDayName (date : DateTime) : string {
    return date.toFormat("cccc");
}

/**
 * dayNumber - returns if a DateTime's day number
 */
function getDayNumber (date : DateTime) : string {
    return date.toFormat("d");
}

/**
 * getWeekdays
 */
function getWeekdays () : string[] {
    return Info.weekdays();
}

/**
 * getDailyHours - returns a list of durations from and to the start and end hours in a 24hr period
 * each item is a DateTime object in UTC
 * @param start in hours
 * @param end in hours
 * @param interval in minutes
 */
function getDailyHours (start = 0, end = 24, interval = 30) : Duration[] {
    let totalMinutes = (end - start) * 60;
    let items : Duration[] = [];

    let durationCounter = Duration.fromObject( {hours: start} );
    for(let min = 0; min < totalMinutes; min += interval) {
        items.push(durationCounter.plus({hours: Math.floor(min / 60), minutes: min % 60}))
    }

    return items;
}

/**
 * Takes the date from the Day, and the Time from the Time. Returns a new DateTime object in UTC
 * @param day
 * @param time
 */
function getCombinedDayAndTime(day: DateTime, time: Duration ) : DateTime {
    return DateTime.fromObject({year: day.year, month: day.month, day: day.day } ).plus(time);
}

/**
 * getScheduleDays returns a list of DateTimes equal to the provided days value
 * Will attempt to return DateTimes from the start of the selected week
 * If the number of days requested does not allow for this, then DateTimes working backwards from the selectedDate will be returned
 * @param selectedDate
 * @param days
 */
function getScheduleDays (
    selectedDate: any,
    days: number
) : DateTime[] {
    let currDate = getScheduleStartDate(selectedDate, days);

    let scheduleDays = [] as DateTime[];

    for(let i = 0; i < days; i++) {
        scheduleDays.push(currDate)
        currDate = currDate.plus({days: 1});
    }
    return scheduleDays;
}

/**
 * getDaySuffix returns a unique Id that can be tied to the day/min/hour of a DateTime (used for Id's in the DOM usually)
 * @param day
 */
function getDaySuffix(day: number) : string {
    const th = 'th'
    const rd = 'rd'
    const nd = 'nd'
    const st = 'st'

    if (day === 11 || day === 12 || day === 13) return th

    let lastDigit = day.toString().slice(-1)

    switch (lastDigit) {
        case '1': return st
        case '2': return nd
        case '3': return rd
        default:  return th
    }
}

/**
 * getDateTimeId returns a unique Id that can be tied to the day/min/hour of a DateTime (used for Id's in the DOM usually)
 *
 * @param date
 */
function getDateTimeId(date : DateTime) : string {
    return `${date.day}-${date.hour}-${date.minute}`;
}

/**
 * getIsWithinWorkingHours
 *
 * @param dateTime
 * @param workingHourStart
 * @param workingHourEnd
 */
let getIsWithinWorkingHours = (duration : Duration, workingHourStart : number, workingHourEnd : number) : boolean => {
    return duration.hours >= workingHourStart && duration.hours < workingHourEnd;
}

/**
 * getBlockIndexFromTime
 *
 * @param dateTime
 * @param workingHourStart
 * @param workingHourEnd
 */
let getBlockIndexFromTime = (dateTime : DateTime, interval: number, workingHourStart : number, workingHourEnd : number) : number => {
    const offsetStart = (workingHourStart * 60);
    return (dateTime.minute + ((dateTime.hour * 60) - offsetStart)) / interval;
}

/**
 * getScheduleDateRange
 *
 * @param selectedDate
 * @param workingHourStart
 * @param workingHourEnd
 * @param days
 */
let getScheduleDateRange = (selectedDate : any, workingHourStart : number, workingHourEnd : number, days: number) : DateRange => {
    let startDate = getScheduleStartDate(selectedDate, days);
    let endDate = startDate.plus({days: days - 1});

    let dateFrom = startDate.startOf('day').plus(Duration.fromObject({ hours: workingHourStart }));
    let dateTo = workingHourEnd == 24 ?
        endDate.endOf('day') :
        endDate.startOf('day').plus(Duration.fromObject({ hours: workingHourEnd }));

    return {
        date_from: dateFrom.toString(),
        date_to: dateTo.toString(),
    }
}

/**
 * getScheduleStartDate
 *
 * @param selectedDate
 * @param days
 */
let getScheduleStartDate = (selectedDate : any, days: number) : DateTime => {
    let startDate = selectedDate.startOf('week');

    let diffInDays = selectedDate.diff(startDate, 'days');

    if(diffInDays.toObject().days > days - 1) {
        startDate = selectedDate.minus({days: days - 1});
    }

    return startDate.startOf('day');
}

/**
 * getTotalOverlaps
 *
 * @param scheduleBlock
 * @param allBlocks
 */
let getTotalBlockOverlaps = (scheduleBlock : SlimlineScheduleBlock, allBlocks: SlimlineScheduleBlock[]) : any => {
    let overlaps = 0;
    let allOverlaps : SlimlineScheduleBlock[] = [];
    allBlocks.forEach(block => {
        let include = true;
        if(scheduleBlock.id == block.id) include = false;

        let earliestBlock;
        let latestBlock;

        if(block.date_from > scheduleBlock.date_from) {
            earliestBlock = block;
            latestBlock = scheduleBlock;
        } else {
            earliestBlock = scheduleBlock;
            latestBlock = block;
        }

        if(include) {
            // I can't think in dates, so I am going to think in rectangle collision
            const rectALeft = earliestBlock.date_from;
            const rectARight = earliestBlock.date_to;
            const rectBLeft = latestBlock.date_from;
            const rectBRight = latestBlock.date_to;
            if(rectALeft < rectBRight && rectARight > rectBLeft) {
                overlaps++;
                allOverlaps.push(block);
            }
        }
    })

    let overlapIndex = 0;

    if(overlaps > 0) {
        // push the block thats overlapping onto the
        // list of all other overlapping blocks
        allOverlaps.push(scheduleBlock);
        allOverlaps =  allOverlaps.sort((x: SlimlineScheduleBlock, y:SlimlineScheduleBlock) => {
            if(x.date_from > y.date_from) return 1;
            if(x.date_from < y.date_from) return -1;
            if(x.id && y.id && x.id < y.id) return -1;
            return 0;
        });

        //once sorted, find the index, so we know what order this overlap sits in
        overlapIndex = allOverlaps.findIndex((b) => {
            return b.id == scheduleBlock.id;
        })
    }

    return { overlaps, overlapIndex };
}

function getSlimlineBlockLabel(block: SlimlineScheduleBlock) : string {
    if (block.name) {
        return block.name;
    }

    if (block.dogs) {
        return block.dogs;
    }

    return "No Name / No Dogs Assigned";
}

function getBlockLabel(block: ScheduleBlock) : string {
    if (block.name) {
        return block.name;
    }

    if (block.lines && block.lines.length > 0) {
        return block.lines
            .filter(l => l.dog)
            .map(l => l.dog!.name)
            .join(", ");
    }

    return "No Name / No Dogs Assigned";
}

/**
 * This will let you know if a Schedule is in the past and have yet been complete. We use this to show warning notifications to the user.
 * @param block
 * @param intervalMinutes
 */
const getScheduleHasWarning = (block: SlimlineScheduleBlock, intervalMinutes : number): boolean => {
    return (block.status === ScheduleBlockStatus.STATUS_SCHEDULED || block.status === ScheduleBlockStatus.STATUS_IN_PROGRESS)
        && getIsInThePast(block.date_from.plus({ minutes: intervalMinutes }))
}

export default {
    getIsToday,
    getCombinedDayAndTime,
    getScheduleDays,
    getWeekdays,
    getDailyHours,
    getDateTimeId,
    getDayName,
    getDayNumber,
    getIsWithinWorkingHours,
    getBlockIndexFromTime,
    getIsInThePast,
    getScheduleDateRange,
    getScheduleStartDate,
    getDaySuffix,
    getTotalBlockOverlaps,
    getScheduleHasWarning,
    getBlockLabel,
    getSlimlineBlockLabel
}
