<template>
    <table class="table-auto">
        <tr>
            <td v-for="(header, index) in headers" :key="index" class="font-bold">{{header}}</td>
        </tr>
        <tr v-for="(item, index) in items" :key="index">
            <td class="pr-4 pb-2">
                <div class="h-12 mb-2">
                    <TextInput
                        :id="`${idPrefix}_name_${index}`"
                        :label="''"
                        :value="item.name"
                        :errors="errors[`${idPrefix}[${index}].name`]"
                        :isEditing="true"
                        @update:value="v => (item.name = v)" />
                </div>
            </td>
            <td class="pb-2 w-20">
                <div class="h-12 mb-2">
                    <MoneyInput
                        :id="`${idPrefix}_price_per_minute_${index}`"
                        :label="''"
                        :isEditing="true"
                        :value="item.price_per_minute"
                        :errors="errors[`${idPrefix}[${index}].price_per_minute`]"
                        :max-value="10000"
                        @update:value="v => (item.price_per_minute = v)" />
                </div>
            </td>
            <td class="pb-4">
                <div class="ml-2 mb-2">
                    <Button
                        v-if="items.length > 1"
                        variety="warning"
                        :is-small-button="true"
                        @click.stop="onSubmit"
                        @click="removeItem(index)">x</Button>
                </div>
                </td>
        </tr>
        <tr>
            <td colspan="3">
                <div class="">
                    <Button
                        class=""
                        variety="primary"
                        @click.stop="onSubmit"
                        @click="addItem()">Add new {{ namePrefix }} type</Button>
                </div>
            </td>
        </tr>
    </table>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {useForm} from "vee-validate";
import TextInput from "../../../components/forms/TextInput.vue";
import MoneyInput from "../../../components/forms/MoneyInput.vue";
import Button from "../../../components/forms/Button.vue";

export default defineComponent({
    name: "PricesTable",
    components: {
        Button,
        TextInput,
        MoneyInput
    },
    props:{
        idPrefix:{
            required: true,
            type: String
        },
        namePrefix:{
            required: true,
            type: String
        },
        items: {
            required: true,
            type: Array
        },
        errors: {
            required: true,
            type: Object
        }
    },
    emits:[
        'onWalkPricesSubmit'
    ],
    setup(props){
        const headers = ['Type', 'Cost', ''];

        const { handleSubmit } = useForm({
        });

        const onSubmit = handleSubmit(values => {
        });

        const removeItem = function(index: number){
            props.items.splice(index, 1);
        }

        const addItem = function(){
            let newItem = {name: '', price_per_minute: 0.1};
            props.items.push(newItem);
        }

        return {
            headers,
            onSubmit,
            addItem,
            removeItem
        }
    }
})
</script>

<style scoped>
</style>
