<template>
        <div class="whitespace-nowrap">
            <div class="flex items-center">
                <div v-if="userDetails" class="flex-grow flex flex-col w-1/2">
                    <div class="font-medium text-gray-900 dark:text-gray-200 truncate">
                        {{ userDetails.name }}
                    </div>
                    <div class="font-medium text-gray-500 dark:text-gray-400 truncate">
                        {{ userDetails.business.name }}
                    </div>
                </div>
                <div class="flex flex-row gap-4">
                    <router-link to="/settings">
                        <CogIcon class="dark:text-gray-50" />
                    </router-link>
                    <div class="cursor-pointer" @click="logout">
                        <ExitIcon class="dark:text-gray-50" />
                    </div>
                </div>
            </div>
        </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured} from "vue";
import {useRouter} from "vue-router";
import {clearCookies} from "../../../utils/cookies";
import useUserStore from "../useUserStore";
import ExitIcon from "../../../components/icons/ExitIcon.vue";
import CogIcon from "../../../components/icons/CogIcon.vue";

export default defineComponent({
    name: "UserSidebarItem",
    components: { ExitIcon, CogIcon },
    setup () {
        const store = useUserStore();
        const router = useRouter();

        const userDetails = computed(() => store.user)

        const logout = async function(){
            clearCookies();
            await store.userLoggingOut();
            await router.go(0);
        }

        return {
            logout,
            userDetails
        }
    }
})
</script>

<style scoped>

</style>
