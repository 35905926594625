import axios from "axios";
import { ApiAsyncWrapper, ApiResponseWrapper } from "./utils/ApiAsyncWrapper";

function getCountries(): Promise<ApiResponseWrapper<{ code: string, name: string }[]>> {
    return ApiAsyncWrapper(axios.get(`/countries`));
}

export default {
    getCountries
}
