<template>

    <PageTitleContainer>
        <div class="flex flex-grow ">
            <h1 class="text-2xl font-semibold text-gray-900 dark:text-gray-100 flex-1">
                WalkBuddy Subscription
            </h1>
        </div>
    </PageTitleContainer>
    <PageContentContainer>
        <div v-if="!isAlreadySubscribed" class="flex flex-col flex-grow gap-y-4 p-2 md:p-0 bg-white dark:bg-gray-700 rounded shadow dark:text-gray-100">
            <div class="flex flex-col lg:flex-row justify-around">
                <img
                    :src="doggoWithMoney"
                    alt="Picture of a dog eating money"
                    class="m-4 max-h-64 sm:max-h-64 self-center"
                />
                <div class="p-4 self-center">
                    <h2 class="text-xl">Great to hear that you're thinking of subscribing!</h2>
                    <p class="text-lg flex-wrap">To help make your decision, see below for details on what you get with a WalkBuddy subscription:</p>
                    <div class="grid grid-cols-2 gap-8 mt-8">
                        <ProTierCard :is-selected="isSubscribing" @selected="isSubscribing = true" />
                        <FreeTierCard :is-selected="!isSubscribing" @selected="handleReturnHome" />
                    </div>
                </div>
            </div>
            <div v-if="isSubscribing">
                <p class="text-lg text-center flex-wrap px-2">To purchase a new subscription, please enter your payment details in the form below, and click <span class="font-bold">"Confirm Subscription"</span></p>
                <div class="flex-1 p-4 dark:text-gray-100">
                    <StripeElements
                        v-if="stripeLoaded"
                        v-slot="{ elements, instance }"
                        ref="elms"
                        :stripe-key="stripeKey"
                        :instance-options="instanceOptions"
                        :elements-options="elementsOptions"
                    >
                        <StripeElement
                            id="stripe-input"
                            ref="card"
                            :elements="elements"
                            :options="cardOptions"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </StripeElements>
                </div>
                <div class="w-full flex-1 px-4 pb-4">
                    <Button
                        variety="primary"
                        @click="pay"
                        class="mt-4 w-full sm:w-64 ml-auto mr-auto"
                        :disabled="paymentInProgress"
                    >
                        Confirm Subscription
                    </Button>
                </div>
            </div>
        </div>
        <div v-else class="flex flex-col flex-grow gap-y-4 p-2 md:p-0 bg-white rounded shadow">
            <div class="p-4">
                You already have an active subscription, to manage it please visit your <router-link to="/business" class="text-blue-500 pointer">Business Profile</router-link>.
            </div>
        </div>
    </PageContentContainer>
</template>

<script lang="ts">
import { StripeElements, StripeElement } from 'vue-stripe-js'
import {loadStripe, StripeCardElementOptions} from '@stripe/stripe-js'
import {defineComponent, ref, onBeforeMount, computed} from 'vue'
import PageContentContainer from "../../../components/PageContentContainer.vue";
import PageTitleContainer from "../../../components/PageTitleContainer.vue";
import Button from "../../../components/forms/Button.vue";
import SubscriptionApiClient from "../../../api/SubscriptionApiClient";
import useBusinessStore from "../useBusinessStore";
import router from "../../../router";
import emitter from "../../../utils/emitter";
import doggoWithMoney from "../../../assets/doggo-with-money.png";
import ProTierCard from "../components/ProTierCard.vue";
import FreeTierCard from "../components/FreeTierCard.vue";
import {useRouter} from "vue-router";
import useLocalStorageStore from "../../../utils/useLocalStorageStore";

export default defineComponent({
    name: 'Subscription',

    components: {
        Button,
        PageContentContainer,
        PageTitleContainer,
        StripeElements,
        StripeElement,
        ProTierCard,
        FreeTierCard
    },

    setup() {
        const router = useRouter();
        const isAlreadySubscribed = computed(() => businessStore.activeBusiness.subscription.is_subscribed);
        const clientSecret = ref();
        const paymentInProgress = ref(false);
        const localStorageStore = useLocalStorageStore();

        const stripeKey = ref(import.meta.env.VITE_STRIPE_KEY);
        const instanceOptions = ref({
            // https://stripe.com/docs/js/initializing#init_stripe_js-options
        })
        const elementsOptions = ref({
            // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
        })
        const cardOptions = ref<StripeCardElementOptions>({
            style: {
                base: {
                    fontSize: '16px',
                    color: localStorageStore.theme === "dark" ? '#FFF': '#000',
                    fontFamily: 'Lato, Roboto, sans-serif',
                    fontSmoothing: 'antialiased',
                    '::placeholder': {
                        color: '#f00',
                    },
                },
                invalid: {
                    color: '#f00',
                    iconColor: '#f00',
                },
            },
        });
        const stripeLoaded = ref(false)
        const card = ref()
        const elms = ref()
        const isSubscribing = ref(false);

        const handleReturnHome = function() {
            router.push("/dashboard");
        };

        const businessStore = useBusinessStore();

        onBeforeMount(async () => {
            const stripePromise = loadStripe(stripeKey.value)
            stripePromise.then(() => {
                stripeLoaded.value = true
            })

            let intentResult = await SubscriptionApiClient.getPaymentIntent();
            if(intentResult.success){
                clientSecret.value = intentResult.data?.client_secret;
            }
        })

        const pay = () => {
            paymentInProgress.value = true;
            // Get stripe element
            const cardElement = card.value.stripeElement

            // Access instance methods, e.g. createToken()
            elms.value.instance.confirmCardSetup(clientSecret.value, {
                payment_method:{
                    card: cardElement,
                    billing_details:{
                        name: businessStore.activeBusiness.name
                    }
                },
                return_url: `${import.meta.env.VITE_BASE_URL}/subscription/confirm`
            }).then(async (result: object) => {
                // Handle result.error or result.token
                if(result.error){

                    emitter.emit('error-captured', {
                        error: "Your payment card was declined."
                    });
                }
                else{
                    await SubscriptionApiClient.payment(result.setupIntent.payment_method);
                    await businessStore.loadBusiness();
                    await router.push("/business");
                }
                paymentInProgress.value = false;

            })
        }

        return {
            doggoWithMoney,
            isAlreadySubscribed,
            stripeKey,
            stripeLoaded,
            instanceOptions,
            elementsOptions,
            cardOptions,
            card,
            elms,
            pay,
            paymentInProgress,
            isSubscribing,
            handleReturnHome
        }
    },
})
</script>