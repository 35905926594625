<template>
    <!-- Create a contact modal -->
    <Modal
        size="xlarge"
        :title="(isEditing ? contactModelToEdit ? `${contactModelToEdit.name}` : 'Create a Contact' : `${contactModelToEdit?.name}`)"
        :visible="createModalVisible"
    >
        <template #icon>
            <PeopleIcon />
        </template>


        <CustomerContactForm
            :countries="countries"
            :contactModelToEdit="contactModelToEdit"
            :isEditing="isEditing"
            @onSubmit="saveContact"
            ref="customerContactForm"
        />

        <template #buttons>
            <DeleteButtonWithConfirmation
                v-if="!isEditing && userHasManageCustomerPermission"
                :message="`Are you sure you wish to delete the contact '${contactModelToEdit?.name}'? This action cannot be undone.`"
                @onDelete="contactDeleted"
            />
            <Button
                v-if="!isEditing && userHasManageCustomerPermission"
                variety="primary"
                @click="isEditing = true"
            >
                Edit
            </Button>
        </template>

        <template #footer-buttons>
            <Button v-if="!isEditing" variety="warning" @click="closeModal">
                Close
            </Button>
            <Button v-if="isEditing" variety="warning" @click="closeModal">
                Cancel
            </Button>
            <Button v-if="isEditing" variety="confirm" @click="$refs.customerContactForm.onSubmit()">
                Save
            </Button>
        </template>
    </Modal>

    <!-- Root card -->
    <Card title="Contacts" :isLoading="contactsLoading">
        <template #icon>
            <PeopleIcon />
        </template>

        <InformationList v-if="contacts && contacts.length">
            <InformationListItem
                v-for="contact in contacts"
                :key="contact"
                @click="existingContactClicked(contact.id)"
            >
                <div class="flex-1 min-w-0">
                    <a href="#" class="focus:outline-none">
                        <span class="absolute inset-0" aria-hidden="true"></span>
                        <p class="font-medium text-gray-900 dark:text-gray-100">
                            {{ contact.name }}
                        </p>
                        <p class="text-gray-500 dark:text-gray-100 truncate">
                            {{ humanReadableNameFromContactType(contact.type) }}
                        </p>
                    </a>
                </div>
                <div class="flex flex-1 flex-col items-end text-gray-500 dark:text-gray-100">
                    <div>{{ contact.telephone }}</div>
                    <div>{{ contact.email }}</div>
                </div>
            </InformationListItem>
        </InformationList>

        <div v-else class="text-gray-500">
            No contacts have been created for this customer.
        </div>

        <template #buttons>
            <Button
                v-if="userHasManageCustomerPermission"
                @click="createContactClicked"
                variety="primary"
            >
                New
            </Button>
        </template>
    </Card>
</template>

<script lang="ts">
import {defineComponent, ref, computed} from "vue";
import useCustomerStore from "../useCustomerStore";
import useMiscInformationStore from "../../../utils/useMiscInformationStore";
import CustomerContactForm from "./CustomerContactForm.vue"
import CustomerContact from "../types/CustomerContact";
import { humanReadableNameFromContactType } from "../utils/contact-type-utils";
import CustomerContactApiClient from "../../../api/CustomerContactApiClient";
import PeopleIcon from "../../../components/icons/PeopleIcon.vue";
import useUserStore from "../../user/useUserStore";
import { PermissionGroup } from "../../user/enums/PermissionGroup";

export default defineComponent({
    components: {
        CustomerContactForm,
        PeopleIcon
    },
    setup() {
        const userStore = useUserStore();
        const customerStore = useCustomerStore();
        const miscInformationStore = useMiscInformationStore();

        const createModalVisible = ref(false);
        const isEditing = ref(true);
        const contactsLoading = computed(() => customerStore.contactsLoading);
        const contacts = computed(() => customerStore.contacts);
        const userHasManageCustomerPermission = computed(() => userStore.userHasPermission(PermissionGroup.MANAGE_CUSTOMERS));
        const contactModelToEdit = ref({} as CustomerContact | undefined);

        /**
         * Re-initialises properties when a new contact is to be created.
         */
        function createContactClicked() {
            contactModelToEdit.value = undefined;
            isEditing.value = true;
            createModalVisible.value = true;
        }

        async function existingContactClicked(contactId: number) {
            const contactDetails = await CustomerContactApiClient.getCustomerContact(contactId);
            if (contactDetails.success) {
                contactModelToEdit.value = contactDetails.data!;
                isEditing.value = false;
                createModalVisible.value = true;
            }
        }

        async function saveContact(contact: CustomerContact) {
            try{
                if (contactModelToEdit?.value?.id) {
                    await customerStore.customerContactUpdated(contactModelToEdit.value.id, contact);
                } else {
                    await customerStore.customerContactCreated(contact);
                }

                closeModal();
            }
            catch (error){}
            finally {
                customerStore.contactsLoading = false;
            }
        }

        async function contactDeleted() {
            try {
                await customerStore.customerContactDeleted(contactModelToEdit.value!);

                closeModal();
            }
            catch (error) {}
        }

        function closeModal() {
            createModalVisible.value = false;
        }

        return {
            humanReadableNameFromContactType,

            createModalVisible,
            contactModelToEdit,
            isEditing,
            countries: miscInformationStore.countries,
            contacts,
            contactsLoading,
            userHasManageCustomerPermission,

            closeModal,
            saveContact,
            createContactClicked,
            existingContactClicked,
            contactDeleted,
        }
    },
})
</script>
