<template>
    <Card title="Information">
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
        </template>

        <template #buttons>
            <Button
                v-if="!isEditingBusinessDetails"
                variety="primary"
                :disabled="isEditingBankDetails || isEditingOperatingHours || isEditingScheduleSettings"
                @click="isEditingBusinessDetails = true"
            >
                Edit
            </Button>
            <Button
                v-if="isEditingBusinessDetails"
                variety="plain"
                @click="isEditingBusinessDetails = false"
            >
                Cancel
            </Button>
            <Button
                v-if="isEditingBusinessDetails"
                variety="primary"
                @click="$refs.businessDetailsForm.onSubmit()"
            >
                Save
            </Button>
        </template>

        <BusinessInformationForm
            :isEditing="isEditingBusinessDetails"
            :business="activeBusiness"
            ref="businessDetailsForm"
            @onSubmit="onBusinessDetailsSaved"
        />

    </Card>

    <Card title="Operating Hours">
        <template #icon>
            <ClockIcon />
        </template>

        <template #buttons>
            <Button
                v-if="!isEditingOperatingHours"
                variety="primary"
                :disabled="isEditingBankDetails || isEditingBusinessDetails || isEditingScheduleSettings"
                @click="isEditingOperatingHours = true"
            >
                Edit
            </Button>
            <Button
                v-if="isEditingOperatingHours"
                variety="plain"
                @click="isEditingOperatingHours = false"
            >
                Cancel
            </Button>
            <Button
                v-if="isEditingOperatingHours"
                variety="primary"
                @click="$refs.operatingHoursForm.onSubmit()"
            >
                Save
            </Button>
        </template>

        <OperatingHoursForm
            ref="operatingHoursForm"
            :business="activeBusiness"
            :isEditing="isEditingOperatingHours"
            @onSubmit="onOperatingHoursSaved"
        />
    </Card>

    <Card title="Schedule Settings">
        <template #icon>
            <PeopleIcon />
        </template>

        <template #buttons>
            <Button
                v-if="!isEditingScheduleSettings"
                variety="primary"
                :disabled="isEditingBankDetails || isEditingBusinessDetails || isEditingOperatingHours"
                @click="isEditingScheduleSettings = true"
            >
                Edit
            </Button>
            <Button
                v-if="isEditingScheduleSettings"
                variety="plain"
                @click="isEditingScheduleSettings = false"
            >
                Cancel
            </Button>
            <Button
                v-if="isEditingScheduleSettings"
                variety="primary"
                @click="$refs.scheduleSettingsForm.onSubmit()"
            >
                Save
            </Button>
        </template>

        <ScheduleSettingsForm
            ref="scheduleSettingsForm"
            :business="activeBusiness"
            :isEditing="isEditingScheduleSettings"
            @onSubmit="onScheduleSettingsSaved"
        />
    </Card>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, onMounted, PropType, ref} from "vue";
import Business from "../types/Business";
import BusinessInformationForm from "./BusinessInformationForm.vue";
import BankDetailsForm from "./BankDetailsForm.vue";
import OperatingHoursForm from "./OperatingHoursForm.vue";
import LibraryIcon from "../../../components/icons/LibraryIcon.vue";
import ClockIcon from "../../../components/icons/ClockIcon.vue";
import useBusinessStore from "../useBusinessStore";
import ScheduleSettingsForm from "./ScheduleSettingsForm.vue";
import PeopleIcon from "../../../components/icons/PeopleIcon.vue";

export default defineComponent( {
    name: "BusinessInformation",
    components: { BusinessInformationForm, BankDetailsForm, OperatingHoursForm, ScheduleSettingsForm, LibraryIcon, ClockIcon, PeopleIcon },
    emits:[
        'onBusinessDetailsChange'
    ],
    setup(props){
        const businessStore = useBusinessStore();

        const isEditingBusinessDetails = ref(false);
        const isEditingBankDetails = ref(false);
        const isEditingOperatingHours = ref(false);
        const isEditingScheduleSettings = ref(false);

        const activeBusiness = computed(() => businessStore.activeBusiness);

        async function onBusinessDetailsSaved(values: Business) {
            try{
                await businessStore.businessUpdated({ ...activeBusiness.value, ...values });
                isEditingBusinessDetails.value = false;
            }
            catch (error){}
            finally {
                businessStore.activeBusinessLoading = false;
            }
        }

        async function onBankDetailsSaved(values: Business) {
            try{
                await businessStore.businessUpdated({ ...activeBusiness.value, ...values });
                isEditingBusinessDetails.value = false;
            }
            catch (error){}
            finally {
                businessStore.activeBusinessLoading = false;
            }
        }

        async function onOperatingHoursSaved(values: Partial<Business>) {
            try{
                await businessStore.businessUpdated({ ...activeBusiness.value, ...values });
                isEditingBusinessDetails.value = false;
            }
            catch (error){}
            finally {
                businessStore.activeBusinessLoading = false;
            }
        }

        async function onScheduleSettingsSaved(values: Partial<Business>) {
            try{
                await businessStore.businessUpdated({ ...activeBusiness.value, ...values });
                isEditingBusinessDetails.value = false;
            }
            catch (error){}
            finally {
                businessStore.activeBusinessLoading = false;
            }
        }

        return {
            activeBusiness,

            isEditingBusinessDetails,
            onBusinessDetailsSaved,

            isEditingBankDetails,
            onBankDetailsSaved,

            isEditingOperatingHours,
            onOperatingHoursSaved,

            isEditingScheduleSettings,
            onScheduleSettingsSaved
        }
    }
})
</script>

<style scoped>

</style>
