import { createWebHistory, createRouter } from "vue-router";
import CustomerDashboard from '../modules/customer/pages/CustomerDashboard.vue';
import CustomerManage from '../modules/customer/pages/CustomerManage.vue';
import Login from '../modules/user/pages/Login.vue';
import FourZeroFour from '../layouts/FourZeroFour.vue';
import Schedule from '../modules/schedule/pages/Schedule.vue'
import Breeds from '../modules/breeds/pages/Breeds.vue';
import BusinessOverview from "../modules/business/pages/BusinessOverview.vue";
import Subscription from "../modules/business/pages/Subscription.vue";
import UserSettings from "../modules/user/pages/UserSettings.vue";
import OnboardingWizard from "../modules/onboarding/pages/OnboardingWizard.vue";
import FourZeroFourPage from "../404.vue";
import Dashboard from "../modules/dashboard/pages/Dashboard.vue";
import HelpPage from "../modules/help/pages/HelpPage.vue";
import SingleSchedule from "../modules/schedule/pages/SingleSchedule.vue";
import ErrorPage from "../ErrorPage.vue";
import AboutUsPage from "../modules/about/pages/AboutUsPage.vue";
import VerifyEmail from "../modules/user/pages/VerifyEmail.vue";
import ResetPassword from "../modules/user/pages/ResetPassword.vue";
import FeatureVotingPage from "../modules/voting/pages/FeatureVotingPage.vue";
import InvoiceOverview from "../modules/invoices/pages/InvoiceOverview.vue";
import InvoiceDetail from "../modules/invoices/pages/InvoiceDetail.vue";
import GenerateInvoices from "../modules/invoices/pages/GenerateInvoices.vue";

const routes = [
    {
        path: "/",
        name: "login",
        meta: {layout: "no-sidebar-layout"},
        component: Login
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
    },
    {
        path: "/invoices",
        name: "invoices",
        component: InvoiceOverview,
    },
    {
        path: "/invoices/generate",
        name: "invoicesGeneration",
        component: GenerateInvoices,
    },
    {
        path: "/invoices/:id",
        name: "invoice",
        component: InvoiceDetail,
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
    },
    {
        path: "/business",
        name: "businessOverview",
        component: BusinessOverview,
    },
    {
        path: "/business/subscribe",
        name: "businessSubscription",
        component: Subscription,
    },
    {
        path: "/customers",
        name: "customerDashboard",
        component: CustomerDashboard,
    },
    {
        path: "/customers/:id",
        name: "customerManage",
        component: CustomerManage,
    },
    {
        path: "/schedule",
        name: "schedule",
        component: Schedule,
    },
    {
        path: "/schedule/:id/:edit?/:dateFrom?/:duration?",
        name: "singleSchedule",
        component: SingleSchedule,
    },
    {
        path: "/breeds",
        name: "breeds",
        component: Breeds,
    },
    {
        path: "/settings",
        name: "userSettings",
        component: UserSettings
    },
    {
        path: "/register/:onboardingLevel?",
        name: "register",
        meta: {layout: "no-sidebar-layout"},
        component: OnboardingWizard
    },
    {
        path: "/help",
        name: "help",
        component: HelpPage
    },
    {
        path: "/:pathMatch(.*)*",
        meta: {layout: "no-sidebar-layout"},
        component: FourZeroFour
    },
    {
        path: '/404',
        component: FourZeroFourPage,
        meta: {layout: "no-sidebar-layout"}
    },
    {
        path: "/error",
        name: "error",
        component: ErrorPage,
        meta: {layout: "no-sidebar-layout"}
    },
    {
        path: "/about-us",
        name: "aboutUs",
        component: AboutUsPage,
    },
    {
        path: "/verify-my-email/:token",
        name: "verifyEmail",
        meta: {layout: "no-sidebar-layout"},
        component: VerifyEmail,
    },
    {
        path: "/reset-password/:token",
        name: "resetPassword",
        meta: {layout: "no-sidebar-layout"},
        component: ResetPassword,
    },
    {
        path: "/features",
        name: "features",
        component: FeatureVotingPage
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


export default router;
