<template>
    <Modal
        :title="isEditing ? keyToEdit ? keyToEdit.name : 'Create a Key' : keyToEdit?.name"
        size="xlarge"
        :visible="keyModalVisible"
    >
        <template #icon>
            <KeyIcon />
        </template>

        <template #buttons>
            <DeleteButtonWithConfirmation v-if="!isEditing && !disableCreation && userHasManageCustomersPermission" :message="`Are you sure you wish to delete the key '${keyToEdit?.name}'? This action cannot be undone.`" @onDelete="keyDeleted" />
            <Button v-if="!isEditing && userHasManageCustomersPermission" variety="primary" @click.stop="isEditing = true">Edit</Button>
        </template>

        <CustomerKeyForm
            :keyToEdit="keyToEdit"
            :isEditing="isEditing"
            @onSubmit="keyUpdatedOrCreated"
            @keyImageChanged="keyImageChanged"
            @keyImageDeleted="keyImageDeleted"
            ref="customerKeyForm"
        />

        <template #footer-buttons>
            <Button
                v-if="!isEditing"
                variety="warning"
                @click="closeModal"
            >
                Close
            </Button>
            <Button
                v-if="isEditing"
                variety="warning"
                @click.stop="closeModal"
            >
                Cancel
            </Button>
            <Button
                v-if="isEditing"
                variety="confirm"
                @click.stop="$refs.customerKeyForm.onSubmit()"
            >
                Save
            </Button>
        </template>
    </Modal>

    <Card title="Keys" :isLoading="keysLoading">
        <template #icon>
            <KeyIcon />
        </template>

        <template
            #buttons
            v-if="userHasManageCustomersPermission"
        >
            <Button v-if="!disableCreation" variety="primary" @click="addKeyClicked">New</Button>
        </template>

        <InformationList v-if="keys && keys.length">
            <InformationListItem
                v-for="key in keys"
                :key="key"
                @click="keyClicked(key)"
            >
                <div class="flex flex-1 flex-row justify-between dark:text-gray-100">
                    {{ key.name }}
                    <span v-if="!key.held_by" class="text-red-500">Held by {{ keyHeldBy(key) }}</span>
                    <span v-else class="text-green-500">Held by {{ keyHeldBy(key) }}</span>
                </div>
            </InformationListItem>
        </InformationList>

        <div
            v-else
            class="text-gray-500 dark:text-gray-100"
        >
            No keys have been created for this customer.
        </div>
    </Card>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue'
import KeyIcon from '../../../components/icons/KeyIcon.vue';
import { PermissionGroup } from '../../user/enums/PermissionGroup';
import useUserStore from "../../user/useUserStore"
import CreatingEditingCustomerKey from '../types/CreatingEditingCustomerKey';
import CustomerKey from '../types/CustomerKey';
import useCustomerStore from '../useCustomerStore';
import CustomerKeyForm from "./CustomerKeyForm.vue";
import {FathomEvent, sendFathomEvent} from "../../fathom/fathomUtils";

export default defineComponent({
    components: { KeyIcon, CustomerKeyForm },
    props:{
        disableCreation: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup() {
        const userStore = useUserStore();
        const customerStore = useCustomerStore();

        const userHasManageCustomersPermission = computed(() => userStore.userHasPermission(PermissionGroup.MANAGE_CUSTOMERS));
        const keysLoading = computed(() => customerStore.keysLoading);
        const keys = computed(() => customerStore.keys);

        const keyImageToSave = ref(undefined as File | undefined);
        const keyToEdit = ref(undefined as CustomerKey | undefined);
        const keyModalVisible = ref(false);
        const isEditing = ref(false);

        function keyClicked(key: CustomerKey) {
            customerStore.customerKeySelected(key);
            keyToEdit.value = key;
            keyModalVisible.value = true;
        }

        function addKeyClicked() {
            isEditing.value = true;
            keyModalVisible.value = true;
        }

        function closeModal() {
            keyModalVisible.value = false;
            isEditing.value = false;
            keyToEdit.value = undefined;
            keyImageToSave.value = undefined;
        }

        async function keyUpdatedOrCreated(key: CreatingEditingCustomerKey) {
            try{
                if (key.held_by_user_id as any === "") {
                    key.held_by_user_id = undefined;
                }

                if (keyToEdit.value?.id) {
                    await customerStore.customerKeyUpdated(keyToEdit.value?.id, key);
                } else {
                    const keyId = await customerStore.customerKeyCreated(key);
                    sendFathomEvent("CREATED_A_CUSTOMER_KEY");
                    if (keyImageToSave.value) {
                        await updateKeyImage(keyId, keyImageToSave.value);
                    }
                }
                closeModal();
            }
            catch (error){}
            finally {
                customerStore.keysLoading = false;
            }
        }

        async function keyDeleted() {
            try {
                await customerStore.customerKeyDeleted(keyToEdit.value!);
                closeModal();
            }
            catch (error) {}
        }

        async function keyImageChanged(file: File) {
            try{
                // If we're editing an existing key, it'll already have an id and we can simply upload the image when it
                // changes.
                if (keyToEdit.value) {
                    updateKeyImage(keyToEdit.value.id!, file);
                }
                // Otherwise, we need to wait until we create and then do it after it has an id.
                else {
                    keyImageToSave.value = file;
                }
            }
            catch (error){}
        }

        async function keyImageDeleted() {
            try {
                // If we're creating a key and the key image is deleted, then we want to make sure we don't create it when
                // the key itself gets created. Nothing needs to be done other than that.
                if (!keyToEdit.value) {
                    keyImageToSave.value = undefined;
                    return;
                }

                await customerStore.customerKeyImageDeleted(keyToEdit.value.id!);
                keyImageToSave.value = undefined;
            }
            catch (error) {}
        }

        async function updateKeyImage(keyId: number, file: File) {
            try{
                await customerStore.customerKeyImageUpdated(keyId, file);
            }
            catch (error){}
        }

        function keyHeldBy(key: CustomerKey) {
            if (!key.held_by) {
                return "Customer";
            }

            return key.held_by.name;
        }

        return {
            userHasManageCustomersPermission,
            keysLoading,
            keys,

            keyToEdit,
            keyModalVisible,
            isEditing,

            addKeyClicked,
            closeModal,
            keyUpdatedOrCreated,
            keyHeldBy,
            keyClicked,
            keyDeleted,
            keyImageChanged,
            keyImageDeleted
        }
    },
})
</script>
