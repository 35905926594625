import Surcharge from "../types/Surcharge";

function apify(surcharge: Surcharge): Surcharge {
    return { ...surcharge, price_per_minute: surcharge.price_per_minute * 100 };
}

function deApify(surcharge: Surcharge): Surcharge {
    return { 
        ...surcharge, 
        price_per_minute: surcharge.price_per_minute / 100
    }
}

export default {
    apify,
    deApify
}
