<template>
    <Modal
        :visible="addNewModalIsVisible"
        size="xlarge"
        title="Request a new feature"
        @onClose="toggleModal"
    >
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
            </svg>
        </template>

        <FeatureSubmitForm @on-cancel="toggleModal" @on-submit="handleSuggestFeature" />

    </Modal>

    <div v-if="isLoading" class="flex-1 relative overflow-y-auto focus:outline-none pb-4">
        <LoadingSpinner class="w-32" />
    </div>
    <div v-else class="w-100 py-2 px-4 sm:px-6 md:px-8 pb-5 border-gray-200 sm:py-6 sm:flex sm:items-center sm:justify-between bg-white dark:bg-gray-800" id="voting-header">
        <h1 class="text-2xl font-bold text-black mr-4 text-center dark:text-gray-100">Feature Voting</h1>
        <div class="flex flex-col items-center flex-1 justify-end gap-4 sm:flex-row">
            <div class="flex flex-row items-center gap-2 mt-2 md:mt-0">
                <label for="customer-dropdown" class="block font-medium text-gray-700 dark:text-gray-100">
                    Show Me:
                </label>
                <DropdownSelector
                    id="customer-dropdown"
                    :value="showMeFilter"
                    :isEditing="true"
                    :selectors="showMeOptions"
                    @update:value="(v) => showMeFilter = v"
                />
            </div>
            <div class="flex items-center">
                <Button class="ml-2" variety="primary" @click="toggleModal">Suggest Feature</Button>
            </div>
        </div>
    </div>

    <main v-if="isLoading" class="flex-1 relative overflow-y-auto focus:outline-none pb-4">
        <LoadingSpinner class="w-32" />
    </main>
    <main v-else class="flex-1 relative overflow-y-auto overflow-x-auto focus:outline-none bg-gray-200 dark:bg-gray-600">
        <div class="px-4">
            <div class="flex">
                <div class="flex-grow">
                    <div>
                        <div class="mt-5 flex grid grid-cols-12">
                            <div class="col-span-12 md:col-span-10 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mr-2 flex dark:bg-gray-800">
                                <div class="text-sm font-medium text-sky-blue-400 p-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                                    </svg>
                                </div>
                                <div class="text-sm font-medium text-gray-500 dark:text-gray-100 p-4">
                                    As a user of WalkBuddy each month you get a vote to help us drive the product forward. We aim to release
                                    new features on a monthly basis and the most popular feature will be developed each month!
                                </div>
                            </div>
                            <UserVotesRemainingCard
                                class="col-span-12 md:col-span-2 mt-5 md:mt-0 dark:bg-gray-800"
                                :remaining="userVotesRemaining"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white dark:bg-gray-800 flex flex-col mt-4" v-if="featuresFiltered">
                <FeatureCard
                    v-for="feature in featuresFiltered"
                    :feature="feature"
                    :key="feature.id"
                    @on-vote-up="handleVoteUp"
                    @on-vote-down="handleVoteDown"
                />
            </div>
          <div class="bg-white flex flex-col mt-4 text-sm font-medium text-gray-500 p-4" v-else>
            No feature requests found, please suggest one!
          </div>
        </div>
    </main>

</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from "vue";
import VotingStatus from "../types/VotingStatus";
import FeatureCard from "../components/FeatureCard.vue";
import FeatureSubmitForm from "../components/FeatureSubmitForm.vue";
import UserVotesRemainingCard from "../components/UserVotesRemainingCard.vue";
import useVotingStore from "../useVotingStore";
import FeatureSuggestion from "../types/FeatureSuggestion";
import VotingFeature from "../types/VotingFeature";
import VoteType from "../types/VoteType";
import useTitleManager from "../../../utils/useTitleManager";

export default defineComponent({
    name: "FeatureVotingPage",
    components: {
        FeatureCard,
        FeatureSubmitForm,
        UserVotesRemainingCard
    },
    setup() {
        const { setTitlePrefix } = useTitleManager();
        const addNewModalIsVisible = ref(false);
        const isLoading = ref(false);
        const votingStore = useVotingStore();
        const showMeFilter = ref<VotingStatus>();
        const showMeOptions = [
            {
                name: "Planned Features",
                value: VotingStatus.Planned
            },
            {
                name: "Features In Progress",
                value: VotingStatus.InProgress
            },
            {
                name: "Completed Features",
                value: VotingStatus.Completed
            }]


        onMounted(async () =>{
            setTitlePrefix("Feature Voting");
            isLoading.value = true;
            await votingStore.listFeatures();
            showMeFilter.value = VotingStatus.Planned;
            isLoading.value = false;
        });
        const userVotesRemaining = computed( () =>  votingStore.getVotesRemaining());

        const featuresFiltered = computed(() => {
            return (votingStore.features) ? votingStore.getFeatures().filter(f => f.status === showMeFilter.value) : false;
        });
        const toggleModal = () => {
            addNewModalIsVisible.value = !addNewModalIsVisible.value;
        }

        const handleVoteUp = async (feature: VotingFeature) => {
            try{
                await votingStore.voteForFeature(feature, VoteType.Up);
            }
            catch (error){}
        }

        const handleVoteDown = async (feature: VotingFeature) => {
            try{
                await votingStore.voteForFeature(feature, VoteType.Down);
            }
            catch (error){}
        }

        const handleSuggestFeature = (formValues : FeatureSuggestion) => {
            const newFeature = <FeatureSuggestion>{
                title: formValues.title,
                description: formValues.description,
            };
            votingStore.suggestFeature(newFeature)

            toggleModal();
        }

        return {
            isLoading,
            toggleModal,

            handleVoteUp,
            handleVoteDown,
            handleSuggestFeature,

            featuresFiltered,

            addNewModalIsVisible,

            userVotesRemaining,
            showMeFilter,
            showMeOptions,

            VotingStatus
        }
    }
})
</script>

<style scoped>

</style>
