<template>
    <div class="h-full flex overflow-hidden bg-gray-100">
        <div class="flex flex-col w-0 flex-1 overflow-hidden">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
    name: "NoSidebar"
}
</script>

<style scoped>

</style>