import {defineStore} from "pinia";

const useLocalStorageStore = defineStore({
    id: "localstorage",
    state: () => ({
        theme: "light" as string
    }),
    persist: true,
    actions: {
        setTheme(theme: string) {
            this.theme = theme;
        }
    },
    getters: {
        getTheme(): string{
            return this.theme;
        }
    }
});

export default useLocalStorageStore;