<template>
    <Form @submit.prevent="onSubmit" :divide="false" additional-classes="h-full" class="p-4 sm:p-8 h-full w-full">
        <div class="bg-white h-full flex justify-between flex-col">
            <div class="w-full lg:w-96">
                <h2 class="text-2xl font-extrabold text-gray-900 mb-6">
                    Step 1: Business Info
                </h2>
                <div class="flex flex-row gap-8">
                    <div class="w-full ">
                        <div class="grid grid-cols-3 col-span-3 mb-2 items-center">
                            <TextInput
                                id="name"
                                label="Name"
                                placeholder=""
                                :isEditing="true"
                                :value="name"
                                :errors="nameError"
                                :required="false"
                                @update:value="v => (name = v)"
                            />
                        </div>

                        <AddressLookup
                            :hasTopPadding="false"
                            id="address-lookup"
                            :errors="errors"
                            general-error-text="Address is required"
                            @update:value="v => (updateAddress(v))"
                        />

                        <div class="grid grid-cols-3 col-span-3 mb-2 items-center">
                            <DropdownSelector
                                id="business-type"
                                label="Business Type"
                                :isEditing="true"
                                :selectors="businessTypes"
                                :value="businessType"
                                :errors="businessTypeError"
                                :required="false"
                                @update:value="v => (businessType = v)"
                            />
                        </div>
                        <div class="grid grid-cols-3 col-span-3 mb-2 gap-2">
                            <TextInput
                                v-if="showRegisteredCompanyExtras"
                                id="vat"
                                label="VAT Number"
                                placeholder=""
                                :isEditing="true"
                                :value="vat"
                                :errors="vatError"
                                :required="false"
                                @update:value="v => (vat = v)"
                            />

                            <TextInput
                                v-if="showRegisteredCompanyExtras"
                                id="business-id"
                                label="Company Number"
                                placeholder=""
                                :isEditing="true"
                                :value="businessId"
                                :errors="businessIdError"
                                :required="false"
                                @update:value="v => (businessId = v)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="self-end">
                <div>
                    <Button variety="primary" @click.stop="onSubmit">
                        Next
                    </Button>
                </div>
            </div>
        </div>
    </Form>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, Ref} from "vue";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import AddressLookup from "../../../components/forms/AddressLookup.vue";

export default defineComponent({
    name: "BusinessInfoForm",
    components: {AddressLookup},
    emits:[
        'onBusinessInfoSubmit'
    ],
    setup(props, context){
        const businessTypes = [{value: 0, name: 'Sole Trader'}, {value: 1, name: 'Company'}];
        const countries = [{value: 'GBR', name: 'United Kingdom'}];

        const schema = yup.object({
            name: yup.string().required("Name is required."),
            address_line_1: yup.string().required("Address Line 1 is required.").min(1, "Address Line 1 must be at least 1 characters."),
            city: yup.string().required("City is required.").min(2, "City must be at least 2 characters."),
            country_id: yup.string().required("Country is required."),
            zip_code: yup.string().required("Postcode is required."),
            business_type_id: yup.number().required("The type of business is required."),
            vat_number: yup.string().when("business_type_id", {
                is: 1,
                then: yup.string().nullable().min(6, "VAT Number must be at least 6 characters.")
            }),
            companies_house_id: yup.string().when("business_type_id", {
                is: 1,
                then: yup.string().nullable().min(4, "Business ID must be at least 4 characters.")
            })
        });

        const { handleSubmit, errors } = useForm({
            validationSchema: schema,
        });

        const onSubmit = handleSubmit(values => {
            context.emit("onBusinessInfoSubmit", values);
        });

        const { value: name, errorMessage: nameError } = useField("name");
        const { value: addressLine1, errorMessage: addressLine1Error } = useField("address_line_1");
        const { value: addressLine2, errorMessage: addressLine2Error } = useField("address_line_2");
        const { value: addressLine3, errorMessage: addressLine3Error } = useField("address_line_3");
        const { value: city, errorMessage: cityError } = useField("city");
        const { value: county, errorMessage: countyError } = useField("county");
        const { value: countryId, errorMessage: countryIdError } = useField("country_id");
        const { value: zipCode, errorMessage: zipCodeError } = useField("zip_code");
        const { value: businessType, errorMessage: businessTypeError } = useField("business_type_id");
        const { value: vat, errorMessage: vatError } = useField("vat_number");
        const { value: businessId, errorMessage: businessIdError } = useField("companies_house_id");

        let showRegisteredCompanyExtras = computed(() => businessType.value === 1);

        const updateAddress = function(value: any) {
            addressLine1.value = value.address_line_1;
            addressLine2.value = value.address_line_2;
            addressLine3.value = value.address_line_3;
            city.value = value.city;
            county.value = value.county;
            zipCode.value = value.zip_code;
            countryId.value = value.country_id;
        }

        return {
            onSubmit,
            name,
            nameError,
            addressLine1,
            addressLine1Error,
            city,
            cityError,
            county,
            countyError,
            countryId,
            countryIdError,
            zipCode,
            zipCodeError,
            businessType,
            businessTypeError,
            vat,
            vatError,
            businessId,
            businessIdError,
            showRegisteredCompanyExtras,
            businessTypes,
            countries,
            updateAddress,
            errors
        }
    }
})
</script>

<style scoped>
</style>
