<template>
    <!-- Create a note modal -->
    <Modal
        size="large"
        :title="isEditingANote ? 'Edit Note' : 'Create a Note'"
        :visible="isModalVisible"
    >
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
        </template>

        <template #buttons>
            <DeleteButtonWithConfirmation v-if="isEditingANote" :message="`Are you sure you wish to delete the note? This action cannot be undone.`" @onDelete="openNoteDeleted" />
        </template>

        <Form
            :divide="false"
            @submit="submitCustomerNote"
        >
            <p class="mb-4 text-gray-500 dark:text-gray-100" v-if="!isEditingANote">Write a new note for the customer below.</p>
            <p class="mb-4 text-gray-500 dark:text-gray-100" v-else>Edit the note for the customer below.</p>

            <textarea
                v-model="newNoteData.note"
                id="about"
                name="about"
                rows="10"
                class="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 dark:border-gray-900 dark:bg-gray-600 rounded-md text-sm"
            >
            </textarea>

        </Form>
        <template #footer-buttons>
            <Button
                variety="warning"
                @click="closeModal"
            >
                Cancel
            </Button>
            <Button
                variety="confirm"
                :disabled="!canSaveNewNote"
                @click.stop="submitCustomerNote"
            >
                Save
            </Button>
        </template>
    </Modal>

    <!-- Notes card -->
    <Card :title="cardTitle ?? 'Notes'" :isLoading="activeCustomerNotesLoading">
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
        </template>

        <template #buttons>
            <Button
                type="submit"
                @click="isModalVisible = true"
                variety="primary"
            >
                New
            </Button>
        </template>

        <InformationList v-if="activeCustomerNotes?.length">
            <InformationListItem v-for="note in activeCustomerNotes" :key="note" @click="existingNoteClicked(note)">
                <div class="flex flex-1 space-between gap-2">
                    <div class="flex flex-1 flex-col dark:text-gray-100" style="word-break: break-word;">
                        {{ note.note }}
                    </div>
                    <div class="flex flex-col text-gray-500 dark:text-gray-400">
                        <span>{{ moment(note.created_at).fromNow() }}</span>
                    </div>
                </div>
            </InformationListItem>
        </InformationList>

        <div v-else class="text-gray-500">
            No notes have been created for this customer.
        </div>
    </Card>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {CustomerNoteType} from "../types/CustomerNoteType";
import CustomerNote from "../types/CustomerNote";
import moment from "moment";
import {humanReadableNameFromNoteType} from "../utils/note-type-utils";
import useCustomerStore from "../useCustomerStore";
import {sendFathomEvent} from "../../fathom/fathomUtils";

export default defineComponent({
    name: "CustomerNotesList",
    props: {
        class: {
            type: String,
            required: false
        },
        cardTitle: {
            type: String,
            required: false,
            default: null
        }
    },
    setup(){
        const customerStore = useCustomerStore();
        const isModalVisible = ref(false);
        const noteBeingEdited = ref(undefined as CustomerNote | undefined);
        const isEditingANote = computed(() => !!noteBeingEdited.value);
        const newNoteData = ref({
            note : '',
            note_type: CustomerNoteType.STANDARD
        });
        const activeCustomerNotes = computed(() => customerStore.notes);
        const activeCustomerNotesLoading = computed(() => customerStore.notesLoading);
        const canSaveNewNote = computed(() => newNoteData.value?.note !== "")

        async function submitCustomerNote() {
            if(newNoteData.value.note === "") return;

            newNoteData.value.note_type = CustomerNoteType.INTERNAL;
            if (noteBeingEdited.value && noteBeingEdited.value.id) {
                await customerStore.customerNoteUpdated(noteBeingEdited.value.id, newNoteData.value);
            } else {
                await customerStore.customerNoteCreated(newNoteData.value);
                sendFathomEvent("CREATED_A_CUSTOMER_NOTE")
            }

            closeModal();
        }

        function existingNoteClicked(note: CustomerNote) {
            noteBeingEdited.value = note;
            newNoteData.value = {
                note: note.note,
                note_type: note.note_type
            }
            isModalVisible.value = true;
        }

        async function openNoteDeleted() {
            await customerStore.customerNoteDeleted(noteBeingEdited.value?.id!);
            closeModal();
        }

        function closeModal() {
            newNoteData.value = { note: "", note_type: CustomerNoteType.STANDARD };
            noteBeingEdited.value = undefined;
            isModalVisible.value = false;
        }

        return {
            isModalVisible,
            newNoteData,
            canSaveNewNote,
            CustomerNoteType,
            submitCustomerNote,
            activeCustomerNotes,
            moment,
            humanReadableNameFromNoteType,
            activeCustomerNotesLoading,
            existingNoteClicked,
            noteBeingEdited,
            isEditingANote,
            closeModal,
            openNoteDeleted
        }
    }
})
</script>

<style scoped>

</style>
