<template>
    <teleport to="body">
        <Modal title="Copy A Week's Schedule Block" :visible="isVisible" size="medium">
            <div v-if="!showSuccessMessage && !showFailedMessage">
                This will copy all schedule items from the current week to the next. Click save to continue.
            </div>
            <div v-if="showSuccessMessage" class="bg-green-200 p-2">
                Schedule items have been successfully copied to next week.
            </div>
            <div v-if="showFailedMessage" class="bg-red-200 p-2">
                There was an error copying your schedule items, please try again.
            </div>
            <template #footer-buttons>
                <Button
                    variety="warning"
                    @click="handleClose"
                >
                    Close
                </Button>
                <Button
                    v-if="!showSuccessMessage && !showFailedMessage"
                    variety="confirm"
                    @click.stop="handleSave"
                >
                    Copy
                </Button>
            </template>
        </Modal>
    </teleport>
</template>

<script>
import {defineComponent} from "vue";
import Modal from "../../../../components/Modal.vue";
import DateTimeRangePicker from "../../../../components/forms/DateTimeRangePicker.vue";
import Button from "../../../../components/forms/Button.vue";

export default defineComponent({
    name: "CopyMultipleScheduleModal",
    components: {
        Button,
        DateTimeRangePicker,
        Modal
    },
    props: {
        isVisible: {
            type: Boolean,
            required: true
        },
        showSuccessMessage: {
            type: Boolean,
            required: false,
            default: false,
        },
        showFailedMessage: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    emits: [
        'save',
        'close'
    ],
    setup(props, {emit}){
        const handleSave = () => {
            emit('save');
        }

        const handleClose = () => {
            emit('close');
        }

        return {
            handleSave,
            handleClose
        }
    }
})
</script>

<style scoped>

</style>
