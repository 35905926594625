<template>
    <div
        class="p-2 rounded flex flex-col w-full dark:text-gray-100"
    >
        <strong>{{ scheduleBlockGroup.customer.name }}</strong>
        <InvoiceWizardScheduleBlock
            v-for="scheduleBlock in scheduleBlockGroup.blocks"
            :scheduleBlock="scheduleBlock"
            :is-selected="true"
            :is-slimline="true"
            class="mb-2"
        />
    </div>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import { defineComponent, PropType } from "vue";
import ScheduleBlock from "../../schedule/types/ScheduleBlock";
import CheckIcon from "../../../components/icons/CheckIcon.vue";
import InvoiceWizardScheduleBlock from "./InvoiceWizardScheduleBlock.vue";


interface SelectedCustomerScheduleBlocks {
    [key: number]: ScheduleBlock[]
}

export default defineComponent({
    components: {
        CheckIcon,
        InvoiceWizardScheduleBlock
    },
    props: {
        scheduleBlockGroup: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        return {
        }
    },
})
</script>
