import Pusher from "pusher-js";
import Echo from "laravel-echo";
import User from "./modules/user/types/User";
import useInvoiceStore from "./modules/invoices/useInvoiceStore";
import { InvoicePdfCreatedEvent } from "./types/events/InvoicePdfCreatedEvent";

// @ts-ignore
window.Pusher = Pusher;

function handleInvoicePdfCreatedEvent(message: InvoicePdfCreatedEvent) {
    const invoiceStore = useInvoiceStore();
    invoiceStore.invoicePdfDownloadedInBackground(message);
}

export default function createRealtimeNotifications(user: User) {
    const echo = new Echo({
        broadcaster: "pusher",
        key: import.meta.env.VITE_PUSHER_KEY,
        cluster: import.meta.env.VITE_PUSHER_CLUSTER,
        forceTLS: true,
        authTransport: "jsonp",
        authEndpoint: `${import.meta.env.VITE_BASE_API_URL}/broadcasting/auth`
    });

    echo
        .private(`business.${user.business.id}.all`)
        .listen(".domain.business.invoices.pdf_created", handleInvoicePdfCreatedEvent);
}
