import {DateTime} from "luxon";
import MoneyUtils from "./MoneyUtils";

function toCurrencyString(value: number, isPence : boolean = false): string {
    if(isNaN(value)) {
        return "£0.00";
    }

    if(isPence){
        value = MoneyUtils.convertToPounds(value);
    }

    return value.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });
}

function formatDate(date: string, format: string): string {
    return DateTime.fromISO(date).toFormat(format);
}

export default {
    toCurrencyString,
    formatDate
};
