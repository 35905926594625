<template>
    <CopySingleScheduleModal
        :is-visible="mediaFlags.md.grThanOrEq && copyModalIsVisible"
        :current-schedule-item="scheduledItems[contextMenuCurrentItemIndex]"
        :show-failed-message="showCopyModalFailedMessage"
        :show-success-message="showCopyModalSuccessMessage"
        @update:date="updateTargetDate"
        @close="copyModalIsVisible = false; showCopyModalFailedMessage = false; showCopyModalSuccessMessage = false;"
        @save="saveScheduleCopy"
        @view="handleViewNewCopiedScheduleItem"
    />
    <ContextMenu
        :is-visible="mediaFlags.md.grThanOrEq && contextmenuIsVisible"
        :x="contextMenuX"
        :y="contextMenuY"
        :list-of-actions="contextMenuOptions"
        @menu-item-clicked="handleContextMenuClick"
        @menu-closed="contextmenuIsVisible = false"
    ></ContextMenu>

    <Teleport to="#app">
        <UnauthorizedBlockChangeModal
            :show="showUnauthorizedBlockChangeModal"
            :message="'The schedule block you are trying to modify has one or more lines that have already been invoiced.'"
            @on-cancel="showUnauthorizedBlockChangeModal = false"
        />
    </Teleport>
    <main v-if="canCreateSchedules || isLoading" class="flex-1 relative focus:outline-none bg-white dark:bg-gray-700 select-none touch-none" id="rootSchedulerElement">
        <div class="flex flex-col bg-white dark:bg-gray-800 mt-2 p-4 pt-0" v-if="showAsList">
            <div v-show="scheduledItems" v-for="(scheduledItem, index) in sortedScheduledItems" class="flex mb-2 border-b pb-2">
                <div class="flex flex-col flex-grow">
                    <h2 class="font-semibold text-gray-900 dark:text-gray-50">{{ scheduledItem.date_from.toFormat("T")}} to {{ scheduledItem.date_to.toFormat("T")}}</h2>
                    <div class="break-all dark:text-gray-100" v-if="scheduledItem.name">
                        {{SchedulerUtils.getSlimlineBlockLabel(scheduledItem)}}
                    </div>
                    <div class="text-sm leading-6 text-gray-500 dark:text-gray-50 break-all">
                        {{scheduledItem.dogs}}
                    </div>
                </div>
                <div class="ml-2">
                    <Button variety="primary" :is-small-button="true" @click="goToSchedule(scheduledItem.id)">View</Button>
                </div>
            </div>
            <div v-if="sortedScheduledItems.length === 0" class="p-2 text-gray-900 dark:text-gray-50">
                There are currently no scheduled items occurring today. Please click the button below to add one.
            </div>
            <div class="text-center mt-4 mb-4">
                <Button :variety="primary" @click="(ev) => onEmptyBlockClicked(ev, currentDateTime)">Add new schedule entry</Button>
            </div>
        </div>
        <div v-show="isMultiDayView && !showAsList" class="sticky top-0 bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-900 border-b-2" style="z-index: 500">
            <div class="relative flex flex-row grid-cols-11">
                <div style="width: 70px;">
                </div>
                <div class="flex-1">
                    <div class="grid" :class="[`grid-cols-${days}`]">
                        <div v-for="dateTimeItem in scheduleDays" :key="SchedulerUtils.getDateTimeId(dateTimeItem)" class="col-span-1 text-center p-2 m-1" :class="{
                            'bg-sky-blue-400 rounded-xl' : SchedulerUtils.getIsToday(dateTimeItem),
                            'text-gray-800 dark:text-gray-100': !SchedulerUtils.getIsToday(dateTimeItem)
                        }">
                            <span class="text-sml select-none">{{SchedulerUtils.getDayName(dateTimeItem)}}</span><br/>
                            <span class="text-4xl font-bold select-none">{{SchedulerUtils.getDayNumber(dateTimeItem)}}</span>
                        </div>
                    </div>
                </div>
                <div style="width: 70px;">
                </div>
            </div>
        </div>
        <div v-show="!showAsList" class="grid p-4" :class="[!disableBackground ? 'bg-gray-100 dark:bg-gray-800' : '']">
            <div class="flex col-span-11 flex-row " :class="[!disableBackground ? 'bg-gray-100 dark:bg-gray-800' : '']">

                <div class="relative">
                    <div class="grid grid-cols-1 relative time-container-column sm:mt-[-1px]">
                        <div v-for="(time) in scheduleTimes" :key="time.toFormat('hh:mm')"
                             :id="isWorkingHoursStart(time.hours) ? 'working-hours-start': undefined"
                             class="col-span-1 pr-4 text-right time-container relative sm:mb-[1px]">
                            <div class="back font-semibold select-none dark:text-gray-100">{{time.toFormat("hh:mm")}}</div>
                        </div>
                    </div>
                </div>

                <div class="flex-1">
                    <div
                         class="bg-gray-200 dark:bg-black shadow divide-y dark:divide-gray-800 sm:divide-y-0 sm:grid sm:gap-px max-h-full h-full relative" :style="scheduleBlockSpaceGridStyle"
                         id="scheduler-root"
                         @mouseup="(ev) => onScheduleMouseUp(ev)"
                         @mousemove="(ev) => onScheduleMouseMove(ev)"
                         @touchend="(ev) => onScheduleTouchEnd(ev)"
                         @touchmove="(ev) => onScheduleTouchMove(ev)"
                    >

                        <Transition name="fade">
                            <div v-show="isLoading" class="absolute top-0 left-0 right-0 bottom-0 w-full min-h-screen z-50 bg-white opacity-75 flex flex-col items-center justify-center">
                                <LoadingSpinner/>
                            </div>
                        </Transition>
                        <!-- all the schedule items -->
                        <div
                            v-for="(scheduledItem, index) in scheduledItems"
                             class="bg-transparent cursor-pointer absolute overflow-hidden schedule-block"
                             :style="getStyleForScheduledItem(scheduledItem, index)"
                            oncontextmenu="return false;"
                        >
                            <div
                                 class="p-1 rounded h-full border touch-none"
                                 :class="{
                                'bg-sky-blue-300 hover:bg-sky-blue-400 border-sky-blue-500': scheduledItem.status === ScheduleBlockStatus.STATUS_SCHEDULED,
                                'bg-yellow-300 hover:bg-yellow-400 border-yellow-500': scheduledItem.status === ScheduleBlockStatus.STATUS_IN_PROGRESS,
                                'bg-green-300 hover:bg-green-400 border-green-500': scheduledItem.status === ScheduleBlockStatus.STATUS_COMPLETE,
                                'bg-red-300 hover:bg-red-400 border-red-500': scheduledItem.status === ScheduleBlockStatus.STATUS_CANCELLED
                            }"
                                 @mousedown="(ev) => showContextMenu(ev, index)"
                                 @touchstart="(ev) => onBlockTouchStart(ev, scheduledItem)"
                                 @touchend="(ev) => onBlockTouchEnd( scheduledItem)"
                            >
                                <div class="flex items-center">
                                    <div
                                        class="schedule-warning"
                                        v-if="SchedulerUtils.getScheduleHasWarning(scheduledItem, intervalMinutes)">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                        </svg>
                                    </div>
                                    <div class="truncate overflow-ellipsis select-none">
                                        <b class="whitespace-nowrap select-none">{{SchedulerUtils.getSlimlineBlockLabel(scheduledItem)}}</b>
                                    </div>
                                </div>
                                <div class="truncate overflow-ellipsis select-none">{{ scheduledItem.date_from.toFormat("T")}} to {{ scheduledItem.date_to.toFormat("T")}}</div>
                            </div>
                        </div>

                        <!-- A single virtual block when dragging an item around -->
                        <div v-if="virtualBlock !== null && didPassDragThreshold"
                             class="bg-transparent cursor-pointer absolute"
                             :style="getStyleForScheduledItem(virtualBlock, 1000)"
                        >
                            <div
                                class="p-1 rounded h-full border touch-none"
                                :class="{
                                'bg-sky-blue-300 hover:bg-sky-blue-400 border-sky-blue-500': virtualBlock.status === ScheduleBlockStatus.STATUS_SCHEDULED,
                                'bg-yellow-300 hover:bg-yellow-400 border-yellow-500': virtualBlock.status === ScheduleBlockStatus.STATUS_IN_PROGRESS,
                                'bg-green-300 hover:bg-green-400 border-green-500': virtualBlock.status === ScheduleBlockStatus.STATUS_COMPLETE,
                                'bg-red-300 hover:bg-red-400 border-red-500': virtualBlock.status === ScheduleBlockStatus.STATUS_CANCELLED
                            }"

                                @mouseup="(ev) => onBlockMouseUp(virtualBlock)"
                            >
                                <div class="truncate overflow-ellipsis select-none"><b class="whitespace-nowrap select-none">{{SchedulerUtils.getSlimlineBlockLabel(virtualBlock)}}</b></div>
                                <div class="truncate overflow-ellipsis select-none">{{ virtualBlock.date_from.toFormat("T")}} to {{ virtualBlock.date_to.toFormat("T")}}</div>
                            </div>
                        </div>

                        <!-- Empty spaces -->
                        <div v-for="(blockSpace, idx) in scheduleBlockEmptySpaces"
                             :key="SchedulerUtils.getDateTimeId(blockSpace.dayTime)"
                             :id="`blockspace-${idx}`"
                             class="schedule-block-empty-space bg-white dark:bg-gray-600 h-16 hover:bg-gray-100 cursor-pointer z-10"
                             :style="`grid-column: ${blockSpace.dayIndex}; grid-row :${blockSpace.timeIndex};`"
                             :class="[!isValidWorkingHour(blockSpace.dayTime.hour) ? 'out-of-hours' : '',
                             SchedulerUtils.getIsInThePast(blockSpace.dayTime.plus({ minutes: intervalMinutes })) ? 'in-the-past' : '',
                             isAtValidWorkingHourDivide(blockSpace.dayTime.hour, scheduleBlockEmptySpaces[idx + 1] ? scheduleBlockEmptySpaces[idx + 1].dayTime.hour : null) ? 'out-of-hours-divide' : '']"
                             @click="(ev) => onEmptyBlockClicked(ev, blockSpace.dayTime)"
                        >

                        </div>
                    </div>
                </div>

                <div v-if="isMultiDayView" class="relative">
                    <div class="grid grid-cols-1 relative time-container-column sm:mt-[-1px]">
                        <div v-for="(time) in scheduleTimes" :key="time.toFormat('hh:mm')"
                             class="col-span-1 pl-4 text-left time-container-r relative sm:mb-[1px]">
                            <div class="back font-semibold dark:text-gray-100">{{time.toFormat("hh:mm")}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <main v-else class="flex flex-1 bg-white dark:bg-gray-800 dark:text-gray-100 m-4 p-4 shadow items-center" :class="days === 1 ? 'flex-col' : 'flex-col md:flex-row' ">
        <div style="flex-basis:25%" class="p-4">
            <img alt="checklist" :src="checklist">
        </div>
        <div class="ml-4 mt-1 align-middle">

            <div>
                In order to get started there are a few things you need to do first:
            </div>

            <div class="mt-2 mb-2">
                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-sky-blue-200">
                    <div :style="{ width : setUpPercent + '%'}" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-sky-blue-500"></div>
                </div>
            </div>

            <div class="flex items-center mb-2">
                <ThumbsUpIcon v-if="hasWalkCategories" class="text-green-400" />
                <ThumbsDownIcon v-else class="text-red-400" />
                <div class="ml-2">
                    Define some Walk Types by heading over to the <router-link to="/business" class="underline hover:text-sky-blue-900">Business</router-link> page.
                </div>
            </div>

            <div class="flex items-center mb-2">
                <ThumbsUpIcon v-if="hasACustomer" class="text-green-400" />
                <ThumbsDownIcon v-else class="text-red-400" />
                <div class="ml-2">
                    Add your first Customer by heading over to the <router-link to="/customers" class="underline hover:text-sky-blue-900">Customers</router-link> page.
                </div>
            </div>

            <div class="flex items-center mb-2">
                <ThumbsUpIcon v-if="hasCustomerWithDog" class="text-green-400" />
                <ThumbsDownIcon v-else class="text-red-400" />
                <div class="ml-2">
                    Add your first Dog to an existing customer by heading over to the <router-link to="/customers" class="underline hover:text-sky-blue-900">Customers</router-link> page.
                </div>
            </div>

        </div>
    </main>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {XIcon} from '@heroicons/vue/outline'
import {LinkIcon, PlusIcon, QuestionMarkCircleIcon} from '@heroicons/vue/solid'

import useBusinessStore from "../../business/useBusinessStore";
import ScheduleBlock from "../types/ScheduleBlock";
import SchedulerUtils from "../utils/scheduler-utils"
import {DateTime, Interval} from "luxon";
import ScheduleBlockEditor from "./ScheduleBlockEditor.vue";
import ScheduleBlockEditorState from "../types/ScheduleBlockEditorState";
import {useDragBehavior} from "../behaviors/useDrag";
import {useSchedulerInfo} from "../behaviors/useSchedulerInfo";
import moment from "moment";
import useSchedulerStore from "../useSchedulerStore";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import {useRoute, useRouter} from "vue-router";
import ScheduleBlockStatus from "../types/ScheduleBlockStatus";
import {parseInt} from "lodash";
import SlimlineScheduleBlock from "../types/SlimlineScheduleBlock";
import CalenderIcon from "../../../components/icons/CalenderIcon.vue";
import UnauthorizedBlockChangeModal from "./UnauthorizedBlockChangeModal.vue";
import TickIcon from "../../../components/icons/TickIcon.vue";
import ThumbsUpIcon from "../../../components/icons/ThumbsUpIcon.vue";
import ThumbsDownIcon from "../../../components/icons/ThumbsDownIcon.vue";
import useCustomerStore from "../../customer/useCustomerStore";
import checklist from "../../../assets/checklist.png";
import Button from "../../../components/forms/Button.vue";
import {useMediaQuery} from "../../core/useMediaQuery";
import ContextMenu from "../../../components/ContextMenu.vue";
import DateTimeRangePicker from "../../../components/forms/DateTimeRangePicker.vue";
import CopySingleScheduleModal from "./copying/CopySingleScheduleModal.vue";
import schedule from "../pages/Schedule.vue";

export default defineComponent({
    name: "Scheduler",
    components: {
        CopySingleScheduleModal,
        DateTimeRangePicker,
        ContextMenu,
        Button,
        ThumbsDownIcon,
        ThumbsUpIcon,
        TickIcon,
        UnauthorizedBlockChangeModal,
        CalenderIcon,
        LoadingSpinner,
        ScheduleBlockEditor,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        LinkIcon,
        PlusIcon,
        QuestionMarkCircleIcon,
        XIcon,
    },
    props:{
        showCancelled: {
            type: Boolean,
            required: false,
            default: false
        },
        selectedDate: {
            type: Object,
            required: true
        },
        intervalMinutes: {
            type: Number,
            required: false,
            default: 30
        },
        days: {
            type: Number,
            required: false,
            default: 7
        },
        interval: {
            type: Number,
            required: false,
            default: 5
        },
        disableBackground: {
            type: Boolean,
            required: false,
            default: false
        },
        showList: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props, context){
        const router = useRouter();

        const { mediaFlags } = useMediaQuery("tailwind");

        const businessStore = useBusinessStore();
        const customerStore = useCustomerStore();
        const businessInfo = computed(() => businessStore.activeBusiness);
        const showUnauthorizedBlockChangeModal = ref(false);
        const schedulerStore = useSchedulerStore();
        const scheduledItems = ref<SlimlineScheduleBlock[]>([])
        const sortedScheduledItems = computed(() => {
            return scheduledItems.value.sort((a : SlimlineScheduleBlock,b : SlimlineScheduleBlock) : number => {
                if(a.date_from.ts > b.date_to.ts) {
                    return 1;
                }
                if(a.date_from.ts < b.date_to.ts) {
                    return -1;
                }
                return 0;
            })
        })
        const isLoading = ref(true);

        const route = useRoute();
        const isScheduleOverview = route.name == "dashboard";
        const isMobileView = mediaFlags.sm.lessThanOrEq

        const hasWalkCategories = computed(() => businessStore.walkCategories.length > 0);
        const hasACustomer = computed(() => customerStore.customers?.data && customerStore.customers.data.length > 0);
        const hasCustomerWithDog = computed(() => customerStore.availableDogs.length > 0);
        const canCreateSchedules = computed(() => hasWalkCategories.value && hasACustomer.value && hasCustomerWithDog.value);
        let setUpPercent = 0;
        if (hasWalkCategories.value) setUpPercent += 33.33;
        if (hasACustomer.value) setUpPercent += 33.33;
        if (hasCustomerWithDog.value) setUpPercent += 33.33;


        const selectedScheduleBlock = ref({} as SlimlineScheduleBlock);
        const currentScheduleBlockEditorState = ref(ScheduleBlockEditorState.NONE);
        const schedulerRenderArea = ref({ width: 0, height: 0});

        // Scheduler Setup
        const {
            startHours,
            endHours,
            weekdays,
            scheduleDays,
            scheduleTimes,
            scheduleBlockEmptySpaces
        } = useSchedulerInfo(props, businessInfo);


        onMounted(async () => {
            isLoading.value = true;
            await businessStore.loadBusinessUsers();
            await customerStore.listingCustomers({url : ""});
            const observableElement = document.getElementById("scheduler-root")
            if (observableElement){
                new ResizeObserver((entry: ResizeObserverEntry[]) => {
                    schedulerRenderArea.value = {
                        width: entry[0].contentRect.width,
                        height: entry[0].contentRect.height,
                    }
                }).observe(observableElement);
            }

            document.addEventListener("mouseup", () => {
                onScheduleMouseUp();
            });

            await syncSchedule();
            if(route.name == "schedule"){
                const scheduleWrapper = document.getElementById('scroll-area');
                const startPos = document.getElementById('working-hours-start')?.offsetTop;
                scheduleWrapper?.scrollTo(0, (startPos ?? 0));
            }
            isLoading.value = false;
        });

        // Drag & Drop
        const { onScheduleMouseUp, onScheduleMouseMove,
            onBlockMouseDown, onBlockMouseUp,
            onScheduleTouchEnd, onScheduleTouchMove,
            onBlockTouchStart, onBlockTouchEnd,
            onEmptyBlockClicked,
            virtualBlock,
            didPassDragThreshold
        } = useDragBehavior(
            startHours,
            endHours,
            props.days,
            props.interval, //5mins
            (block) => {
                selectedScheduleBlock.value = block;
                currentScheduleBlockEditorState.value = ScheduleBlockEditorState.VIEWING;
            },
            async (block) => {
                if(Object.keys(block.invoice_state).filter(x => x !== "draft" && x !=="N/A").length > 0){
                    showUnauthorizedBlockChangeModal.value = true;
                    return false;
                }

                try{
                    await schedulerStore.updateSlimlineScheduleBlockTime(block);
                    isLoading.value = true;
                    await syncSchedule();
                    isLoading.value = false;
                }
                catch (error){}
            },
            async (block) => {
                await router.push({path : `/schedule/${block.id}`});
            },
            (date) => {
                currentScheduleBlockEditorState.value = ScheduleBlockEditorState.CREATING;
                router.push({path : `/schedule/null/edit/${date}/${props.intervalMinutes}`});
            },
            (!isScheduleOverview || !isMobileView)
        );

        // Scheduler Dynamic styling
        const scheduleBlockSpaceGridStyle = computed(() => {
            const colStyle = `repeat(${scheduleDays.value.length}, minmax(0, 1fr)) `;
            const rowStyle = `repeat(${scheduleTimes.value.length}, minmax(0, 1fr))`;
            return {
                "grid-template-rows": rowStyle,
                "grid-template-columns": colStyle,
            };
        });

        let isValidWorkingHour = (hour: string) => {
            let parsedHour = moment(hour, 'h:mm A').format('H');
            return parseInt(parsedHour) >= businessInfo.value.working_hours_start && parseInt(parsedHour) < businessInfo.value.working_hours_end
        }

        let isAtValidWorkingHourDivide = (hour: string, nextHour: string) => {
            if(!nextHour) {
                return false;
            }

            return (!isValidWorkingHour(hour) && isValidWorkingHour(nextHour)) || (isValidWorkingHour(hour) && !isValidWorkingHour(nextHour));
        }

        let syncSchedule = async () => {
            let dateRange = SchedulerUtils.getScheduleDateRange(
                props.selectedDate,
                0,
                24,
                props.days);

            await schedulerStore.getScheduleBlocks(dateRange).then((result) => {
                if(result != null) {
                    if(!props.showCancelled) {
                        scheduledItems.value = result.filter(x => x.status !== ScheduleBlockStatus.STATUS_CANCELLED);
                    } else {
                        scheduledItems.value = result;
                    }

                }
            });
        }

        let getStyleForScheduledItem = computed( () => (scheduledItem: ScheduleBlock, index: number) => {
            const fromMinute = Math.max((scheduledItem.date_from.minute + ((scheduledItem.date_from.hour-startHours) * 60)), 0);
            const totalMinutes = (endHours - startHours) * 60;
            const totalBlockMinutes = Interval.fromDateTimes(scheduledItem.date_from, scheduledItem.date_to).length("minutes");
            const { overlapIndex } = SchedulerUtils.getTotalBlockOverlaps(scheduledItem, scheduledItems.value);

            let scheduleStartDate = SchedulerUtils.getScheduleStartDate(props.selectedDate, props.days);
            let diffInDays = scheduledItem.date_from.diff(scheduleStartDate, 'days').toObject();
            let dayOfScheduledWeek = Math.max(Math.floor(diffInDays.days ?? 0), 0);

            const originalWidth = (schedulerRenderArea.value.width / props.days);
            const widthReduction = (originalWidth * 0.20) * overlapIndex;

            let newWidth = originalWidth - widthReduction;
            let newHeight = (totalBlockMinutes/totalMinutes)*schedulerRenderArea.value.height;

            if(newHeight <= 45) {
                newHeight = 30;
            }

            return {
                "left": `${((dayOfScheduledWeek / props.days) * schedulerRenderArea.value.width) + widthReduction}px`,
                "top": `${(((fromMinute/totalMinutes) * schedulerRenderArea.value.height))}px`,
                "width": `${newWidth}px`,
                "height": `${newHeight}px`,
                "padding": `1px`,
                "opacity" : (virtualBlock.value && scheduledItem.id == virtualBlock.value.id) ? "50%" : "100%",
                "zIndex": (index + 20) + (overlapIndex > 0 ? scheduledItems.value.length + overlapIndex : 0)
            }
        });

        watch(
            () => props.selectedDate,
            async () => {
                scheduledItems.value = [];
                isLoading.value = true;
                await syncSchedule();
                isLoading.value = false;
            });

        watch(
            () => [props.showCancelled],
            async () => {
                scheduledItems.value = [];
                isLoading.value = true;
                await syncSchedule();
                isLoading.value = false;
            });

        const isMultiDayView = computed(() => props.days > 1);
        const showAsList = computed(() => props.showList);

        const goToSchedule = (scheduleId : string) => {
            router.push(`/schedule/${scheduleId}`);
        }

        const currentDateTime = DateTime.local();

        const isWorkingHoursStart = (hour : number) => hour == businessInfo.value.working_hours_start;


        const contextmenuIsVisible = ref(false);
        const contextMenuCurrentItemIndex = ref(0);
        const contextMenuY = ref("0px");
        const contextMenuX = ref("0px");
        const copyModalIsVisible = ref(false);
        const scheduleCopyDateTo = ref(DateTime.now().toISO());
        const showCopyModalSuccessMessage = ref(false);
        const showCopyModalFailedMessage = ref(false);
        const newCopiedScheduleId = ref(0);
        const contextMenuOptions = [
            {
                label: "View",
                eventId: "view-schedule"
            },
            {
                label: "Copy",
                eventId: "copy-schedule"
            }
        ]
        const showContextMenu = (ev, itemIndex : number) => {
            ev.stopPropagation();
            ev.preventDefault();
            const item = scheduledItems.value[itemIndex];
            if(ev.button !== 2) { // We are not clicking right click so carry on to normal flow
                return onBlockMouseDown(ev, item)
            }
            // Show context menu
            contextMenuCurrentItemIndex.value = itemIndex;
            contextmenuIsVisible.value = true;
            scheduleCopyDateTo.value = item.date_to.toISO();
            contextMenuX.value = ev.clientX + "px";
            contextMenuY.value = ev.clientY + "px";
        }
        const handleContextMenuClick = (eventId : string) => {
            contextmenuIsVisible.value = false;
            if (eventId === "view-schedule"){
                const item = scheduledItems.value[contextMenuCurrentItemIndex.value];
                router.push({path : `/schedule/${item.id}`});
            } else if (eventId === "copy-schedule") {
                copyModalIsVisible.value = true;
            }
        }
        const saveScheduleCopy = async() =>{
            const item = scheduledItems.value[contextMenuCurrentItemIndex.value];
            const scheduleFullInfo : ScheduleBlock | null = await schedulerStore.getScheduleBlock(item.id);
            if(!scheduleFullInfo) return; // Can't copy something that does not exist

            const targetDate = DateTime.fromISO(scheduleCopyDateTo.value);
            const newDateFrom = scheduleFullInfo.date_from.set({ day: targetDate.day, month: targetDate.month, year: targetDate.year });
            const newDateTo = scheduleFullInfo.date_to.set({ day: targetDate.day, month: targetDate.month, year: targetDate.year });

            const newScheduleBlock : ScheduleBlock = {
                assigned_user_ids: scheduleFullInfo.assigned_user_ids,
                assigned_users: scheduleFullInfo.assigned_users,
                date_from: newDateFrom,
                date_to: newDateTo,
                lines: scheduleFullInfo.lines,
                name: scheduleFullInfo.name,
                status: ScheduleBlockStatus.STATUS_SCHEDULED
            }
            const newBlock = await schedulerStore.createScheduleBlock(newScheduleBlock, true);
            if (newBlock) {
                showCopyModalSuccessMessage.value = true;
                newCopiedScheduleId.value = newBlock.id;
            } else {
                showCopyModalFailedMessage.value = true;
            }

            isLoading.value = true;
            await syncSchedule();
            isLoading.value = false;
        }

        const handleViewNewCopiedScheduleItem = async () => {
            showCopyModalFailedMessage.value = false;
            showCopyModalSuccessMessage.value = false;
            copyModalIsVisible.value = false;
            await router.push(`/schedule/${newCopiedScheduleId.value}`);
        }

        const updateTargetDate = (value) => {
            scheduleCopyDateTo.value = DateTime.fromJSDate(value.value);
        }

        return{
            isLoading,

            // calender management
            SchedulerUtils,
            scheduledItems,
            scheduleDays,
            scheduleTimes,
            weekdays,
            isMultiDayView,
            showAsList,


            //block interaction
            selectedScheduleBlock,
            onBlockMouseDown, onBlockMouseUp,
            onScheduleMouseUp, onScheduleMouseMove,
            onScheduleTouchEnd, onScheduleTouchMove,
            onBlockTouchStart, onBlockTouchEnd,
            onEmptyBlockClicked,
            virtualBlock,
            didPassDragThreshold,

            ScheduleBlockEditorState,
            ScheduleBlockStatus,
            currentScheduleBlockEditorState,
            getStyleForScheduledItem,
            scheduleBlockSpaceGridStyle,
            scheduleBlockEmptySpaces,

            businessInfo,
            isValidWorkingHour,
            isAtValidWorkingHourDivide,
            router,

            showUnauthorizedBlockChangeModal,
            hasWalkCategories,
            hasACustomer,
            hasCustomerWithDog,
            canCreateSchedules,
            setUpPercent,
            checklist,
            days: props.days,
            goToSchedule,
            sortedScheduledItems,
            currentDateTime,
            isWorkingHoursStart,

            // Context Menu & Copy Schedule
            contextmenuIsVisible,
            contextMenuY,
            contextMenuX,
            contextMenuOptions,
            copyModalIsVisible,
            contextMenuCurrentItemIndex,
            scheduleCopyDateTo,
            showCopyModalSuccessMessage,
            showCopyModalFailedMessage,
            showContextMenu,
            handleContextMenuClick,
            saveScheduleCopy,
            updateTargetDate,
            handleViewNewCopiedScheduleItem,

            mediaFlags
        }
    }
})
</script>

<style scoped>

.time-container, .time-container-r{
    height:64px;
}

.time-container:after{
    content:"";
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height:64px;
    border-top: 1px solid rgb(229, 231, 235);
}

.time-container-r:after{
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    border-top: 1px solid rgb(229, 231, 235);
}
.time-container > div, .time-container-r > div {
    margin-top:-10px;
}

.out-of-hours{
    background: repeating-linear-gradient(
        45deg,
        #bfbfbf,
        #efefef 2px
    );
}

.dark .out-of-hours{
    background: repeating-linear-gradient(
        45deg,
        #606060,
        #091221 2px
    );
}

.dark .in-the-past:not(.out-of-hours){
    background: repeating-linear-gradient(
        45deg,
        #3e495b,
        rgba(32, 58, 100, 0.28) 2px
    );
}

.in-the-past:not(.out-of-hours){
    background: repeating-linear-gradient(
        45deg,
        #efefef,
        white 2px
    );
}

.out-of-hours-divide{
    border-bottom: 4px solid #bfbfbf !important;
}

.dark .out-of-hours-divide{
    border-bottom: 4px solid #0f141a !important;
}

.schedule-block:hover{
    z-index:999!important;
    min-height:100px!important;
    min-width:150px!important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.7s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
