<template>
    <div class="flex flex-row h-full bg-white">
        <WizardPanel
            class="hidden lg:block"
            :onboardingLevel="onboardingLevel"
        ></WizardPanel>
        <div class="flex flex-col h-full w-full overflow-y-scroll">
            <WizardHeader
                :hide-onboarding-text="onboardingLevel === OnboardingLevel.Invoicing"
                v-if="showOnboardingHeader">
            </WizardHeader>
            <div class="bg-sky-blue-500 block h-2 lg:hidden">&nbsp;</div>
            <WizardContent
                :onboardingLevel="onboardingLevel"
                :userDetails="userDetails"
                @onRegister="onRegister"
                @onBusinessInfoSubmit="onBusinessInfoSubmit"
                @onWalkPricesSubmit="onWalkPricesSubmit"
                @onInvoicingSubmit="onInvoicingSubmit">
            </WizardContent>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, ref, watch} from "vue";
import WizardPanel from "../components/WizardPanel.vue";
import WizardContent from "../components/WizardContent.vue";
import {OnboardingLevel} from "../types/OnboardingLevel";
import CreatingUser from "../types/CreatingUser";
import UserApiClient from "../../../api/UserApiClient";
import {useRoute} from "vue-router";
import WizardHeader from "../components/WizardHeader.vue";
import WalkPricesOnboarding from "../types/WalkPricesOnboarding";
import BusinessInfoOnboarding from "../types/BusinessInfoOnboarding";
import InvoicingOnboarding from "../types/InvoicingOnboarding";
import useBusinessStore from "../../business/useBusinessStore";
import DogCategory from "../../business/types/DogCategory";
import WalkCategory from "../../business/types/WalkCategory";
import useUserStore from "../../user/useUserStore";
import {FathomEvent, sendFathomEvent} from "../../fathom/fathomUtils";
import Surcharge from "../../business/types/Surcharge";
import User from "../../user/types/User";

export default defineComponent({
    name: "OnboardingWizard",
    components: {WizardHeader, WizardContent, WizardPanel},
    setup(){
        const route = useRoute();
        const businessStore = useBusinessStore();
        const userStore = useUserStore();

        let onboardingLevel = ref(OnboardingLevel.NotRegistered);

        onBeforeMount(() => {
            // Remove dark mode if the user has it set from a previous session
            let html = document.querySelector("html");
            if (html) {
                html.classList.remove('light', 'dark');
            }
        });

        watch(
            route,
            async () => {
                if (!route.params.onboardingLevel) {
                    return;
                }

                onboardingLevel.value = parseInt(route.params.onboardingLevel as unknown as string);
            },
            { immediate: true }
        );

        let userDetails = ref();

        async function onRegister(user: CreatingUser) {
            const userDetailsResponse = await UserApiClient.createUser(user);

            if (userDetailsResponse.success) {
                onboardingLevel.value = OnboardingLevel.VerificationRequired;
                userDetails.value = userDetailsResponse.data;
            }
        }

        async function onBusinessInfoSubmit(businessInfo: BusinessInfoOnboarding) {
            businessInfo.telephone = "000000";

            let updatedBusiness = { ...businessStore.activeBusiness, ...businessInfo }

            let success = await businessStore.businessUpdated(updatedBusiness);

            // Also update the current users address
            let user = userStore.user;
            if(user){
                await userStore.userUpdated({
                    name: user.name,
                    address_line_1: updatedBusiness.address_line_1,
                    address_line_2: updatedBusiness.address_line_2,
                    address_line_3: updatedBusiness.address_line_3,
                    city: updatedBusiness.city,
                    county: updatedBusiness.county,
                    country_id: updatedBusiness.country_id,
                    zip_code: updatedBusiness.zip_code
                } as User);
            }


            if(success) {
                onboardingLevel.value = OnboardingLevel.BusinessInfo;
            }
        }

        async function onWalkPricesSubmit(walkPrices: WalkPricesOnboarding) {
            let allCategoriesAdded = true;

            for (let i = 0; i < walkPrices.surcharges.length; i++) {
                let dogType = walkPrices.surcharges[i];

                let surcharge = { ...dogType} as Surcharge;

                let success = await businessStore.surchargeAdded(surcharge);

                allCategoriesAdded = allCategoriesAdded && success;
            }

            if(!allCategoriesAdded) {
                return;
            }

            for (let i = 0; i < walkPrices.walk_types.length; i++) {
                let walkType = walkPrices.walk_types[i];

                let walkCategory = { ...walkType} as WalkCategory;

                let success = await businessStore.walkCategoryAdded(walkCategory);

                allCategoriesAdded = allCategoriesAdded && success;
            }

            if(!allCategoriesAdded) {
                return;
            }

            let updatedBusiness = { ...businessStore.activeBusiness, ...{default_schedule_block_minutes: walkPrices.appointment_length} }

            let success = await businessStore.businessUpdated(updatedBusiness);

            if(!success) {
                return;
            }

            onboardingLevel.value = OnboardingLevel.Pricing;

            // Auto-invoicing not supported in V1 so skip straight to completion
            await onInvoicingSubmit(undefined as unknown as InvoicingOnboarding);
            sendFathomEvent("ONBOARDING_COMPLETE")
        }

        async function onInvoicingSubmit(invoicingOnboarding: InvoicingOnboarding) {
            // Auto-Invoicing coming soon
            onboardingLevel.value = OnboardingLevel.Invoicing;

            await userStore.userOnboardingUpdated();
        }

        const showOnboardingHeader = computed(() => onboardingLevel.value >= OnboardingLevel.Account);

        return {
            onboardingLevel,
            OnboardingLevel,
            userDetails,
            onRegister,
            onBusinessInfoSubmit,
            onWalkPricesSubmit,
            onInvoicingSubmit,
            showOnboardingHeader
        }
    }
})
</script>
