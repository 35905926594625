<template>
    <div v-if="isSlimline"
         class="p-2 rounded flex flex-col w-full border-2 break-all"
    >
        <span class="font-bold text-gray-500 dark:text-gray-100">{{ prettyPrintInvoiceDate(scheduleBlock.date_from) }}</span>
        <span>{{ scheduleBlock.lines.map(l => l.dog.name).join(", ") }}</span>
    </div>
    <div
        v-else
        class="p-2 rounded flex flex-col w-full break-all cursor-pointer"
        :class="{
            'border-2': !isSelected,
            'border-2 bg-sky-blue-300 border-sky-blue-600 dark:border-sky-blue-800 dark:bg-sky-blue-900': isSelected
        }"
    >
        <span class="font-bold text-gray-500 dark:text-gray-100">{{ prettyPrintInvoiceDate(scheduleBlock.date_from) }}</span>
        <span class="dark:text-gray-100">{{ durationOfScheduleBlock(scheduleBlock) }} mins</span>
        <span class="dark:text-gray-100">{{ scheduleBlock.lines.map(l => l.dog.name).join(", ") }}</span>

        <div class="flex flex-row w-full justify-between dark:text-gray-100">
            <span>£{{ scheduleBlock.lines.reduce((total, line) => total += convertToPounds(line.price), 0).toFixed(2) }}</span>
            <div
                v-if="isSelected"
            >
                <CheckIcon />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import { defineComponent, PropType } from "vue";
import ScheduleBlock from "../../schedule/types/ScheduleBlock";
import InvoiceUtils from "../utils/InvoiceUtils";
import CheckIcon from "../../../components/icons/CheckIcon.vue";
import MoneyUtils from "../../../utils/MoneyUtils";

export default defineComponent({
    components: {
        CheckIcon,
    },
    props: {
        scheduleBlock: {
            type: Object as PropType<ScheduleBlock>,
            required: true
        },
        isSelected: {
            type: Boolean,
            required: false,
            default: false
        },
        isSlimline: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props) {
        function durationOfScheduleBlock(scheduleBlock: ScheduleBlock) {
            const duration = DateTime.fromISO(scheduleBlock.date_to).diff(DateTime.fromISO(scheduleBlock.date_from));
            return duration.as("minutes");
        }

        const convertToPounds = (value: number) => MoneyUtils.convertToPounds(value);

        return {
            prettyPrintInvoiceDate: InvoiceUtils.prettyPrintInvoiceDateWithTimeAndDay,
            durationOfScheduleBlock,
            convertToPounds
        }
    },
})
</script>
