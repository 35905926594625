<template>
    <div class="grid grid-cols-12 flex p-4 pl-0 border-b dark:border-gray-900" id="feature-{{ feature.id }}">
        <div class="col-span-2 md:col-span-1 flex text-gray-400 dark:text-gray-100">
            <div class="grid grid-rows-3 flex-grow h-full">

                <!-- @TODO - get this working with flex -->
                <div
                    title="Upvote this feature"
                    class="row-start-1 cursor-pointer hover:anim flex flex-grow" style="margin-left:auto; margin-right:auto;"
                    :class="{
                        'text-green-500' : hasUserUpVotedThisFeature(),
                        'text-gray-400' : !hasUserUpVotedThisFeature(),
                     }"
                    @click="$emit('on-vote-up', feature)"
                >
                    <CaretIcon :direction="'up'"/>
                </div>
                <!-- @TODO - get this working with flex -->
                <div class="row-start-2 text-lg" style="margin-left:auto; margin-right:auto;">
                    {{ feature.score }}
                </div>
                <!-- @TODO - get this working with flex -->
                <div
                    title="Downvote this feature"
                    class="row-start-3 cursor-pointer" style="margin-left:auto; margin-right:auto;"
                    :class="{
                        'text-green-500' : hasUserDownVotedThisFeature(),
                        'text-gray-400' : !hasUserDownVotedThisFeature(),
                     }"
                    @click="$emit('on-vote-down', feature)"
                >
                    <CaretIcon :direction="'down'"/>
                </div>
            </div>
        </div>
        <div class="col-span-10 md:col-span-11 flex-grow dark:text-gray-100">
            <div class="text-xl font-bold capitalize">{{ feature.title }}</div>
            <div class="inline-flex gap-2">
                <FeatureStatus :status="feature.status" />
                <UserVotedTag v-show="hasUserVotedForThisFeature()" />
            </div>
            <div class="pt-4 dark:text-gray-400">
                {{ feature.description }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import VotingStatus from "../types/VotingStatus";
import FeatureStatus from "./FeatureStatus.vue";
import ThumbsDownIcon from "../../../components/icons/ThumbsDownIcon.vue";
import ThumbsUpIcon from "../../../components/icons/ThumbsUpIcon.vue";
import QuestionIcon from "../../../components/icons/QuestionIcon.vue";
import VotingFeature from "../types/VotingFeature";
import CaretIcon from "../../../components/icons/CaretIcon.vue";
import VoteType from "../types/VoteType";
import UserVotedTag from "./UserVotedTag.vue";

export default defineComponent({
    name: "FeatureCard",
    components: {UserVotedTag, CaretIcon, QuestionIcon, ThumbsUpIcon, ThumbsDownIcon, FeatureStatus},
    props: {
        feature: {
            type: Object as PropType<VotingFeature>,
            required: true
        }
    },
    emits:[
        'on-vote-up',
        'on-vote-down'
    ],
    setup(props){

        const canVoteForFeature = (feature: VotingFeature) => {
            return feature.status == VotingStatus.Planned && !feature.has_user_voted;
        }

        const hasUserVotedForThisFeature = () => {
            return !!props.feature.user_vote_type;
        }

        const hasUserUpVotedThisFeature = () => {
            return props.feature.user_vote_type === VoteType.Up;
        }

        const hasUserDownVotedThisFeature = () => {
            return props.feature.user_vote_type === VoteType.Down;
        }

        return {
            feature : props.feature,
            VotingStatus,
            canVoteForFeature,
            hasUserVotedForThisFeature,
            hasUserUpVotedThisFeature,
            hasUserDownVotedThisFeature
        }
    }
})
</script>

<style scoped>

</style>