<template>

    <CopyMultipleScheduleModal
        :is-visible="mediaFlags.md.grThanOrEq && copyModalIsVisible"
        :show-failed-message="showCopyModalFailedMessage"
        :show-success-message="showCopyModalSuccessMessage"
        @close="copyModalIsVisible = false; showCopyModalSuccessMessage = false; showCopyModalFailedMessage = false;"
        @save="saveScheduleCopy"
    />

    <!-- Title -->
    <PageTitleContainer>
        <div class="flex flex-grow mb-2 md:mb-0">
            <button class="hover:text-sky-blue-400 dark:text-gray-100 pl-2 pr-2" @click="onPrev">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
            </button>
            <button class="hover:text-sky-blue-400 dark:text-gray-100 pl-2 pr-2" @click="onNext">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
            </button>
            <div class="flex-grow pl-4">
                <h1 class="text-xl md:text-2xl font-semibold text-gray-900 dark:text-gray-100 flex-1">
                    {{calendarDays === 7
                    ? selectedDate.toFormat('MMMM yyyy')
                    : selectedDate.toFormat(`EEEE `) + selectedDate.toFormat(`d`) + SchedulerUtils.getDaySuffix(selectedDate.day) + selectedDate.toFormat(` MMMM`)}}
                </h1>
            </div>
            <div>
                <Clock />
            </div>
        </div>
    </PageTitleContainer>

    <div class="flex md:mb-0 justify-between gap-2 bg-white dark:bg-gray-800 p-2 border-b">
        <div class="flex gap-2">
            <Button @click="resetSelectedDate" variety="primary">
                Jump To Today
            </Button>
            <Button v-if="mediaFlags.md.grThanOrEq" @click="copyModalIsVisible = true" variety="secondary">
                Copy Week's Schedule Items
            </Button>
        </div>
        <div class="flex gap-2">
            <ToggleComponent id="show-cancelled-toggle" label-text="Show Cancelled" :is-toggled-on="showCancelled" @on-toggle-change="onChangeShowCancelled"/>
        </div>
    </div>

    <!-- Content -->
    <PageContentContainer class="gpu-render" :no-padding="true">
        <Scheduler
            v-if="mediaFlags.sm.lessThanOrEq"
            :show-cancelled="showCancelled"
            :selected-date="selectedDate"
            :days="1"
            :interval-minutes="intervalMinutes"
        />
        <Scheduler
            v-if="mediaFlags.md.grThanOrEq"
            :show-cancelled="showCancelled"
            :selected-date="selectedDate"
            :interval-minutes="intervalMinutes"
        />
    </PageContentContainer>

</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from "vue";
import {ChevronLeftIcon} from '@heroicons/vue/outline'
import Scheduler from "../components/Scheduler.vue";
import {DateTime} from 'luxon';
import Button from "../../../components/forms/Button.vue";
import DropdownSelector from "../../../components/forms/DropdownSelector.vue";
import FormComponent from "../../../components/forms/FormComponent.vue";
import {MqResponsive} from 'vue3-mq'
import QuestionIcon from "../../../components/icons/QuestionIcon.vue";
import {useMediaQuery} from "../../core/useMediaQuery";
import SchedulerUtils from "../utils/scheduler-utils";
import ToggleComponent from "../../../components/ToggleComponent.vue";
import {useRoute, useRouter} from "vue-router";
import PageTitleContainer from "../../../components/PageTitleContainer.vue";
import PageContentContainer from "../../../components/PageContentContainer.vue";
import useBusinessStore from "../../business/useBusinessStore";
import useTitleManager from "../../../utils/useTitleManager";
import Clock from "../../../components/Clock.vue";
import useUserStore from "../../user/useUserStore";
import {preferenceTypes} from "../../user/utils/preferences.utils";
import CopyMultipleScheduleModal from "../components/copying/CopyMultipleScheduleModal.vue";
import useSchedulerStore from "../useSchedulerStore";


export default defineComponent({
    name: "Schedule",
    components: {
        CopyMultipleScheduleModal,
        Clock,
        PageContentContainer,
        PageTitleContainer,
        ToggleComponent,
        QuestionIcon,
        FormComponent,
        DropdownSelector,
        Button,
        Scheduler,
        ChevronLeftIcon,
        MqResponsive
    },
    setup(){
        const route = useRoute();
        const router = useRouter();
        const { setTitlePrefix } = useTitleManager();

        const businessStore = useBusinessStore();
        const userStore = useUserStore();
        const schedulerStore = useSchedulerStore();

        const showCancelled = ref(userStore.getUserPreference(preferenceTypes.SHOW_CALENDAR_CANCELLED) === "1");

        const selectedDateAsString = computed({
            get: () => {
                if(route.query.selectedDate) {
                    return route.query.selectedDate as string;
                }
                return DateTime.now().toISO();
            },
            set: (value) => {
                router.push({
                    query: {
                        selectedDate:  value
                    }
                })
            }
        });

        const selectedDate = computed(() => {
            return DateTime.fromISO(selectedDateAsString.value)
        });

        const { mediaQuery, mediaFlags } = useMediaQuery("tailwind");

        const calendarDays = computed(() => {
            if(mediaQuery("sm", "<=")) {
                return 1;
            } else if(mediaQuery("md", ">=")) {
                return 7;
            }
        });

        const intervalMinutes = businessStore.activeBusiness.default_schedule_block_minutes ?? 30;

        const onNext = () => {
            selectedDateAsString.value = DateTime
                .fromISO(selectedDateAsString.value)
                .plus({days: calendarDays.value})
                .toISO();
        }

        const onPrev = () => {
            selectedDateAsString.value = DateTime
                .fromISO(selectedDateAsString.value)
                .minus({days: calendarDays.value})
                .toISO();
        }

        const onChangeShowCancelled = (ev: any) => {
            const newValue = !showCancelled.value
            userStore.updatePreference({name : preferenceTypes.SHOW_CALENDAR_CANCELLED, value: newValue ? "1":"0"});
            showCancelled.value = newValue;
        }

        const resetSelectedDate = (ev: any) => {
            selectedDateAsString.value = DateTime.now().toISO();
        }

        const goToBusiness = () => {
            router.push({path : "/business"});
        }

        onMounted(() =>{
            setTitlePrefix("Schedule");
        });

        const copyModalIsVisible = ref(false);
        const showCopyModalSuccessMessage = ref(false);
        const showCopyModalFailedMessage = ref(false);

        const saveScheduleCopy = async () => {
            const scheduleDays = SchedulerUtils.getScheduleDays(selectedDate.value, 7);

            const newStartDate = scheduleDays[6]
                .plus({days:1})
                .toISO();

            const newBlocks = await schedulerStore.copyRangeOfScheduledItems(
                scheduleDays[0].toISO(),
                scheduleDays[6].set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                    millisecond: 999
                }).toISO(),
                newStartDate
            );
            newBlocks && newBlocks.length > 0 ? showCopyModalSuccessMessage.value = true : showCopyModalFailedMessage.value = true;
        }

        return {
            onNext,
            onPrev,
            onChangeShowCancelled,
            showCancelled,
            selectedDate,
            resetSelectedDate,
            goToBusiness,
            mediaQuery,
            calendarDays,
            SchedulerUtils,
            mediaFlags,
            intervalMinutes,

            copyModalIsVisible,
            showCopyModalSuccessMessage,
            showCopyModalFailedMessage,
            saveScheduleCopy
        }
    }
})
</script>

<style scoped>
.gpu-render {
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}
</style>
