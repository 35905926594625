<template>
    <div class="bg-white h-full w-full flex flex-col lg:flex-row justify-between p-4 sm:p-8">
        <div>
            <h2 class="text-2xl font-extrabold text-gray-900 mb-6">
                You're all set up and ready to go!
            </h2>
            <div class="flex flex-col gap-2">
                <p>You're ready to start adding customers and getting those appointments in the calendar!</p>
                <!--
                <p>Over the next few days, we'll be sending you some tips and tricks for getting the most of out Walk Buddy.</p>
                <p>A link to the first of these, 'How to a add a customer', should be in your inbox now!</p>
                <p>Alternatively, you can watch the video at anytime along with all of our How To guides over at our Help page.</p>
                -->
            </div>
        </div>
        <div class="self-end">
            <div class="flex justify-center items-center gap-8">
                <Button variety="primary" @click="navigateToLandingPage">
                    Let's go!
                </Button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import router from "../../../router";

export default defineComponent({
    name: "OnboardingComplete",
    setup(){
        const navigateToHelpPage = async function(){
            await router.push({path : "/help"});
        }

        const navigateToLandingPage = async function(){
            await router.push({path : "/dashboard"});
        }

        return {
            navigateToHelpPage,
            navigateToLandingPage
        }
    }
})
</script>

<style scoped>
</style>
