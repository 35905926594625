<template>
    <!-- see https://github.com/vueform/multiselect/issues/99 apparently fixed in 2.1.1 but doesn't seem to be -->
    <!-- had to add a hack to clear the search on select -->
    <Multiselect
        :classes="multiSelectClasses"
        ref="multiselect"
        @select="$refs.multiselect.clearSearch()"
        style="width: 100%"
        placeholder="Select.."
        mode="single"
        valueProp="id"
        trackBy="searchName"
        label="name"
        v-model="currentValue"
        :options="customerDogs"
        :minChars="1"
        :searchable="true"
        @change="handleOnChange"
        @open="scrollToSelectList"
    >
        <template v-slot:option="{ option }">
            <div class="multiselect-option-text">
                <b>{{ option.customer?.name }}</b> {{ option.name }}
            </div>
        </template>
    </Multiselect>
</template>

<script lang="ts">
import {defineComponent, nextTick, onMounted, ref, toRefs, watch} from 'vue'
import CustomerDogForm from './CustomerDogForm.vue';
import CustomerDog from "../types/CustomerDog";
import useCustomerStore from "../useCustomerStore";

export default defineComponent({
    components: { CustomerDogForm },
    props:{
        dogList: {
            type: Array,
            required: true
        },
        value: {
            type: Object,
            required: false
        }
    },
    setup(props, context) {
        // const customerStore = useCustomerStore();


        const multiselect = ref(null);

        let currentValue = ref();
        const customerDogs: CustomerDog[] = props.dogList.map((x: CustomerDog) => {
            x.searchName = x.customer ? x.customer?.name + " " + x.name : x.name;
            return x;
        });

        let handleOnChange = (value: number) => {
            let currentItem = customerDogs.find(dog => dog.id == value);
            if(currentItem){
                currentItem.disabled = true;
            }
            context.emit('update:value', customerDogs.find(dog => dog.id == value));
        }

        const { value } = toRefs(props);
        watch(value, val => {
            if(val) currentValue.value = val.id;
        }, { immediate: true })


        const multiSelectClasses = {
            container: 'relative mx-auto w-full flex items-center justify-end cursor-pointer bg-white dark:bg-gray-500 text-base leading-snug outline-none',
            containerDisabled: 'cursor-default bg-gray-100 dark:bg-gray-800',
            containerOpen: 'rounded-b-none',
            containerOpenTop: 'rounded-t-none',
            containerActive: 'ring ring-sky-blue-500 ring-opacity-30',
            singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
            singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
            multipleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
            search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white dark:bg-gray-500 rounded pl-3.5 rtl:pl-0 rtl:pr-3.5',
            tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 rtl:pl-0 rtl:pr-2',
            tag: 'bg-sky-blue-500 text-white dark:text-gray-100 text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
            tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
            tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
            tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
            tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
            tagsSearch: 'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full',
            tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
            placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
            caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
            caretOpen: 'rotate-180 pointer-events-auto',
            clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
            clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
            spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
            inifite: 'flex items-center justify-center w-full',
            inifiteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
            dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white dark:bg-gray-500 flex flex-col rounded-b',
            dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
            dropdownHidden: 'hidden',
            options: 'flex flex-col p-0 m-0 list-none',
            optionsTop: '',
            group: 'p-0 m-0',
            groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
            groupLabelPointable: 'cursor-pointer',
            groupLabelPointed: 'bg-gray-300 text-gray-700',
            groupLabelSelected: 'bg-sky-blue-600 text-white',
            groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
            groupLabelSelectedPointed: 'bg-sky-blue-600 text-white opacity-90',
            groupLabelSelectedDisabled: 'text-sky-blue-100 bg-sky-blue-600 bg-opacity-50 cursor-not-allowed',
            groupOptions: 'p-0 m-0',
            option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
            optionPointed: 'text-gray-800 dark:text-gray-800 bg-gray-100 dark:bg-gray-400',
            optionSelected: 'text-white bg-sky-blue-500',
            optionDisabled: 'text-gray-300 dark:text-gray-400 cursor-not-allowed',
            optionSelectedPointed: 'text-white bg-sky-blue-500 opacity-90',
            optionSelectedDisabled: 'text-sky-blue-100 bg-sky-blue-500 bg-opacity-50 cursor-not-allowed',
            noOptions: 'py-2 px-3 text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-500 text-left',
            noResults: 'py-2 px-3 text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-500 text-left',
            fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
            spacer: 'h-9 py-px box-content',
        }
        const scrollToSelectList = () => {
            nextTick(() => {
                if(!multiselect.value){
                    return;
                }
                multiselect.value.input.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest"
                });
            })

        }

        return {
            multiselect,
            handleOnChange,
            currentValue,
            customerDogs,
            multiSelectClasses,
            scrollToSelectList
        }
    }
})
</script>
<style>
/* Forces subsequent label to vertically align correctly */
.multiselect-single-label:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
/* Output of calc is in px allowing for text-overflow styling to be applied */
.multiselect-single-label {
    width: calc(85%);
    white-space: nowrap;
}
.multiselect-option-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
