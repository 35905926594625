<template>

    <Card :title="title" class="m-4">
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
            </svg>
        </template>
        <div class="flex flex-grow mt-2 dark:text-gray-100">
            {{ description }}
        </div>
        <div class="flex">
            <Button
                variety="primary"
                @click="goToSubscriptions"
                class="mt-4 w-64"
            >
                Go to Subscription plans
            </Button>
        </div>
    </Card>

</template>

<script lang="ts">
import {defineComponent} from "vue";
import Card from "./Card.vue";
import Button from "./forms/Button.vue";
import router from "../router";

export default defineComponent({
    name: "SubscribeCard",
    components:{
        Button,
        Card
    },
    props:{
        title:{
            type: String,
            required: false,
            default: "Subscription Required"
        },
        description:{
            type: String,
            required:false,
            default: "Subscribe to gain access to this section!"
        }
    },
    setup(props){

        const goToSubscriptions = async () => {
            await router.push("/business/subscribe");
        }
        return {
            goToSubscriptions

        }
    }
})
</script>

<style>

</style>