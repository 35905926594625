<template>
    <Form v-if="isEditing" @submit.prevent="onSubmit">
        <div class="flex  flex-col md:flex-row gap-8">
            <div class="divide divide-y flex-1">
                <FormComponent :hasTopPadding="false">
                    <TextInput
                        id="dog-name"
                        label="Name"
                        placeholder="Lassy"
                        :isEditing="isEditing"
                        :value="name"
                        :errors="nameError"
                        :required="true"
                        @update:value="v => (name = v)"
                    />
                </FormComponent>

                <FormComponent>
                    <TextInput
                        id="dog-yob"
                        label="Year of Birth"
                        type="number"
                        placeholder="e.g: 2015"
                        :isEditing="isEditing"
                        :value="yearOfBirth"
                        :errors="yearOfBirthError"
                        :step="1"
                        :displayValue="yearOfBirthDisplay"
                        @update:value="v => (yearOfBirth = v || null)"
                    />
                </FormComponent>

                <FormComponent>
                    <BreedMultiSelector
                        id="dog-breed"
                        label="Breed"
                        :value="breed"
                        :errors="breedError"
                        :is-editing="isEditing"
                        @update:value="v => (breed = v)"
                    />
                </FormComponent>

                <FormComponent>
                    <label for="dog-surcharges" class="block font-medium text-gray-700 dark:text-gray-100">
                        Surcharges
                    </label>
                    <MultiSelectDropdown
                        id="dog-surcharges"
                        label="name"
                        mode="multiple"
                        :close-on-select="false"
                        valueProp="id"
                        track-by="name"
                        :isEditing="isEditing"
                        :selectors="surchargesList"
                        :value="surcharges"
                        :errors="surchargesError"
                        @update:value="v => handleSurgchargesChange(v)"
                    />
                </FormComponent>

                <FormComponent>
                    <RadioButtonGroup
                        label="Gender"
                        id="dog-gender"
                        name="dog-gender"
                        :value="gender"
                        :required="true"
                        :isEditing="isEditing"
                        :selections="dogGenders"
                        @update:value="v => (gender = v)"
                    />
                </FormComponent>

                <FormComponent>
                    <RadioButtonGroup
                        id="dog-neutered"
                        label="Neutered?"
                        name="dog-neutered"
                        :value="neutered"
                        :required="true"
                        :isEditing="isEditing"
                        :selections="dogNeuteredOptions"
                        @update:value="v => (neutered = v)"
                    />
                </FormComponent>

                <FormComponent>
                    <RadioButtonGroup
                        id="dog-status"
                        label="Status"
                        name="dog-status"
                        :value="status"
                        :required="true"
                        :isEditing="isEditing"
                        :selections="dogStatuses"
                        @update:value="v => (status = v)"
                    />
                </FormComponent>

                <FormComponent>
                    <TextInput
                        id="dog-dietary"
                        label="Dietary Needs"
                        placeholder="Enter dietary needs."
                        :isEditing="isEditing"
                        :value="dietaryNeeds"
                        :errors="dietaryNeedsError"
                        @update:value="v => (dietaryNeeds = v)"
                    />
                </FormComponent>

                <FormComponent :hasBottomPadding="false">
                    <TextArea
                        id="dog-notes"
                        label="Notes"
                        placeholder="Enter notes."
                        :rows="5"
                        :isEditing="isEditing"
                        :value="notes"
                        :errors="notesError"
                        @update:value="v => (notes = v)"
                    />
                </FormComponent>
            </div>

            <div class="flex flex-col align-center justify-start">
                <ImageUpload
                    :initialImageUrlToDisplay="dogToEdit?.image_path"
                    :is-editing="isEditing"
                    @dataChanged="$emit('dogImageChanged', $event)"
                    @imageDeleted="$emit('dogImageDeleted')"
                />
            </div>
        </div>
    </Form>
    <div v-else>
        <div class="grid grid-cols-3 md:grid-cols-4 mb-2">
            <div class="col-span-1"><strong>Name :</strong></div>
            <div class="col-span-2 md:col-span-3">{{ name }}</div>
        </div>

        <div class="grid grid-cols-3 md:grid-cols-4 mb-2">
            <div class="col-span-1"><strong>Year of Birth :</strong></div>
            <div class="col-span-2 md:col-span-3">{{ yearOfBirthDisplay }}</div>
        </div>

        <div class="grid grid-cols-3 md:grid-cols-4 mb-2">
            <div class="col-span-1"><strong>Breed :</strong></div>
            <div class="col-span-2 md:col-span-3">{{ breedDisplay }}</div>
        </div>

        <div class="grid grid-cols-3 md:grid-cols-4 mb-2">
            <div class="col-span-1"><strong>Surcharges :</strong></div>
            <div class="col-span-2 md:col-span-3">{{ dogSurchargesDisplay ?? "None Selected" }}</div>
        </div>

        <div class="grid grid-cols-3 md:grid-cols-4 mb-2">
            <div class="col-span-1"><strong>Gender :</strong></div>
            <div class="col-span-2 md:col-span-3 first-letter-upper">{{ gender }}</div>
        </div>

        <div class="grid grid-cols-3 md:grid-cols-4 mb-2">
            <div class="col-span-1"><strong>Neutered? :</strong></div>
            <div class="col-span-2 md:col-span-3 first-letter-upper">{{ neutered }}</div>
        </div>

        <div class="grid grid-cols-3 md:grid-cols-4 mb-2">
            <div class="col-span-1"><strong>Status :</strong></div>
            <div class="col-span-2 md:col-span-3 first-letter-upper">{{ status }}</div>
        </div>

        <div class="grid grid-cols-3 md:grid-cols-4 mb-2">
            <div class="col-span-1"><strong>Dietary Needs :</strong></div>
            <div class="col-span-2 md:col-span-3">{{ dietaryNeeds ?? "Not Specified" }}</div>
        </div>

        <div class="grid grid-cols-3 md:grid-cols-4 mb-2">
            <div class="col-span-1"><strong>Notes:</strong></div>
            <div class="col-span-2 md:col-span-3">{{ notes ?? "Not Specified" }}</div>
        </div>

        <div class="grid grid-cols-3 md:grid-cols-4 mb-2">
            <ImageUpload
                v-if="dogToEdit?.image_path"
                :initialImageUrlToDisplay="dogToEdit?.image_path"
                :is-editing="false"
            />
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import useMiscInformationStore from "../../../utils/useMiscInformationStore";
import useBusinessStore from "../../business/useBusinessStore";
import CustomerDog from "../types/CustomerDog";
import BreedMultiSelector from "../../breeds/components/BreedMultiSelector.vue";
import TextArea from "../../../components/forms/TextArea.vue";
import MultiSelectDropdown from "../../../components/forms/MultiSelectDropdown.vue";
import Surcharge from "../../business/types/Surcharge";
import SurchargeUtils from "../../business/utils/SurchargeUtils";

export default defineComponent({
    components: {
        MultiSelectDropdown,
        TextArea,
        BreedMultiSelector
    },
    props: {
        isEditing: {
            type: Boolean,
            required: false,
            default: false
        },
        dogToEdit: {
            type: Object as PropType<CustomerDog | undefined>,
            required: false,
            default: undefined
        },
    },
    setup(props, context) {
        const miscInformationStore = useMiscInformationStore();
        const businessStore = useBusinessStore();

        const schema = yup.object({
            name: yup.string().required("Name is required.").max(255),
            age: yup.number().integer().nullable().min(2000, "Dog must not have been born before the year 2000.").max(new Date().getFullYear() + 1, "Dog must not have been born after the current year."),
            breed_id: yup.number().nullable(),
            dog_category_id: yup.number().nullable(),
            gender: yup.string().required(),
            status: yup.string().required(),
            neutered: yup.string().required(),
            dietary: yup.string().nullable(),
            notes: yup.string().nullable(),
        });

        const { handleSubmit } = useForm({
            validationSchema: schema,
        });

        const onSubmit = handleSubmit(values => {
            context.emit("onSubmit", values);
        });

        const { value: name, errorMessage: nameError } = useField("name");
        const { value: yearOfBirth, errorMessage: yearOfBirthError } = useField("age");
        const { value: breed, errorMessage: breedError } = useField("breed_id");
        const { value: dogCategory, errorMessage: dogCategoryError } = useField("dog_category_id");
        const { value: surcharges, errorMessage: surchargesError } = useField("surcharges");
        const { value: gender, errorMessage: genderError } = useField("gender");
        const { value: neutered, errorMessage: neuteredError } = useField("neutered");
        const { value: status, errorMessage: statusError } = useField("status");
        const { value: dietaryNeeds, errorMessage: dietaryNeedsError } = useField("dietary");
        const { value: notes, errorMessage: notesError } = useField("notes");

        const breeds = computed(() => miscInformationStore.breeds.map(b => ({ name: b.name, value: b.id })));
        const dogCategories = computed(() => businessStore.dogCategories.map(d => ({ name: d.name, value: d.id })));
        const dogGenders = computed(() => miscInformationStore.dogGenders);
        const dogStatuses = computed(() => miscInformationStore.dogStatuses);
        const dogNeuteredOptions = computed(() => miscInformationStore.dogNeuteredOptions);
        const surchargesList = computed(() => businessStore.surcharges);

        const yearOfBirthDisplay = computed(() => {
            const years = (new Date().getFullYear() - yearOfBirth.value);
            const yearsText = years > 1 ? "yrs old" : "yr old";
         return `${yearOfBirth.value ?
             yearOfBirth.value + " " + (yearOfBirth.value === (new Date().getFullYear()) ? '' : ' (' + years + yearsText + ')' )
             : 'N/A'
         }`;
        });
        const breedDisplay = computed(() => breeds.value.find(b => b.value === breed.value)?.name ?? "Unknown");
        const dogCategoryDisplay = computed(() => dogCategories.value.find(dc => dc.value === dogCategory.value)?.name ?? "Not Specified");
        const dogSurchargesDisplay = computed(() => surcharges.value?.map(sc => surchargesList.value.find(sl => sl.id == sc)?.name)?.join(", "));


        const handleSurgchargesChange = (value: number) => {
            surcharges.value = value;
        }

        if (props.dogToEdit) {
            name.value = props.dogToEdit.name;
            yearOfBirth.value = props.dogToEdit.age;
            breed.value = props.dogToEdit.breed_id;
            dogCategory.value = props.dogToEdit.dog_category_id;
            gender.value = props.dogToEdit.gender;
            neutered.value = props.dogToEdit.neutered;
            status.value = props.dogToEdit.status;
            dietaryNeeds.value = props.dogToEdit.dietary;
            notes.value = props.dogToEdit.notes;
            surcharges.value = props.dogToEdit.surcharges ?? [];
        } else {
            gender.value = "male";
            status.value = "active";
            neutered.value = "unknown";
            surcharges.value = [];
        }

        return {
            breeds,
            dogCategories,
            dogGenders,
            dogStatuses,
            dogNeuteredOptions,
            surchargesList,

            onSubmit,

            name,
            nameError,

            yearOfBirth,
            yearOfBirthError,
            yearOfBirthDisplay,

            breed,
            breedError,
            breedDisplay,

            dogCategory,
            dogCategoryError,
            dogCategoryDisplay,

            dogSurchargesDisplay,

            gender,
            genderError,

            neutered,
            neuteredError,

            status,
            statusError,

            dietaryNeeds,
            dietaryNeedsError,

            notes,
            notesError,

            surcharges,
            surchargesError,
            handleSurgchargesChange
        }
    },
})
</script>
<style scoped>
.first-letter-upper:first-letter {
    text-transform: uppercase;
}
</style>
