<template>
    <div style="display: flex; flex-direction: column; align-items: center; justify-content: center"
         class="h-full flex overflow-hidden bg-sky-blue-400">
        <div style="display: flex; align-items: center; flex-direction: column">
            <img style="max-width: 400px" class="mb-4" alt="loading Image of a dog" :src="failDog">
            <div class="text-center text-xl mt-2">Oops... Looks like the dogs are causing issues again. Click <router-link to="/" class="underline">here</router-link> to go back to the home page</div>
        </div>
    </div>
</template>

<script>
import failDog from "./assets/gone-wrong.png";

import {defineComponent} from "vue";
export default defineComponent( {
    name: "404Page",
    setup(){
        return{
            failDog
        }
    }
});
</script>

<style scoped>

</style>
