<template>
    <Modal
        title="Generate Invoices from Walks"
        size="xlarge"
        :visible="true"
    >
        <template #icon>
            <CollectionIcon />
        </template>

        <InvoiceWizardFilters
            v-if="!isSaving"
            :fromDate="fromDate"
            :toDate="toDate"
            @fromDateChanged="fromDateChanged"
            @toDateChanged="toDateChanged"
        />

        <div v-if="!isDateRangeValid" class="text-red-600 text-xs mt-1">
            {{ dateRangeValidationError }}
        </div>

        <div class="generate-invoice-modal-content-wrapper flex flex-col justify-center">
            <div class="mt-4 text-center border p-1 bg-red-300" v-if="showNoSchedulesFound">
                No Schedules Found, please refine your date range
            </div>

            <div v-if="!isSaving" class="mt-4 flex flex-col gap-2">
                <div class="text-center border p-1" v-if="scheduleBlocks.length > 0">
                    Click on walks below you would like to generate invoices from
                </div>

                <div v-if="!isBlockSelectionValid" class="text-red-600 text-xs text-center">
                    {{ blockSelectionValidationError }}
                </div>

                <div class="flex flex-col gap-2" v-for="customerScheduleBlocks in scheduleBlocks" :key="customerScheduleBlocks">
                    <h1 class="font-bold text-lg text-gray-500">{{ customerScheduleBlocks.customer.name }}</h1>
                    <div class="grid grid-cols-3 gap-2">
                        <InvoiceWizardScheduleBlock
                            v-for="scheduleBlock in customerScheduleBlocks.scheduleBlocks"
                            :key="scheduleBlock"
                            :scheduleBlock="scheduleBlock"
                            :isSelected="scheduleBlockIsSelected(scheduleBlock, customerScheduleBlocks.customer)"
                            @click.stop="scheduleBlockSelected(scheduleBlock, customerScheduleBlocks.customer)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <LoadingSpinner v-if="isSaving" />
        <template #footer-buttons>
            <Button
                variety="plain"
                :loading="isSaving"
                @click.stop="$emit('closed')"
            >
                Close
            </Button>

            <Button
                variety="primary"
                :loading="isSaving"
                @click.stop="wizardSaved"
                :disabled="Object.keys(selectedScheduleBlocks).length === 0"
            >
                Create
            </Button>
        </template>
    </Modal>
</template>

<script lang="ts">
import _ from "lodash";
import { DateTime } from "luxon";
import { defineComponent, onMounted, ref} from "vue";
import InvoiceApiClient from "../../../api/InvoiceApiClient";
import SchedulerApiClient from "../../../api/SchedulerApiClient";
import CollectionIcon from "../../../components/icons/CollectionIcon.vue";
import { Customer } from "../../customer/types/Customer";
import ScheduleBlock from "../../schedule/types/ScheduleBlock";
import CreatedInvoice from "../types/CreatedInvoice";
import PaymentType from "../types/PaymentType";
import ScheduleLineToInvoiceLineUtils from "../utils/ScheduleLineToInvoiceLineUtils";
import InvoiceWizardFilters from "./InvoiceWizardFilters.vue";
import InvoiceWizardScheduleBlock from "./InvoiceWizardScheduleBlock.vue";
import DateUtils from "../../../utils/DateUtils";

interface CustomerScheduleBlocks {
    customer: Customer;
    scheduleBlocks: ScheduleBlock[];
}

interface SelectedCustomerScheduleBlocks {
    [key: number]: ScheduleBlock[]
}

export default defineComponent({
    components: {
        CollectionIcon,
        InvoiceWizardFilters,
        InvoiceWizardScheduleBlock,
    },
    setup(props, context) {
        const isSaving = ref(false);
        const fromDate = ref(DateTime.utc().minus({ days: 14 }).set({ hour: 0, minute: 0, second: 0}).toISO() as string | undefined);
        const toDate = ref(DateTime.utc().set({ hour: 23, minute: 59, second: 59}).toISO() as string | undefined);
        const selectedScheduleBlocks = ref({} as SelectedCustomerScheduleBlocks);
        const scheduleBlocks = ref([] as CustomerScheduleBlocks[]);
        const showNoSchedulesFound = ref(false);
        const isDateRangeValid = ref(true);
        const dateRangeValidationError = ref("");
        const isBlockSelectionValid = ref(true);
        const blockSelectionValidationError = ref("");

        onMounted(async () => {
            await reloadScheduleBlocks();
        });

        async function fromDateChanged(date: string | undefined) {
            fromDate.value = date ? new Date(date).toISOString() : undefined;

            validateDateRange();

            if(!isDateRangeValid.value) {
                return;
            }

            await reloadScheduleBlocks();
        }

        async function toDateChanged(date: string | undefined) {
            toDate.value = date ? new Date(date).toISOString() : undefined;
            validateDateRange();

            if(!isDateRangeValid.value) {
                return;
            }
            await reloadScheduleBlocks();
        }

        function validateDateRange() {
            if(!fromDate.value || !toDate.value) {
                dateRangeValidationError.value = "Both From Date and To Date must be entered";
                isDateRangeValid.value = false;
                return;
            }

            const parsedFromDate = DateTime.fromISO(fromDate.value as unknown as string).toLocal()
            const parsedToDate = DateTime.fromISO(toDate.value as unknown as string).toLocal();

            if(parsedToDate < parsedFromDate) {
                dateRangeValidationError.value = "From Date cannot be before To Date";
                isDateRangeValid.value = false;
                return;
            }

            if(DateUtils.getDiffInMonths(parsedFromDate, parsedToDate) > 1) {
                dateRangeValidationError.value = "To Date cannot be more than a month after From Date";
                isDateRangeValid.value = false;
                return;
            }

            dateRangeValidationError.value = "";
            isDateRangeValid.value = true;
        }

        function scheduleBlockIsSelected(scheduleBlock: ScheduleBlock, customer: Customer): boolean {
            return customer.id! in selectedScheduleBlocks.value && selectedScheduleBlocks.value[customer.id!].some(x => x.id === scheduleBlock.id);
        }

        function scheduleBlockSelected(scheduleBlock: ScheduleBlock, customer: Customer) {
            // If the customer is not selected already, we can just chuck them in as it must be an add!
            if (!selectedScheduleBlocks.value[customer.id!]) {
                selectedScheduleBlocks.value[customer.id!] = [scheduleBlock];
                validateBlockSelection();
                return;
            }

            const indexOfScheduleBlock = selectedScheduleBlocks.value[customer.id!].findIndex(s => s.id === scheduleBlock.id);

            if (indexOfScheduleBlock === -1) {
                selectedScheduleBlocks.value[customer.id!].push(scheduleBlock);
            } else {
                selectedScheduleBlocks.value[customer.id!].splice(indexOfScheduleBlock, 1);

                if(selectedScheduleBlocks.value[customer.id!].length === 0) {
                    delete selectedScheduleBlocks.value[customer.id!];
                }
            }

            validateBlockSelection();
        }

        function validateBlockSelection() {
            let selectedCustomers = Object.entries(selectedScheduleBlocks.value);

            if(selectedCustomers.length < 1) {
                isBlockSelectionValid.value = false;
                blockSelectionValidationError.value = "At least one schedule item must be selected";
                return;
            }

            isBlockSelectionValid.value = true;
            blockSelectionValidationError.value = "";
        }

        async function reloadScheduleBlocks() {
            showNoSchedulesFound.value = false
            scheduleBlocks.value = [];

            const scheduleBlockResponse = await SchedulerApiClient.listSchedulingBlocks(
                { date_from: fromDate.value!, date_to: toDate.value! },
                false,
                Number.MAX_SAFE_INTEGER,
                1
            );

            if (!scheduleBlockResponse.success || scheduleBlockResponse.data?.length === 0) {
                showNoSchedulesFound.value = true
                return;
            }

            const uniqueCustomers = _.uniqBy(scheduleBlockResponse.data!.flatMap(s => s.lines.map(l => l.customer)).filter(x => x), i => i!.id);

            for (const uniqueCustomer of uniqueCustomers)
            {
                scheduleBlocks.value.push({
                    customer: uniqueCustomer!,
                    scheduleBlocks: scheduleBlockResponse.data!
                        .filter(x => x.lines.some(y => y.customer_id === uniqueCustomer!.id))
                        .map(scheduleBlock => ({ ...scheduleBlock, lines: scheduleBlock.lines.filter(line => line.customer_id === uniqueCustomer!.id )}))!
                })
            }
        }

        async function wizardSaved() {
            validateBlockSelection();

            if(!isBlockSelectionValid.value) {
                return;
            }

            isSaving.value = true;

            try {
                for (const [customerId, customerScheduleBlocks] of Object.entries(selectedScheduleBlocks.value)) {
                    const invoice: CreatedInvoice = {
                        customer_id: parseInt(customerId),
                        payment_type: PaymentType.DIRECT_DEBIT,
                        lines: customerScheduleBlocks.flatMap(ScheduleLineToInvoiceLineUtils.convertScheduleLinesToInvoiceLines)
                    };
                    await InvoiceApiClient.createInvoice(invoice);
                }

                context.emit("itemsSaved");
                context.emit("closed");
            } finally {
                isSaving.value = false;
            }
        }

        return {
            isSaving,
            fromDate,
            toDate,
            scheduleBlocks,
            showNoSchedulesFound,

            fromDateChanged,
            toDateChanged,
            scheduleBlockIsSelected,
            scheduleBlockSelected,
            wizardSaved,
            isDateRangeValid,
            dateRangeValidationError,
            isBlockSelectionValid,
            blockSelectionValidationError,
            selectedScheduleBlocks
        }
    },
})
</script>

<style scoped>
.generate-invoice-modal-content-wrapper {
    min-height: 18rem;
}
</style>
