<template>
    <label :for="id" class="block font-medium text-gray-700 dark:text-gray-50">
        {{label}}{{ required && isEditing ? "*" : ""}}
    </label>
    <div  v-if="isEditing" class="mt-0 col-span-2">
        <select
            :id="id"
            :name="label"
            v-model="internalValue"
            class="dropdown-selector max-w-lg block dark:bg-gray-500
                focus:ring-sky-blue-200 focus:border-sky-blue-500 border-gray-300 dark:border-gray-800 w-full shadow-sm sm:max-w-xs sm:border-gray-300 rounded-md text-sm dark:text-gray-50"
        >
            <option selected disabled value="-1" v-if="required">Please select an option...</option>
            <option v-for="option in selectors" :value="option.value" :key="String(option.value)">{{option.name}}</option>
        </select>
        <span class="text-red-600 text-xs">{{errors}}</span>
    </div>
    <label v-else class="block text-gray-700 dark:text-gray-100 col-span-2">
        {{ readOnlyValue }}
    </label>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue";
import SelectionOption from "../../types/SelectionOption";

export default defineComponent({
    name: "DropdownSelector",
    props:{
        label:{
            required: false,
            type: String,
        },
        value:{
            required: true,
            default: "-1"
        },
        errors:{
            required: false,
            type: String,
        },
        placeholder:{
            required: false,
            type: String,
            default: ''
        },
        isEditing: {
            required: false,
            type: Boolean,
            default: false,
        },
        selectors:{
            required: true,
            type: Array as PropType<SelectionOption[]>,
            default: []
        },
        id:{
            required: true,
            type: String
        },
        required: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    setup(props, context){
        const internalValue = computed({
            get: () => {
                return props.value;
            },
            set: (newValue) => {
                context.emit('update:value', newValue);
            }
        });

        const readOnlyValue = computed(() => props.selectors.find(s => s.value === props.value)?.name);

        return {
            internalValue,
            readOnlyValue
        }
    }
})
</script>
