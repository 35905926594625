<template>
    <!-- Loading Section -->
    <div v-if="!activeInvoice || invoiceLoading" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-gray-400 opacity-30 h-full w-full rounded-lg">
    </div>
    <div v-if="!activeInvoice || invoiceLoading" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
        <LoadingSpinner class="w-32" />
    </div>

    <main class="flex-1 relative overflow-y-auto focus:outline-none pb-4">

        <PageTitleContainer>
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100 flex flex-1 flex-row align-center gap-2">
                    <button class="hover:text-sky-blue-400" @click="goBackToInvoices">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <span v-if="activeInvoice" class="p-4 bg-gray-100 dark:bg-gray-700 rounded-full text-xs flex flex-initial self-center">{{ friendlyInvoiceStatus }}</span>
                    <h1 v-if="activeInvoice" class="self-center">Invoice for customer {{ activeInvoice.customer.name }}</h1>
                </div>

                <div class="flex flex-row justify-center gap-2 dark:text-gray-100">
                    <div v-if="activeInvoice" class="flex">
                        <span class="text-xs self-start hidden sm:block mr-2" v-if="activeInvoice.status !== InvoiceStatus.DRAFT">Mark as:</span>
                        <InvoiceWorkflowButtons
                            :invoice="activeInvoice"
                            :isEditingAnything="isEditingDetails || isEditingLines"
                            @statusSelected="invoiceStatusUpdated"
                            class="text-xs sm:text-base"
                        />
                    </div>
                </div>
        </PageTitleContainer>

        <PageContentContainer>
            <div class="flex flex-col">

                <div v-if="!business.account_number || !business.account_name || !business.sort_code" class="font-extrabold flex p-4 bg-sky-orange-600 flex-col">
                    <span>Your Business bank account details are incomplete, please fill in the blanks before sending invoices.</span>
                    <span class="flex-grow-0">
                        <Button variety="primary" :is-small-button="true" class="border-0 flex-grow-0 focus:border-0 hover:border-0" @click="goToBusinessSettings">
                            Go to Business Settings
                        </Button>
                    </span>
                </div>
                <div class="p-4 grid grid-cols-1 gap-4 xl:grid-cols-6 2xl:grid-cols-4">

                    <div class="flex flex-col flex-grow gap-y-4 xl:col-span-4 2xl:col-span-3">
                        <Card
                            title="Invoice Details"
                        >
                            <template #icon>
                                <ChartIcon />
                            </template>

                            <InvoiceDetailsForm
                                v-if="activeInvoice"
                                ref="invoiceDetailsForm"
                                :is-editing="isEditingDetails"
                                :customer-editable="false"
                                :show-buttons="false"
                                :invoice="activeInvoice"
                                @onSubmit="invoiceDetailsUpdated"
                                @requestReminder="sendReminder"
                            />

                            <template #buttons>
                                <Button
                                    v-if="!isEditingDetails"
                                    variety="primary"
                                    :disabled="activeInvoice == null || isEditingLines || activeInvoice.status === InvoiceStatus.PAID"
                                    @click.stop="isEditingDetails = true"
                                >
                                    Edit
                                </Button>
                                <Button
                                    variety="plain"
                                    v-if="isEditingDetails"
                                    @click.stop="isEditingDetails = false"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    v-if="isEditingDetails"
                                    variety="primary"
                                    @click.stop="$refs.invoiceDetailsForm.onSubmit()"
                                >
                                    Save
                                </Button>
                            </template>
                        </Card>

                        <Card
                            title="Invoice Lines"
                        >
                            <template #icon>
                                <CollectionIcon />
                            </template>

                            <template #buttons>
                                <Button
                                    v-if="!isEditingLines"
                                    :disabled="activeInvoice == null || !invoiceEditable || isEditingDetails"
                                    variety="primary"
                                    @click.stop="isEditingLines = true"
                                >
                                    Edit
                                </Button>
                                <Button
                                    variety="plain"
                                    v-if="isEditingLines"
                                    @click.stop="handleCancelEditingLines"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    v-if="isEditingLines"
                                    variety="primary"
                                    @click.stop="$refs.invoiceLinesForm.onSubmit()"
                                >
                                    Save
                                </Button>
                            </template>

                            <InvoiceLinesForm
                                v-if="activeInvoice"
                                ref="invoiceLinesForm"
                                :isEditing="isEditingLines"
                                :invoice="activeInvoice"
                                :vatRegistered="businessVatRegistered"
                                @onSubmit="invoiceLinesUpdated"
                            />
                        </Card>
                    </div>

                    <div class="flex flex-col gap-y-4 xl:col-span-2 2xl:col-span-1">
                        <Card
                            title="Financials"
                        >
                            <template #icon>
                                <CashIcon />
                            </template>

                            <InvoiceFinancialsForm
                                v-if="activeInvoice"
                                :invoice="activeInvoice"
                                :vatRegistered="businessVatRegistered"
                            />
                        </Card>

                        <InvoiceDocumentCard
                            v-if="activeInvoice && activeInvoice.pdf_url"
                            :pdfUrl="activeInvoice.pdf_url"
                        />
                    </div>
                </div>
            </div>
        </PageContentContainer>
    </main>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, ref, watch} from "vue";
import DownloadIcon from "../../../components/icons/DownloadIcon.vue";
import ChartIcon from "../../../components/icons/ChartIcon.vue";
import CashIcon from "../../../components/icons/CashIcon.vue";
import CollectionIcon from "../../../components/icons/CollectionIcon.vue";
import InvoiceDetailsForm from "../components/InvoiceDetailsForm.vue";
import InvoiceFinancialsForm from "../components/InvoiceFinancialsForm.vue";
import InvoiceLinesForm from "../components/InvoiceLinesForm.vue";
import InvoiceDocumentCard from "../components/InvoiceDocumentCard.vue";
import useInvoiceStore from "../useInvoiceStore"
import Invoice from "../types/Invoice";
import InvoiceLine from "../types/InvoiceLine";
import {useRoute, useRouter} from "vue-router";
import InvoiceStatus from "../types/InvoiceStatus";
import InvoiceWorkflowButtons from "../components/InvoiceWorkflowButtons.vue";
import AddInvoiceLinesFromScheduleBlocksWizard from "../components/AddInvoiceLinesFromScheduleBlocksWizard.vue";
import useBusinessStore from "../../business/useBusinessStore";
import PageContentContainer from "../../../components/PageContentContainer.vue";
import PageTitleContainer from "../../../components/PageTitleContainer.vue";

export default defineComponent({
    components: {
        PageContentContainer,
        PageTitleContainer,
        DownloadIcon,
        ChartIcon,
        CashIcon,
        CollectionIcon,
        InvoiceDetailsForm,
        InvoiceFinancialsForm,
        InvoiceLinesForm,
        InvoiceWorkflowButtons,
        AddInvoiceLinesFromScheduleBlocksWizard,
        InvoiceDocumentCard
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const invoiceStore = useInvoiceStore();
        const businessStore = useBusinessStore();

        const isGeneratingLinesFromScheduleBlock = ref(false);
        const isEditingDetails = ref(false);
        const isEditingLines = ref(false);

        const invoiceLoading = computed(() => invoiceStore.invoiceLoading);
        const activeInvoice = computed(() => invoiceStore.invoice);
        const invoiceEditable = computed(() => activeInvoice.value && activeInvoice.value.status === InvoiceStatus.DRAFT);
        const friendlyInvoiceStatus = computed(
            () => invoiceStore.statuses.find(i => i.value === activeInvoice.value?.status)?.name.toUpperCase()
        );

        const invoiceLinesForm = ref(null);

        watch(
            route,
            async () => {
                if (!route.params.id) {
                    return;
                }
                await invoiceStore.activeInvoiceChanged(route.params.id as unknown as number);
            },
            { immediate: true }
        );

        async function invoiceStatusUpdated(status: InvoiceStatus) {
            await invoiceStore.invoiceUpdated(activeInvoice.value!.id, { ...activeInvoice.value!, status });
        }

        async function invoiceDetailsUpdated(invoice: Invoice) {
            await invoiceStore.invoiceUpdated(activeInvoice.value!.id, { ...activeInvoice.value!, ...invoice });
            isEditingDetails.value = false;
        }

        async function invoiceLinesUpdated({ lines }: { lines: InvoiceLine[] }) {
            await invoiceStore.invoiceLinesUpdated(activeInvoice.value!.id, lines);
            isEditingLines.value = false;
            isGeneratingLinesFromScheduleBlock.value = false;
            await invoiceLinesForm.value?.resetUnInvoicedLines();
        }

        async function sendReminder(){
            await invoiceStore.sendReminder(activeInvoice.value!.id);
        }

        const goBackToInvoices = async function() {
            await router.push('/invoices');
        }

        const handleCancelEditingLines = async function() {
            isEditingLines.value = false
            await invoiceLinesForm.value?.resetUnInvoicedLines();
        }

        async function goToBusinessSettings() {
            await router.push('/business');
        }

        return {
            business: businessStore.activeBusiness,
            goToBusinessSettings,
            InvoiceStatus,
            isEditingDetails,
            isEditingLines,
            isGeneratingLinesFromScheduleBlock,

            invoiceLoading,
            activeInvoice,
            invoiceEditable,
            friendlyInvoiceStatus,
            sendReminder,
            businessVatRegistered: businessStore.vatRegistered,

            invoiceStatusUpdated,
            invoiceDetailsUpdated,
            invoiceLinesUpdated,

            goBackToInvoices,

            invoiceLinesForm,
            handleCancelEditingLines
        }
    },
})
</script>
