<template>

    <Modal
        :visible="surchargesModalVisible"
        size="xlarge"
        :title="isEditing ? surchargeToEdit ? surchargeToEdit.name : 'Create a Surcharge Type' : surchargeToEdit?.name"
        @onClose="toggleModal"
    >
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
            </svg>
        </template>


        <CategoriesForm
            :isEditing="isEditing"
            :catToEdit="surchargeToEdit"
            :allowNegativePrice="true"
            @onSubmit="surchargeCreatedOrUpdated"
            ref="categoriesForm"
        />

        <template #footer-buttons>
            <DeleteButtonWithConfirmation v-if="!isEditing" :message="`Are you sure you wish to delete the category '${surchargeToEdit?.name}'? This action cannot be undone.`" @onDelete="surchargeDeleted" />
            <Button v-if="!isEditing" variety="primary" @click="isEditing = true">Edit</Button>
            <Button
                v-if="!isEditing"
                variety="plain"
                @click="toggleModal"
            >
                Close
            </Button>
            <Button
                v-if="isEditing"
                variety="plain"
                @click="toggleModal"
            >
                Cancel
            </Button>
            <Button
                v-if="isEditing"
                variety="primary"
                @click="$refs.categoriesForm.onSubmit()"
            >
                Save
            </Button>
        </template>
    </Modal>

    <Card title="Surcharge Types">
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
            </svg>
        </template>

        <template #buttons>
            <Button
                variety="primary"
                @click="addSurchargeClicked"
            >
                Add
            </Button>
        </template>

        <div v-if="surcharges && surcharges.length" class="flex flex-col gap-y-2 dark:text-gray-100">
            <div class="p-1 grid grid-cols-2 font-bold text-gray-600 dark:text-gray-100">
                <div>Type</div>
                <div>Price</div>
            </div>
            <div class="grid grid-cols-2 p-1 hover:bg-gray-100 cursor-pointer rounded-lg border border-gray-100 dark:border-gray-900 shadow p-2 mb-1 dark:bg-gray-600" v-for="cat in surcharges" :key="cat" @click="surchargeClicked(cat)">
                <div class="break-all">{{cat.name}}</div>
                <div class="pl-4">{{MoneyUtils.getDisplayValue(cat.price_per_minute)}}</div>
            </div>

        </div>
        <div v-else class="text-gray-500 dark:text-gray-100">
            No surcharge types have been created.
        </div>
    </Card>

</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import Card from "../../../components/Card.vue";
import useBusinessStore from "../useBusinessStore";
import Surcharge from "../types/Surcharge";
import DeleteButtonWithConfirmation from "../../../components/forms/DeleteButtonWithConfirmation.vue";
import CategoriesForm from "./CategoriesForm.vue";
import MoneyUtils from "../../../utils/MoneyUtils";

export default defineComponent({
    name: "SurchargesCard",
    components: {CategoriesForm, DeleteButtonWithConfirmation, Card},
    props:{
      surcharges :{
          type: Array,

      }
    },
    setup(props){
        const businessStore = useBusinessStore();
        const surcharges = computed(() => businessStore.surcharges);
        const surchargesModalVisible = ref(false);
        const isEditing = ref(false);
        const surchargeToEdit = ref(undefined as Surcharge | undefined);

        const toggleModal = () => {
            surchargesModalVisible.value = !surchargesModalVisible.value;
        }

        function addSurchargeClicked() {
            surchargeToEdit.value = undefined;
            isEditing.value = true;
            surchargesModalVisible.value = true;
        }

        function surchargeClicked(cat: Surcharge) {
            surchargeToEdit.value = cat;
            isEditing.value = false;
            surchargesModalVisible.value = true;
        }

        async function surchargeCreatedOrUpdated(cat: Surcharge) {
            try {
                if (surchargeToEdit.value?.id) {
                    await businessStore.surchargeUpdated({...cat, id: surchargeToEdit.value.id});
                } else {
                    await businessStore.surchargeAdded(cat);
                }

                closeModal();
            }
            catch (error){}
        }

        async function surchargeDeleted() {
            try{
                await businessStore.surchargeDeleted(surchargeToEdit.value as Surcharge);
                closeModal();
            }
            catch (error){}
        }

        function closeModal() {
            surchargesModalVisible.value = false;
            isEditing.value = false;
        }

        return{
            surcharges,
            isEditing,
            toggleModal,
            surchargesModalVisible,
            surchargeToEdit,
            addSurchargeClicked,
            surchargeDeleted,
            surchargeClicked,
            surchargeCreatedOrUpdated,
            MoneyUtils
        }
    }
})
</script>

<style scoped>

</style>
