<template>
    <div v-if="status === VotingStatus.Planned" class="text-lg text-black">
          <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
            Planned
          </span>
    </div>
    <div v-if="status === VotingStatus.InProgress" class="text-lg text-blue-600">
        <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
            In Progress
        </span>
    </div>
    <div v-if="status === VotingStatus.Completed" class="text-lg text-green-600">
      <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
            Completed
      </span>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import VotingStatus from "../types/VotingStatus";

export default defineComponent({
    name: "FeatureStatus",
    props: {
        status: {
            type: String,
        }
    },
    setup(props) {
        return {
            status: props.status,
            VotingStatus
        }
    }
})
</script>

<style scoped>

</style>
