import axios from "axios";
import { ApiAsyncWrapper, ApiResponseWrapper } from "./utils/ApiAsyncWrapper";
import FaqQuestion from "../modules/help/types/FaqQuestion";
import FaqVideo from "../modules/help/types/FaqVideo";

function listFaqs(): Promise<ApiResponseWrapper<FaqQuestion[]>> {
    return ApiAsyncWrapper(axios.get('/faqs'));
}
function listVideos(): Promise<ApiResponseWrapper<FaqVideo[]>> {
    return ApiAsyncWrapper(axios.get('/faqs/videos'));
}

export default {
    listFaqs,
    listVideos
}
