<template>
<div
    :class="[isHorizontal? 'p-1' : 'p-8']"
    class="h-full flex flex-col" >
    <img class="w-80" alt="WalkBuddy logo" :src="logo" v-if="!isHorizontal" />
    <div
        :class="[isHorizontal? 'flex-row' : 'flex-col pt-8']"
        class="flex text-gray-100 font-bold">
        <OnboardingStepperStep
            v-for="(onboardingStep) in onboardingSteps"
            :key="onboardingStep.step"
            :step="onboardingStep.step"
            :step-text="onboardingStep.stepText"
            :is-last-step="onboardingStep.isLastStep"
            :current-step="onboardingLevel"
            :is-horizontal="isHorizontal"
        />
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import logo from "../../../assets/logo-coloured-bg.png";
import OnboardingStepperStep from "./OnboardingStepperStep.vue";

export default defineComponent({
    name: "OnboardingStepper",
    components: {
        OnboardingStepperStep
    },
    props:{
        onboardingLevel: {
            type: Number,
            required: true
        },
        isHorizontal: {
            type: Boolean,
            required: false
        }
    },
    setup(){
        const onboardingSteps = [
            { step: 2, stepText: 'Business Info', isLastStep: false },
            { step: 3, stepText: 'Walk Prices', isLastStep: true }
        ]

        return {
            logo,
            onboardingSteps
        }
    }
})
</script>

<style scoped>
</style>
