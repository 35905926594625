import WalkCategory from "../types/WalkCategory";

function apify(walkCategory: WalkCategory): WalkCategory {
    return { ...walkCategory, price_per_minute: walkCategory.price_per_minute * 100 };
}

function deApify(walkCategory: WalkCategory): WalkCategory {
    return { 
        ...walkCategory, 
        price_per_minute: walkCategory.price_per_minute > 0 ? walkCategory.price_per_minute / 100 : 0 
    }
}

export default {
    apify,
    deApify
}
