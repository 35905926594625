import axios from "axios";
import CreatingUser from "../modules/onboarding/types/CreatingUser";
import UpdatingUser from "../modules/user/types/UpdatingUser";
import User from "../modules/user/types/User";
import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper";
import UserPreference from "../modules/user/types/UserPreference";


async function details(): Promise<ApiResponseWrapper<User>> {
    return await ApiAsyncWrapper(axios.get("/user"));
}

async function createUser(user: CreatingUser): Promise<ApiResponseWrapper<User>> {
    return await ApiAsyncWrapper(axios.post('/user', user));
}

async function updateUser(user: UpdatingUser, userId: number): Promise<ApiResponseWrapper<User>> {
    return await ApiAsyncWrapper(axios.put(`/user/${userId}`, user));
}

async function resendEmail(email: string): Promise<ApiResponseWrapper<{}>> {
    return await ApiAsyncWrapper(axios.post(`/user/email-confirmation/resend`, {
        "email": email
    }));
}

async function verifyEmail(token: string): Promise<ApiResponseWrapper<{}>> {
    return await ApiAsyncWrapper(axios.get(`/user/email-confirmation/confirm/${token}`));
}

async function resetPassword(token: string, password: string): Promise<ApiResponseWrapper<{}>> {
    return await ApiAsyncWrapper(axios.post(`/reset-password`, {
        "token": token,
        "password": password
    }));
}

async function getPreferences() : Promise<ApiResponseWrapper<Array<UserPreference>>> {
    return ApiAsyncWrapper(axios.get("/user/preferences"));
}

async function setPreference(preference: UserPreference) : Promise<ApiResponseWrapper<UserPreference>> {
    return ApiAsyncWrapper(axios.post("/user/preferences", preference));
}

export default {
    details,
    createUser,
    updateUser,
    resendEmail,
    verifyEmail,
    resetPassword,
    getPreferences,
    setPreference
}
