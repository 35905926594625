import {createApp, Events} from 'vue'
import axios from "axios";
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from './App.vue'
import './index.css'
import router from './router';

import Default from "./layouts/Default.vue";
import NoSidebar from "./layouts/NoSidebar.vue";

import LoadingSpinner from "./components/LoadingSpinner.vue";
import Card from "./components/Card.vue";
import Pill from "./components/Pill.vue";
import Modal from "./components/Modal.vue";
import InformationList from "./components/InformationList.vue";
import InformationListItem from "./components/InformationListItem.vue";

import Form from "./components/forms/Form.vue";
import FormComponent from "./components/forms/FormComponent.vue";
import TextInput from "./components/forms/TextInput.vue";
import MoneyInput from "./components/forms/MoneyInput.vue";
import DropdownSelector from "./components/forms/DropdownSelector.vue";
import DateTimePicker from "./components/forms/DateTimePicker.vue";
import Button from "./components/forms/Button.vue";
import RadioButtonGroup from "./components/forms/RadioButtonGroup.vue";
import ImageUpload from "./components/forms/ImageUpload.vue";
import DeleteButtonWithConfirmation from "./components/forms/DeleteButtonWithConfirmation.vue";
import Multiselect from "@vueform/multiselect";
import VCalendar from 'v-calendar';
import Checkbox from "./components/forms/Checkbox.vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { Vue3Mq } from "vue3-mq";

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import Emitter from "./utils/emitter";

import gAuthPlugin from "vue3-google-oauth2";

const app = createApp(App)

const emitter = Emitter;
app.provide("emitter", emitter);

axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = import.meta.env.VITE_BASE_API_URL as string;

if (import.meta.env.VITE_ENABLE_SENTRY === "true") {
    Sentry.init({
        app,
        dsn: "https://9f5164bd6c5a459aab3acb38eeea1e27@o523909.ingest.sentry.io/6081038",
        environment: <string>import.meta.env.VITE_APP_ENV,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "walkbuddy.net", "app.walkbuddy.net", "app-dev.walkbuddy.net", /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

app.component('default-layout', Default);
app.component('no-sidebar-layout', NoSidebar);

// General components.
app.component('Card', Card);
app.component('Pill', Pill);
app.component('Modal', Modal);
app.component('InformationList', InformationList);
app.component('InformationListItem', InformationListItem);
app.component("LoadingSpinner", LoadingSpinner);

// Form components.
app.component('Form', Form);
app.component('FormComponent', FormComponent);
app.component('TextInput', TextInput);
app.component('MoneyInput', MoneyInput);
app.component('DropdownSelector', DropdownSelector);
app.component('Button', Button);
app.component('RadioButtonGroup', RadioButtonGroup);
app.component("ImageUpload", ImageUpload);
app.component("DeleteButtonWithConfirmation", DeleteButtonWithConfirmation);
app.component("Checkbox", Checkbox);
app.component("DateTimePicker", DateTimePicker);

app.component('Multiselect', Multiselect)
app.use(VCalendar, {})

app.use(PerfectScrollbar)

app.use(router)
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)

app.use(pinia);

app.use(Vue3Mq, {
    preset: "tailwind"
});

app.config.performance = true;

app.use(gAuthPlugin, {
    clientId: import.meta.env.VITE_GOOGLE_SIGN_IN_CLIENT_ID,
    scope: 'email',
    prompt: 'consent'
})

app.provide('gAuth', app.config.globalProperties.$gAuth);


app.mount('#app')
