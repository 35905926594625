<template>
    <label :for="id" class="block font-medium text-gray-700 dark:text-gray-100">
        {{ label }}{{ required && isEditing ? "*" : "" }}
    </label>
    <v-date-picker
        v-if="isEditing"
        :id="id"
        v-model="internalValue"
        :minute-increment="30"
        :mode="mode"
        :timezone="timezone"
        :popover="{ 'visibility': 'focus' }"
    >
        <template v-slot="{ inputValue, inputEvents }">
            <input
                class="block w-full shadow-sm bg-white dark:bg-gray-600 border px-2 py-1 rounded dark:text-gray-100"
                :value="inputValue"
                v-on="inputEvents"
                @focus="onFocus"
            />
            <span class="text-red-600 text-xs">{{errors}}</span>
        </template>
    </v-date-picker>
    <label v-else class="block font-medium text-gray-700 dark:text-gray-100">
        {{ displayValue ?? value }}
    </label>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue";

export default defineComponent({
    name: "DateTimePicker",
    props:{
        mode: {
            required: true,
            type: String as PropType<"date" | "time" | "datetime">,
            default: "datetime"
        },
        timezone: {
            required: false,
            type: String as PropType<string | undefined>,
            default: undefined
        },
        label:{
            required: true,
            type: String,
        },
        value:{
            required: true,
            type: [String],
            default: ''
        },
        errors:{
            required: false,
            type: String,
        },
        isEditing: {
            required: false,
            type: Boolean,
            default: false,
        },
        displayValue: {
            required: false,
            type: String
        },
        required: {
            required: true,
            type: Boolean,
            default: false
        }
    },
    emits:[
        'update:value',
        'onFocus'
    ],
    setup(props,context){
        const internalValue = computed({
            get: () => {
                return props.value;
            },
            set: (newValue) => {
                context.emit('update:value', newValue);
            }
        });

        const onFocus = function() {
            context.emit('onFocus');
        }

        return {
            props,
            internalValue,
            onFocus
        }
    }
})
</script>

<style scoped>

</style>
