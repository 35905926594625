<template>
    <!-- Editing -->
    <label
        v-if="isEditing"
        :for="id"
        class="block font-medium text-gray-700 dark:text-gray-100"
    >
        {{ label }}{{ required ? "*" : "" }}
    </label>
    <div
        v-if="isEditing"
        class="flex col-span-2 gap-4"
        :class="selections.length >= 3 ? 'flex-col' : 'flex-row'"
    >
        <div
            v-for="(selection, i) in selections"
            :key="selection"
        >
            <input
                type="radio"
                :name="name"
                :id="`${name}-${i}`"
                :value="selection.value"
                class="mr-1"
                @change="$emit('update:value', $event.target?.value)"
                :checked="value === selection.value"
            />
            <span class="font-medium dark:text-gray-100">{{ selection.name }}</span>
        </div>
    </div>

    <!-- Not editing -->
    <span
        v-if="!isEditing"
        class="block font-medium text-gray-700 dark:text-gray-100"
    >
        {{ label }}
    </span>
    <span
        v-if="!isEditing"
        class="font-medium dark:text-gray-100"
    >
        {{ readOnlyValue }}
    </span>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRefs } from 'vue'

export default defineComponent({
    props: {
        label: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        value: {
            required: true
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        isEditing: {
            type: Boolean,
            required: false,
            default: false
        },
        selections: {
            type: Array as PropType<{ value: any, name: string }[]>,
            required: true
        },
    },
    setup(props) {
        const { selections, value } = toRefs(props);
        const readOnlyValue = computed(() => selections.value.find(s => s.value === value.value)?.name);

        return {
            readOnlyValue
        }
    },
})
</script>
