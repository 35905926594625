<template>
    <Form @submit="onSubmit" :divide="false">
        <div class="grid grid-cols-3 gap-4">
            <div class="col-span-3 md:col-span-2 divide divide-y">
                <FormComponent :hasTopPadding="false">
                    <TextInput
                        id="key-name"
                        label="Name"
                        placeholder="Front Door Key"
                        :isEditing="isEditing"
                        :value="name"
                        :errors="nameError"
                        :required="true"
                        @update:value="v => (name = v)"
                    />
                </FormComponent>

                <FormComponent>
                    <DropdownSelector
                        id="key-current-holder"
                        label="Key Held By"
                        placeholder="Staff Member A"
                        :required="false"
                        :isEditing="isEditing"
                        :value="heldByUserId ?? ''"
                        :errors="heldByUserIdError"
                        :selectors="currentHolderOptions"
                        @update:value="v => (heldByUserId = v)"
                    />
                </FormComponent>
            </div>
            <div class="col-span-3 md:col-span-1">
                <ImageUpload
                    :is-editing="isEditing"
                    :initialImageUrlToDisplay="keyToEdit?.image_path"
                    @dataChanged="$emit('keyImageChanged', $event)"
                    @imageDeleted="$emit('keyImageDeleted')"
                />
            </div>
        </div>

        <div class="mt-4" v-if="!isEditing && keyToEdit && userHasManageCustomersPermission">
            <h2 class="text-lg mt-4 border-b pb-4">Key Logs</h2>
            <div class="mt-4" v-if="auditLogsLoading">
                <LoadingSpinner />
            </div>
            <div class="mt-4" v-else-if="auditLogs?.length">
                <div v-for="log in auditLogs" :key="log" class="border-l-2 border-sky-blue-500 pl-4 mt-4">
                    <div class="flex flex-1 justify-between">
                        <span>Key moved from <span class="font-bold text-gray-600">{{ log.from_user?.name ?? customerName }}</span> to <span class="font-bold text-gray-600">{{ log.to_user?.name ?? customerName }}</span></span>
                        <span class="text-gray-500">{{ moment(log.created_at).fromNow() }}</span>
                    </div>
                </div>
                <Button
                    v-if="shouldAllowTogglingOfAuditLogLength"
                    variety="plain"
                    class="w-full mt-2"
                    @click.stop="showAllAuditLogs = !showAllAuditLogs"
                >
                    {{ showAllAuditLogs ? "View less" : "Load more" }}
                </Button>
            </div>
            <div class="mt-4 text-gray-500" v-else>
                No audit logs for this key.
            </div>
        </div>
    </Form>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, onMounted, PropType, ref} from "vue";
import moment from "moment";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import useBusinessStore from '../../business/useBusinessStore'
import useUserStore from '../../user/useUserStore';
import useCustomerStore from '../useCustomerStore';
import CustomerKey from "../types/CustomerKey";
import { PermissionGroup } from "../../user/enums/PermissionGroup";

export default defineComponent({
    props: {
        keyToEdit: {
            type: Object as PropType<CustomerKey | undefined>,
            required: false,
            default: undefined
        },
        isEditing: {
            type: Boolean,
            required: true
        }
    },
    setup(props, context) {
        const userStore = useUserStore();
        const businessStore = useBusinessStore();
        const customerStore = useCustomerStore();

        const showAllAuditLogs = ref(false);
        const customerName = computed(() => `${customerStore.activeCustomer!.name} (Customer)`);
        const userHasManageCustomersPermission = computed(() => userStore.userHasPermission(PermissionGroup.MANAGE_CUSTOMERS));
        const auditLogsLoading = computed(() => customerStore.selectedKeyAuditLogsLoading);
        const shouldAllowTogglingOfAuditLogLength = computed(() => customerStore.selectedKeyAuditLogs.length > 5);
        const auditLogs = computed(() => showAllAuditLogs.value || customerStore.selectedKeyAuditLogs.length < 5 ?
            customerStore.selectedKeyAuditLogs :
            customerStore.selectedKeyAuditLogs.slice(0, 5)
        );

        onMounted(() => businessStore.loadBusinessUsers());

        const currentHolderOptions = computed(() =>
            [{ name: `${customerStore.activeCustomer?.name} (Customer)`, value: "" as any }]
                .concat(
                    [{ value: userStore.user!.id, name: userStore.user!.name }]
                )
                .concat(
                    businessStore.businessUsers
                        .filter(bu => bu.id !== userStore.user?.id)
                        .map(b => ({name: b.name, value: b.id }))
                )
        );

        const schema = yup.object({
            name: yup.string().required("Name is required.").max(255),
            held_by_user_id: yup.string().nullable()
        });

        const { handleSubmit } = useForm({
            validationSchema: schema,
        })

        const { value: name, errorMessage: nameError } = useField("name");
        const { value: heldByUserId, errorMessage: heldByUserIdError } = useField("held_by_user_id");

        if (props.keyToEdit) {
            name.value = props.keyToEdit.name;
            heldByUserId.value = props.keyToEdit.held_by?.id ?? "";
        }

        const onSubmit = handleSubmit(values => {
            context.emit("onSubmit", values);
        });

        return {
            moment,
            currentHolderOptions,
            auditLogsLoading,
            auditLogs,
            customerName,
            userHasManageCustomersPermission,
            showAllAuditLogs,
            shouldAllowTogglingOfAuditLogLength,

            name,
            nameError,

            heldByUserId,
            heldByUserIdError,

            onSubmit
        }
    },
})
</script>
