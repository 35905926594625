<template>
    <div 
        class="rounded p-1 text-xs"
        :class="{
            'bg-gray-100 text-gray-500': variety === 'plain',
        }"
    >
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    props: {
        variety: {
            type: String as PropType<'primary' | 'secondary' | 'plain'>,
            required: true
        }   
    },
    setup() {
        
    },
})
</script>
