<template>
        <component v-if="$props.hotJarEnabled" :is="'script'">
            <!-- Hotjar Tracking Code for https://walkbuddy.net/ -->
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2790382,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        </component>
</template>

<script>
import {defineComponent} from "vue";
import {installFathom} from "../modules/fathom/fathomUtils";

export default defineComponent({
    name: "TrackingCodes",
    props: {
        hotJarEnabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(props){
        installFathom();
    },
});
</script>

<style scoped>

</style>
