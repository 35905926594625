<template>

    <PageTitleContainer>
        <div class="flex items-center flex-grow">
            <button v-if="$route.query.scheduleBlockId" class="hover:text-sky-blue-400 pl-2 pr-2 " @click="goBackToScheduleBlock">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
            </button>
            <h1 v-if="activeCustomer && !activeCustomerLoading" class="text-2xl font-semibold text-gray-900 dark:text-gray-100 flex-1">
                {{ activeCustomer.name }}
            </h1>
            <div v-if="activeCustomer && !activeCustomerLoading">
                <DeleteButtonWithConfirmation
                    v-if="userHasManageCustomerPermission"
                    :message="`Are you sure you wish to delete the customer '${activeCustomer.name}'? This action cannot be undone.`"
                    :is-disabled="false"
                    @onDelete="customerDeleted"
                />
            </div>
        </div>
    </PageTitleContainer>

    <PageContentContainer>
        <LoadingSpinner v-if="activeCustomerLoading" class="w-32" />
        <div v-if="!activeCustomerLoading && !activeCustomer" class="m-2 md:m-0 bg-white dark:bg-gray-800 p-2 flex-grow shadow flex flex-col items-center">
            <img style="max-width: 300px" class="mb-4" alt="loading Image of a dog" :src="failDog">
            The customer you are looking for does not exist. To create a customer please head over to the customers dashboard.
            <div class="border rounded-lg mt-2">
                <SidebarMenuItem link-url="/customers" label="Click here to go to customer dashboard" />
            </div>
        </div>
        <ErrorBanner
            :show="showDeleteCustomerError"
            :message="deleteCustomerError"
        />
        <div v-if="activeCustomer" class="grid grid-cols-1 gap-4 xl:grid-cols-6 2xl:grid-cols-4" style="width: 100%">
            <div class="flex flex-col flex-grow gap-y-4 xl:col-span-4 2xl:col-span-3 p-2 md:p-0">
                <PersonalInformation
                    :is-editing="isEditingCustomer"
                    :active-customer="activeCustomer"
                    @onCustomerDetailsChange="saveCustomer"
                    @onToggleEditing="toggleEditing"
                />
                <CustomerInvoicesList/>
                <CustomerNotesList/>
            </div>
            <div class="flex flex-col gap-y-4 xl:col-span-2 2xl:col-span-1 p-2 md:p-0">
                <CustomerDogsList />
                <CustomerContactsList />
                <CustomerKeysList />
            </div>
        </div>
    </PageContentContainer>
</template>

<script lang="ts">
import failDog from "../../../assets/gone-wrong.png";
import {computed, defineComponent, inject, onErrorCaptured, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {PermissionGroup} from "../../user/enums/PermissionGroup";
import {Customer} from "../types/Customer";
import useCustomerStore from "../useCustomerStore";
import useUserStore from "../../user/useUserStore";
import useTitleManager from "../../../utils/useTitleManager";
import CustomerContactsList from "../components/CustomerContactsList.vue";
import CustomerDogsList from "../components/CustomerDogsList.vue";
import CustomerKeysList from "../components/CustomerKeysList.vue";
import CustomerInvoicesList from "../components/CustomerInvoicesList.vue";
import CustomerNotesList from "../components/CustomerNotesList.vue";
import ErrorBanner from "../../../components/ErrorBanner.vue";
import PageTitleContainer from "../../../components/PageTitleContainer.vue";
import PersonalInformation from "../components/PersonalInformation.vue";
import PageContentContainer from "../../../components/PageContentContainer.vue";
import SidebarMenuItem from "../../../components/SidebarMenuItem.vue";

export default defineComponent({
    name: "CustomerManage",
    components: {
        SidebarMenuItem,
        PageContentContainer,
        PageTitleContainer,
        PersonalInformation,
        ErrorBanner,
        CustomerNotesList,
        CustomerContactsList,
        CustomerDogsList,
        CustomerKeysList,
        CustomerInvoicesList
    },
    setup(){
        const route = useRoute();
        const router = useRouter();
        const { setTitlePrefix } = useTitleManager();
        const userStore = useUserStore();
        const customerStore = useCustomerStore();

        const activeCustomerLoading = computed(() => customerStore.activeCustomerLoading);
        const activeCustomer = computed(() => customerStore.activeCustomer);
        const savingCustomer = ref(false);
        const editEnabled = ref(false);
        const isEditingCustomer = ref(false);

        const showDeleteCustomerError = ref(false);
        const deleteCustomerError = ref<string>("Unable to delete this customer at the current time, please try again later.");

        const userHasManageCustomerPermission = computed(() => userStore.userHasPermission(PermissionGroup.MANAGE_CUSTOMERS))

        onMounted(async () => {
            await customerStore.activeCustomerChanged(parseInt(route.params.id as string));
        })

        watch(activeCustomer, c => {
            if (c?.name) {
                setTitlePrefix(c.name + " - " + "Customers");
            }
        });

        const saveCustomer = async function(newCustomerData : Customer) {
            try{
                savingCustomer.value = true;
                await customerStore.customerUpdated(activeCustomer.value!.id!, newCustomerData);
                savingCustomer.value = false;
                isEditingCustomer.value = false;
            }
            catch (error){
            }
            finally {
                customerStore.activeCustomerLoading = false;
            }
        }

        const customerDeleted = async function(){
            try {
                await customerStore.customerDeleted(customerStore.activeCustomer!);
                await customerStore.listingCustomers();
                router.push("/customers");
            }
            catch (error) {}
        }

        const goBackToScheduleBlock = async function() {
            await router.replace(`/schedule/${parseInt(route.query.scheduleBlockId as string)}`);
        }

        const toggleEditing = (value: boolean) => {
            isEditingCustomer.value = value;
        }

        return {
            activeCustomerLoading,
            activeCustomer,
            editEnabled,
            isEditingCustomer,
            saveCustomer,
            customerDeleted,
            userHasManageCustomerPermission,
            goBackToScheduleBlock,
            showDeleteCustomerError,
            deleteCustomerError,
            toggleEditing,
            failDog
        }
    }
})
</script>

<style scoped>

</style>
