import { CustomerNoteType } from "../types/CustomerNoteType";

/**
 * Takes a note type for a customer note and converts it into a human readable name.
 * 
 * @param noteType The note type to convert into a human readable name.
 */
export function humanReadableNameFromNoteType(noteType: CustomerNoteType) {
    switch (noteType) {
        case CustomerNoteType.INTERNAL:
            return "Internal";
        case CustomerNoteType.STANDARD:
            return "Standard";
    }
}
