<template>
    <div v-if="settingUpApp"
         class="flex flex-col justify-center spacing flex overflow-hidden bg-sky-blue-400 p-4 h-full">
            <img style="margin-left: auto; margin-right: auto;" alt="loading Image of a dog" :src="logo">
            <LoadingSpinner style="margin-left: auto; margin-right: auto;"/>
            <div class="text-center text-xl mt-2">loading...</div>
    </div>
    <component v-else :is="layout" />
    <NotificationWrapper />
    <ErrorHandler />
    <TrackingCodes
        :hot-jar-enabled="hotJarEnabled"
    />
    <ConsentModal />
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, ref} from 'vue'
import {useRoute, useRouter} from "vue-router";
import AuthApiClient from "./api/AuthApiClient";
import LoadingSpinner from "./components/LoadingSpinner.vue";
import useMiscInformationStore from './utils/useMiscInformationStore';
import useUserStore from './modules/user/useUserStore';
import logo from "./assets/logo-coloured-bg.png";
import NotificationWrapper from "./components/NotificationWrapper.vue";
import TrackingCodes from "./components/TrackingCodes.vue";
import * as Sentry from "@sentry/vue";
import ErrorHandler from "./components/ErrorHandler.vue";
import {OnboardingLevel} from "./modules/onboarding/types/OnboardingLevel";
import ConsentModal from "./modules/user/components/consent/ConsentModal.vue";

const defaultLayout = "default";

export default defineComponent({
    name: 'App',
    components: {
        ConsentModal,
        ErrorHandler,
        TrackingCodes,
        NotificationWrapper,
        LoadingSpinner,
    },
    setup(){
        const userStore = useUserStore();
        const miscInformationStore = useMiscInformationStore();

        const router = useRouter();
        const route = useRoute();
        const layout = computed(() => route.meta.layout || defaultLayout + '-layout');
        let settingUpApp = ref(true);
        const hotJarEnabled = import.meta.env.VITE_ENABLE_HOTJAR === "true";

        onBeforeMount(async () => {
            await AuthApiClient.getCSRFCookie();

            await Promise.all([
                miscInformationStore.loadCountries(),
                miscInformationStore.loadBreeds(),
            ]);

            try {
                await userStore.userHasAuthenticated();

                if(!userStore.userIsAuthenticated){
                    return;
                }
                if (!userStore.userHasCompletedOnboarding()) {
                    await router.push({path: `/register/${OnboardingLevel.Account}`})
                    settingUpApp.value = false;
                    return;
                }
                settingUpApp.value = false;
            }
            catch (error) {
                if(route.path === "/register"
                || route.name === "verifyEmail"
                || route.name === "resetPassword"){
                    settingUpApp.value = false;
                    return;
                }
                if (error.status == 401) {
                    await router.push({path: '/'});
                    settingUpApp.value = false;
                    return;
                }
                else{
                    Sentry.captureException(error);
                    await router.push({path: '/error'});
                    settingUpApp.value = false;
                    return;
                }
            }

            if (route.name === "login") {
                await router.push({path: "/dashboard"});
                settingUpApp.value = false;
                return;
            }
        });

        return {
            layout,
            settingUpApp,
            logo,
            hotJarEnabled
        }
    }
})
</script>

<style>
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
