<template>
    <form @submit="$emit('submit')">
        <div :class="[{ 'divide-y divide-gray-50 dark:divide-gray-800': divide }, additionalClasses]">
            <slot></slot>
        </div>
        <div class="flex flex-1 justify-end border-t border-gray-200 mt-8 pt-4 gap-2" v-if="$slots.buttons">
            <slot name="buttons"></slot>
        </div>
    </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        divide: {
            type: Boolean,
            default: true
        },
        additionalClasses: {
            type: String,
            default: ""
        }
    },
    setup({ divide, additionalClasses }) {

        return {
            divide,
            additionalClasses
        }
    },
})
</script>
