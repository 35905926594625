<template>
    <teleport to="body">
        <Modal title="Copy A Schedule Block" :visible="isVisible" size="medium">
            <div v-if="showSuccessMessage" class="bg-green-200 p-2">
                Schedule item(s) have been copied.
            </div>
            <div v-if="showFailedMessage" class="bg-red-200 p-2">
                There was an error copying your schedule item, please try again.
            </div>
            <div v-if="!showSuccessMessage && !showFailedMessage">
                Select a date to copy the schedule item to. It will be copied to the same time.
            </div>
            <div v-if="!showSuccessMessage && !showFailedMessage" class="mt-2 h-80">
                <v-date-picker v-model="targetDate">
                    <template v-slot="{ inputValue, togglePopover }">
                        <input
                            style="height: 38px;"
                            :value="inputValue"
                            class="bg-white dark:bg-gray-500
                                 rounded-md
                                 text-gray-700 dark:text-gray-100
                                 w-full py-1 px-2
                                 appearance-none
                                 border border-gray-300 dark:border-gray-900
                                 focus:outline-none
                                 focus:border-sky-blue-500"
                            readonly
                            @click="togglePopover()"
                        />
                    </template>
                </v-date-picker>
            </div>
            <template #footer-buttons>
                <Button
                    variety="warning"
                    @click="handleClose"
                >
                    Close
                </Button>
                <Button
                    v-if="!showSuccessMessage && !showFailedMessage"
                    variety="confirm"
                    @click.stop="handleSave"
                    :disabled="isSaveDisabled"
                >
                    Copy
                </Button>
                <Button
                    v-if="showSuccessMessage"
                    variety="confirm"
                    @click.stop="handleView"
                >
                    View
                </Button>
            </template>
        </Modal>
    </teleport>
</template>

<script>
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import Modal from "../../../../components/Modal.vue";
import DateTimeRangePicker from "../../../../components/forms/DateTimeRangePicker.vue";
import Button from "../../../../components/forms/Button.vue";
import {DateTime} from "luxon";

export default defineComponent({
    name: "CopySingleScheduleModal",
    components: {
        Button,
        DateTimeRangePicker,
        Modal
    },
    props: {
        currentScheduleItem: {
            type: Object,
            required: false
        },
        isVisible: {
            type: Boolean,
            required: true
        },
        showSuccessMessage: {
            type: Boolean,
            required: false,
            default: false,
        },
        showFailedMessage: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    emits: [
        'update:date',
        'save',
        'close',
        'view'
    ],
    setup(props, {emit}){

        const targetDate = ref();
        const isSaveDisabled = computed(() => {
            return props.currentScheduleItem.date_from === targetDate.value
        });

        const handleSave = () => {
            emit('update:date', targetDate);
            emit('save');
        }

        const handleClose = () => {
            emit('close');
        }

        watch(props, c => {
            if(props.currentScheduleItem) {
                targetDate.value = props.currentScheduleItem.date_from;
            }
        }, { immediate: true });

        const handleView = (newValue) => {
            emit('view', newValue);
        }

        return {
            handleSave,
            handleClose,
            handleView,
            targetDate,
            isSaveDisabled
        }
    }
})
</script>

<style scoped>

</style>
