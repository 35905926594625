import axios from "axios";
import DogBreed from "../types/DogBreed";
import { ApiAsyncWrapper, ApiResponseWrapper } from "./utils/ApiAsyncWrapper";

async function loadBreeds(): Promise<ApiResponseWrapper<DogBreed[]>> {
    return ApiAsyncWrapper(axios.get("/breeds"));
}

export default {
    loadBreeds
}
