<template>
    <!-- Create invoice modal -->
    <Modal
        title="Create an Invoice"
        size="xlarge"
        :visible="isAddingAnInvoice"
    >
        <template #icon>
            <ChartIcon />
        </template>

        <InvoiceDetailsForm
            :is-editing="true"
            :customer-editable="true"
            @onCancel="isAddingAnInvoice = false"
            @onSubmit="invoiceCreated"
        />
    </Modal>

    <PageTitleContainer>
        <div class="flex items-center">
            <h1 class="text-2xl font-bold text-black mr-4 flex-grow dark:text-gray-100">Invoices</h1>
        </div>
        <div v-if="isSubscribed" class="flex flex-1 flex-col md:flex-row justify-end gap-4">
            <!-- for mobile view filter items -->
            <!-- Action buttons -->
            <div class="hidden md:flex items-center">
                <Button variety="primary" @click.stop="handleGenerateInvoices">Generate Invoices from Schedule</Button>
                <Button class="ml-2" variety="primary" @click.stop="handleNewInvoice">Add Invoice</Button>
            </div>
        </div>
    </PageTitleContainer>
    <!-- Main Content -->
    <PageContentContainer>
        <div v-if="isSubscribed" class="flex-1 relative overflow-y-auto focus:outline-none">
            <div v-if="!business.account_number || !business.account_name || !business.sort_code" class="font-extrabold flex p-4 bg-sky-orange-600 flex-col">
                <span>Your Business bank account details are incomplete, please fill in the blanks before sending invoices.</span>
                <span class="flex-grow-0">
                <Button variety="primary" :is-small-button="true" class="border-0 flex-grow-0 focus:border-0 hover:border-0" @click="goToBusinessSettings">
                    Go to Business Settings
                </Button>
                </span>
            </div>
            <div class="m-4 block md:hidden">
                <MetricsWidget :slim-mode="true" />
            </div>
            <div class="m-4 hidden md:block">
                <MetricsWidget :slim-mode="false" />
            </div>

            <!-- Invoice list. -->
            <div class="m-4">
                <div class="bg-white dark:bg-gray-800 p-4 w-full shadow overflow-auto">

                    <div class="block md:hidden flex items-center justify-between">
                        <div>Filters</div>
                    </div>

                    <div class="flex flex-wrap gap-4 md:flex-nowrap md:items-center md:justify-between text-xs md:text-base flex-col sm:flex-row">
                        <!-- for desktop filter items -->
                        <div class="flex-1">
                            <DropdownSelector
                                id="customer-dropdown"
                                label="Customer:"
                                :value="customerToUse"
                                :isEditing="true"
                                :selectors="customerFilterSelections"
                                @update:value="customerFilterChanged"
                            />
                        </div>
                        <div class="flex-1">
                            <DropdownSelector
                                id="status-dropdown"
                                label="Status:"
                                value=""
                                :isEditing="true"
                                :selectors="invoiceStatuses"
                                @update:value="statusFilterChanged"
                            />
                        </div>
                        <div class="flex-1">
                            <DropdownSelector
                                id="sort-dropdown"
                                label="Sort By:"
                                value="createdDesc"
                                :isEditing="true"
                                :selectors="invoiceSortOptions"
                                @update:value="sortChanged"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="m-4">
                <InvoiceList
                    :invoice-statuses="invoiceStatuses"
                    @pagination-link-clicked="onPaginationLinkClicked"
                />
            </div>


            <div class="flex flex-col md:hidden justify-end items-end sticky bottom-10 mr-10" id="global-action-button">
                <transition name="slide">
                    <div class="flex flex-col gap-2 mb-2" v-if="showActionButtons">
                        <button @click.stop="handleGenerateInvoices" type="button" class="items-center p-3 border border-transparent rounded-full shadow text-white bg-gray-700 focus:outline-none">
                            Generate Invoices From Schedule
                        </button>
                        <button @click.stop="handleNewInvoice" type="button" class="ml-1 items-center p-3 border border-transparent rounded-full shadow text-white bg-gray-700 focus:outline-none">
                            Create Invoice
                        </button>
                    </div>
                </transition>
                <div class="flex flex-initial justify-end">
                    <button @click="showActionButtons = !showActionButtons" type="button" class="p-3 border border-transparent rounded-full shadow text-white bg-sky-blue-600 hover:bg-sky-blue-700">
                        <!-- Heroicon name: outline/plus-sm -->
                        <svg v-if="!showActionButtons" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                        <svg v-if="showActionButtons" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div v-else class="flex-1 relative overflow-y-auto focus:outline-none bg-gray-200 dark:bg-gray-800">
            <SubscribeCard
               title="Subscription Required"
               description="To access invoicing, please purchase a WalkBuddy subscription plan."
            />
        </div>
    </PageContentContainer>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, onMounted, ref} from "vue";
import { useRoute, useRouter } from "vue-router";
import SubscribeCard from "../../../components/SubscribeCard.vue";
import useTitleManager from "../../../utils/useTitleManager";
import useInvoiceStore from "../useInvoiceStore";
import InvoiceList from "../components/InvoiceList.vue";
import InvoiceDetailsForm from "../components/InvoiceDetailsForm.vue";
import ChartIcon from "../../../components/icons/ChartIcon.vue";
import useCustomerStore from "../../customer/useCustomerStore";
import CreatedInvoice from "../types/CreatedInvoice";
import InvoiceStatus from "../types/InvoiceStatus";
import GenerateInvoicesFromScheduleBlocksWizard from "../components/GenerateInvoicesFromScheduleBlocksWizard.vue";
import MetricsWidget from "../../dashboard/components/MetricsWidget.vue";
import useDashboardStore from "../../dashboard/useDashboardStore";
import Button from "../../../components/forms/Button.vue";
import DropdownSelector from "../../../components/forms/DropdownSelector.vue";
import useBusinessStore from "../../business/useBusinessStore";
import PageContentContainer from "../../../components/PageContentContainer.vue";
import PageTitleContainer from "../../../components/PageTitleContainer.vue";

export default defineComponent({
    components: {
        PageContentContainer,
        PageTitleContainer,
        SubscribeCard,
        DropdownSelector,
        InvoiceList,
        ChartIcon,
        InvoiceDetailsForm,
        GenerateInvoicesFromScheduleBlocksWizard,
        MetricsWidget,
        Button
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const invoiceStore = useInvoiceStore();
        const customerStore = useCustomerStore();
        const businessStore = useBusinessStore();
        const dashboardStore = useDashboardStore();
        const titleManager = useTitleManager();

        onMounted(async () => {
            titleManager.setTitlePrefix("Invoices");

            if (route.query.customerId) {
                customerToUse.value = parseInt(route.query.customerId as string);
            }

            await dashboardStore.refreshDashboardMetrics();

            await handleFilterChange();
        });

        const isSubscribed = computed(() => businessStore.activeBusiness.subscription.is_subscribed);
        const isGeneratingInvoices = ref(false);
        const isAddingAnInvoice = ref(false);
        const isSendingAnInvoice = ref(false);
        const sortToUse = ref("createdDesc");
        const paginationLink = ref(undefined as string | undefined);
        const statusToUse = ref(undefined as InvoiceStatus | undefined);
        const customerToUse = ref(0 as number | undefined);
        const showActionButtons = ref(false);
        const invoicesLoading = computed(() => invoiceStore.invoicesLoading);
        const invoiceSortOptions = computed(() => invoiceStore.sortOptions);
        const invoiceStatuses = computed(() => [ { value: "", name: "Any Status" }, ...invoiceStore.statuses ]);
        const customerSelections = computed(() => customerStore.availableCustomers.map(customer => ({ name: customer.name, value: customer.id })));
        const customerFilterSelections = computed(() => [ { value: 0, name: "Any Customer" }, ...customerSelections.value]);

        async function onPaginationLinkClicked(link: string) {
            paginationLink.value = link;
            await reloadResultsAccordingToFilters();
        }

        async function sortChanged(sort: string) {
            sortToUse.value = sort;
            await reloadResultsAccordingToFilters();
        }

        async function statusFilterChanged(status: string) {
            statusToUse.value = status ? status as InvoiceStatus : undefined;
            await handleFilterChange();
        }

        async function customerFilterChanged(customer: number) {
            customerToUse.value = customer || 0;
            await handleFilterChange();
        }

        async function handleFilterChange() {
            paginationLink.value = undefined;
            await reloadResultsAccordingToFilters();
        }

        async function reloadResultsAccordingToFilters() {
            await invoiceStore.loadInvoices(statusToUse.value, customerToUse.value || undefined, paginationLink.value, sortToUse.value);
        }

        async function invoiceCreated(invoice: CreatedInvoice) {
            const createdInvoiceId = await invoiceStore.invoiceCreated(invoice);
            router.push(`/invoices/${createdInvoiceId}`);
        }

        async function handleGenerateInvoices() {
            await router.push('/invoices/generate');
        }

        async function goToBusinessSettings() {
            await router.push('/business');
        }

        async function handleNewInvoice() {
            isAddingAnInvoice.value = true;
            showActionButtons.value = false;
        }

        return {
            business: businessStore.activeBusiness,
            goToBusinessSettings,
            isSubscribed,
            invoicesLoading,
            invoiceSortOptions,
            invoiceStatuses,
            customerSelections,
            customerFilterSelections,
            isGeneratingInvoices,
            isAddingAnInvoice,
            isSendingAnInvoice,
            customerToUse,
            showActionButtons,

            statusFilterChanged,
            customerFilterChanged,
            onPaginationLinkClicked,
            sortChanged,
            invoiceCreated,
            handleGenerateInvoices,
            handleNewInvoice
        };
    },
});
</script>

<style>
.slide-leave-active,
.slide-enter-active {
    transition: 0.33s ease-in-out;
}

.slide-enter-active {
    transform: translate(0, 200%);
}
.slide-enter-to {
    transform: translate(0, 0);
}

.slide-leave-active  {
    transform: translate(0, 0);
}
.slide-leave-to {
    transform: translate(0, 200%);
}

</style>
