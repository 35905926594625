import {defineStore} from "pinia";
import ScheduleBlock from "./types/ScheduleBlock";
import SchedulerApiClient from "../../api/SchedulerApiClient";
import DateRange from "../../types/DateRange";
import {DateTime} from "luxon";
import _ from "lodash";
import SlimlineScheduleBlock from "./types/SlimlineScheduleBlock";
import {sendFathomEvent} from "../fathom/fathomUtils";
import SurchargeUtils from "../business/utils/SurchargeUtils";

function serverBlockToLocalTime<T>(block : T & { date_from: DateTime, date_to: DateTime }): T {
    block.date_from = DateTime.fromISO(block.date_from as unknown as string).toLocal();
    block.date_to = DateTime.fromISO(block.date_to as unknown as string).toLocal();
    return block;
}

function localBlockToUTC<T>(block : T & { date_from: DateTime, date_to: DateTime }) : T {
    const copyOf = _.cloneDeep(block);
    copyOf.date_from = block.date_from.toUTC();
    copyOf.date_to = block.date_to.toUTC();
    return copyOf;
}


const useSchedulerStore = defineStore({
    id: "scheduler",
    state: () => ({
        selectedDate: DateTime.now() as DateTime
    }),
    actions: {
        /**
         * List Schedule Block
         * Gets a list of all the schedule blocks within a timeframe
         */
        async getScheduleBlocks(dateRange: DateRange) : Promise<SlimlineScheduleBlock[] | null> {
            let result = await SchedulerApiClient.listSchedulingBlocks(dateRange);

            if(result.success && result.data) {
                result.data.forEach(block => serverBlockToLocalTime<SlimlineScheduleBlock>(block));
                return result.data;
            }

            return null;
        },

        /**
         * Get Schedule Block
         * Obtains a specific schedule block by id
         */
        async getScheduleBlock(id: number) : Promise<ScheduleBlock | null> {
            let result = await SchedulerApiClient.getSchedulingBlock(id);
            if(result.success && result.data ) {
                result.data = serverBlockToLocalTime(result.data);
                result.data?.lines?.map(function(line){
                    line.surcharges = line.surcharges?.map(surcharge => SurchargeUtils.deApify(surcharge))
                })
                return result.data;
            }
            return null;
        },

        /**
         * Create Schedule Block
         * Blocks out a specific time range for a specific customer, with details on the dogs
         */
        async createScheduleBlock(scheduleBlock: ScheduleBlock, isCopying : boolean = false) : Promise<ScheduleBlock | null> {
            let remoteBlock = localBlockToUTC(scheduleBlock);
            let result = await SchedulerApiClient.createSchedulingBlock(remoteBlock);
            if (result.success && result.data) {
                !isCopying ?
                    sendFathomEvent("CREATED_A_SCHEDULE_BLOCK")
                    : sendFathomEvent("COPY_SINGLE_SCHEDULE_ITEM");
                return await this.getScheduleBlock(result.data.id!);
            }

            return null;
        },

        /**
         * Attempts to duplicate a set of schedule blocks by date range and return a list of new blocks
         */
        async copyRangeOfScheduledItems(startDate: string, endDate: string, newStartDate: string) {
            let result = await SchedulerApiClient.copyRangeOfScheduledItems(startDate, endDate, newStartDate);
            if(result.success && result.data) {
                sendFathomEvent("COPY_MULTIPLE_SCHEDULE_ITEMS");
                return result.data;
            }
            return null;
        },

        /**
         * updateSlimlineScheduleBlockTime
         * Update a slimline blocks time, accepts a block and tweaks its time to the new one
         * by retrieving the full fat one first
         */
        async updateSlimlineScheduleBlockTime(scheduleBlock: SlimlineScheduleBlock) {
            if(!scheduleBlock.id) {
                throw Error("updateScheduleBlock: called with no block ID.");
            }

            const blockToUtc = localBlockToUTC(scheduleBlock);

            // get the full fat block
            const response = await SchedulerApiClient.getSchedulingBlock(scheduleBlock.id);
            if(response.success) {
                let fullFatBlock = response.data as ScheduleBlock;
                fullFatBlock.date_to = blockToUtc.date_to;
                fullFatBlock.date_from = blockToUtc.date_from;
                await SchedulerApiClient.updateSchedulingBlock(fullFatBlock);
            }
        },


        /**
         * Update Schedule Block
         * Updates an existing schedule block
         */
        async updateScheduleBlock(scheduleBlock: ScheduleBlock) {
            if(!scheduleBlock.id) {
                throw Error("updateScheduleBlock: called with no block ID.");
            }

            const result = await SchedulerApiClient.updateSchedulingBlock(localBlockToUTC(scheduleBlock));
            if(result.success && result.data) {
                return result.data;
            }
            return undefined;

        },


        /**
         * Starts a Walk
         * Updates an existing schedule block status
         */
        async startWalk(scheduleBlock: ScheduleBlock) {
            if(!scheduleBlock.id) {
                throw Error("updateScheduleBlock: called with no block ID.");
            }

            const result = await SchedulerApiClient.updateScheduleBlockStartWalk(scheduleBlock);
            if(result.success && result.data) {
                return result.data;
            }
            return undefined;
        },

        /**
         * Delete Schedule Block
         * Specify a schedule block to be deleted
         */
        async deleteScheduleBlock(scheduleBlockId: number) {
          let result = await SchedulerApiClient.deleteSchedulingBlock(scheduleBlockId);
          if(result.success){
            return true;
          }
          else{
              let error = "Unable to delete schedule block(s)";
              switch (result.status){
                  case 403:
                      error = "You do not have the correct permissions to delete one or more schedule blocks."
                      break;
              }
              throw Error(error);
          }
        },
    },
    getters: {
        /**
         * Identifies and returns whether or not the user has the permission requested.
         *
         * @param state The current state.
         * @returns Whether or not the current user has the permission requested.
         */
        someGetter: (state) => (): boolean => {
            return true;
        }
    }
});

export default useSchedulerStore;
