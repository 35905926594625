<template>
    <div class="min-h-full bg-sky-blue-400 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md p-4 pb-0">
            <img class="max-h-24 w-auto" :src="logo" alt="PugWalk Logo">
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div v-if="!checkingLoginDetails" class="m-2 md:m-0 bg-white py-8 px-4 shadow sm:px-10">
                <h2 class="text-center text-3xl font-extrabold text-gray-900 mb-6">
                    <span v-if="!showPasswordResetForm">Sign in to your account</span><span v-else>Resetting Your Password</span>
                </h2>
                <form v-if="showPasswordResetForm" @submit="handleForgottenPassword">
                    <div v-if="passwordResetRequestSent">
                        <div class="flex inline">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="green">
                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                            <span class="ml-2">Please check your email for a password reset link.</span>
                        </div>
                    </div>
                    <div v-else>
                        <div class="mb-4">
                            To reset your password, please input your email address below and hit 'reset'. We'll then send
                            you an email with instructions to follow.
                        </div>
                        <div>
                            <label for="email" class="block font-medium text-gray-700">
                                Email address
                            </label>
                            <div class="mt-1">
                                <input id="email-forgot"
                                       :disabled="checkingLoginDetails"
                                       name="email"
                                       type="email"
                                       v-model="email"
                                       autocomplete="email"
                                       required
                                       class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                            </div>

                            <div class="flex mt-4">
                                <button type="submit"
                                        :disabled="checkingLoginDetails"
                                        @click="toggleForgottenPassword"
                                        class="py-2 px-4 border border-transparent border-gray-400 hover:text-sky-blue-900"
                                        style="border: 0; border-bottom: 1px solid #efefef"
                                >
                                    Cancel
                                </button>
                                <Button type="submit"
                                        variety="primary"
                                        :disabled="checkingLoginDetails"
                                        @click="handleForgottenPassword"
                                        class="w-full ml-4 flex justify-center py-2 px-4">
                                    Reset
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
                <form v-else @submit="checkingLoginDetails">
                    <div>
                        <label for="email" class="block font-medium text-gray-700">
                            Email address
                        </label>
                        <div class="mt-1">
                            <input id="email"
                                   :disabled="checkingLoginDetails"
                                   name="email"
                                   type="email"
                                   v-model="email"
                                   autocomplete="email"
                                   required
                                   class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        </div>
                    </div>
                    <div class="mt-2">
                        <label for="password" class="block font-medium text-gray-700">
                            Password
                        </label>
                        <div class="mt-1">
                            <input id="password"
                                   name="password"
                                   :disabled="checkingLoginDetails"
                                   type="password"
                                   v-model="password"
                                   autocomplete="current-password" required
                                   class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        </div>
                    </div>
                    <div class="mt-4 flex">
                        <Button type="submit"
                                variety="primary"
                                :disabled="checkingLoginDetails"
                                @click="login"
                                :class="googleLoginEnabled? 'mr-4' : 'mr-0' "
                                class="w-full flex justify-center py-2 px-4 mr-auto">
                            Sign in
                        </Button>
                    </div>
                    <div class="mt-4 flex">
                        <Button
                                v-if="googleLoginEnabled"
                                variety="secondary"
                                :disabled="checkingLoginDetails"
                                @click="googleLogin"
                                class="w-full mr-4 flex justify-center py-2 px-4 mr-auto !dark:text-black !text-black">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                                <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
                                <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
                                <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
                                <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
                            </svg>
                            &nbsp;Login/Sign-up with Google
                        </Button>
                    </div>
                    <div class="text-center mt-4">
                        <button type="submit"
                                v-if="registrationEnabled"
                                :disabled="checkingLoginDetails"
                                @click="register"
                                class="py-2 px-4 border border-transparent border-gray-400 hover:text-sky-blue-900 mr-auto"
                                style="border: 0; border-bottom: 1px solid #efefef"
                        >
                            Register for an account
                        </button>
                    </div>
                    <div class="text-center mt-4">
                        <button type="submit"
                                :disabled="checkingLoginDetails"
                                @click="toggleForgottenPassword"
                                class="py-2 px-4 border border-transparent border-gray-400 hover:text-sky-blue-900 mr-auto"
                                style="border: 0; border-bottom: 1px solid #efefef"
                        >
                            Forgotten your password? Click here.
                        </button>
                    </div>
                </form>
            </div>
            <div v-else class="m-2 md:m-0 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <LoadingSpinner class="m-auto"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import logo from "../../../assets/logo-coloured-bg.png";
import {defineComponent, inject, onBeforeMount, onErrorCaptured, onMounted, ref} from "vue";
import AuthApiClient from "../../../api/AuthApiClient";
import useUserStore from "../useUserStore";
import {useRouter} from "vue-router";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import {OnboardingLevel} from "../../onboarding/types/OnboardingLevel";
import Button from "../../../components/forms/Button.vue";
import {FathomEvent, sendFathomEvent} from "../../fathom/fathomUtils";

export default defineComponent({
    name: "Login",
    components: {
        Button,
        LoadingSpinner
    },
    setup() {
        const gAuth = inject('gAuth');

        const userStore = useUserStore();
        const router = useRouter();

        // Form
        let email = ref('');
        let password = ref('');

        // Login Checking
        let checkingLoginDetails = ref(false);

        // showPasswordResetForm
        let showPasswordResetForm = ref(false);
        let passwordResetRequestSent = ref(false);

        const registrationEnabled = import.meta.env.VITE_ENABLE_REGISTRATION === "true";
        const googleLoginEnabled = import.meta.env.VITE_ENABLE_GOOGLE_LOGIN === "true";

        onBeforeMount(() => {
            if (userStore.userIsAuthenticated) {
                router.removeRoute("login")
                if (!userStore.userHasCompletedOnboarding()) {
                    router.push({path: `/register/${OnboardingLevel.Account}`})
                } else {
                    router.push({path: "/dashboard"})
                }
            }
        });

        const login = async function () {
            if (checkingLoginDetails.value) {
                return;
            }
            checkingLoginDetails.value = true;

            try {
                await AuthApiClient.login(email.value, password.value);
            } catch (error) {
                checkingLoginDetails.value = false;
            }
            finally {
                checkingLoginDetails.value = false;
            }

            sendFathomEvent("USER_LOGIN");

            try{
                await userStore.userHasAuthenticated();
            }
            catch (e) {
                return;
            }

            if (!userStore.userHasCompletedOnboarding()) {
                await router.push({path: `/register/${OnboardingLevel.Account}`})
            } else {
                await router.push({path: "/dashboard"})
            }
        }

        // OAuth Login Checking
        const googleLogin = async function () {
            try {
                checkingLoginDetails.value = true;

                const googleUser = await gAuth.signIn();

                if (!googleUser) {
                    return null;
                }
                let oauth = googleUser.getAuthResponse();
                /**
                    * @TODO
                    * This needs to be planned out, we need a way of detecting which business a potential new user
                    * is going to belong to in order to prepopulate this, a business_id is a required field on the
                    * user table
                */
                let response = await AuthApiClient.oauthLogin("google", oauth);

                if(response.success){
                    try{
                        await userStore.userHasAuthenticated();
                    }
                    catch (e) {
                        return;
                    }
                    if (!userStore.userHasCompletedOnboarding()) {
                        await router.push({path: `/register/${OnboardingLevel.Account}`})
                    } else {
                        await router.push({path: "/dashboard"})
                    }
                }
            }
            catch (error) {
                checkingLoginDetails.value = false;
            }
            finally {
                checkingLoginDetails.value = false;
            }
        }

        const register = async function () {
            await router.push({path: "/register"})
        }

        const handleForgottenPassword = async function () {
            if (email.value.length <= 0) {
                return;
            }

            if (checkingLoginDetails.value) {
                return;
            }

            checkingLoginDetails.value = true;
            try{
                const response = await AuthApiClient.resetPassword(email.value);
                if(response.success){
                    passwordResetRequestSent.value = true;
                }
            }
            catch (e) {}
            finally {
                checkingLoginDetails.value = false;
            }
        }

        const toggleForgottenPassword = function () {
            showPasswordResetForm.value = !showPasswordResetForm.value
        }

        return {
            logo,
            email,
            password,
            checkingLoginDetails,
            showPasswordResetForm,
            passwordResetRequestSent,
            registrationEnabled,
            googleLoginEnabled,
            login,
            googleLogin,
            register,
            toggleForgottenPassword,
            handleForgottenPassword
        }
    }
})
</script>

<style scoped>

</style>
