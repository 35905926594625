<template>
    <TierCard title="Pro" description="Everything you need to run your professional dog walking business" monthly-price="£10" :features="proFeatures" select-button-text="Upgrade to Pro" @selected="handleSelected" :style="additionalStyles"/>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
import TierCard from "../components/TierCard.vue";
export default defineComponent({
    name: "ProTierCard",
    components: {
        TierCard
    },
    props:{
        isSelected: {
            type: Boolean,
            required: true
        }
    },
    emits:[
        'selected'
    ],
    setup(props, context){
        const proFeatures = [
            { display: "Customer management", included: true },
            { display: "Dog management", included: true },
            { display: "Walk scheduling", included: true },
            { display:"Invoicing", included: true }
        ]

        const additionalStyles = computed(() => props.isSelected ? "border: solid 2px rgb(115, 213, 229);" : "");

        const handleSelected = function() {
            context.emit("selected");
        }

        return {
            proFeatures,
            handleSelected,
            additionalStyles
        };
    }
})
</script>

<style scoped>

</style>
