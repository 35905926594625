export const menuItems = [
    {
        label : "Home",
        url: "/dashboard",
    },
    {
        label : "Schedule",
        url: "/schedule",
    },
    {
        label : "Customers",
        url: "/customers",
    },
    {
        label : "Invoices",
        url: "/invoices",
    },
    {
        label : "Business",
        url: "/business",
    },
    {
        label : "Help",
        url: "/help",
    },
]

export const walkBuddyMenuItems  = [
    {
        label : "About Us",
        url: "/about-us"
    }
]
