<template>
    <div class="flex flex-col items-center">
        <div v-if="!newImagePreview" class="bg-gray-50 flex h-32 w-48 justify-center border-2 border-dashed border-gray-300 text-gray-400 p-4 items-center justify-center text-center" :class="{ 'rounded-full h-48 w-48': rounded }">
            No Photo
        </div>
        <div v-else class="flex h-32 w-48 justify-center" :class="{ 'h-48 w-48': rounded }">
            <img :src="newImagePreview" class="max-h-full w-full bg-gray-100 bg-blend-hard-light" :class="{ 'rounded-full object-cover': rounded, 'object-contain': !rounded }" />
        </div>
        <LoadingSpinner v-if="isSaving" />
        <div class="flex w-full flex-row gap-1 mt-2" v-if="isEditing">
            <input id='hidden-upload' type='file' hidden @change="onFileChange"/>
            <Button variety="plain" class="flex-1" :disabled="isSaving" @click.stop.prevent="openFileDialogue">
                {{ isSaving ? "Please Wait" : "Upload Photo"}}
            </Button>
            <Button variety="plainer" @click.stop.prevent="onFileDeleted" v-if="newImagePreview">X</Button>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'

export default defineComponent({
    props: {
        initialImageUrlToDisplay: {
            type: String,
            required: false
        },
        rounded: {
            type: Boolean,
            required: false,
            default: false
        },
        isEditing: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        "dataChanged",
        "imageDeleted"
    ],
    setup(props, context) {
        const newImageFile = ref({} as File);
        const newImagePreview = ref(props.initialImageUrlToDisplay as string | ArrayBuffer | undefined | null);

        const isSaving = ref(false);

        function resetValues() {
            newImageFile.value = {} as File;
            newImagePreview.value = '';
        }

        function onFileChange(event: Event) {
            isSaving.value = true;
            newImageFile.value = (event.target as HTMLInputElement).files![0];
            // Use the following to get the preview
            let reader = new FileReader();
            reader.addEventListener("load", () => {
                newImagePreview.value = reader.result;
            });
            reader.readAsDataURL( newImageFile.value );
            context.emit("dataChanged", newImageFile.value);
            isSaving.value = false;
            resetValues();
        }

        function onFileDeleted() {
            resetValues();
            isSaving.value = true;
            context.emit("imageDeleted");
            isSaving.value = false;
        }

        function openFileDialogue(){
            document.getElementById('hidden-upload')!.click();
        }

        return {
            isSaving,
            openFileDialogue,
            onFileChange,
            onFileDeleted,
            resetValues,
            newImagePreview
        }
    },
})
</script>
