<template>
    <!-- Title -->
    <PageTitleContainer>
        <h1 class="text-2xl font-bold text-black dark:text-gray-100 mr-4">{{ dashboardTitle }}</h1>
    </PageTitleContainer>

    <!-- Content -->
    <PageContentContainer>
        <div class="flex flex-col items-start md:flex-row flex-grow gap-y-4 md:gap-x-4 p-2 md:p-0">
            <div class="flex" style="flex-basis: 50%">
                <ScheduleWidget></ScheduleWidget>
            </div>
            <div class="flex flex-col gap-y-4">
                <div class="flex flex-col gap-y-4 order-first sm:order-last" style="flex-basis: 50%">
                    <mq-responsive>
                        <template style="width: 100%" #sm->
                            <MetricsWidget :slim-mode="true"></MetricsWidget>
                        </template>
                        <template #md+>
                            <MetricsWidget></MetricsWidget>
                        </template>
                    </mq-responsive>
                </div>
                <div class="flex flex-col gap-y-4 order-first sm:order-last" style="flex-basis: 50%">
                    <mq-responsive>
                        <template style="width: 100%" #sm->
                            <InvoicesWidget :slim-mode="true"></InvoicesWidget>
                        </template>
                        <template #md+>
                            <InvoicesWidget></InvoicesWidget>
                        </template>
                    </mq-responsive>
                </div>
            </div>
        </div>
    </PageContentContainer>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted} from "vue";
import ScheduleWidget from "../components/ScheduleWidget.vue";
import InvoicesWidget from "../components/InvoicesWidget.vue";
import useBusinessStore from "../../business/useBusinessStore";
import MetricsWidget from "../components/MetricsWidget.vue";
import useDashboardStore from "../useDashboardStore";
import { MqResponsive } from 'vue3-mq';
import PageTitleContainer from "../../../components/PageTitleContainer.vue";
import PageContentContainer from "../../../components/PageContentContainer.vue";
import useTitleManager from "../../../utils/useTitleManager";

export default defineComponent({
    name: "Dashboard",
    components: {
        PageContentContainer,
        PageTitleContainer,
        ScheduleWidget,
        MetricsWidget,
        InvoicesWidget,
        MqResponsive
    },
    setup() {
        const businessStore = useBusinessStore();
        const dashboardStore = useDashboardStore();

        const { setTitlePrefix } = useTitleManager();

        const dashboardTitle = computed(() => `${businessStore.activeBusiness.name} Overview`);

        onMounted(async () => {
            await dashboardStore.refreshDashboardMetrics();
            setTitlePrefix("Dashboard");
        });

        return {
            dashboardTitle
        }
    }
})
</script>

<style scoped>

</style>
