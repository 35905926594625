<template>

    <!-- Mobile View -->
    <div v-if="mediaFlags.sm.lessThanOrEq" class="main-bg overflow-hidden mobile-container pb-safe h-full  dark:bg-gray-800">
        <div style="height: 100%; overflow:hidden; display: flex; flex-direction: column">
            <Navigation/>
            <div id="main-section" class="flex flex-grow flex-col overflow-hidden bg-gray-100">
                <!-- the router view needs to be aware that it is in a flex column and overflow is hidden -->
                <router-view v-if="mediaFlags.sm.lessThanOrEq"/>
                <div id="sticky"></div>
            </div>
            <div v-if="mediaFlags.sm.lessThanOrEq"
                 class="flex md:hidden bg-gray-200 dark:bg-gray-800 z-10 border-t-2 border-gray-300 dark:border-gray-800 relative mt-auto">
                <div style="flex-basis: 33%" :class="isCurrentRoute('/customers') ? 'bg-sky-blue-500 text-white' : 'bg-white dark:bg-gray-600 text-gray-600 dark:text-gray-50' "
                     class="p-2 px-4  text-center flex flex-col flex-grow"
                     @click="handleRouteChange('/customers')">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 m-auto" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                    </svg>
                    Customers
                </div>
                <div style="flex-basis: 33%" :class="isCurrentRoute('/dashboard') ? 'bg-sky-blue-500 text-white' : 'bg-white dark:bg-gray-600 text-gray-600 dark:text-gray-50' "
                     class="border-l border-r p-2 px-4 text-white z-20 text-center flex flex-col flex-grow"
                     @click="handleRouteChange('/dashboard')">
                    <svg class="h-10 w-10 m-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    Home
                </div>
                <div style="flex-basis: 33%" :class="isCurrentRoute('/schedule') ? 'bg-sky-blue-500 text-white' : 'bg-white dark:bg-gray-600 text-gray-600 dark:text-gray-50' "
                     class="p-1 px-4 text-white z-20 text-center flex flex-col flex-grow"
                     @click="handleRouteChange('/schedule')">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 m-auto" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                    </svg>
                    Schedule
                </div>
            </div>
        </div>
    </div>

    <!-- Tablet and Desktop -->
    <div v-else class="main-bg h-full flex flex-col p-0 md:p-4 md:pb-2 md:pr-1 dark:bg-gray-700">
        <div class="flex overflow-hidden flex-grow">
            <Sidebar />
            <div class="flex flex-col flex-1 overflow-hidden relative">
                <Navigation />
                <div class="flex flex-col flex-grow overflow-hidden border dark:border-black rounded-b-none rounded-2xl shadow bg-gray-100 dark:bg-transparent">
                    <router-view />
                </div>
                <div class="hidden md:flex text-sm mt-2 p-1 rounded text-xs text-gray-600 dark:text-gray-50">
                    <div class="flex-grow">
                        &copy;{{ new Date().getFullYear() }} Walkbuddy.net
                    </div>
                    <div class="">
                        <a href="https://app.termly.io/document/privacy-policy/0c7872ac-09a2-4365-bd67-aae14a009b43" target="_blank" class="mr-2 underline hover:text-sky-blue-900">Privacy Policy</a>
                        | <a target="_blank" href="https://app.termly.io/document/cookie-policy/638b8306-77c1-40fd-b177-ecfe86a91358" class="mr-2 ml-2 underline hover:text-sky-blue-900">Cookie Policy</a>
                        | <div target="_blank" class="inline-block cursor-pointer mr-2 ml-2 underline hover:text-sky-blue-900 pointer" @click="displayPreferenceModal">Manage Cookie Preferences</div>
                        | <a target="_blank" href="https://app.termly.io/document/disclaimer/9d388a5b-0db7-4d82-abf7-5c18cd9de105" class="mr-2 ml-2 underline hover:text-sky-blue-900">Disclaimer</a>
                    </div>
                </div>
                <div class="md:hidden flex z-10 border-t-2 border-gray-300 relative mt-auto">
                    <div :class="isCurrentRoute('/customers') ? 'bg-sky-blue-500 text-white' : 'bg-white text-gray-600' "
                         class="p-2 px-4  text-center flex flex-col flex-grow"
                         @click="handleRouteChange('/customers')">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 m-auto" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                        </svg>
                        Customers
                    </div>
                    <div :class="isCurrentRoute('/dashboard') ? 'bg-sky-blue-500 text-white' : 'bg-white text-gray-600' "
                         class="border-l border-r p-2 px-4 text-white z-20 text-center flex flex-col flex-grow"
                         @click="handleRouteChange('/dashboard')">
                        <svg class="h-10 w-10 m-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                        </svg>
                        Home
                    </div>
                    <div :class="isCurrentRoute('/schedule') ? 'bg-sky-blue-500 text-white' : 'bg-white text-gray-600' "
                         class="p-1 px-4 text-white z-20 text-center flex flex-col flex-grow"
                         @click="handleRouteChange('/schedule')">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 m-auto" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                        </svg>
                        Schedule
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import pug from "../assets/doggo-white-01.png";
import pugWhite from "../assets/logo-coloured-bg.png";
import Sidebar from "../components/Sidebar.vue";
import {computed, defineComponent, ref} from "vue";
import SidebarMenuItem from "../components/SidebarMenuItem.vue";
import {menuItems} from "./utils/menuUtils";
import {useRoute, useRouter} from "vue-router";
import UserSidebarItem from "../modules/user/components/UserSidebarItem.vue";
import Navigation from "./components/Navigation.vue";
import {useMediaQuery} from "../modules/core/useMediaQuery";
import ConsentUtils from "../modules/user/components/consent/ConsentUtils";

export default defineComponent({
    name: "Default",
    components:{
        Navigation,
        UserSidebarItem,
        SidebarMenuItem,
        Sidebar
    },
    setup(){
        const route = useRoute();
        const router = useRouter();
        const routePath = computed(() =>route.path)
        const isMobileMenuVisible = ref(false);
        const { mediaFlags } = useMediaQuery("tailwind");

        const toggleMenu = function() {
            isMobileMenuVisible.value = !isMobileMenuVisible.value
        }

        const isCurrentRoute = function(route : string) {
            return routePath.value === route
        }

        const handleRouteChange = function(route: string) {
            router.push(route);
        }

        const displayPreferenceModal = function() {
            ConsentUtils.setShowConsentModal(true);
            router.go(0);
        }

        return {
            pug,
            pugWhite,
            isMobileMenuVisible,
            isCurrentRoute,
            toggleMenu,
            menuItems,
            handleRouteChange,
            mediaFlags,
            displayPreferenceModal
        }
    }
})
</script>

<style>
</style>
