import ConsentUtils from "../user/components/consent/ConsentUtils";

const fathomEnabled = import.meta.env.VITE_ENABLE_FATHOM === "true";
const envName = import.meta.env.VITE_APP_ENV ?? "dev";
const fathomSiteId = <string>import.meta.env.VITE_FATHOM_SITE_ID ?? "";

/**
 * Attempt to install fathom if the env variable is enabled
 */
export function installFathom(){
    if(window.fathom) return;
    const cookiesEnabled = ConsentUtils.getConsentPermission();
    if(fathomEnabled && cookiesEnabled === "true"){
        const plugin = document.createElement("script");
        plugin.setAttribute(
            "src",
            "https://cdn.usefathom.com/script.js"
        );
        plugin.setAttribute(
            "data-site",
            fathomSiteId
        );
        plugin.defer = true;
        document.head.appendChild(plugin);
    }
}

/**
 * Attempt to fire and event to fathom if the env variable is enabled
 * @param eventName
 */
type fathomEvent = {
    [key: string]: string
}
export function sendFathomEvent(eventName : string){
    // Check if not installed and attempt install
    if(!window.fathom) {
        installFathom();
    }

    // This could still be false as cookies disabled so dont attempt to send
    if(!window.fathom) return;

    let events: fathomEvent = {};
    let eventCode: string|undefined = undefined;
    const cookiesEnabled = ConsentUtils.getConsentPermission();

    switch (envName){
        case "dev":
            events = {
                USER_LOGIN: "AB1KV3K8",

                // Onboarding
                SKIP_ONBOARDING_STAGE_BUSINESS_INFO: "KXJGWE6V",
                SKIP_ONBOARDING_STAGE_PRICING: "13DXDK38",
                SKIP_ONBOARDING_STAGE_INVOICING: "N23JQ6A0",
                ONBOARDING_COMPLETE: "1RJ2PBM2",

                // Dashboard
                USER_PREF_SHOWASLIST: "APWW7YGI",

                // Schedule
                CREATED_A_SCHEDULE_BLOCK: "81QQEGI0",

                // DOGS
                CREATED_A_DOG: "CBDPJREE",
                CREATED_A_DOG_NOTE: "QGFZYUTK",

                // Customers
                CREATED_A_CUSTOMER: "8QCQXDWK",
                CREATED_A_CUSTOMER_KEY: "B8FMZSCI",
                CREATED_A_CUSTOMER_NOTE: "99SMONOR",

                // Copying Schedules
                COPY_SINGLE_SCHEDULE_ITEM: "YO5T3ZHZ",
                COPY_MULTIPLE_SCHEDULE_ITEMS: "OGTUIMHU"
            }
            eventCode = events[eventName];
            break;

        case "production":
            events = {
                USER_LOGIN: "RTD8FJP0",

                // Onboarding
                SKIP_ONBOARDING_STAGE_BUSINESS_INFO: "JS9RUBDW",
                SKIP_ONBOARDING_STAGE_PRICING: "37SGYYDF",
                SKIP_ONBOARDING_STAGE_INVOICING: "OVGP4EDN",
                ONBOARDING_COMPLETE: "8CJZE5IL",

                // Dashboard
                USER_PREF_SHOWASLIST: "QN929KYU",

                // Schedule
                CREATED_A_SCHEDULE_BLOCK: "KBV6KWON",

                // DOGS
                CREATED_A_DOG: "42ZQFPPS",
                CREATED_A_DOG_NOTE: "NDTSGOL4",

                // Customers
                CREATED_A_CUSTOMER: "BPNH1UH0",
                CREATED_A_CUSTOMER_KEY: "OEMKALWQ",
                CREATED_A_CUSTOMER_NOTE: "AUAPSM0V",

                // Copying Schedules
                COPY_SINGLE_SCHEDULE_ITEM: "PX1DXBEV",
                COPY_MULTIPLE_SCHEDULE_ITEMS: "GWUPKJQS"
            }
            eventCode = events[eventName];

            break;

        default:

            break;
    }

    if(fathomEnabled && eventCode && cookiesEnabled === "true"){
        window.fathom.trackGoal(eventCode, 0);
    }
}
