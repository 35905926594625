function convertToPence(pounds: number): number {
    return pounds * 100;
}

function convertToPounds(pence: number): number {
    return Number((pence / 100).toFixed(2));
}

function calculateVat(hasVat: boolean, costInPounds: number): number {
    if(hasVat) {
        return convertToPounds(convertToPence(costInPounds) * 0.2);
    }

    return 0;
}

function getDisplayValue(pounds: number, positivePrefix: string = ""): string {
    const prefix = pounds < 0 ? "-" : positivePrefix;

    return `${prefix}£${Math.abs(pounds).toFixed(2)}`;
}

function getDisplayValueFromPence(pence: number, positivePrefix: string = ""): string {
    const prefix = pence < 0 ? "-" : positivePrefix;

    return `${prefix}£${Math.abs(convertToPounds(pence)).toFixed(2)}`;
}

export default {
    convertToPence,
    convertToPounds,
    calculateVat,
    getDisplayValue,
    getDisplayValueFromPence
};
