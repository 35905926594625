<template>
    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
        <div class="flex flex-col w-48">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex flex-col flex-grow pt-5 pr-4 overflow-y-auto">
                <div class="flex items-center flex-shrink-0">
                    <img alt="pugImage" :src="pug" class="h-10 hidden md:block dark:md:hidden">
                    <img alt="pugImageWhite" :src="pugWhite" class="h-10 block md:hidden dark:md:block">
                </div>
                <div class="text-left ml-1">
                    <DarkModeToggle />
                </div>
                <div class="mt-2 flex-grow flex flex-col border-b pb-2">
                    <nav class="flex-1 space-y-1">
                        <SidebarMenuItem v-for="item in menuItems" :link-url="item.url" :label="item.label" :is-current-local="isCurrentRoute(item.url)" />
                    </nav>
                    <div class="mb-4 text-center">
                        <div class="z-10 relative pl-2">
                            <img alt="Help us doggy" :src="help">
                        </div>
                        <div class="z-20 relative bg-white dark:bg-gray-600 shadow p-2 -mt-6 rounded border-gray-200 dark:border-gray-500 border-2 dark:text-gray-50">
                            Help us shape the product
                            by voting on the next features
                            you want to see.
                            <div class="mt-2">
                                <button @click="goToFeatureRequests" type="submit"
                                        class="px-4 py-2 border border-transparent rounded-md shadow-sm
                            font-medium text-white dark:text-black bg-sky-blue-500  hover:bg-sky-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2
                            focus:ring-indigo-500 disabled:opacity-50">
                                    Suggest Features
                                </button>
                            </div>
                        </div>
                    </div>
                    <UserSidebarItem />
                </div>
                <div class="mt-2">
                    <SidebarMenuItem :small="true" v-for="item in walkBuddyMenuItems" :link-url="item.url" :label="item.label" :is-current-local="isCurrentRoute(item.url)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import pug from "../assets/doggo-white-01.png";
import pugWhite from "../assets/doggo-black-01.png";
import help from "../assets/help.png";
import {computed, defineComponent, ref} from "vue";
import UserSidebarItem from "../modules/user/components/UserSidebarItem.vue";
import ChartIcon from "../components/icons/ChartIcon.vue";
import {PermissionGroup} from "../modules/user/enums/PermissionGroup";
import useUserStore from "../modules/user/useUserStore";
import {useRoute, useRouter} from "vue-router";
import SidebarMenuItem from "./SidebarMenuItem.vue";
import {menuItems, walkBuddyMenuItems} from "../layouts/utils/menuUtils";
import DarkModeToggle from "./DarkModeToggle.vue";

export default defineComponent({
    name: "Sidebar",
    components: {SidebarMenuItem, UserSidebarItem, ChartIcon, DarkModeToggle},
    setup () {
        const route = useRoute();
        const router = useRouter();
        const routePath = computed(() =>route.path)
        const store = useUserStore();
        const userHasViewCustomersPermission = computed(() => store.userHasPermission(PermissionGroup.VIEW_CUSTOMERS));
        const userHasManageBusinessPermissions = computed(() => store.userHasPermission(PermissionGroup.MANAGE_BUSINESS));
        const userHasManageFinancesPermission = computed(() => store.userHasPermission(PermissionGroup.MANAGE_FINANCES));

        const isCurrentRoute = function(route : string){
            return routePath.value === route
        }

        const goToFeatureRequests = async function(){
            await router.push({path : "/features"});
        }

        return {
            pug,
            pugWhite,
            help,
            userHasViewCustomersPermission,
            userHasManageBusinessPermissions,
            userHasManageFinancesPermission,
            goToFeatureRequests,
            menuItems,
            walkBuddyMenuItems,
            isCurrentRoute,
        }
    }
})
</script>

<style scoped>

</style>
