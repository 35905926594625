<template>
    <div class="bg-sky-blue-400">
        <WizardIntro
            v-if="showIntro">
        </WizardIntro>
        <OnboardingStepper
            v-else-if="showStepper"
            :is-horizontal="isHorizontal"
            :onboardingLevel="onboardingLevel">
        </OnboardingStepper>

        <div class="cursor-pointer absolute bottom-1 left-1" @click="logout">
            <ExitIcon class="ml-1 text-white"/>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
import logo from "../../../assets/logo-coloured-bg.png";
import WizardIntro from "./WizardIntro.vue";
import {OnboardingLevel} from "../types/OnboardingLevel";
import OnboardingStepper from "./OnboardingStepper.vue";
import {clearCookies} from "../../../utils/cookies";
import useUserStore from "../../user/useUserStore";
import {useRouter} from "vue-router";
import ExitIcon from "../../../components/icons/ExitIcon.vue";

export default defineComponent({
    name: "WizardPanel",
    components: {
        OnboardingStepper,
        WizardIntro,
        ExitIcon
    },
    props:{
        onboardingLevel: {
            type: Number,
            required: true
        },
        isHorizontal: {
            type: Boolean,
            required: false
        }
    },
    setup(props){
        const showIntro = computed(() => props.onboardingLevel === OnboardingLevel.NotRegistered || props.onboardingLevel === OnboardingLevel.VerificationRequired);
        const showStepper = computed(() => props.onboardingLevel !== OnboardingLevel.NotRegistered && props.onboardingLevel !== OnboardingLevel.VerificationRequired);


        const store = useUserStore();
        const router = useRouter();

        const userDetails = computed(() => store.user)

        const logout = async function(){
            await clearCookies();
            await store.userLoggingOut();
            await router.go(0);
        }

        return {
            logo,
            showIntro,
            showStepper,
            logout
        }
    }
})
</script>

<style scoped>

</style>
