<template>

    <div v-if="showAsDropdownSelector && currentEditState !== ScheduleBlockEditorState.EDITING" class="flex flex-col flex-grow">
        <div v-if="showDropdownPopup" class="mb-4 grid grid-cols-4 gap-2">
            <Button
                class="col-span-2"
                v-if="canStartWalk && !hiddenBecauseOfEdit"
                :is-small-button="showSmallButtons"
                variety="confirm"
                :disabled="isButtonDisabled"
                @click="onScheduleBlockInProgress">Start Walk</Button>
            <Button
                class="col-span-2"
                variety="confirm"
                v-if="currentEditState === ScheduleBlockEditorState.EDITING"
                @click="onScheduleBlockUpdate">Update Walk</Button>
            <Button
                class="col-span-2"
                variety="primary"
                v-if="currentEditState === ScheduleBlockEditorState.CREATING"
                @click="onScheduleBlockUpdate">Create Walk</Button>
            <Button
                class="col-span-2"
                v-if="canCompleteWalk && !hiddenBecauseOfEdit"
                :is-small-button="showSmallButtons"
                variety="confirm"
                :disabled="isButtonDisabled"
                @click="onScheduleBlockComplete">Complete Walk</Button>
            <Button
                class="col-span-2"
                v-if="canResetWalk && !hiddenBecauseOfEdit"
                :is-small-button="showSmallButtons"
                variety="secondary"
                :disabled="isButtonDisabled"
                @click="onScheduleBlockScheduled">Reset Walk</Button>
            <Button
                class="col-span-2"
                v-if="canCancelWalk && !hiddenBecauseOfEdit"
                :is-small-button="showSmallButtons"
                variety="warning"
                :disabled="isButtonDisabled"
                @click="onScheduleBlockCancelled">Cancel Walk</Button>
            <DeleteButtonWithConfirmation
                class="col-span-2"
                v-if="currentEditState !== ScheduleBlockEditorState.EDITING && currentEditState !== ScheduleBlockEditorState.CREATING"
                message="Are you sure you wish to delete the schedule block? This action cannot be undone."
                :center-confirmation="true"
                @onDelete="onScheduleBlockDeleted"
                :is-disabled="!isDeletable"
                label="Delete Walk"
            />
            <Button
                class="col-span-2"
                variety="secondary"
                v-if="currentEditState !== ScheduleBlockEditorState.EDITING && currentEditState !== ScheduleBlockEditorState.CREATING"
                @click="onCopy">Copy Walk</Button>
            <div class="row-span-1 col-span-2 col-start-2">
                <Button
                    style="width: 100%"
                    :disabled="!isEditable"
                    variety="secondary"
                    v-if="canEditWalk"
                    @click="onEdit">Edit Walk</Button>
            </div>
        </div>
        <div :class="showDropdownPopup? 'border-t border-gray-200 pt-4' : ''">
            <Button @click="showDropdownPopup = !showDropdownPopup">I want to...</Button>
        </div>
    </div>

    <div v-if="showAsDropdownSelector && currentEditState === ScheduleBlockEditorState.EDITING">
        <Button
            class="col-span-2"
            variety="confirm"
            v-if="currentEditState === ScheduleBlockEditorState.EDITING"
            @click="onScheduleBlockUpdate">Update Walk</Button>
        <Button
            class="col-span-2"
            variety="primary"
            v-if="currentEditState === ScheduleBlockEditorState.CREATING"
            @click="onScheduleBlockUpdate">Create Walk</Button>
    </div>

    <div v-if="!showAsDropdownSelector" class="flex gap-2">
        <Button
            v-if="canStartWalk && !hiddenBecauseOfEdit"
            :is-small-button="showSmallButtons"
            variety="confirm"
            :disabled="isButtonDisabled"
            @click="onScheduleBlockInProgress">Start Walk</Button>
        <Button
            :disabled="!isEditable"
            variety="secondary"
            v-if="canEditWalk"
            @click="onEdit">Edit Walk</Button>
        <Button
            variety="confirm"
            v-if="currentEditState === ScheduleBlockEditorState.EDITING"
            @click="onScheduleBlockUpdate">Update Walk</Button>
        <Button
            variety="primary"
            v-if="currentEditState === ScheduleBlockEditorState.CREATING"
            @click="onScheduleBlockUpdate">Create Walk</Button>
        <Button
            v-if="canCompleteWalk && !hiddenBecauseOfEdit"
            :is-small-button="showSmallButtons"
            variety="confirm"
            :disabled="isButtonDisabled"
            @click="onScheduleBlockComplete">Complete Walk</Button>
        <Button
            v-if="canResetWalk && !hiddenBecauseOfEdit"
            :is-small-button="showSmallButtons"
            variety="secondary"
            :disabled="isButtonDisabled"
            @click="onScheduleBlockScheduled">Reset Walk</Button>
        <Button
            v-if="canCancelWalk && !hiddenBecauseOfEdit"
            :is-small-button="showSmallButtons"
            variety="warning"
            :disabled="isButtonDisabled"
            @click="onScheduleBlockCancelled">Cancel Walk</Button>
        <DeleteButtonWithConfirmation
            v-if="currentEditState !== ScheduleBlockEditorState.EDITING && currentEditState !== ScheduleBlockEditorState.CREATING"
            message="Are you sure you wish to delete the schedule block? This action cannot be undone."
            :center-confirmation="true"
            @onDelete="onScheduleBlockDeleted"
            :is-disabled="!isDeletable"
            label="Delete Walk"
        />
        <Button
            v-if="currentEditState !== ScheduleBlockEditorState.EDITING && currentEditState !== ScheduleBlockEditorState.CREATING"
            variety="secondary"
            @click="onCopy">Copy Walk</Button>
    </div>

</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref} from "vue";
import ScheduleBlockEditorState from "../types/ScheduleBlockEditorState";
import ScheduleBlockStatus from "../types/ScheduleBlockStatus";
import DeleteButtonWithConfirmation from "../../../components/forms/DeleteButtonWithConfirmation.vue";
import Button from "../../../components/forms/Button.vue";

export default defineComponent({
    name: "ScheduleActionButtons",
    components: {Button, DeleteButtonWithConfirmation},
    props: {
        status: {
            type: String as PropType<ScheduleBlockStatus>,
            required: true
        },
        currentEditState : {
            type: String,
            required: true
        },
        isDeletable : {
            type: Boolean,
            required: true
        },
        isEditable : {
            type: Boolean,
            required: true
        },
        hiddenBecauseOfEdit: {
            type: Boolean,
            required: true
        },
        showSmallButtons: {
            type:Boolean,
            required: false,
            default: false
        },
        isDisabled: {
            type: Boolean,
            required: true
        },
        showAsDropdownSelector: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    emits: [
        "on-schedule-block-in-progress",
        "on-schedule-block-complete",
        "on-schedule-block-cancelled",
        "on-schedule-block-scheduled",
        "on-schedule-block-deleted",
        "on-edit",
        "on-schedule-block-update",
        "on-cancel",
        "on-copy"
    ],
    setup(props, context){

        const canUpdateWalkStatus = computed(() =>
            props.currentEditState !== ScheduleBlockEditorState.EDITING
            && props.currentEditState !== ScheduleBlockEditorState.CREATING);

        const canEditWalk = computed(() =>
            props.status === ScheduleBlockStatus.STATUS_SCHEDULED
            && props.currentEditState !== ScheduleBlockEditorState.EDITING
            && props.currentEditState !== ScheduleBlockEditorState.CREATING);

        const canStartWalk = computed(() => props.status === ScheduleBlockStatus.STATUS_SCHEDULED && canUpdateWalkStatus);
        const canCompleteWalk = computed(() => props.status === ScheduleBlockStatus.STATUS_IN_PROGRESS && canUpdateWalkStatus);
        const canCancelWalk = computed(() => props.status === ScheduleBlockStatus.STATUS_SCHEDULED && canUpdateWalkStatus);
        const canResetWalk = computed(() => props.status && props.status !== ScheduleBlockStatus.STATUS_SCHEDULED && canUpdateWalkStatus);
        const isButtonDisabled = computed(() => props.isDisabled);

        const onScheduleBlockInProgress = async () => {
            context.emit("on-schedule-block-in-progress");
        }

        const onScheduleBlockComplete = async () => {
            context.emit("on-schedule-block-complete");
        }

        const onScheduleBlockCancelled = async () => {
            context.emit("on-schedule-block-cancelled");
        }

        const onScheduleBlockScheduled = async () => {
            context.emit("on-schedule-block-scheduled");
        }

        const onScheduleBlockDeleted = () => {
            context.emit('on-schedule-block-deleted');
        }

        const onEdit = () => {
            context.emit('on-edit');
        }

        const onScheduleBlockUpdate = () => {
            context.emit('on-schedule-block-update');
        }

        const onCancel = () => {
            context.emit('on-cancel');
        }

        const onCopy = () => {
            context.emit('on-copy');
        }

        const internalSelectionValue = ref("");
        const showDropdownPopup = ref("");

        return {
            onScheduleBlockDeleted,
            onEdit,
            onScheduleBlockUpdate,
            onCancel,

            ScheduleBlockEditorState,
            ScheduleBlockStatus,

            canStartWalk,
            canCompleteWalk,
            canCancelWalk,
            canResetWalk,
            isButtonDisabled,
            canEditWalk,
            internalSelectionValue,
            showDropdownPopup,

            onScheduleBlockInProgress,
            onScheduleBlockComplete,
            onScheduleBlockCancelled,
            onScheduleBlockScheduled,
            onCopy
        }

    }
})
</script>

<style scoped>

</style>
