<template>
    <Form @submit="onSubmit">
        <FormComponent>
            <TextInput
                id="working-hours-start"
                label="Working Hours Start"
                placeholder=""
                :value="workingHoursStart"
                :errors="workingHoursStartError"
                :isEditing="isEditing"
                type="number"
                :step="1"
                :minNumber="0"
                :maxNumber="24"
                @update:value="(value) => (workingHoursStart = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="working-hours-end"
                label="Working Hours End"
                placeholder=""
                :step="1"
                :value="workingHoursEnd"
                :errors="workingHoursEndError"
                :isEditing="isEditing"
                type="number"
                :minNumber="0"
                :maxNumber="24"
                @update:value="(value) => (workingHoursEnd = value)"
            />
        </FormComponent>
    </Form>
</template>

<script lang="ts">
import { useField, useForm } from "vee-validate";
import {computed, defineComponent, PropType, watch} from "vue";
import * as yup from "yup";
import Business from "../types/Business";

export default defineComponent({
    props: {
        isEditing: {
            type: Boolean,
            required: true
        },
        business: {
            type: Object as PropType<Business>,
            required: true
        }
    },
    setup(props, context) {
        const isEditing = computed(() => props.isEditing);
        const schema = yup.object({
            working_hours_start: yup.string().nullable(),
            working_hours_end: yup.string().nullable(),
        });

        const {handleSubmit} = useForm({
            validationSchema: schema,
        });

        const onSubmit = handleSubmit(values => {
            context.emit('onSubmit', values);
        });

        const { value: workingHoursStart, errorMessage: workingHoursStartError } = useField('working_hours_start');
        const { value: workingHoursEnd, errorMessage: workingHoursEndError } = useField('working_hours_end');

        watch(
            isEditing,
            () => {
                workingHoursStart.value = props.business.working_hours_start;
                workingHoursEnd.value = props.business.working_hours_end;
            },
            { immediate: true }
        );

        return {
            workingHoursStart,
            workingHoursStartError,

            workingHoursEnd,
            workingHoursEndError,

            onSubmit
        }
    }
})
</script>
