<template>
    <div class="flex flex-grow h-full" v-if="isOnboarding">
        <BusinessInfoForm
            v-if="showBusinessInfoForm"
            @onBusinessInfoSubmit="onBusinessInfoSubmit">
        </BusinessInfoForm>
        <WalkPricesForm
            v-if="showWalkPricesForm"
            @onWalkPricesSubmit="onWalkPricesSubmit">
        </WalkPricesForm>
        <InvoicingForm
            v-if="showInvoicingForm"
            @onInvoicingSubmit="onInvoicingSubmit">
        </InvoicingForm>
        <OnboardingComplete
            v-if="showOnboardingComplete">
        </OnboardingComplete>
    </div>
    <div class="flex flex-grow h-full items-center justify-center" v-else >
        <RegistrationForm
            v-if="showRegistrationForm"
            @onSubmit="onRegister">
        </RegistrationForm>
        <VerificationRequired
            v-else-if="showVerificationRequired"
            :userDetails="userDetails">
        </VerificationRequired>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
import RegistrationForm from "./RegistrationForm.vue";
import {OnboardingLevel} from "../types/OnboardingLevel";
import VerificationRequired from "./VerificationRequired.vue";
import WizardHeader from "./WizardHeader.vue";
import BusinessInfoForm from "./BusinessInfoForm.vue";
import WalkPricesForm from "./WalkPricesForm.vue";
import InvoicingForm from "./InvoicingForm.vue";
import OnboardingComplete from "./OnboardingComplete.vue";

export default defineComponent({
    name: "WizardContent",
    components: {
        OnboardingComplete,
        BusinessInfoForm,
        WizardHeader,
        VerificationRequired,
        RegistrationForm,
        WalkPricesForm,
        InvoicingForm
    },
    props:{
        onboardingLevel: {
            type: Number,
            required: true
        },
        userDetails: {
            type: Object,
            required: false
        }
    },
    emits:[
        "onRegister",
        "onBusinessInfoSubmit",
        "onWalkPricesSubmit",
        "onInvoicingSubmit"
    ],
    setup(props, context){
        const showRegistrationForm = computed(() => props.onboardingLevel === OnboardingLevel.NotRegistered);

        const showVerificationRequired = computed(() => props.onboardingLevel === OnboardingLevel.VerificationRequired);

        const isOnboarding = computed(() => props.onboardingLevel >= OnboardingLevel.Account);

        const showBusinessInfoForm = computed(() => props.onboardingLevel === OnboardingLevel.Account);

        const showWalkPricesForm = computed(() => props.onboardingLevel === OnboardingLevel.BusinessInfo);

        const showInvoicingForm = computed(() => props.onboardingLevel === OnboardingLevel.Pricing);

        const showOnboardingComplete = computed(() => props.onboardingLevel === OnboardingLevel.Invoicing);

        async function onRegister(values: {[name: string]: string}) {
            context.emit("onRegister", values);
        }

        async function onBusinessInfoSubmit(values: {[name: string]: string}) {
            context.emit("onBusinessInfoSubmit", values);
        }

        async function onWalkPricesSubmit(values: {[name: string]: string}) {
            context.emit("onWalkPricesSubmit", values);
        }

        async function onInvoicingSubmit(values: {[name: string]: string}) {
            context.emit("onInvoicingSubmit", values);
        }

        return {
            showRegistrationForm,
            showVerificationRequired,
            isOnboarding,
            showBusinessInfoForm,
            showWalkPricesForm,
            showInvoicingForm,
            showOnboardingComplete,
            onRegister,
            onBusinessInfoSubmit,
            onWalkPricesSubmit,
            onInvoicingSubmit
        }
    }
})
</script>

<style scoped>

</style>
