<template>
    <div
        class="grid grid-cols-3 gap-4 items-start"
        :class="{
            'py-5': hasTopPadding && hasBottomPadding,
            'pt-5': hasTopPadding && !hasBottomPadding,
            'pb-5': !hasTopPadding && hasBottomPadding
        }">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        hasTopPadding: {
            type: Boolean,
            required: false,
            default: true
        },
        hasBottomPadding: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    setup({ hasTopPadding, hasBottomPadding }) {
        return {
            hasTopPadding,
            hasBottomPadding
        }
    },
})
</script>
