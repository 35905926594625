<template>
    <div class="mx-auto px-4 sm:px-6 md:px-8 mb-4 w-48 h-48" v-if="invoicesLoading">
        <LoadingSpinner />
    </div>
    <div v-else  class="flex flex-col">
        <SendInvoiceModal
            v-if="statusChangeAttempt != null"
            :customer-name="invoiceToSend?.customer_name"
            :customer-email="invoiceToSend?.customer_email"
            :status-change-attempt="statusChangeAttempt"
            @cancel="cancelSendInvoice"
            @status-selected="statusSelected"
        />
            <div class="overflow-x-auto">
                <div class="align-middle inline-block min-w-full dark:text-gray-100">
                    <!-- No invoices found -->
                    <div v-if="!invoices?.data || !invoices.data.length">
                        No invoices have been created. If you have any filters enabled, try changing them.
                    </div>
                    <div v-else class="relative shadow overflow-hidden border-b border-gray-200 dark:border-gray-900">
                        <table class="hidden md:table min-w-full divide-y divide-gray-200  dark:divide-gray-800">
                            <thead class="bg-gray-50 dark:bg-gray-900">
                            <tr>
                                <th v-if="columnLimit.includes(0)" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider">
                                    Reference
                                </th>
                                <th v-if="columnLimit.includes(1)" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider">
                                    Customer
                                </th>
                                <th v-if="columnLimit.includes(2)" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider">
                                    Total Due
                                </th>
                                <th v-if="columnLimit.includes(3)" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider">
                                    Status
                                </th>
                                <th v-if="columnLimit.includes(4)" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider w-64">
                                    Expected Payment Date
                                </th>
                                <th v-if="columnLimit.includes(5)" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider w-64">
                                    Created
                                </th>
                                <th v-if="columnLimit.includes(6)" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider w-32">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody class="bg-white dark:bg-gray-700 divide-y divide-gray-200 dark:divide-gray-800">
                            <tr
                                class="hover:bg-gray-100 dark:hover:bg-gray-500 dark:hover:text-gray-900 text-gray-900 dark:text-gray-100 cursor-pointer"
                                v-for="invoice in invoicesToShow"
                                :key="invoice"
                                @click.stop="invoiceClicked(invoice)">
                                <td v-if="columnLimit.includes(0)" class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex items-center font-medium">
                                        {{ invoice.reference ?? invoice.id }}
                                    </div>
                                </td>
                                <td v-if="columnLimit.includes(1)" class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex items-center font-medium">
                                        {{ invoice.customer_name }}
                                    </div>
                                </td>
                                <td v-if="columnLimit.includes(2)" class="px-6 py-4 whitespace-nowrap">
                                    <div>&pound;{{ invoice.total_price ? (invoice.total_price / 100).toFixed(2) : "0.00" }}</div>
                                </td>
                                <td v-if="columnLimit.includes(3)" class="px-6 py-4 whitespace-nowrap">
                                    {{ friendlyStatusName(invoice) }}
                                </td>
                                <td v-if="columnLimit.includes(4)" class="px-6 py-4 whitespace-nowrap">
                                    {{ prettyPrintInvoiceDate(invoice.expected_payment_date) }}
                                </td>
                                <td v-if="columnLimit.includes(5)" class="px-6 py-4 whitespace-nowrap">
                                    {{ prettyPrintInvoiceDate(invoice.created_at) }}
                                </td>
                                <td v-if="columnLimit.includes(6)" class="flex px-6 py-4 whitespace-nowrap w-32 justify-end gap-1">
                                    <Button
                                        v-if="invoice.status === InvoiceStatus.DRAFT"
                                        variety="plain"
                                        @click.stop="startSendInvoice(invoice)"
                                    >
                                        Send
                                    </Button>
                                    <Button
                                        v-if="invoice.status === InvoiceStatus.SENT || invoice.status === InvoiceStatus.MANUALLY_SENT"
                                        variety="primary"
                                        @click.stop="markInvoiceAsPaid(invoice)"
                                    >
                                        Mark as paid&nbsp;
                                    </Button>
                                    <DeleteButtonWithConfirmation
                                        v-if="(invoice.status === InvoiceStatus.CANCELLED || invoice.status === InvoiceStatus.DRAFT) && !invoice.deleted_at"
                                        message="Delete invoice?"
                                        @on-delete="deleteInvoice(invoice.id)"
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div class="block md:hidden bg-white dark:bg-gray-700 mb-2 p-2 shadow"
                             v-for="invoice in invoicesToShow"
                             @click.stop="invoiceClicked(invoice)"
                             :key="invoice">
                            <div class="flex">
                                <div class="flex-grow">
                                    <div class="font-medium text-gray-900 dark:text-gray-100">
                                        <strong>{{ invoice.customer_name }}</strong><br />
                                        <span>&pound;{{ invoice.total_price ? (invoice.total_price / 100).toFixed(2) : "0.00" }}</span><br />
                                        <strong>Number</strong> : {{ invoice.id }}<br/>
                                        <strong>Status</strong> : {{ friendlyStatusName(invoice) }}<br/>
                                        <strong>Created At</strong> : {{ prettyPrintInvoiceDate(invoice.created_at) }}<br/>
                                        <strong>Due By:</strong> {{ prettyPrintInvoiceDate(invoice.expected_payment_date) }}
                                    </div>
                                </div>
                                <div v-if="columnLimit.includes(6)" class="ml-4 flex flex-1 flex-col gap-2 justify-between">
                                    <Button
                                        v-if="invoice.status === InvoiceStatus.DRAFT"
                                        variety="plain"
                                        @click.stop="startSendInvoice(invoice)"
                                    >
                                        Send
                                    </Button>
                                    <Button
                                        v-if="invoice.status === InvoiceStatus.SENT || invoice.status === InvoiceStatus.MANUALLY_SENT"
                                        variety="primary"
                                        @click.stop="markInvoiceAsPaid(invoice)"
                                    >
                                        Mark as paid&nbsp;
                                    </Button>
                                    <DeleteButtonWithConfirmation
                                        v-if="(invoice.status === InvoiceStatus.CANCELLED || invoice.status === InvoiceStatus.DRAFT) && !invoice.deleted_at"
                                        message="Delete invoice?"
                                        @on-delete="deleteInvoice(invoice.id)"
                                    />
                                </div>
                            </div>
                        </div>

                        <Pagination v-if="enablePagination"
                            :paging="invoices"
                            @linkClicked="$emit('pagination-link-clicked', $event)"
                        />
                    </div>
                </div>
            </div>
        </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, PropType, ref} from "vue";
import { useRouter } from "vue-router";
import Pagination from "../../../components/Pagination.vue";
import SelectionOption from "../../../types/SelectionOption";
import Invoice from '../types/Invoice'
import InvoiceStatus from "../types/InvoiceStatus";
import SlimlineInvoice from "../types/SlimlineInvoice";
import useInvoiceStore from "../useInvoiceStore";
import InvoiceUtils from "../utils/InvoiceUtils";
import Button from "../../../components/forms/Button.vue";
import DeleteButtonWithConfirmation from "../../../components/forms/DeleteButtonWithConfirmation.vue";
import SendInvoiceModal from "../components/SendInvoiceModal.vue";

export default defineComponent({
    components: {
        DeleteButtonWithConfirmation,
        Pagination,
        Button,
        SendInvoiceModal
    },
    props: {
        invoiceStatuses: {
            type: Array as PropType<SelectionOption[]>,
            required: true
        },
        enablePagination: {
            type: Boolean,
            required: false,
            default: true
        },
        invoiceLimit: {
            type: Number,
            required: false,
            default: 0
        },
        columnLimit: {
            type: Array as PropType<Number[]>,
            required: false,
            default: [0, 1, 2, 3, 4, 5, 6]
        }
    },
    setup(props) {
        const router = useRouter();
        const invoiceStore = useInvoiceStore();

        const invoicesLoading = computed(() => invoiceStore.invoicesLoading);
        const invoices = computed(() => invoiceStore.invoices);
        const invoicesToShow = computed(() => props.invoiceLimit === 0 ? invoices.value?.data : invoices.value?.data?.slice(0, props.invoiceLimit));

        let invoiceToSend = ref<SlimlineInvoice | null>();
        let statusChangeAttempt = ref<InvoiceStatus | null>();

        function friendlyStatusName(invoice: SlimlineInvoice) {
            return props.invoiceStatuses.find(s => s.value === invoice.status)?.name;
        }

        function invoiceClicked(invoice: SlimlineInvoice) {
            router.push(`/invoices/${invoice.id}`);
        }

        async function markInvoiceAsPaid(invoice: SlimlineInvoice) {
            await invoiceStore.invoiceStatusChanged(invoice.id, InvoiceStatus.PAID);
        }

        async function markInvoiceAs(invoice: SlimlineInvoice, invoiceStatus: InvoiceStatus) {
            await invoiceStore.invoiceStatusChanged(invoice.id, invoiceStatus);
        }

        async function deleteInvoice(invoiceId: number) {
            await invoiceStore.invoiceDeleted(invoiceId);
        }

        async function statusSelected(invoiceStatus: InvoiceStatus) {
            await markInvoiceAs(invoiceToSend.value!, invoiceStatus);

            invoiceToSend.value = null;
            statusChangeAttempt.value = null;
        }

        function startSendInvoice(invoice: SlimlineInvoice) {
            invoiceToSend.value = invoice;
            statusChangeAttempt.value = InvoiceStatus.SENT;
        }

        function cancelSendInvoice() {
            invoiceToSend.value = null;
            statusChangeAttempt.value = null;
        }

        return {
            friendlyStatusName,
            prettyPrintInvoiceDate: InvoiceUtils.prettyPrintInvoiceDate,
            InvoiceStatus,

            invoiceClicked,
            markInvoiceAsPaid,
            deleteInvoice,

            invoices,
            invoicesToShow,
            invoicesLoading,

            invoiceToSend,
            statusChangeAttempt,
            startSendInvoice,
            cancelSendInvoice,
            statusSelected
        }
    }
})
</script>
