import { defineStore } from "pinia";
import VotingApiClient from "../../api/VotingApiClient";
import VoteType from "./types/VoteType";
import VotingFeature from "./types/VotingFeature";
import FeatureSuggestion from "./types/FeatureSuggestion";
import VoteError from "./classes/VoteError";
import useUserStore from "../user/useUserStore";

const useVotingStore = defineStore({
    id: "voting",
    state: () => ({
        features: [] as VotingFeature[],
        userVotesRemaining: useUserStore().user?.votes_remaining ?? 0
    }),
    actions: {
        /**
         * Lists all available features
         */
        async listFeatures() {
            let response = await VotingApiClient.listVotingFeatures();
            if(response.success) {
                this.features = response.data as VotingFeature[];
            }
        },
        /**
         * Vote for feature
         */
        async voteForFeature(feature: VotingFeature, voteType: VoteType) : Promise<boolean> {
            const id = feature.id;
            const alreadyVotedForThisFeature = feature.has_user_voted;
            try {
                let response = await VotingApiClient.voteForFeature(id, voteType);
                if (response.success) {
                    let updatedFeatureResponse = await VotingApiClient.getFeature(id);
                    if (updatedFeatureResponse.success && this.features && updatedFeatureResponse.data?.id) {
                        feature = this.features[this.features.findIndex(f => f.id === id)];
                        feature.votes = updatedFeatureResponse.data.votes;
                        feature.has_user_voted = updatedFeatureResponse.data.has_user_voted;
                        feature.score = updatedFeatureResponse.data.score;
                        feature.user_vote_type = updatedFeatureResponse.data.user_vote_type;

                        if(!alreadyVotedForThisFeature){
                            this.userVotesRemaining--;
                        }
                        return true;
                    }
                } else {
                    return false;
                }
            }
            catch (e) {
                throw new VoteError(
                    e.errors.errors
                )
            }
            return false;
        },
        /**
         * Suggest feature
         */
        async suggestFeature(suggestion: FeatureSuggestion) {
            let response = await VotingApiClient.suggestFeature(suggestion);
            return response.success;

        },

        setVotesRemaining(amount: number){
            this.userVotesRemaining = amount;
        }
    },
    getters: {
        getFeatures: (state) => (): VotingFeature[] => {
            return state.features;
        },
        getVotesRemaining: (state) => (): number => {
            return state.userVotesRemaining;
        }
    }
});

export default useVotingStore;