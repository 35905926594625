<template>
  <Modal
      style="z-index: 8000"
      :visible="show"
      size="medium"
      title="Unable to modify schedule block."
  >

      <p class="-mt-4">{{ message }}</p>

      <Button class="mt-4" variety="primary" @click="$emit('onCancel');">Cancel</Button>
  </Modal>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Button from "../../../components/forms/Button.vue";

export default defineComponent({
    components: {
        Button,
    },
    props:{
      show: {
          type: Boolean,
          required: true,
      },
      message: {
          type: String,
          required: true,
      }
    }
})
</script>
