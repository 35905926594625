<template>

    <Modal
        :visible="dogCategoriesModalVisible"
        size="xlarge"
        :title="isEditing ? dogCatToEdit ? dogCatToEdit.name : 'Create a Surcharge Type' : dogCatToEdit?.name"
        @onClose="toggleModal"
    >
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
            </svg>
        </template>


        <CategoriesForm
            :isEditing="isEditing"
            :catToEdit="dogCatToEdit"
            @onSubmit="dogCatCreatedOrUpdated"
            ref="categoriesForm"
        />

        <template #footer-buttons>
            <DeleteButtonWithConfirmation v-if="!isEditing" :message="`Are you sure you wish to delete the category '${dogCatToEdit?.name}'? This action cannot be undone.`" @onDelete="catDeleted" />
            <Button v-if="!isEditing" variety="primary" @click="isEditing = true">Edit</Button>
            <Button
                v-if="!isEditing"
                variety="plain"
                @click="toggleModal"
            >
                Close
            </Button>
            <Button
                v-if="isEditing"
                variety="plain"
                @click="toggleModal"
            >
                Cancel
            </Button>
            <Button
                v-if="isEditing"
                variety="primary"
                @click="$refs.categoriesForm.onSubmit()"
            >
                Save
            </Button>
        </template>
    </Modal>

    <Card title="Surcharge Types">
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
            </svg>
        </template>

        <template #buttons>
            <Button
                variety="primary"
                @click="addCatClicked"
            >
                Add
            </Button>
        </template>

        <div v-if="dogCategories && dogCategories.length" class="flex flex-col gap-y-2">
            <div class="p-1 grid grid-cols-2 font-bold text-gray-600">
                <div>Type</div>
                <div>Price</div>
            </div>
            <div class="grid grid-cols-2 p-1 hover:bg-gray-100 cursor-pointer rounded-lg border border-gray-100 shadow p-2 mb-1" v-for="cat in dogCategories" :key="cat" @click="catClicked(cat)">
                <div>{{cat.name}}</div>
                <div>&pound;{{cat.price_per_minute.toFixed(2)}}</div>
            </div>

        </div>
        <div v-else class="text-gray-500">
            No surcharge types have been created.
        </div>
    </Card>

</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, onMounted, ref} from "vue";
import Card from "../../../components/Card.vue";
import useBusinessStore from "../useBusinessStore";
import DogCategory from "../types/DogCategory";
import DeleteButtonWithConfirmation from "../../../components/forms/DeleteButtonWithConfirmation.vue";
import CategoriesForm from "./CategoriesForm.vue";

export default defineComponent({
    name: "DogCategoriesCard",
    components: {CategoriesForm, DeleteButtonWithConfirmation, Card},
    props:{
      walkCategories :{
          type: Array,

      }
    },
    setup(props){
        const businessStore = useBusinessStore();
        const dogCategories = computed(() => businessStore.dogCategories);
        const dogCategoriesModalVisible = ref(false);
        const isEditing = ref(false);
        const dogCatToEdit = ref(undefined as DogCategory | undefined);

        const toggleModal = () => {
            dogCategoriesModalVisible.value = !dogCategoriesModalVisible.value;
        }

        function addCatClicked() {
            dogCatToEdit.value = undefined;
            isEditing.value = true;
            dogCategoriesModalVisible.value = true;
        }

        function catClicked(cat: DogCategory) {
            dogCatToEdit.value = cat;
            isEditing.value = false;
            dogCategoriesModalVisible.value = true;
        }

        async function dogCatCreatedOrUpdated(cat: DogCategory) {
            try {
                if (dogCatToEdit.value?.id) {
                    await businessStore.dogCategoryUpdated({...cat, id: dogCatToEdit.value.id});
                } else {
                    await businessStore.dogCategoryAdded(cat);
                }

                closeModal();
            }
            catch (error){}
        }

        async function catDeleted() {
            try{
                await businessStore.dogCategoryDeleted(dogCatToEdit.value as DogCategory);
                closeModal();
            }
            catch (error){}
        }

        function closeModal() {
            dogCategoriesModalVisible.value = false;
            isEditing.value = false;
        }

        return{
            dogCategories,
            isEditing,
            toggleModal,
            dogCategoriesModalVisible,
            dogCatToEdit,
            addCatClicked,
            catDeleted,
            catClicked,
            dogCatCreatedOrUpdated
        }
    }
})
</script>

<style scoped>

</style>
