import { defineStore } from "pinia";
import CustomerApiClient from "../../api/CustomerApiClient";
import CustomerContactApiClient from "../../api/CustomerContactApiClient";
import CustomerDogsApiClient from "../../api/CustomerDogsApiClient";
import CustomerKeysApiClient from "../../api/CustomerKeysApiClient";
import CustomerNotesApiClient from "../../api/CustomerNotesApiClient";
import PaginatedResponse from "../../types/PaginatedResponse";
import CreatingEditingCustomerKey from "./types/CreatingEditingCustomerKey";
import { Customer } from "./types/Customer";
import CustomerContact from "./types/CustomerContact";
import CustomerDog from "./types/CustomerDog";
import CustomerKey from "./types/CustomerKey";
import CustomerKeyAuditLog from "./types/CustomerKeyAuditLog";
import CustomerNote from "./types/CustomerNote";
import { CustomersListOptions } from "./types/CustomersListOptions";
import SlimCustomer from "./types/SlimCustomer";
import SlimCustomerContact from "./types/SlimCustomerContact";
import SlimlineInvoice from "../invoices/types/SlimlineInvoice";
import InvoiceApiClient from "../../api/InvoiceApiClient";
import InvoiceStatus from "../invoices/types/InvoiceStatus";
import CustomerDogUpdateRequest from "./types/CustomerDogUpdateRequest";

const useCustomerStore = defineStore({
    id: "customers",
    state: () => ({
        availableCustomers: [] as SlimCustomer[],
        availableDogs: [] as CustomerDog[],

        customersLoading: true,
        customers: undefined as PaginatedResponse<SlimCustomer[]> | undefined,

        activeCustomerLoading: true,
        activeCustomer: undefined as Customer | undefined,

        contactsLoading: true,
        contacts: [] as SlimCustomerContact[],

        notesLoading: true,
        notes: [] as CustomerNote[],

        dogsLoading: true,
        dogs: [] as CustomerDog[],

        keysLoading: true,
        keys: [] as CustomerKey[],

        unpaidInvoicesLoading: true,
        unpaidInvoices: [] as SlimlineInvoice[],

        selectedKey: undefined as CustomerKey | undefined,
        selectedKeyAuditLogsLoading: true,
        selectedKeyAuditLogs: [] as CustomerKeyAuditLog[]
    }),
    actions: {
        async listingCustomers(options?: CustomersListOptions) {
            try {
                this.customersLoading = true;
                const response = await CustomerApiClient.getCustomerList(options || {} as CustomersListOptions);
                if (response.success) {
                    this.customers = response.data!;
                }
            } finally {
                this.customersLoading = false;
            }
        },

        async businessChanged() {
            const customers = await CustomerApiClient.getCustomerList({} as CustomersListOptions);
            if (customers.success) {
                this.availableCustomers = customers.data!.data!;
            }

            const dogs = await CustomerDogsApiClient.listAllDogs();

            if (dogs.success) {
                this.availableDogs = dogs.data!;
            }
        },

        async activeCustomerChanged(newActiveCustomerId: number) {
            // Main customer.
            try {
                this.activeCustomerLoading = true;
                this.contactsLoading = true;
                this.notesLoading = true;
                this.dogsLoading = true;
                this.keysLoading = true;
                this.unpaidInvoicesLoading = true;
                this.activeCustomer = undefined;
                this.notes = [];
                this.contacts = [];
                this.unpaidInvoices = [];

                const customerResponse = await CustomerApiClient.getCustomer(newActiveCustomerId);
                if (!customerResponse.success) {
                    return;
                }

                this.activeCustomer = customerResponse.data!;
                this.contacts = [];
            } finally {
                this.activeCustomerLoading = false;
            }

            // Dogs.
            try {
                const dogsResponse = await CustomerDogsApiClient.listDogsForCustomer(this.activeCustomer!.id!);
                this.dogs = dogsResponse.data!;
            } finally {
                this.dogsLoading = false;
            }

            // Contacts.
            try {
                const contactsResponse = await CustomerContactApiClient.listCustomerContacts(this.activeCustomer!.id!);
                this.contacts = contactsResponse.data!;
            } finally {
                this.contactsLoading = false;
            }

            // Notes.
            try {
                const notesResponse = await CustomerNotesApiClient.getCustomerNotes(this.activeCustomer!.id!);
                this.notes = notesResponse.data!;
            } finally {
                this.notesLoading = false;
            }

            // Keys.
            try {
                const keysResponse = await CustomerKeysApiClient.getCustomerKeys(this.activeCustomer!.id!);
                this.keys = keysResponse.data!;
            } finally {
                this.keysLoading = false;
            }

            // Unpaid invoices.
            try {
                const invoicesResponse = await InvoiceApiClient.listInvoices(
                    [InvoiceStatus.SENT, InvoiceStatus.MANUALLY_SENT],
                    this.activeCustomer!.id!,
                    undefined
                );
                this.unpaidInvoices = invoicesResponse.data!.data!;
            } finally {
                this.unpaidInvoicesLoading = false;
            }
        },

        async customerCreated(customer: Customer): Promise<number> {
            const createCustomerResponse = await CustomerApiClient.createCustomer(customer);

            if (createCustomerResponse.success) {
                let createdCustomer = { ...createCustomerResponse.data!, id: createCustomerResponse.data!.id as number, dogs: [] };

                this.availableCustomers.push(createdCustomer);

                this.activeCustomer = createCustomerResponse.data!;
                return this.activeCustomer.id!;
            }

            return 0;
        },

        async customerDeleted(customer: Customer): Promise<void> {
            const response = await CustomerApiClient.deleteCustomer(customer.id!);

            if (response.success) {
                const indexOfAvailableCustomer = this.availableCustomers.findIndex(c => c.id === customer.id);
                this.availableCustomers.splice(indexOfAvailableCustomer, 1);

                this.activeCustomer = undefined;
                this.notes = [];
                this.contacts = [];
            }
        },

        async customerUpdated(customerId: number, customer: Omit<Customer, "id">): Promise<void> {
            this.activeCustomerLoading = true;

            const response = await CustomerApiClient.updateCustomer(customerId, customer);
            if (response.success) {
                this.activeCustomer = response.data!;
                this.listingCustomers();
            }

            this.activeCustomerLoading = false;
        },

        async customerContactCreated(contact: CustomerContact): Promise<void> {
            this.contactsLoading = true;

            const createContactResponse = await CustomerContactApiClient.createCustomerContact(
                this.activeCustomer!.id!,
                contact
            );

            if (createContactResponse.success) {
                this.contacts.push(createContactResponse.data!);
            }

            this.contactsLoading = false;
        },

        async customerContactUpdated(contactId: number, contact: Omit<CustomerContact, "id">): Promise<void> {
            this.contactsLoading = true;

            const updateContactResponse = await CustomerContactApiClient.updateCustomerContact(contactId, contact);
            if (updateContactResponse.success) {
                const currentIndex = this.contacts.findIndex(c => c.id === contactId);
                this.contacts.splice(currentIndex, 1, updateContactResponse.data!);
            }

            this.contactsLoading = false;
        },

        async customerContactDeleted(contact: CustomerContact): Promise<void> {
            this.contactsLoading = true;

            const deleteContactResponse = await CustomerContactApiClient.deleteCustomerContact(contact);
            if (deleteContactResponse.success) {
                const currentIndex = this.contacts.findIndex(c => c.id === contact.id);
                this.contacts.splice(currentIndex, 1);
            }

            this.contactsLoading = false;
        },

        async customerNoteCreated(note: Omit<CustomerNote, "id">): Promise<void> {
            this.notesLoading = true;

            const createNoteResponse = await CustomerNotesApiClient.addCustomerNote(this.activeCustomer!.id!, note);

            if (createNoteResponse.success) {
                this.notes = [createNoteResponse.data!, ...this.notes];
            }

            this.notesLoading = false;
        },

        async customerNoteUpdated(noteId: number, note: Omit<CustomerNote, "id">): Promise<void> {
            this.notesLoading = true;

            const updateNoteResponse = await CustomerNotesApiClient.updateCustomerNote(
                this.activeCustomer!.id!,
                noteId,
                note
            );
            if (updateNoteResponse.success) {
                const currentNoteIndex = this.notes.findIndex(n => n.id === noteId);
                this.notes.splice(currentNoteIndex, 1, updateNoteResponse.data!);
            }

            this.notesLoading = false;
        },

        async customerNoteDeleted(noteId: number): Promise<void> {
            this.notesLoading = true;

            const deleteNoteResponse = await CustomerNotesApiClient.deleteCustomerNote(this.activeCustomer!.id!, noteId);
            if (deleteNoteResponse.success) {
                const currentNoteIndex = this.notes.findIndex(n => n.id === noteId);
                this.notes.splice(currentNoteIndex, 1);
            }

            this.notesLoading = false;
        },

        async customerDogCreated(dog: CustomerDog): Promise<number | undefined> {
            this.dogsLoading = true;

            const createDogResponse = await CustomerDogsApiClient.createDog(this.activeCustomer?.id!, dog);

            if (createDogResponse.success) {
                let createdDog = { ...createDogResponse.data!, customer: this.activeCustomer};

                this.dogs.push(createdDog);
                this.availableDogs.push(createdDog);
            }

            this.dogsLoading = false;
            return createDogResponse.success ? createDogResponse.data!.id : undefined;
        },

        async customerDogUpdated(dog: CustomerDogUpdateRequest): Promise<void> {
            this.dogsLoading = true;

            const updateDogResponse = await CustomerDogsApiClient.updateDog(dog);

            if (updateDogResponse.success) {
                let updatedDog = { ...updateDogResponse.data!, customer: this.activeCustomer};

                const indexOfDog = this.dogs.findIndex(d => d.id === dog.id);
                this.dogs.splice(indexOfDog, 1, updatedDog);

                const indexOfAvailableDog = this.availableDogs.findIndex(d => d.id === dog.id);
                this.availableDogs.splice(indexOfAvailableDog, 1, updatedDog);
            }

            this.dogsLoading = false;
        },

        async customerDogDeleted(dog: CustomerDog): Promise<Boolean> {
            this.dogsLoading = true;

            const deleteDogResponse = await CustomerDogsApiClient.deleteDog(dog);
            if (deleteDogResponse.success) {
                const indexOfDog = this.dogs.findIndex(d => d.id === dog.id);
                this.dogs.splice(indexOfDog, 1);

                const indexOfAvailableDog = this.availableDogs.findIndex(d => d.id === dog.id);
                this.availableDogs.splice(indexOfAvailableDog, 1);
                this.dogsLoading = false;
                return true;
            }
            return false;

        },

        async customerKeySelected(key: CustomerKey): Promise<void> {
            this.selectedKey = key;
            this.selectedKeyAuditLogsLoading = true;
            this.selectedKeyAuditLogs = [];

            const selectedKeyAuditLogsResponse = await CustomerKeysApiClient.getCustomerKeyAuditLogs(key.id!);
            if (selectedKeyAuditLogsResponse.success) {
                this.selectedKeyAuditLogs = selectedKeyAuditLogsResponse.data!;
            }

            this.selectedKeyAuditLogsLoading = false;
        },

        async customerKeyCreated(key: Omit<CreatingEditingCustomerKey, "id">): Promise<number> {
            this.keysLoading = true;

            const createKeyResponse = await CustomerKeysApiClient.createCustomerKey(this.activeCustomer!.id!, key);
            if (createKeyResponse.success) {
                this.keys.push(createKeyResponse.data!);
            }

            this.keysLoading = false;
            return createKeyResponse.data!.id!;
        },

        async customerKeyUpdated(keyId: number, key: CreatingEditingCustomerKey): Promise<void> {
            this.keysLoading = true;

            const updateKeyResponse = await CustomerKeysApiClient.updateCustomerKey(keyId, key);
            if (updateKeyResponse.success) {
                const keyIndex = this.keys.findIndex(k => k.id === keyId);
                this.keys.splice(keyIndex, 1, updateKeyResponse.data!);
            }

            this.keysLoading = false;
        },

        async customerKeyDeleted(key: CustomerKey): Promise<void> {
            this.keysLoading = true;

            const deleteKeyResponse = await CustomerKeysApiClient.deleteCustomerKey(key);
            if (deleteKeyResponse.success) {
                const indexOfKey = this.keys.findIndex(k => k.id === key.id);
                this.keys.splice(indexOfKey, 1);
            }

            this.keysLoading = false;
        },

        async customerKeyImageDeleted(keyId: number): Promise<void> {
            const keyToRemoveImageFrom = this.keys.find(k => k.id === keyId);
            await CustomerKeysApiClient.updateCustomerKey(
                keyId,
                {
                    ...keyToRemoveImageFrom!,
                    held_by_user_id: keyToRemoveImageFrom?.held_by?.id,
                    remove_key_image: true
                }
            );

            keyToRemoveImageFrom!.image_path = undefined;
        },

        async customerKeyImageUpdated(keyId: number, file: File): Promise<void> {
            const updateKeyImageResponse = await CustomerKeysApiClient.updateCustomerKeyImage(keyId, file);
            if (updateKeyImageResponse.success) {
                this.keys.find(k => k.id === keyId)!.image_path = updateKeyImageResponse.data!.image_path;
            }
        },

        async customerDogImageUpdated(dogId: number, file: File): Promise<void> {
            const updateDogImageResponse = await CustomerDogsApiClient.updateDogImage(dogId, file);
            if (updateDogImageResponse.success) {
                this.dogs.find(d => d.id === dogId)!.image_path = updateDogImageResponse.data!.image_path;
            }
        }
    }
});

export default useCustomerStore;
