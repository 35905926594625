<template>
    <Modal
        :title="statusChangeTitle"
        size="xlarge"
        :visible="statusChangeAttempt != null"
    >
        <template #icon>
            <ChartIcon />
        </template>
        <template #buttons>
            <Button v-if="statusChangeAttempt === InvoiceStatus.SENT" variety="confirm" @click="statusSelected(statusChangeAttempt)">Send Invoice</Button>
            <Button v-else-if="statusChangeAttempt === InvoiceStatus.MANUALLY_SENT" variety="confirm" @click="statusSelected(statusChangeAttempt)">Manually Send Invoice</Button>
            <Button variety="warning" @click="onCancel">Cancel</Button>
        </template>
        <div class="pb-2">
            <div v-if="statusChangeAttempt === InvoiceStatus.SENT">
                Are you sure you wish to send invoice to customer <strong> {{customerName}} </strong>? They will receive an invoice to their registered email address <strong v-if="customerEmail">{{ customerEmail }}</strong>.
                <br/>
                <br/>
                <i>If you wish to send this invoice manually, you can <Button class="ml-1" @click="statusSelected(InvoiceStatus.MANUALLY_SENT); statusChangeAttempt = null">Manually Send Invoice</Button></i>
            </div>
            <div v-if="statusChangeAttempt === InvoiceStatus.MANUALLY_SENT">
                Are you sure you wish to manually send this invoice to your customer <strong> {{customerName}} </strong>?
                <br/>
                <br/>
                <i>If you wish for us to email it to them for you, you can <Button class="ml-1" @click="statusSelected(InvoiceStatus.SENT); statusChangeAttempt = null">Send Invoice</Button></i>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue'
import InvoiceStatus from '../types/InvoiceStatus'

export default defineComponent({
    props: {
        customerName: {
            type: String,
            required: true
        },
        customerEmail: {
            type: String,
            required: true
        },
        statusChangeAttempt: {
            type: Object as PropType<InvoiceStatus>,
            required: true
        }
    },
    setup(props, context) {
        const statusChangeTitle = computed(() => {
            if(props.statusChangeAttempt === InvoiceStatus.SENT) {
                return "Send Invoice?"
            } else if(props.statusChangeAttempt === InvoiceStatus.MANUALLY_SENT) {
                return "Manually Send Invoice?"
            }
        });

        function statusSelected(status: InvoiceStatus) {
            context.emit("status-selected", status);
        }

        function onCancel() {
            context.emit("cancel");
        }

        return {
            statusSelected,
            statusChangeTitle,
            InvoiceStatus,
            onCancel
        }
    },
})
</script>
