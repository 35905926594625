<template>
            <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <div v-if="show"
                     @mouseover="stopHide"
                     @mouseout="allowHide"
                     class="max-w-sm w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="p-4">
                        <div class="flex items-start">
                            <div class="flex-shrink-0">
                                <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" v-if="$props.type === NotificationType.SUCCESS"/>
                                <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" v-if="$props.type === NotificationType.ERROR"/>
                                <ShieldExclamationIcon class="h-6 w-6 text-sky-orange-600" aria-hidden="true" v-if="$props.type === NotificationType.WARNING"/>
                                <ExclamationIcon class="h-6 w-6 text-blue-400" aria-hidden="true" v-if="$props.type === NotificationType.INFO"/>
                                <ChipIcon class="h-6 w-6 text-black" aria-hidden="true" v-if="$props.type === NotificationType.DEBUG"/>
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-medium text-gray-900 dark:text-gray-100">
                                    {{ $props.title }}
                                </p>
                                <p class="mt-1 text-sm text-gray-600 dark:text-gray-100">
                                    {{ $props.body }}
                                </p>
                                <p class="mt-1 text-sm text-gray-500 dark:text-gray-100" v-if="$props.debug">
                                    Additional Information:<br />
                                    {{ JSON.stringify($props.debug) }}
                                </p>
                            </div>
                            <div class="ml-4 flex-shrink-0 flex">
                                <button @click="show = false" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    <span class="sr-only">Close</span>
                                    <XIcon class="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
</template>

<script>
import {ref} from 'vue'
import {CheckCircleIcon, ChipIcon, ExclamationIcon, ShieldExclamationIcon, XCircleIcon} from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/solid'
import NotificationType from "../types/NotificationType";

export default {
    components: {
        CheckCircleIcon,
        ChipIcon,
        ExclamationIcon,
        ShieldExclamationIcon,
        XCircleIcon,
        XIcon,
    },
    props:{
        type: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        body: {
            type: String,
            required: true
        },
        debug: {
            type: Object,
            required: false
        },
        autoHide: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    setup(props) {
        const show = ref(true);

        let timer = null;

        const stopHide = () => {
            stopTimer();
        }

        const allowHide = () => {
            startTimer();
        }

        const startTimer = () => {
            timer = setTimeout(x => show.value = false, 5000);
        }

        const stopTimer = () => {
            clearTimeout(timer);
        }

        if(props.autoHide) {
            startTimer();
        }

        return {
            show,
            NotificationType,
            stopHide,
            allowHide
        }
    },
}
</script>
