import axios from "axios";
import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper";
import ScheduleBlock from "../modules/schedule/types/ScheduleBlock";
import DateRange from "../types/DateRange";
import {ScheduleBlockLine} from "../modules/schedule/types/ScheduleBlockLine";
import {stringify} from "query-string";
import _, * as lodash from "lodash";
import {DateTime} from "luxon";
import SlimlineScheduleBlock from "../modules/schedule/types/SlimlineScheduleBlock";

function listSchedulingBlocksForCustomer(
    customerId: number,
    dateFrom: string | undefined = undefined,
    dateTo: string | undefined = undefined,
    includeBlocksAssociatedWithInvoices: boolean = true,
    scheduleLineIds: number[] | undefined = undefined
): Promise<ApiResponseWrapper<ScheduleBlock[]>> {
    const url = `/customers/${customerId}/schedule-blocks?` + stringify({
        dateFrom,
        dateTo,
        includeBlocksAssociatedWithInvoices: includeBlocksAssociatedWithInvoices ? 1 : 0,
        scheduleLineIds
    }, {arrayFormat: "bracket"});

    return ApiAsyncWrapper(axios.get(url));
}

function cleanBlockRequest(block: ScheduleBlock) {
    return _.omit(_.omitBy(block, _.isNil), ["assigned_users"]);
}

function cleanBlockLineRequest(block: ScheduleBlockLine) {
    return _.omit(_.omitBy(block, _.isNil), ["walk_category", "customer", "dog"]);
}

function listSchedulingBlocks(
    dateRange: DateRange,
    includeBlocksAssociatedWithInvoices = true,
    limit: number | undefined = undefined,
    withLines: number | undefined = undefined,
    completed: number = 0,
    customerId: number | undefined = undefined
): Promise<ApiResponseWrapper<SlimlineScheduleBlock[]>> {
    const url = "/schedule-blocks?" + stringify({
        dateFrom: dateRange.date_from,
        dateTo: dateRange.date_to,
        includeBlocksAssociatedWithInvoices: includeBlocksAssociatedWithInvoices ? 1 : 0,
        limit,
        withLines,
        completed,
        customerId
    })
    return ApiAsyncWrapper(axios.get(url));
}

function getSchedulingBlock(id: number): Promise<ApiResponseWrapper<ScheduleBlock>> {
    const url = `/schedule-blocks/${id}`;
    return ApiAsyncWrapper(axios.get(url));
}

function getVirtualSchedulingBlock(id: string): Promise<ApiResponseWrapper<ScheduleBlock>> {
    return ApiAsyncWrapper(axios.get(`/schedule-blocks/virtual/${id}`));
}

async function createSchedulingBlock(scheduleBlock: ScheduleBlock): Promise<ApiResponseWrapper<ScheduleBlock>> {
    return await ApiAsyncWrapper(axios.post(`/schedule-blocks`, cleanBlockRequest(scheduleBlock)));
}

async function copyRangeOfScheduledItems(startDate: string, endDate: string, newStartDate: string) : Promise<ApiResponseWrapper<SlimlineScheduleBlock[]>> {
    return await ApiAsyncWrapper(axios.post(`/schedule-blocks/copy`, {
        "search_date_from" : startDate,
        "search_date_to" : endDate,
        "copy_to_start" : newStartDate,
    }));
}

async function updateSchedulingBlock(scheduleBlock: ScheduleBlock): Promise<ApiResponseWrapper<ScheduleBlock>> {
    let blockResponse: ApiResponseWrapper<ScheduleBlock> = await ApiAsyncWrapper(axios.put(`/schedule-blocks/${scheduleBlock.id}`, cleanBlockRequest(scheduleBlock)));
    if (blockResponse.success && blockResponse.data) {
        blockResponse.data.date_from = DateTime.fromISO(blockResponse.data.date_from as unknown as string);
        blockResponse.data.date_to = DateTime.fromISO(blockResponse.data.date_from as unknown as string);
    }
    return blockResponse;
}

async function updateScheduleBlockStartWalk(scheduleBlock: ScheduleBlock): Promise<ApiResponseWrapper<ScheduleBlock>> {
    let blockResponse: ApiResponseWrapper<ScheduleBlock> = await ApiAsyncWrapper(
        axios.post(`/schedule-blocks/${scheduleBlock.id}/start-walk`)
    );
    if (blockResponse.success && blockResponse.data) {
        blockResponse.data.date_from = DateTime.fromISO(blockResponse.data.date_from as unknown as string);
        blockResponse.data.date_to = DateTime.fromISO(blockResponse.data.date_from as unknown as string);
    }
    return blockResponse;
}

async function deleteSchedulingBlock(scheduleBlockId: number): Promise<ApiResponseWrapper<null>> {
    return await ApiAsyncWrapper(axios.delete(`/schedule-blocks/${scheduleBlockId}`));
}

export default {
    listSchedulingBlocksForCustomer,
    listSchedulingBlocks,
    createSchedulingBlock,
    updateSchedulingBlock,
    updateScheduleBlockStartWalk,
    deleteSchedulingBlock,
    getSchedulingBlock,
    getVirtualSchedulingBlock,
    copyRangeOfScheduledItems
}
