<template>
    <Form @submit.prevent="onSubmit" :divide="false" additional-classes="h-full" class="p-4 sm:p-8 h-full w-full">
        <div class="bg-white h-full flex justify-between">
            <div class="w-full">
                <div class="flex flex-col lg:flex-row justify-between">
                    <div class="flex flex-col">
                        <h2 class="text-2xl font-extrabold text-gray-900 mb-6">
                            Step 3: Invoicing
                        </h2>
                        <div class="flex flex-col gap-2">
                            <p>Our system allows you to turn on auto-invoicing.</p>
                            <p>This will send out invoices to your customers at a specified interval.</p>
                        </div>
                    </div>
                    <div class="mt-8 lg:mt-0">
                        <ExampleBox>
                            <div class="max-w-sm">
                                <div>If you decide not to enable this feature you will need to manually click to create each invoice on the invoice management screen.</div>
                            </div>
                        </ExampleBox>
                    </div>
                </div>
                <div class="pt-8">
                    <Checkbox
                        :id="'enable-auto-invoicing'"
                        :label="'Enable Automatic Invoicing'"
                        :isEditing="true"
                        @update:value="v => (enableAutoInvoicing = v)">
                    </Checkbox>
                </div>
                <div class="flex pt-8" v-if="enableAutoInvoicing">
                    <div class="w-16">Every</div>
                    <div class="w-28">
                        <TextInput
                            id="invoicing-frequency"
                            label=""
                            placeholder=""
                            :isEditing="true"
                            :value="invoicingFrequency"
                            :errors="invoicingFrequencyError"
                            :required="false"
                            :type="'number'"
                            :step="1"
                            @update:value="v => (invoicingFrequency = v)"
                        />
                    </div>
                    <div class="w-28">
                        <DropdownSelector
                            class="ml-4"
                            id="invoicing-frequency-unit"
                            label=""
                            :isEditing="true"
                            :selectors="invoicingFrequencyUnitOptions"
                            :value="invoicingFrequencyUnit"
                            :errors="invoicingFrequencyUnitError"
                            :required="false"
                            @update:value="v => (invoicingFrequencyUnit = v)"
                        />
                    </div>
                </div>
            </div>
            <div class="self-end">
                <div>
                    <Button variety="primary" @click.stop="onSubmit">
                        Next
                    </Button>
                </div>
            </div>
        </div>
    </Form>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import * as yup from "yup";
import {useField, useForm} from "vee-validate";
import ExampleBox from "./ExampleBox.vue";
import Checkbox from "../../../components/forms/Checkbox.vue";
import FormComponent from "../../../components/forms/FormComponent.vue";
import TextInput from "../../../components/forms/TextInput.vue";
import DropdownSelector from "../../../components/forms/DropdownSelector.vue";

export default defineComponent({
    name: "InvoicingForm",
    components: {
        Checkbox,
        ExampleBox,
        FormComponent,
        TextInput,
        DropdownSelector
    },
    emits:[
        'onInvoicingSubmit'
    ],
    setup(props, context){
        const invoicingFrequencyUnitOptions = [{value: 0, name: 'Days'}, {value: 1, name: 'Weeks'}, {value: 2, name: 'Months'}, {value: 3, name: 'Years'}];

        const schema = yup.object({
            enable_auto_invoicing: yup.boolean(),
            invoicing_frequency: yup.number().when("enable_auto_invoicing", {
                is: true,
                then: yup.number().typeError("Invoicing Frequency must be a number.").required("Invoicing Frequency is required.").min(1, "Invoicing Frequency must be at least 1.")
            }),
            invoicing_frequency_unit: yup.number().when("enable_auto_invoicing", {
                is: true,
                then: yup.number().required("Invoicing Frequency Unit is required.")
            })
        });

        const { handleSubmit } = useForm({
            validationSchema: schema,
        });

        const onSubmit = handleSubmit(values => {
            context.emit("onInvoicingSubmit", values);
        });

        const { value: enableAutoInvoicing } = useField("enable_auto_invoicing");
        enableAutoInvoicing.value = false;

        const { value: invoicingFrequency, errorMessage: invoicingFrequencyError } = useField("invoicing_frequency");
        invoicingFrequency.value = 7;

        const { value: invoicingFrequencyUnit, errorMessage: invoicingFrequencyUnitError } = useField("invoicing_frequency_unit");
        invoicingFrequencyUnit.value = 0;

        return {
            onSubmit,
            enableAutoInvoicing,
            invoicingFrequency,
            invoicingFrequencyError,
            invoicingFrequencyUnit,
            invoicingFrequencyUnitError,
            invoicingFrequencyUnitOptions
        }
    }
})
</script>

<style>
#invoicing-frequency {
    max-width: 10ch;
}
</style>
