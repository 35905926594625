import { defineStore } from "pinia";
import BreedsApiClient from "../api/BreedsApiClient";
import CountryApiClient from "../api/CountryApiClient";
import DogBreed from "../types/DogBreed";
import Country from "../types/Country";

const useMiscInformationStore = defineStore({
    id: "countries",
    state: () => ({
        dogStatuses: [{ value: "active", name: "Active" }, { value: "on_break", name: "On Break" }, { value: "disabled", name: "Disabled" }],
        dogGenders: [{ value: "male", name: "Male" }, { value: "female", name: "Female" }, { value: "unknown", name: "Unknown"}],
        dogNeuteredOptions: [{ value: "yes", name: "Yes" }, { value: "no", name: "No" }, { value: "unknown", name: "Unknown"}],
        paymentTypes: [{ value: "direct_debit", name: "Bank Transfer"}, { value: "card_payment", name: "Card" }, { value: "cash", name: "Cash" }],
        countries: [] as Country[],
        breeds: [] as DogBreed[]
    }),
    actions: {
        /**
         * Loads the countries available for selection.
         */
        async loadCountries() {
            const countries = await CountryApiClient.getCountries();
            this.countries = countries.data!.map(apiCountry => ({ name: apiCountry.name, value: apiCountry.code }));
        },

        /**
         * Loads the breeds available for selection.
         */
        async loadBreeds() {
            const breeds = await BreedsApiClient.loadBreeds();
            this.breeds = breeds.data ? breeds.data : [];
        }
    }
});

export default useMiscInformationStore;
