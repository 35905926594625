import { defineStore } from "pinia";
import AuthApiClient from "../../api/AuthApiClient";
import UserApiClient from "../../api/UserApiClient";
import createRealtimeNotifications from "../../echo";
import useBusinessStore from "../business/useBusinessStore";
import useCustomerStore from "../customer/useCustomerStore";
import { PermissionGroup } from "./enums/PermissionGroup";
import UpdatingUser from "./types/UpdatingUser";
import User from "./types/User";
import UserPreference from "./types/UserPreference";
import {ApiResponseWrapper} from "../../api/utils/ApiAsyncWrapper";

const useUserStore = defineStore({
    id: "users",
    state: () => ({
        userIsAuthenticated: false,
        user: undefined as User | undefined,
        userPreferences: [] as Array<UserPreference>
    }),
    actions: {
        async userHasAuthenticated() {
            const userDetailsResponse = await UserApiClient.details();
            if (userDetailsResponse.success) {
                this.userIsAuthenticated = true;
                this.user = userDetailsResponse.data!;
            }

            const userPreferences = await UserApiClient.getPreferences();
            if(userDetailsResponse.success){
                this.userPreferences = userPreferences.data!;
            }

            const businessStore = useBusinessStore();
            const customerStore = useCustomerStore();

            await businessStore.userHasAuthenticated();
            await customerStore.businessChanged();
            createRealtimeNotifications(this.user!);
        },

        async userLoggingOut() {
            await AuthApiClient.logout();
            this.userIsAuthenticated = false;
            this.user = undefined;
        },

        async userUpdated(updatedUser: UpdatingUser): Promise<void> {
            const updateUserDetails = await UserApiClient.updateUser(updatedUser, this.user!.id);
            this.user = { ...this.user, ...updateUserDetails.data as User, id: this.user!.id };
        },

        async userOnboardingUpdated(): Promise<void> {
            let onboardingCompleted = {
                id: undefined,
                email: undefined as unknown as string,
                has_completed_onboarding: true
            };

            let updatedUser = { ...this.user, ...onboardingCompleted } as UpdatingUser;

            this.userUpdated(updatedUser);
        },

        async updatePreference(preference : UserPreference): Promise<ApiResponseWrapper<any>> {
            const foundPreferenceIndex = this.userPreferences.findIndex(element => element.name === preference.name);
            if(foundPreferenceIndex !== -1){
                this.userPreferences[foundPreferenceIndex].value = preference.value;
            } else {
                this.userPreferences.push(preference);
            }
            return await UserApiClient.setPreference(preference);
        }
    },
    getters: {
        /**
         * Identifies and returns whether or not the user has the permission requested.
         *
         * @param state The current state.
         * @returns Whether or not the current user has the permission requested.
         */
        userHasPermission: (state) => (permission: PermissionGroup): boolean => {
            return (state?.user && state?.user?.permissions?.findIndex(p => p === permission) !== -1) ?? false;
        },

        userHasCompletedOnboarding: (state) => (): boolean => {
            return state?.user?.has_completed_onboarding ?? false;
        },

        getUserPreference: (state) => (preferenceKey: string) : any => {
            if (!state?.userPreferences) return null;
            const foundPreference = state.userPreferences.find(element => element.name === preferenceKey);
            return foundPreference?.value ? foundPreference.value : "0";
        }
    }
});

export default useUserStore;
