<template>
    <page-title-container>
        <h1 class="text-2xl font-bold text-black dark:text-gray-100 mr-4">Help</h1>
    </page-title-container>
    <page-content-container v-if="isLoading" class="flex-1 relative overflow-y-auto focus:outline-none pb-4">
        <LoadingSpinner class="w-32" />
    </page-content-container>
    <page-content-container v-else>
        <div class="flex flex-grow flex-col  dark:text-gray-100">
            <Card title="Videos" sub-title="Click on an image to start the video">
                <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                    </svg>
                </template>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" style="width: 100%">
                    <HelpVideo v-for="video in videos" :data="video" :key="video" />
                </div>
            </Card>
            <Card title="Faq" sub-title="Click on a question to expand the answer" class="mt-4">
                <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </template>
                <dl class="space-y-2">
                    <FaqQuestion v-for="question in questions" :data="question" :key="question" />
                </dl>
            </Card>
        </div>
    </page-content-container>
</template>

<script>
import PageTitleContainer from "../../../components/PageTitleContainer.vue";
import PageContentContainer from "../../../components/PageContentContainer.vue";
import useTitleManager from "../../../utils/useTitleManager";
import {computed, onBeforeMount, onMounted, ref} from "vue";
import useHelpStore from "../useHelpStore";
import HelpVideo from "../components/HelpVideo.vue";
import FaqQuestion from "../components/FaqQuestion.vue";

export default {
    name: "HelpPage",
    components: {FaqQuestion, HelpVideo, PageContentContainer, PageTitleContainer},
    setup(){
        const { setTitlePrefix } = useTitleManager();
        const helpStore = useHelpStore();
        const isLoading = ref(false);

        const videos = computed(() => helpStore.getVideos());
        const questions = computed(() => helpStore.getQuestions());

        onMounted(async () => {
            setTitlePrefix("Help");
            isLoading.value = true;
            await helpStore.setup();
            isLoading.value = false;
        });

        return {
            isLoading,
            videos,
            questions
        }
    }
}
</script>
