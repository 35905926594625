<template>
    <Modal :visible="showAvatarUploadScreen" size="small" title="Avatar Upload">
        <template #buttons>
            <Button variety="plain" @click="toggleShowAvatarScreen">Cancel</Button>
            <Button variety="primary" @click="uploadNewAvatar" style="margin-left: 8px" :disabled="!newAvatarFile">Save</Button>
        </template>

        <div>
            <div class="text-center">
                <ImageUpload :initialImageUrlToDisplay="activeBusiness.image_path" @dataChanged="newAvatarFileChange" :is-editing="true" @imageDeleted="deleteBusinessImage" />
            </div>
            <div class="text-center text-sm text-gray-400 mt-8">
                Business images are limited to a size of 1mb (1024kb) and are best uploaded at a size of 250px x 100px.
            </div>
        </div>
    </Modal>

    <PageTitleContainer>
        <div class="flex flex-grow">
            <div class="flex-shrink-0 h-8 w-8 mr-4" v-if="activeBusiness" @click="toggleShowAvatarScreen">
                <img class="h-8 w-8 rounded-full"
                     v-if="activeBusiness.image_path"
                     :src="activeBusiness.image_path"
                     alt="">

                <div v-else class="flex-shrink-0 flex items-center justify-center w-8 h-8 bg-gray-600 text-white font-medium rounded-full cursor-pointer" title="Change Logo" onclick="toggleShowAvatarScreen">
                    {{activeBusiness.name.split(' ')[0]?.charAt(0)}}{{activeBusiness.name.split(' ')[1]?.charAt(0)}}
                </div>
            </div>
            <h1 v-if="activeBusiness" class="text-2xl font-semibold text-gray-900 dark:text-gray-100 flex-1">
                {{ activeBusiness.name }}
            </h1>
            <div class="md:flex items-center">

                <Button variety="primary" @click="toggleShowAvatarScreen">Change Logo</Button>
            </div>
        </div>
    </PageTitleContainer>
    <PageContentContainer>
        <LoadingSpinner v-if="activeBusinessLoading || !activeBusiness" class="w-32" />

        <div v-else class="flex flex-col flex-grow gap-y-4 p-2 md:p-0">
<!--            <SubscriptionsCard-->
<!--                :subscription="activeBusiness.subscription"-->
<!--                @onCancel="cancelSubscription"-->
<!--                @onResume="resumeSubscription"-->
<!--            />-->
            <BusinessInformation v-if="userHasManageBusinessPermission"/>
            <SurchargesCard v-if="userHasManageBusinessPermission"/>
            <WalkCategoriesCard v-if="userHasManageBusinessPermission"/>
            <div v-if="!userHasManageBusinessPermission" class="p-4 grid grid-cols-1 gap-4 xl:grid-cols-6 2xl:grid-cols-4">
                <div class="flex flex-col flex-grow gap-y-4 xl:col-span-4 2xl:col-span-3">
                    <Card title="Invalid Permissions">
                        <template #icon>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                        </template>
                        You do not have permissions to view business details. Please contact your business' administrator.
                    </Card>
                </div>
            </div>
        </div>
    </PageContentContainer>


</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, onMounted, ref} from "vue";
import useBusinessStore from "../useBusinessStore";
import SubscriptionsCard from "../components/SubscriptionsCard.vue";
import BusinessInformation from "../components/BusinessInformation.vue";
import {PermissionGroup} from "../../user/enums/PermissionGroup";
import useUserStore from "../../user/useUserStore";
import Button from "../../../components/forms/Button.vue";
import Modal from "../../../components/Modal.vue";
import ImageUpload from "../../../components/forms/ImageUpload.vue";
import DogCategoriesCard from "../components/DogCategoriesCard.vue";
import WalkCategoriesForm from "../components/WalkCategoriesForm.vue";
import WalkCategoriesCard from "../components/WalkCategoriesCard.vue";
import useTitleManager from "../../../utils/useTitleManager";
import PageTitleContainer from "../../../components/PageTitleContainer.vue";
import PageContentContainer from "../../../components/PageContentContainer.vue";
import SurchargesCard from "../components/SurchargesCard.vue";
import subscriptionApiClient from "../../../api/SubscriptionApiClient";
import BankDetailsForm from "../components/BankDetailsForm.vue";

export default defineComponent({
    name: "BusinessOverview",
    components: {
        SurchargesCard,
        PageContentContainer,
        PageTitleContainer,
        WalkCategoriesCard,
        WalkCategoriesForm,
        DogCategoriesCard,
        ImageUpload,
        Modal,
        Button,
        BusinessInformation,
        SubscriptionsCard
    },
    setup(){
        const businessStore = useBusinessStore();
        const { setTitlePrefix } = useTitleManager();
        const activeBusiness = computed(() => businessStore.activeBusiness);
        const activeBusinessLoading = computed(() => businessStore.activeBusinessLoading);
        const editEnabled = ref(false);
        const showAvatarUploadScreen = ref(false);
        const newAvatarFile = ref(null);

        const userStore = useUserStore();
        const userHasManageBusinessPermission = computed(() => userStore.userHasPermission(PermissionGroup.MANAGE_BUSINESS))

        onMounted(() => setTitlePrefix("Business"));

        const newAvatarFileChange = function(newValue : Blob){
            newAvatarFile.value = newValue;
        }

        const toggleShowAvatarScreen = function(){
            showAvatarUploadScreen.value = !showAvatarUploadScreen.value;
        }

        const uploadNewAvatar = async function(){
            showAvatarUploadScreen.value = false;
            await businessStore.updateBusinessAvatar(newAvatarFile.value);
        }

        const cancelSubscription = async () => {
           await subscriptionApiClient.cancel();
           await businessStore.loadBusiness();
        }

        const resumeSubscription = async () => {
           await subscriptionApiClient.resume();
           await businessStore.loadBusiness();
        }

        const deleteBusinessImage = async () => {
            await businessStore.deleteBusinessAvatar();
            newAvatarFile.value = null;
        }

        return {
            activeBusiness,
            activeBusinessLoading,
            editEnabled,
            userHasManageBusinessPermission,
            showAvatarUploadScreen,
            newAvatarFile,
            toggleShowAvatarScreen,
            uploadNewAvatar,
            deleteBusinessImage,
            newAvatarFileChange,
            cancelSubscription,
            resumeSubscription
        }
    }
})
</script>

<style scoped>

</style>
