import ScheduleBlock from "../types/ScheduleBlock";
import Vector2 from "../types/Vector2";
import {DateTime, Duration, Interval} from "luxon";
import {Ref, ref} from "vue";
import _ from "lodash";
import SlimlineScheduleBlock from "../types/SlimlineScheduleBlock";

let initialDownPosition : Vector2 =  {x:0,y:0};
let offsetPositionFromStart : Vector2 = {x:0, y:0};

let initialMouseDownTime : number;
const clickThreshold = 10; //pixels
const timeThreshold = 600; // milliseconds
let originalDateFrom : DateTime;
let prevTimeOnDrag : number;
let disableDrag = false;

const storeInitialMouseDownPosition = (position: Vector2) => {
    initialDownPosition = position;
}

const storeInitialDateFrom = (dateFrom: DateTime) => {
    originalDateFrom = dateFrom;
}

const storeInitialMouseDownTime = () => {
    initialMouseDownTime = Date.now();
}

const didPassTimeThreshold = () : boolean => {
    return (Date.now() - initialMouseDownTime) > timeThreshold
}

const hapticFeedback = (ms: number) => {
    if(window.navigator && window.navigator.vibrate) {
        window.navigator.vibrate(ms);
    }
}

export const useDragBehavior = (startHours : number,
                                endHours : number,
                                days : number,
                                interval: number,
                                onEditBlock: (block: SlimlineScheduleBlock) => void,
                                onMoveBlock: (block: SlimlineScheduleBlock) => void,
                                onSelectBlock: (block: SlimlineScheduleBlock) => void,
                                onCreateNewBlock: (date: DateTime) => void,
                                enableDrag: boolean = true) => {
    disableDrag = !enableDrag;

    const virtualBlock = ref<(SlimlineScheduleBlock | null)>(null);
    const didPassDragThreshold = ref<boolean>(false);

    let onEmptyBlockClicked = (ev: MouseEvent, date : DateTime) => {
        onCreateNewBlock(date);
    }

    /** Handling of the drag based on inputs **/
    let onScheduleMove = (target: Element, movePosition: Vector2) => {
        if(!virtualBlock.value || disableDrag) return;

        const currMovePosition = {
            x: movePosition.x,
            y: movePosition.y
        }

        const distance =
            Math.sqrt(
                Math.pow(Math.abs(currMovePosition.x - initialDownPosition.x),2) +
                Math.pow(Math.abs(currMovePosition.y - initialDownPosition.y),2)
            );

        if(distance > clickThreshold && !didPassDragThreshold.value) {
            didPassDragThreshold.value = true;
            hapticFeedback(300);
        }

        const rect = (target as HTMLElement).getBoundingClientRect();
        const xLerp = Math.min(Math.max((movePosition.x - rect.x) / rect.width, 0), 1);
        const yLerp = Math.min(Math.max((movePosition.y - rect.y - offsetPositionFromStart.y) / rect.height, 0), 1);

        let totalMins = ((startHours * 60) + (yLerp * ((endHours - startHours) * 60)));
        totalMins = Math.round(totalMins / interval) * interval;

        if((prevTimeOnDrag - totalMins) != 0) {
            hapticFeedback(200);
        }
        prevTimeOnDrag = totalMins;


        const day = Math.floor(xLerp * days);
        const hours = Math.floor((totalMins / 60));
        const mins = Math.ceil((totalMins % 60));

        const getDateFromStartOfWeek = () : DateTime => {
            // week view
            if(days === 7) {
                return virtualBlock.value!.date_from.startOf("week").plus(Duration.fromObject({days: day}));
            }

            // day view
            if(days === 1 ) {
                return virtualBlock.value!.date_from
            }

            console.error(`Scheduler does not support day count: ${days} (yet)`);
            return DateTime.now();
        }

        const dateDiff = virtualBlock.value.date_to.diff(virtualBlock.value.date_from);
        const fromObject = {
            year: getDateFromStartOfWeek().year,
            month: getDateFromStartOfWeek().month,
            day: getDateFromStartOfWeek().day,
            hour: Math.min(Math.max(hours, startHours), endHours),
            minute: Math.min(Math.max(mins, 0), 60)
        };

        virtualBlock.value.date_from = DateTime.fromObject(fromObject);
        if(virtualBlock.value.date_from.invalidExplanation || virtualBlock.value.date_from.invalidReason) {
            console.error(virtualBlock.value.date_from.invalidExplanation)
            console.error(virtualBlock.value.date_from.invalidReason)
        }
        virtualBlock.value.date_to =  virtualBlock.value.date_from.plus(dateDiff);

        const dateHasMovedInterval = virtualBlock.value.date_from.diff(originalDateFrom);
        if(!dateHasMovedInterval.isValid) {
            console.error(dateHasMovedInterval.invalidReason);
            console.error(dateHasMovedInterval.invalidExplanation);
        }
    }

    let onScheduleUp = () => {
        if(virtualBlock.value) {
            const clonedBlock = _.cloneDeep(virtualBlock.value) as SlimlineScheduleBlock;
            //We've released the button on the scheduler
            //Was it a drag(move) or a click(select) ?
            if(didPassDragThreshold.value && didPassTimeThreshold()) {
                onMoveBlock(clonedBlock);
            } else {
                onSelectBlock(clonedBlock);
            }
            virtualBlock.value = null;
        }
    }

    let onBlockDown = (downPosition: Vector2, target: Element, block: SlimlineScheduleBlock) => {
        storeInitialDateFrom(block.date_from);
        if(!virtualBlock.value) {
            // Store a copy of the block to play with
            virtualBlock.value = _.cloneDeep(block);

            didPassDragThreshold.value = false;

            storeInitialMouseDownTime();
            storeInitialMouseDownPosition({
                x: downPosition.x,
                y: downPosition.y
            });

            const targetElement = (target as HTMLElement).getBoundingClientRect();
            offsetPositionFromStart = {
                x: downPosition.x - targetElement.x,
                y: downPosition.y - targetElement.y,
            }
        }
    }

    let onBlockUp = (block: SlimlineScheduleBlock) => {
        const clonedBlock = _.cloneDeep(virtualBlock.value) as SlimlineScheduleBlock;
        virtualBlock.value = null;
        if(didPassDragThreshold.value) {
            // Give a copy of the updated block back
            hapticFeedback(300);
            onMoveBlock(clonedBlock);
            return;
        }

        //If we have a long click it wasn't probably going to be a select
        if(didPassTimeThreshold()) {
            return;
        }
        onSelectBlock(clonedBlock);
    }

    /** Mouse Handling */
    let onScheduleMouseMove = (ev: MouseEvent) => {
       onScheduleMove(ev.currentTarget as Element, {
           x: ev.x,
           y: ev.y
       } as Vector2);
    }

    let onScheduleMouseUp = () => {
        onScheduleUp();
    }

    let onBlockMouseDown = (ev: MouseEvent, block: SlimlineScheduleBlock) => {
       onBlockDown({
           x: ev.x,
           y: ev.y
       } as Vector2,
       ev.currentTarget as Element,
       block);
    }

    let onBlockMouseUp = (block: SlimlineScheduleBlock) => {
        onBlockUp(block);
    }

    /** Mobile Touch Handling */
    let onScheduleTouchEnd = () => {
        onScheduleUp();
    }

    let onScheduleTouchMove = (ev: TouchEvent) => {
        if(virtualBlock.value) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        onScheduleMove(ev.currentTarget as Element, {
            x: ev.touches[0].pageX,
            y: ev.touches[0].pageY
        } as Vector2);
    }

    let onBlockTouchStart = (ev: TouchEvent, block: SlimlineScheduleBlock) => {
        onBlockDown({
                x: ev.touches[0].pageX,
                y: ev.touches[0].pageY
            } as Vector2,
        ev.target as Element,
        block);
    }

    let onBlockTouchEnd = (block: SlimlineScheduleBlock) => {
        onBlockUp(block);
    }

    return {
        /* mouse */
        onScheduleMouseUp,
        onScheduleMouseMove,
        onBlockMouseDown,
        onBlockMouseUp,

        /* touch */
        onScheduleTouchEnd,
        onScheduleTouchMove,
        onBlockTouchStart,
        onBlockTouchEnd,

        /* universal */
        onEmptyBlockClicked,
        virtualBlock,
        didPassDragThreshold
    }
}
