<template>
    <div class="min-h-full bg-sky-blue-400 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md p-4 pb-0">
            <img class="w-60" style="margin-left: auto; margin-right: auto;" alt="loading Image of a dog" :src="logo">
        </div>
        <div v-if="!loading" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

                    <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
                        <div v-if="!resetSuccessfully" class="m-2 md:m-0 bg-white py-8 px-4 shadow sm:px-10">

                            <h2 class="text-center text-3xl font-extrabold text-gray-900 mb-6 mt-4">
                                <span>Reset Your Password</span>
                            </h2>
                            <form @submit="handleResetPassword">
                                <div class="mb-4">
                                    Please enter your new password in the fields below to continue.
                                </div>
                                <div>
                                    <div class="mt-1">

                                        <TextInput
                                            id="password"
                                            label="Password"
                                            type="password"
                                            placeholder=""
                                            :isEditing="true"
                                            :value="password"
                                            :errors="passwordError"
                                            :required="false"
                                            @update:value="v => (password = v)"
                                        />

                                    </div>
                                    <div class="mt-2">
                                        <TextInput
                                            id="password-confirmation"
                                            label="Confirm Password"
                                            type="password"
                                            placeholder=""
                                            :isEditing="true"
                                            :value="passwordConfirmation"
                                            :errors="passwordConfirmationError"
                                            :required="false"
                                            @update:value="v => (passwordConfirmation = v)"
                                        />
                                    </div>

                                    <div class="flex mt-4">
                                        <Button type="submit"
                                                variety="primary"
                                                :disabled="loading"
                                                @click="handleResetPassword"
                                                class="w-full flex justify-center py-2 px-4">
                                            Change Password
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div v-else class="m-2 md:m-0 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 flex inline">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="green">
                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                            <span class="ml-2">
                                Password reset successfully. <br /> Please continue to <router-link to="/" class="underline hover:text-sky-blue-900">login</router-link> .</span>
                        </div>
                    </div>
            </div>


        <div v-else class="m-2 md:m-0 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <LoadingSpinner class="m-auto"/>
        </div>

    </div>
</template>

<script lang="ts">
import logo from "../../../assets/logo-coloured-bg.png";
import {defineComponent, inject, onMounted, ref} from "vue";
import useUserStore from "../useUserStore";
import {useRoute, useRouter} from "vue-router";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import Button from "../../../components/forms/Button.vue";
import UserApiClient from "../../../api/UserApiClient";
import NotificationType from "../../../types/NotificationType";
import failDog from "../../../assets/gone-wrong.png";
import AuthApiClient from "../../../api/AuthApiClient";
import * as yup from "yup";
import {useField, useForm} from "vee-validate";

export default defineComponent({
    name: "ResetPassword",
    components: {
        Button,
        LoadingSpinner
    },
    setup() {
        const emitter = inject('emitter');
        const route = useRoute();
        const router = useRouter();

        const userStore = useUserStore();

        const loading = ref(false);
        const resetSuccessfully = ref(false);

        const schema = yup.object({
            password: yup.string().required("Password is required.").min(12, "Your password needs to be a minimum of 12 characters."),
            password_confirmation: yup.string().when("password", {
                is: (value: string) => value?.length > 0,
                then:  yup.string().required('Your passwords must match.').oneOf([yup.ref('password'), null], 'Your passwords must match.')
            })
        });

        const { handleSubmit } = useForm({
            validationSchema: schema,
        });

        const { value: password, errorMessage: passwordError } = useField("password");
        const { value: passwordConfirmation, errorMessage: passwordConfirmationError } = useField("password_confirmation");

        const handleResetPassword = handleSubmit(async function() {
            if(!password.value){
                return false;
            }

            try{
                loading.value = true;
                const result = await UserApiClient.resetPassword(
                    route.params.token,
                    password.value
                );
                if(result.success){
                    resetSuccessfully.value = true;
                    emitter.emit('show-notification', {
                        type: NotificationType.SUCCESS,
                        title: "Password reset",
                        body: "Your password has been successfully reset.",
                        debug: null
                    });

                    await userStore.userHasAuthenticated();
                    await router.push({path: "/"})
                }
            }
            catch (e){}
            finally {
                loading.value = false;
            }
        });

        return {
            logo,
            loading,
            resetSuccessfully,
            password,
            passwordError,
            passwordConfirmation,
            passwordConfirmationError,
            handleResetPassword
        }
    }
})
</script>

<style scoped>

</style>
