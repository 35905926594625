<template>
    <div class="bg-white dark:bg-gray-800 w-full pb-8 shadow">
        <div class="flex p-4 pb-0">
            <div class="text-2xl font-extrabold text-gray-900 dark:text-gray-100 mb-4">Latest Invoices</div>
        </div>
        <div class="mt-2 lg:ml-4 lg:mr-4">
            <InvoiceList
                :invoice-statuses="invoiceStatuses"
                :enable-pagination = "false"
                :invoice-limit="5"
                :column-limit="[0, 1, 2]"
            />
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, onMounted, ref} from "vue";
import useInvoiceStore from "../../invoices/useInvoiceStore";
import InvoiceList from "../../invoices/components/InvoiceList.vue";
import InvoiceStatus from "../../invoices/types/InvoiceStatus";

export default defineComponent({
    name: "InvoicesWidget",
    components: { InvoiceList },
    setup(){
        const invoiceStore = useInvoiceStore();
        const invoiceStatuses = computed(() => [ { value: "", name: "Any Status" }, ...invoiceStore.statuses ]);

        const sortToUse = ref("createdDesc");
        const paginationLink = ref(undefined as string | undefined);
        const statusToUse = ref(undefined as InvoiceStatus | undefined);
        const customerToUse = ref(0 as number | undefined);

        onMounted(async () => {
            await invoiceStore.loadInvoices(statusToUse.value, customerToUse.value || undefined, paginationLink.value, sortToUse.value);
        });

        return {
            invoiceStatuses
        }
    }
})
</script>

<style scoped>
</style>
