<template>
    <div class="fixed inset-x-0 bottom-0 z-50">
        <div v-if="isVisible" class="bg-sky-orange-800">
            <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div class="flex flex-col md:flex-row">
                    <div class="flex-1 flex items-center">
                      <span class="flex p-2 rounded-lg bg-sky-orange-900">
                        <!-- Heroicon name: outline/speakerphone -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                        <p class="ml-3 font-medium text-white">
                            We use cookies to improve user experience and analyze website traffic. By clicking “Accept“, you agree to our website's cookie use as described in our <a class="hover:text-sky-blue-900" href="https://app.termly.io/document/cookie-policy/638b8306-77c1-40fd-b177-ecfe86a91358" target="_blank">Cookie Policy.</a> You can change your cookie settings at any time by clicking “Manage Cookie Preferences.” in the site footer.
                        </p>
                    </div>
                    <div class="flex mt-2 sm:mt-0 sm:w-auto">
                        <button @click="onHandleAccept" class="mr-4 flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-sky-blue-600 bg-white hover:bg-sky-blue-600 hover:text-white">
                            Accept
                        </button>
                        <button @click="onHandleDecline" class="flex items-center justify-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white border border-white hover:bg-sky-blue-600 hover:text-white">
                            Decline
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ConsentUtils from "./ConsentUtils";
import {defineComponent, onMounted, ref} from "vue";
import {useRouter} from "vue-router";

export default defineComponent({
    name: "ConsentModal",
    setup(){

        const isVisible = ref(null);
        const router = useRouter();

        onMounted(() => {
            const consentModalFlag = ConsentUtils.getShowConsentModal();
            isVisible.value = consentModalFlag === "true" || consentModalFlag === null;
        })

        const onHandleAccept = async function(){
            ConsentUtils.setConsentPermission(true);
            ConsentUtils.setShowConsentModal(false);
            isVisible.value = false;
        }

        const onHandleDecline = async function(){
            ConsentUtils.setConsentPermission(false);
            ConsentUtils.setShowConsentModal(false);
            isVisible.value = false;
        }

        return {
            onHandleAccept,
            onHandleDecline,
            isVisible
        }
    }
})
</script>


<style scoped></style>
