const potentialColours = [
    "#12CDD4",
    "#8FD14F",
    "#DA0063",
    "#CDD412",
    "#126CD4",
    "#12D47A",
    "#D412CD",
    "#D47A12"
]

function getRandomColor(): string {
    return potentialColours[Math.floor(Math.random() * potentialColours.length)];
}

function getColorForIndex(index: number): string {
    return potentialColours[index % 7];
}

export default {
    getRandomColor,
    getColorForIndex
};
