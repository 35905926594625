<template>

    <!-- Title -->
    <PageTitleContainer v-if="user">
        <h1 class="text-2xl font-bold text-black mr-4 dark:text-gray-100">{{ user.name }}</h1>
    </PageTitleContainer>

    <!-- Content -->
    <PageContentContainer>
        <div class="flex flex-col flex-grow gap-4 xl:col-span-3 2xl:col-span-4 p-2 md:p-0">
            <Card title="Details">
                <template #icon>
                    <PersonIcon />
                </template>

                <template #buttons>
                    <Button
                        v-if="!isEditingDetails"
                        variety="secondary"
                        :disabled="isEditingSecurity"
                        @click="isEditingDetails = true"
                    >
                        Edit
                    </Button>
                    <Button v-if="isEditingDetails" variety="secondary" @click="isEditingDetails = false">
                        Cancel
                    </Button>
                    <Button v-if="isEditingDetails" variety="primary" @click="$refs.userForm.onSubmit()">
                        Save
                    </Button>
                </template>

                <UserDetailsForm
                    ref="userForm"
                    :user="user"
                    :isEditing="isEditingDetails"
                    @onSubmit="onSubmitUserDetails"
                />
            </Card>

            <Card title="Security Details">
                <template #icon>
                    <ShieldIcon />
                </template>

                <template #buttons>
                    <Button
                        v-if="!isEditingSecurity"
                        variety="secondary"
                        :disabled="isEditingDetails"
                        @click="isEditingSecurity = true"
                    >
                        Edit
                    </Button>
                    <Button v-if="isEditingSecurity" variety="secondary" @click="isEditingSecurity = false">
                        Cancel
                    </Button>
                    <Button v-if="isEditingSecurity" variety="primary" @click="$refs.securityDetailsForm.onSubmit()">
                        Save
                    </Button>
                </template>

                <UserSecurityDetailsForm
                    ref="securityDetailsForm"
                    :user="user"
                    :isEditing="isEditingSecurity"
                    @onSubmit="onSubmitSecurityDetails"
                />
            </Card>
        </div>
    </PageContentContainer>

</template>

<script lang="ts">
import {defineComponent, computed, onMounted, ref, onErrorCaptured, inject} from 'vue'
import UserDetailsForm from "../components/UserDetailsForm.vue";
import UserSecurityDetailsForm from "../components/UserSecurityDetailsForm.vue";
import useTitleManager from '../../../utils/useTitleManager';
import useUserStore from '../useUserStore'
import PersonIcon from '../../../components/icons/PersonIcon.vue';
import ShieldIcon from '../../../components/icons/ShieldIcon.vue';
import User from '../types/User';
import UpdatingUser from '../types/UpdatingUser';
import PageTitleContainer from "../../../components/PageTitleContainer.vue";
import PageContentContainer from "../../../components/PageContentContainer.vue";
import NotificationType from "../../../types/NotificationType";

export default defineComponent({
    components: {
        PageContentContainer,
        PageTitleContainer,
        UserDetailsForm,
        UserSecurityDetailsForm,
        PersonIcon,
        ShieldIcon
    },
    setup() {
        const emitter = inject("emitter");
        const userStore = useUserStore();
        const titleManager = useTitleManager();

        const isEditingDetails = ref(false);
        const isEditingSecurity = ref(false);
        const user = computed(() => userStore.user!);

        onMounted(() => titleManager.setTitlePrefix("User Settings"));

        async function onSubmitUserDetails(updatedUser: User) {
            try{
                await userStore.userUpdated(updatedUser);
                isEditingDetails.value = false;
            }
            catch (error){}
        }

        async function onSubmitSecurityDetails(
            securityDetails: { new_password?: string, current_password: string, email: string }
        ) {
            try{
                await userStore.userUpdated({ ...user.value, ...securityDetails, id: undefined } as UpdatingUser);
                isEditingSecurity.value = false;
            }
            catch (error){
                emitter.emit('show-notification', {
                    type: NotificationType.WARNING,
                    title: "Unable to update security details",
                    body: "Please double check your details and try again."
                });
            }
        }

        return {
            isEditingDetails,
            isEditingSecurity,
            user,
            onSubmitUserDetails,
            onSubmitSecurityDetails
        };
    },
})
</script>
