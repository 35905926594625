import axios from "axios";
import CustomerNote from "../modules/customer/types/CustomerNote";
import {ApiResponseWrapper, ApiAsyncWrapper} from "./utils/ApiAsyncWrapper";

async function addCustomerNote(
    customerId: number,
    customerNote: Omit<CustomerNote, "id">
): Promise<ApiResponseWrapper<CustomerNote>> {
    return await ApiAsyncWrapper(axios.post(`customers/${customerId}/notes`, customerNote));
}

async function getCustomerNotes(customerId: number): Promise<ApiResponseWrapper<CustomerNote[]>> {
    return ApiAsyncWrapper(axios.get(`customers/${customerId}/notes`));
}

async function updateCustomerNote(
    customerId: number,
    noteId: number,
    note: Omit<CustomerNote, "id">
): Promise<ApiResponseWrapper<CustomerNote>> {
    return await ApiAsyncWrapper(axios.put(`/customers/${customerId}/notes/${noteId}`, note));
}

async function deleteCustomerNote(customerId: number, noteId: number): Promise<ApiResponseWrapper<{}>> {
    return await ApiAsyncWrapper(axios.delete(`/customers/${customerId}/notes/${noteId}`));
}

export default {
    addCustomerNote,
    getCustomerNotes,
    updateCustomerNote,
    deleteCustomerNote
}
