<template>
    <Form @submit.prevent="onSubmit" :divide="false" additional-classes="" class="p-4 sm:p-8 h-full w-full h-full flex flex-col lg:flex-row justify-between bg-white ">
        <div class="w-full">
            <div class="flex justify-between flex-col lg:flex-row">
                <div class="flex flex-col">
                    <h2 class="text-2xl font-extrabold text-gray-900 mb-6">
                        Step 2: Walk Prices
                    </h2>
                    <div class="flex flex-col gap-2">
                        <p>Pricing on WalkBuddy is built in two parts made up of a <b>Walk Type</b> (base cost) and one or more <b>Surcharges</b>.</p>

                        <p>While a <b>Walk Type</b> cost must always be a positive value, <b>Surcharges</b> can be positive or negative, allowing you to add additional costs or discounts per dog.</p>

                        <p>You can set up some default values below <i>(This can be edited at anytime in your app settings)</i></p>
                    </div>
                </div>
                <div class="mt-8 lg:mt-0 lg:ml-6">
                    <ExampleBox>
                        <div>
                            <div><b>Walk Type:</b> Solo Walk (£12.00)</div>
                            <div><b>1st Surcharge:</b> Lead Walk Only (£3.00)</div>
                            <div><b>2nd Surcharge:</b> Loyalty Discount (-£1.00)</div>
                            <div><b>TOTAL Walk Cost:</b> £14.00</div>
                        </div>
                    </ExampleBox>
                </div>
            </div>
            <div class="flex flex-col lg:flex-row gap-0 lg:gap-16">

                <div>
                    <h2 class="text-2xl font-extrabold text-gray-900 mb-6 mt-6">
                        Walk Types
                    </h2>
                    <div>
                        <PricesTable :idPrefix="'walk_types'" :name-prefix="'walk'" :items="walkTypes" :errors="errors"></PricesTable>
                    </div>
                </div>
                <div>
                <h2 class="text-2xl font-extrabold text-gray-900 mb-6 mt-6">
                    Surcharges
                </h2>
                <div>
                    <PricesTable :idPrefix="'surcharges'" :name-prefix="'surcharge'" :items="surcharges" :errors="errors"></PricesTable>
                </div>
                </div>
            </div>
            <div class="pt-8">
                <TextInput
                    id="appointment-length"
                    label="Default Appointment Length (minutes)"
                    placeholder=""
                    :isEditing="true"
                    :value="appointmentLength"
                    :errors="appointmentLengthError"
                    :required="false"
                    :type="'number'"
                    :step="1"
                    :max-number="240"
                    @update:value="v => (appointmentLength = v)"
                />
                <div class="text-xs mt-5 md:mt-2">This is the default duration of a time slot used on the Scheduler (this can be changed when entering the schedule details)</div>
            </div>
        </div>
        <div class="self-end">
            <div class="pt-2 pb-4 text-right">
                <Button variety="primary" @click.stop="onSubmit">
                    Next
                </Button>
            </div>
        </div>
    </Form>
</template>

<script lang="ts">
import {defineComponent, Ref} from "vue";
import * as yup from "yup";
import {useField, useForm} from "vee-validate";
import TextInput from "../../../components/forms/TextInput.vue";
import PricesTable from "./PricesTable.vue";
import PricingItem from "../types/PricingItem";
import ExampleBox from "./ExampleBox.vue";

export default defineComponent({
    name: "WalkPricesForm",
    components: {
        ExampleBox,
        TextInput,
        PricesTable
    },
    emits:[
        'onWalkPricesSubmit'
    ],
    setup(props, context){
        const schema = yup.object({
            surcharges: yup.array().of(
                yup.object({
                    name: yup.string().required("Surcharge Type is required."),
                    price_per_minute: yup.number().typeError("Price per Surcharge Type must be a number.")
                        .required("Price per Surcharge Type is required.")
                        .min(-10000, "Price must be greater than -10,000")
                        .max(10000, "Price must be less than 10,000")
                })),
            walk_types: yup.array().of(
                yup.object({
                    name: yup.string().required("Walk Type is required."),
                    price_per_minute: yup.number().typeError("Price per Walk Type must be a number.")
                        .required("Price per Walk Type is required.")
                        .min(0, "Price must be a positive value.")
                        .max(10000, "Price must be less than 10,000")
                })),
            appointment_length: yup.number().typeError("Appointment Length must be a number.")
                .required("Appointment Length is required.")
                .min(5, "Appointment Length must be greater than 5 minutes.")
                .max(240, "Appointment Length must be less than 240 minutes."),
        });

        const { handleSubmit, errors } = useForm({
            validationSchema: schema,
        });

        const { value: surcharges } = useField("surcharges") as { value: Ref<PricingItem[]> };

        surcharges.value = [
            { name: 'Lead Walk Only', price_per_minute: 3.0}
        ]

        const { value: walkTypes } = useField("walk_types") as { value: Ref<PricingItem[]> };

        walkTypes.value = [
            { name: 'Solo Walk', price_per_minute: 12.0},
            { name: 'Pack Walk', price_per_minute: 10.0}
        ];

        const { value: appointmentLength, errorMessage: appointmentLengthError } = useField("appointment_length");

        appointmentLength.value = 60;

        const onSubmit = handleSubmit(values => {
            context.emit("onWalkPricesSubmit", values);
        });

        return {
            onSubmit,
            appointmentLength,
            appointmentLengthError,
            surcharges,
            walkTypes,
            errors
        }
    }
})
</script>

<style>
#appointment-length {
    max-width: 10ch;
}
</style>
