function getConsentPermission() {
    return window.localStorage.getItem('_wb-consent');
}

function setConsentPermission(newValue : string){
    window.localStorage.setItem('_wb-consent', newValue);
}

function getShowConsentModal() {
    return window.localStorage.getItem('_wb-show-consent-modal');
}

function setShowConsentModal(newValue : string){
    window.localStorage.setItem('_wb-show-consent-modal', newValue);
}

export default {
    getConsentPermission,
    setConsentPermission,
    getShowConsentModal,
    setShowConsentModal
}
