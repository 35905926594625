import axios from "axios";
import CreatingEditingCustomerKey from "../modules/customer/types/CreatingEditingCustomerKey";
import CustomerKey from "../modules/customer/types/CustomerKey";
import CustomerKeyAuditLog from "../modules/customer/types/CustomerKeyAuditLog";
import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper";

function getCustomerKeys(customerId: number): Promise<ApiResponseWrapper<CustomerKey[]>> {
    return ApiAsyncWrapper(axios.get(`/customers/${customerId}/keys`));
}

async function createCustomerKey(
    customerId: number,
    key: Omit<CreatingEditingCustomerKey, "id">
): Promise<ApiResponseWrapper<CustomerKey>> {
    return await ApiAsyncWrapper(axios.post(`/customers/${customerId}/keys`, key));
}

async function updateCustomerKey(
    keyId: number,
    key: Omit<CreatingEditingCustomerKey, "id">
): Promise<ApiResponseWrapper<CustomerKey>> {
    return await ApiAsyncWrapper(axios.put(`/keys/${keyId}`, key));
}

async function deleteCustomerKey(key: CustomerKey): Promise<ApiResponseWrapper<{}>> {
    return await ApiAsyncWrapper(axios.delete(`/keys/${key.id}`));
}

async function updateCustomerKeyImage(keyId: number, file: File): Promise<ApiResponseWrapper<CustomerKey>> {
    const data = new FormData();
    data.append('image', file);
    return await ApiAsyncWrapper(axios.post(`/keys/${keyId}/image`, data));
}

function getCustomerKeyAuditLogs(keyId: number): Promise<ApiResponseWrapper<CustomerKeyAuditLog[]>> {
    return ApiAsyncWrapper(axios.get(`/keys/${keyId}/logs`));
}

export default {
    getCustomerKeys,
    createCustomerKey,
    updateCustomerKey,
    deleteCustomerKey,
    updateCustomerKeyImage,
    getCustomerKeyAuditLogs
}
