import ScheduleBlock from "../../schedule/types/ScheduleBlock";
import InvoiceLine from "../types/InvoiceLine";
import InvoiceUtils from "./InvoiceUtils";

function convertScheduleBlockToInvoiceLines(scheduleBlock: ScheduleBlock): InvoiceLine[] {
    return scheduleBlock.lines!.map(line => ({
        schedule_line_id: line.id,
        line_item: `Walk for ${line.dog!.name} on ${InvoiceUtils.prettyPrintInvoiceDate(scheduleBlock.date_from)}`,
        line_cost: line.cost > 0 ? line.cost / 100 : line.cost,
        line_vat: line.vat > 0 ? line.vat / 100 : line.vat,
        line_quantity: 1,
        line_price: line.price > 0 ? line.price / 100 : line.price
    } as InvoiceLine));
}

export default {
    convertScheduleLinesToInvoiceLines: convertScheduleBlockToInvoiceLines
}
