<template>
    <Form @submit.prevent="onSubmit" class="h-full lg:h-auto w-full lg:w-auto" additional-classes="h-full lg:h-auto">
        <div class="bg-white py-8 px-4 shadow sm:px-10 h-full lg:h-auto w-full lg:w-96">
            <h2 class="text-center text-3xl font-extrabold text-gray-900 mb-6">
                Register
            </h2>
            <div class="flex flex-row gap-8">
                <div class="w-full flex flex-col gap-y-2">
                    <TextInput
                        id="name"
                        label="Name"
                        placeholder=""
                        :isEditing="true"
                        :value="name"
                        :errors="nameError"
                        :required="false"
                        @update:value="v => (name = v)"
                    />

                    <TextInput
                        id="email"
                        label="Login Email"
                        placeholder=""
                        :isEditing="true"
                        :value="email"
                        :errors="emailError"
                        :required="false"
                        @update:value="v => (email = v)"
                    />

                    <TextInput
                        id="password"
                        label="Password"
                        type="password"
                        placeholder=""
                        :isEditing="true"
                        :value="password"
                        :errors="passwordError"
                        :required="false"
                        @update:value="v => (password = v)"
                    />

                    <TextInput
                        id="password-confirmation"
                        label="Confirm Password"
                        type="password"
                        placeholder=""
                        :isEditing="true"
                        :value="passwordConfirmation"
                        :errors="passwordConfirmationError"
                        :required="false"
                        @update:value="v => (passwordConfirmation = v)"
                    />
                </div>
            </div>

            <div>
                By creating an account, you agree to our <a href="https://app.termly.io/document/terms-of-use-for-saas/d40892c0-73b3-40cc-8bad-ce7505e1d199" target="_blank" class="underline hover:text-sky-blue-900">Terms Of Service</a> and have read and acknowledge our <a href="https://app.termly.io/document/privacy-policy/0c7872ac-09a2-4365-bd67-aae14a009b43" target="_blank" class="underline hover:text-sky-blue-900">Privacy Statement.</a>
            </div>

            <div class="flex items-baseline mt-4">
                <Button class="flex-1" variety="primary" @click.stop="onSubmit">
                    Register
                </Button>
            </div>
            <div class="flex">
                <div class="text-center mt-8 flex-grow">
                    <a href="#" @click="returnToLogin" class="italic" style="border: 0; border-bottom: 1px solid #efefef">
                        Did you mean to <span class="font-bold">login</span>?
                    </a>
                </div>
            </div>
        </div>
    </Form>
</template>

<script lang="ts">
import {defineComponent, inject, onBeforeMount, onErrorCaptured} from "vue";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import router from "../../../router";

export default defineComponent({
    name: "RegistrationForm",
    emits:[
        'onSubmit'
    ],
    setup(props, context){

        onBeforeMount(() => {
            // Remove dark mode if the user has it set from a previous session
            let html = document.querySelector("html");
            if (html) {
                html.classList.remove('light', 'dark');
            }
        });

        const schema = yup.object({
            name: yup.string().required("Name is required."),
            email: yup.string().required("Login Email is required.").email("Your email needs to be a valid email address."),
            password: yup.string().required("Password is required.").min(12, "Your password needs to be a minimum of 12 characters."),
            password_confirmation: yup.string().when("password", {
                is: (value: string) => value?.length > 0,
                then:  yup.string().required('Your passwords must match.').oneOf([yup.ref('password'), null], 'Your passwords must match.')
            })
        });

        const { handleSubmit } = useForm({
            validationSchema: schema,
        });

        const onSubmit = handleSubmit(values => {
            context.emit("onSubmit", values);
        });

        const { value: name, errorMessage: nameError } = useField("name");
        const { value: email, errorMessage: emailError } = useField("email");
        const { value: password, errorMessage: passwordError } = useField("password");
        const { value: passwordConfirmation, errorMessage: passwordConfirmationError } = useField("password_confirmation");

        const returnToLogin = async function(){
            await router.push({path : "/"});
        }

        return {
            onSubmit,
            name,
            nameError,
            email,
            emailError,
            password,
            passwordError,
            passwordConfirmation,
            passwordConfirmationError,
            returnToLogin
        }
    }
})
</script>

<style scoped>

</style>
