<template>
    <span class="flex h-10 w-10 rounded-full overflow-hidden bg-gray-100 items-center justify-center">
        <img v-if="dog.image_path" :src="dog.image_path" />
        <PawIcon v-else :fill="iconFillColor" />
    </span>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import PawIcon from "../../../components/icons/PawIcon.vue";
import ColorUtils from "../../../utils/ColorUtils";
import CustomerDog from "../types/CustomerDog";

export default defineComponent({
    components: {PawIcon},
    props: {
        dog: {
            type: Object as PropType<CustomerDog>,
            required: true,
        },
        index: {
            type: Number,
            required: false,
            default: 0
        }
    },
    setup: function (props, context) {
        const iconFillColor = ColorUtils.getColorForIndex(props.index);

        return {
            iconFillColor
        }
    }
})
</script>
