import { ref } from "vue";

const titlePrefix = ref('');

function setTitlePrefix(prefix: string) {
    titlePrefix.value = prefix;
    document.title = prefix ? `${prefix} 🐾 WalkBuddy` : "🐾 WalkBuddy";
}

export default function() {
    return {
        titlePrefix,
        setTitlePrefix
    };
}
