<template>
    <div class="text-center pt-8 block lg:hidden p-2 bg-gray-100">
        <img class="m-auto" alt="Walk Buddy Logo" :src="logo" />
    </div>
    <div v-if="!hideOnboardingText" class="bg-gray-100 flex flex-row justify-center items-center max-h-80 p-4 lg:pb-0 lg:p-0">
        <img class="h-4/5 self-end hidden lg:block" alt="A happy dog waving" :src="dogWaving" />
        <div class="m-0 lg:ml-10 flex">
            <div class="z-20 arrow-left hidden lg:block"></div>
            <div class="z-10 h-full bg-white rounded-3xl max-w-xl text-center justify-between p-4 sm:p-8 shadow">
                <div class="flex flex-col">
                    <div class="sm:text-xl font-extrabold text-gray-900">Welcome to Walk Buddy! As this is your first time here let me help you get set up with some basic settings for your business</div>
                    <a class="mt-4" href="/#" @click.prevent="onSubmit" @click="skipOnboarding">or click here to skip the onboarding wizard</a>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from "vue";
import router from "../../../router";
import dogWaving from "../../../assets/dog-waving.png";
import logo from "../../../assets/doggo-white-01.png";
import useUserStore from "../../user/useUserStore";
import {FathomEvent, sendFathomEvent} from "../../fathom/fathomUtils";
import {useRoute} from "vue-router";
import {OnboardingLevel} from "../types/OnboardingLevel";

export default defineComponent({
    name: "WizardHeader",
    props:{
        hideOnboardingText : {
            type: Boolean,
            required: true
        }
    },
    setup(){
        const userStore = useUserStore();

        let onboardingLevel = ref(OnboardingLevel.NotRegistered);
        const route = useRoute();

        watch(
            route,
            async () => {
                if (!route.params.onboardingLevel) {
                    return;
                }

                onboardingLevel.value = parseInt(route.params.onboardingLevel as unknown as string);
            },
            { immediate: true }
        );

        const skipOnboarding = async function(){
            switch (onboardingLevel.value) {
                case OnboardingLevel.Account:
                    sendFathomEvent("SKIP_ONBOARDING_STAGE_BUSINESS_INFO")
                    break;
                case OnboardingLevel.BusinessInfo:
                    sendFathomEvent("SKIP_ONBOARDING_STAGE_PRICING")
                    break;
                case OnboardingLevel.Pricing:
                    sendFathomEvent("SKIP_ONBOARDING_STAGE_INVOICING")
                    break;
            }
            await userStore.userOnboardingUpdated();
            await router.push({path : "/dashboard"});
        }

        return {
            skipOnboarding,
            dogWaving,
            logo
        }
    }
})
</script>

<style scoped>
.arrow-left {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid white;
    margin-top: -70px;
    margin-left: 2px;
    align-self: center;
}
</style>
