import axios from "axios";
import CustomerContact from "../modules/customer/types/CustomerContact";
import SlimCustomerContact from "../modules/customer/types/SlimCustomerContact";
import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper";

function getCustomerContact(contactId: number): Promise<ApiResponseWrapper<CustomerContact>> {
    return ApiAsyncWrapper(axios.get(`/contacts/${contactId}`));
}

function listCustomerContacts(customerId: number): Promise<ApiResponseWrapper<SlimCustomerContact[]>> {
    return ApiAsyncWrapper(axios.get(`/customers/${customerId}/contacts`));
}

async function createCustomerContact(
    customerId: number,
    contact: CustomerContact
): Promise<ApiResponseWrapper<CustomerContact>> {
    return await ApiAsyncWrapper(axios.post(`/customers/${customerId}/contacts`, contact));
}

async function updateCustomerContact(
    contactId: number,
    contact: Omit<CustomerContact, "id">
): Promise<ApiResponseWrapper<CustomerContact>> {
    return await ApiAsyncWrapper(axios.put(`/contacts/${contactId}`, contact));
}

async function deleteCustomerContact(contact: CustomerContact): Promise<ApiResponseWrapper<{}>> {
    return await ApiAsyncWrapper(axios.delete(`/contacts/${contact.id}`));
}

export default {
    getCustomerContact,
    listCustomerContacts,
    createCustomerContact,
    updateCustomerContact,
    deleteCustomerContact
};
