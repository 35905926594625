<template>

    <PageTitleContainer>
        <div class="flex items-center flex-grow">
            <button class="hover:text-sky-blue-400 pl-2 pr-2 " @click="goBackToInvoices">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
            </button>
            <h1 class="text-2xl font-semibold text-gray-900 dark:text-gray-100 flex-1">
                Generate Invoices from Schedule
            </h1>
        </div>
    </PageTitleContainer>

    <PageContentContainer>
        <div class="flex flex-col p-2 md:p-0">
            <div class="m-4 mt-2">
                <div class="bg-white dark:bg-gray-800 p-4 w-full shadow overflow-auto flex flex-col md:flex-row justify-between flex-wrap">
                    <InvoiceWizardFilters
                        v-if="!isSaving"
                        :fromDate="fromDate"
                        :toDate="toDate"
                        @fromDateChanged="fromDateChanged"
                        @toDateChanged="toDateChanged"
                    />
                    <div class="p-2 flex flex-col md:flex-row">
                        <span class="text-sm hidden md:block self-center dark:text-gray-100">Quick Filters: </span>
                        <Button v-for="option in ['today', 'week', 'fortnight', 'month']"
                                :key="option"
                                @click.stop="quickFilterSelected(option)"
                                class="m-0 md:ml-2 text-sm"
                                :is-small-button="true"
                        >
                            {{ option.charAt(0).toUpperCase() + option.slice(1) }}
                        </Button>
                    </div>
                </div>
            </div>
            <div class="m-4 mt-2">
                <div class="grid grid-cols-1 md:grid-cols-4 gap-2 w-full overflow-auto">
                    <div class="p-2 md:col-span-3 bg-white dark:bg-gray-800 shadow">
                        <div v-if="!isDateRangeValid" class="text-red-600 text-xs mt-1">
                            {{ dateRangeValidationError }}
                        </div>

                        <div class="generate-invoice-modal-content-wrapper flex flex-col">

                            <div v-if="!isSaving" class="mt-0 flex flex-col gap-2">

                                    <div class="border-2 border-gray-200 dark:border-gray-900 p-1">
                                        <div class="flex bg-sky-blue-300 dark:bg-gray-500 justify-center">
                                            <div class="text-xl font-extrabold text-gray-900">
                                                Available Schedule Blocks
                                            </div>
                                        </div>
                                    </div>
                                <div class="text-sm text-left dark:text-gray-100" v-if="scheduleBlocks.length > 0">
                                    Please select the schedule blocks you would like to create invoices for:
                                </div>

                                <div class="mt-4 text-center border dark:border-gray-900 p-1 bg-red-300" v-if="showNoSchedulesFound">
                                    No Schedule blocks found, please try a different date range.
                                </div>

                                <div class="flex flex-col gap-2" v-for="customerScheduleBlocks in scheduleBlocks" :key="customerScheduleBlocks">
                                    <h1 class="font-bold text-lg text-gray-800 dark:text-gray-400">{{ customerScheduleBlocks.customer.name }}</h1>
                                    <div class="grid grid-cols-2 md:grid-cols-3 gap-2">
                                        <InvoiceWizardScheduleBlock
                                            v-for="scheduleBlock in customerScheduleBlocks.scheduleBlocks"
                                            :key="scheduleBlock"
                                            :scheduleBlock="scheduleBlock"
                                            :isSelected="scheduleBlockIsSelected(scheduleBlock, customerScheduleBlocks.customer)"
                                            @click.stop="scheduleBlockSelected(scheduleBlock, customerScheduleBlocks.customer)"
                                            class="col-span-1"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="md:col-span-1 bg-white dark:bg-gray-800 shadow p-2">
                        <div class="border-2 border-gray-200 dark:border-gray-900 p-1">
                            <div class="flex bg-sky-blue-300 dark:bg-gray-500 justify-center">
                                <div class="text-xl font-extrabold text-gray-900">Selected Blocks</div>
                            </div>
                        </div>
                        <div class="">
                            <InvoiceWizardScheduleBlockGroup
                                v-for="scheduleBlockGroup in selectedScheduleBlocks"
                                :scheduleBlockGroup="scheduleBlockGroup"
                            />
                        </div>
                        <div class="align-bottom text-center">
                            <span class="text-xs align-bottom dark:text-gray-100">Clicking "Create" will create {{ willCreateNInvoicesText }}.</span>


                            <div v-if="!isBlockSelectionValid" class="text-red-600 text-xs text-center">
                                {{ blockSelectionValidationError }}
                            </div>

                            <Button
                                variety="primary"
                                :loading="isSaving"
                                @click.stop="wizardSaved"
                                :disabled="Object.keys(selectedScheduleBlocks).length === 0"
                                class="w-full mt-2 dark:text-gray-100"
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </div>
                <LoadingSpinner v-if="isSaving" />
            </div>
        </div>
    </PageContentContainer>

</template>

<script lang="ts">
import _ from "lodash";
import {DateTime, DurationObject} from "luxon";
import {computed, defineComponent, onMounted, ref} from "vue";
import InvoiceApiClient from "../../../api/InvoiceApiClient";
import SchedulerApiClient from "../../../api/SchedulerApiClient";
import CollectionIcon from "../../../components/icons/CollectionIcon.vue";
import { Customer } from "../../customer/types/Customer";
import ScheduleBlock from "../../schedule/types/ScheduleBlock";
import CreatedInvoice from "../types/CreatedInvoice";
import PaymentType from "../types/PaymentType";
import ScheduleLineToInvoiceLineUtils from "../utils/ScheduleLineToInvoiceLineUtils";
import InvoiceWizardFilters from "../components/InvoiceWizardFilters.vue";
import InvoiceWizardScheduleBlock from "../components/InvoiceWizardScheduleBlock.vue";
import InvoiceWizardScheduleBlockGroup from "../components/InvoiceWizardScheduleBlockGroup.vue";
import PageTitleContainer from "../../../components/PageTitleContainer.vue";
import PageContentContainer from "../../../components/PageContentContainer.vue";
import DateUtils from "../../../utils/DateUtils";
import {useRouter} from "vue-router";
import Button from "../../../components/forms/Button.vue";

interface CustomerScheduleBlocks {
    customer: Customer;
    scheduleBlocks: ScheduleBlock[];
}

interface SelectedCustomerScheduleBlocks {
    [key: number]: {
        customer: Customer
        blocks: ScheduleBlock[]
    }
}

export default defineComponent({
    components: {
        Button,
        CollectionIcon,
        InvoiceWizardFilters,
        InvoiceWizardScheduleBlock,
        InvoiceWizardScheduleBlockGroup,
        PageTitleContainer,
        PageContentContainer
    },
    setup(props, context) {
        const router = useRouter();
        const isSaving = ref(false);
        const fromDate = ref(DateTime.utc().minus({ days: 14 }).set({ hour: 0, minute: 0, second: 0}).toISO() as string | undefined);
        const toDate = ref(DateTime.utc().set({ hour: 23, minute: 59, second: 59}).toISO() as string | undefined);
        const selectedScheduleBlocks = ref({} as SelectedCustomerScheduleBlocks);
        const scheduleBlocks = ref([] as CustomerScheduleBlocks[]);
        const showNoSchedulesFound = ref(false);
        const isDateRangeValid = ref(true);
        const dateRangeValidationError = ref("");
        const isBlockSelectionValid = ref(true);
        const blockSelectionValidationError = ref("");
        const willCreateNInvoicesText = computed( () => Object.keys(selectedScheduleBlocks.value).length !== 1 ?
                `${Object.keys(selectedScheduleBlocks.value).length} invoices`
                : `${Object.keys(selectedScheduleBlocks.value).length} invoice`)

        onMounted(async () => {
            await reloadScheduleBlocks();
        });

        const quickFilterSelected = async (option: string) => {
            const filter = quickFilters(option);
            fromDate.value = filter.from;
            toDate.value = filter.to;
            await reloadScheduleBlocks();
        }

        const quickFilters = (option: string) => {
            switch(option)
            {
                case 'today':
                    return {
                        from: DateTime.utc().startOf('day').toISO(),
                        to: DateTime.utc().endOf('day').toISO()
                    }

                case 'week':
                case 'month':
                    return {
                        from: DateTime.utc().startOf(option).toISO(),
                        to: DateTime.utc().endOf(option).toISO()
                    }

                case 'fortnight':
                    return {
                        from: DateTime.utc().startOf('week').minus({days: 7}).toISO(),
                        to: DateTime.utc().endOf('week').toISO(),
                    }

                default:
                    throw new Error('Available options for quick filters are `today`, `week`, `fortnight`, or `month`');
            }
        }

        async function fromDateChanged(date: string | undefined) {
            fromDate.value = date ? new Date(date).toISOString() : undefined;

            validateDateRange();

            if(!isDateRangeValid.value) {
                return;
            }

            await reloadScheduleBlocks();
        }

        async function toDateChanged(date: string | undefined) {
            toDate.value = date ? new Date(date).toISOString() : undefined;
            validateDateRange();

            if(!isDateRangeValid.value) {
                return;
            }
            await reloadScheduleBlocks();
        }

        function validateDateRange() {
            if(!fromDate.value || !toDate.value) {
                dateRangeValidationError.value = "Both From Date and To Date must be entered";
                isDateRangeValid.value = false;
                return;
            }

            const parsedFromDate = DateTime.fromISO(fromDate.value as unknown as string).toLocal()
            const parsedToDate = DateTime.fromISO(toDate.value as unknown as string).toLocal();

            if(parsedToDate < parsedFromDate) {
                dateRangeValidationError.value = "From Date cannot be before To Date";
                isDateRangeValid.value = false;
                return;
            }

            if(DateUtils.getDiffInMonths(parsedFromDate, parsedToDate) > 1) {
                dateRangeValidationError.value = "To Date cannot be more than a month after From Date";
                isDateRangeValid.value = false;
                return;
            }

            dateRangeValidationError.value = "";
            isDateRangeValid.value = true;
        }

        function scheduleBlockIsSelected(scheduleBlock: ScheduleBlock, customer: Customer): boolean {
            return customer.id! in selectedScheduleBlocks.value && selectedScheduleBlocks.value[customer.id!]['blocks'].some(x => x.id === scheduleBlock.id);
        }

        function scheduleBlockSelected(scheduleBlock: ScheduleBlock, customer: Customer) {
            // If the customer is not selected already, we can just chuck them in as it must be an add!
            if (!selectedScheduleBlocks.value[customer.id!]) {
                    selectedScheduleBlocks.value[customer.id!] = {
                        blocks: [scheduleBlock],
                        customer: customer
                    }
                validateBlockSelection();
                return;
            }

            const indexOfScheduleBlock = selectedScheduleBlocks.value[customer.id!]['blocks'].findIndex(s => s.id === scheduleBlock.id);

            if (indexOfScheduleBlock === -1) {
                selectedScheduleBlocks.value[customer.id!]['blocks'].push(scheduleBlock);
            } else {
                selectedScheduleBlocks.value[customer.id!]['blocks'].splice(indexOfScheduleBlock, 1);

                if(selectedScheduleBlocks.value[customer.id!]['blocks'].length === 0) {
                    delete selectedScheduleBlocks.value[customer.id!];
                }
            }

            validateBlockSelection();
        }

        function validateBlockSelection() {
            let selectedCustomers = Object.entries(selectedScheduleBlocks.value);

            if(selectedCustomers.length < 1) {
                isBlockSelectionValid.value = false;
                blockSelectionValidationError.value = "At least one schedule block must be selected";
                return;
            }

            isBlockSelectionValid.value = true;
            blockSelectionValidationError.value = "";
        }

        async function reloadScheduleBlocks() {
            showNoSchedulesFound.value = false
            scheduleBlocks.value = [];

            const scheduleBlockResponse = await SchedulerApiClient.listSchedulingBlocks(
                { date_from: fromDate.value!, date_to: toDate.value! },
                false,
                Number.MAX_SAFE_INTEGER,
                1,
                1
            );

            if (!scheduleBlockResponse.success || scheduleBlockResponse.data?.length === 0) {
                showNoSchedulesFound.value = true
                return;
            }

            const uniqueCustomers = _.uniqBy(scheduleBlockResponse.data!.flatMap(s => s.lines.map(l => l.customer)).filter(x => x), i => i!.id);

            for (const uniqueCustomer of uniqueCustomers)
            {
                scheduleBlocks.value.push({
                    customer: uniqueCustomer!,
                    scheduleBlocks: scheduleBlockResponse.data!
                        .filter(x => x.lines.some(y => y.customer_id === uniqueCustomer!.id))
                        .map(scheduleBlock => ({
                            ...scheduleBlock,
                            lines: scheduleBlock.lines.filter(
                                line => line.customer_id === uniqueCustomer!.id )
                        }))!
                })
            }
        }

        async function wizardSaved() {
            validateBlockSelection();

            if(!isBlockSelectionValid.value) {
                return;
            }

            isSaving.value = true;

            try {
                for (const [customerId, customerScheduleBlocks] of Object.entries(selectedScheduleBlocks.value)) {
                    const invoice: CreatedInvoice = {
                        customer_id: parseInt(customerId),
                        payment_type: PaymentType.DIRECT_DEBIT,
                        issue_date: new Date(),
                        lines: customerScheduleBlocks.blocks.flatMap(ScheduleLineToInvoiceLineUtils.convertScheduleLinesToInvoiceLines)
                    };
                    await InvoiceApiClient.createInvoice(invoice);
                }

            } finally {
                isSaving.value = false;
                await router.push("/invoices");
            }
        }

        const goBackToInvoices = async function() {
            await router.push('/invoices');
        }

        return {
            isSaving,
            fromDate,
            toDate,
            scheduleBlocks,
            showNoSchedulesFound,

            fromDateChanged,
            toDateChanged,
            scheduleBlockIsSelected,
            scheduleBlockSelected,
            wizardSaved,
            isDateRangeValid,
            dateRangeValidationError,
            isBlockSelectionValid,
            blockSelectionValidationError,
            selectedScheduleBlocks,
            goBackToInvoices,
            willCreateNInvoicesText,
            quickFilters,
            quickFilterSelected
        }
    },
})
</script>

<style scoped>
.generate-invoice-modal-content-wrapper {
    min-height: 18rem;
}
</style>