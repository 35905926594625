<template>
    <div v-if="showInThePastWarning">
        <div>
            The schedule you are making is in the past. Would you like to continue?
        </div>
        <div class="mt-2 text-right">
            <Button class="mr-2" @click="$emit('onCancel')" type="submit" variety="plain">
                Cancel
            </Button>
            <Button @click="showInThePastWarning = false" type="submit" variety="primary">
                Continue
            </Button>
        </div>
    </div>
    <Form v-else>
        <div class="shadow p-2 bg-white dark:bg-gray-700">
            <div class="grid" :class="mediaFlags.sm.lessThanOrEq ? 'grid-cols-6' : 'grid-cols-12'">
                <div class="pw-2 mb-4 col-span-6">
                    <TextInput
                        id="name"
                        label="Reference"
                        :value="editedScheduleBlock.name"
                        @update:value="(value) => (editedScheduleBlock.name = value)"
                        :is-editing="isEditing"
                        :required="false"
                    />
                    <div class="text-red-500" v-if="errors['name']">
                        {{errors['name']}}
                    </div>
                </div>
                <div class="col-span-6">
                    <DateTimeRangePicker
                        label="Day & Time"
                        :required="true"
                        id="date-picker"
                        :time-interval="interval"
                        :to-value="editedScheduleBlock.dateTo"
                        :from-value="editedScheduleBlock.dateFrom"
                        :is-editing="isEditing"
                        @update:value-to="(value) => editedScheduleBlock.dateTo = value"
                        @update:value-from="(value) => editedScheduleBlock.dateFrom = value"
                    />
                </div>
            </div>
        </div>
        <div class="shadow p-2 bg-white dark:bg-gray-700 mt-4">
            <ScheduleBlockLinesEditor
                title="Dogs"
                :lines="editedScheduleBlock.lines"
                :errors="errors"
                :is-editing="isEditing"
                :duration="duration"
                :is-deletable="isDeletable"
            />
        </div>
    </Form>
</template>

<script lang="ts">
import AddIcon from "../../../components/icons/AddIcon.vue";
import BinIcon from "../../../components/icons/BinIcon.vue";
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue'
import FormComponent from "../../../components/forms/FormComponent.vue";
import TextInput from "../../../components/forms/TextInput.vue";
import Button from "../../../components/forms/Button.vue";
import DropdownSelector from "../../../components/forms/DropdownSelector.vue";
import {computed, defineComponent, inject, onErrorCaptured, PropType, ref, toRefs, watch} from "vue";
import {ScheduleBlockLine} from "../types/ScheduleBlockLine";
import {Field} from "vee-validate";
import DateTimePicker from "../../../components/forms/DateTimePicker.vue";
import DateTimeRangePicker from "../../../components/forms/DateTimeRangePicker.vue";
import RadioButtonGroup from "../../../components/forms/RadioButtonGroup.vue";
import ScheduleBlock from "../types/ScheduleBlock";
import {DateTime} from "luxon";
import useUserStore from "../../user/useUserStore";
import User from "../../user/types/User";
import ScheduleBlockEditorState from "../types/ScheduleBlockEditorState";
import SchedulerUtils from "../utils/scheduler-utils"
import DeleteButtonWithConfirmation from "../../../components/forms/DeleteButtonWithConfirmation.vue";
import ScheduleBlockLinesEditor from "./ScheduleBlockLinesEditor.vue";
import MultiSelectDropdown from "../../../components/forms/MultiSelectDropdown.vue";
import useBusinessStore from "../../business/useBusinessStore";
import {useMediaQuery} from "../../core/useMediaQuery";

export default defineComponent({
    components: {
        ScheduleBlockLinesEditor,
        RadioButtonGroup,
        DateTimeRangePicker,
        DateTimePicker,
        DropdownSelector,
        Button,
        FormComponent,
        TextInput,
        Popover,
        PopoverButton,
        PopoverPanel,
        Field,
        AddIcon,
        BinIcon,
        DeleteButtonWithConfirmation,
        MultiSelectDropdown
    },
    props:{
        interval: {
            type: Number,
            required: false,
            default: 5
        },
        scheduleBlockToEdit: {
            type: Object as PropType<ScheduleBlock>,
            required: false,
            default: null
        },
        currentState: {
            type: String as PropType<ScheduleBlockEditorState>,
            required: false,
            default: false
        },
        errors:{
            required: false,
            type: Object,
        },
        isEditable:{
            required: false,
            type: Boolean
        },
        isDeletable:{
            required: false,
            type: Boolean
        },
    },
    emits: [
        'onUpdate'
    ],
    setup: function (props, context) {
        const userStore = useUserStore();
        const businessStore = useBusinessStore();
        const { mediaFlags } = useMediaQuery("tailwind");

        const showInThePastWarning = ref(false);
        const isEditing = computed(() => {
            return props.currentState == ScheduleBlockEditorState.CREATING || props.currentState == ScheduleBlockEditorState.EDITING;
        });

        const getInitialLines = function() {
            let lines: ScheduleBlockLine[];

            if(props.scheduleBlockToEdit.lines) {
                lines = props.scheduleBlockToEdit.lines;
                lines.forEach(f => {
                    if(!f.walk_category_id) f.walk_category_id = f.walk_category?.id;
                    if(!f.customer_id) f.customer_id = f.customer?.id;
                    if(!f.dog_id) f.dog_id = f.dog?.id;
                })
            } else {
                lines = [{} as ScheduleBlockLine]
            }

            if(!lines) {
                lines = [];
            }

            return lines;
        }

        const getDuration = (dateFrom: string, dateTo: string): number => {
            if(!dateFrom || !dateTo) {
                return businessStore.activeBusiness?.default_schedule_block_minutes ?? 30;
            }

            return DateTime.fromISO(dateTo).diff(DateTime.fromISO(dateFrom), 'minutes').minutes;
        }

        const editedScheduleBlock = ref({
            name: props.scheduleBlockToEdit.name,
            assignedUsers: [userStore.user as User],
            dateFrom: props.scheduleBlockToEdit.date_from.toISO() ?? DateTime.now().toISO(),
            dateTo: props.scheduleBlockToEdit.date_to.toISO() ?? DateTime.now().toISO(),
            lines: getInitialLines()
        });

        const duration = computed(() => getDuration(editedScheduleBlock.value.dateFrom, editedScheduleBlock.value.dateTo));

        const { currentState } = toRefs(props);

        watch(currentState,
            (newVal) => {
                if(newVal === ScheduleBlockEditorState.CREATING) {
                    showInThePastWarning.value = SchedulerUtils.getIsInThePast(props.scheduleBlockToEdit.date_from);
                }
            });

        watch(editedScheduleBlock.value,
            () => {
            let updatedScheduleBlock = {
                ...props.scheduleBlockToEdit,
                name: editedScheduleBlock.value.name,
                assigned_users: editedScheduleBlock.value.assignedUsers,
                date_from: editedScheduleBlock.value.dateFrom,
                date_to: editedScheduleBlock.value.dateTo,
                lines: editedScheduleBlock.value.lines,
            }

            context.emit("onUpdate", updatedScheduleBlock);
            });

        return {
            currentState,

            ScheduleBlockState: ScheduleBlockEditorState,
            duration,
            editedScheduleBlock,

            isEditing,
            showInThePastWarning,
            mediaFlags
        }
    },
})
</script>


<style>
.editable-field input{
    border: 0;
}

.editable-field .v-money3{
    border:0;
    box-shadow: none;
    border-radius: 0;
}
</style>
