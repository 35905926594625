<template>
    <div class="flex-grow bg-white dark:bg-gray-800 w-full gpu-render shadow">
        <div class="relative m-auto max-h-full overflow-auto" id="schedule-scroll-container">
            <div class="sticky top-0 flex-col p-4 bg-white dark:bg-gray-800" style="z-index: 600">
                <div class="flex justify-between">
                    <div class="flex-col">
                        <div class="text-2xl font-extrabold text-gray-900 dark:text-gray-100">Today's Schedule</div>
                        <div class="text-xl md:text-2xl font-extrabold mb-4 text-gray-700 dark:text-gray-50">{{ displayDate }}</div>
                    </div>
                    <div>
                        <Clock />
                    </div>
                </div>

                <div class="flex items-center gap-x-2 border-b pb-2">
                    <ToggleComponent id="show-list-toggle" label-text="Show as list" :is-toggled-on="showList" @on-toggle-change="toggleListPreference"/>
                    <ToggleComponent id="show-cancelled-toggle" label-text="Show Cancelled" :is-toggled-on="showCancelled" @on-toggle-change="toggleShowCancelledPreference"/>
                </div>
            </div>

            <div>
                <Scheduler
                    :selected-date="selectedDate"
                    :show-cancelled="showCancelled"
                    :days="days"
                    :disable-background="true"
                    :interval-minutes="intervalMinutes"
                    :show-list="showList"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {DateTime} from "luxon";
import Scheduler from "../../schedule/components/Scheduler.vue";
import useBusinessStore from "../../business/useBusinessStore";
import useUserStore from "../../user/useUserStore";
import ToggleComponent from "../../../components/ToggleComponent.vue";
import { preferenceTypes } from "../../user/utils/preferences.utils";
import {sendFathomEvent} from "../../fathom/fathomUtils";
import Clock from "../../../components/Clock.vue";

export default defineComponent({
    name: "ScheduleWidget",
    components: {Clock, ToggleComponent, Scheduler },
    setup(){
        const businessStore = useBusinessStore();
        const selectedDate = ref(DateTime.now());
        const days = ref(1);
        const intervalMinutes = businessStore.activeBusiness.default_schedule_block_minutes ?? 30;

        const userStore = useUserStore();

        const displayDate = computed(() => selectedDate.value.toLocaleString(DateTime.DATE_FULL));

        const showList = ref(userStore.getUserPreference(preferenceTypes.SHOW_CALENDAR_LIST_VIEW) === '1');
        const showCancelled = ref(userStore.getUserPreference(preferenceTypes.SHOW_CALENDAR_CANCELLED) === "1");

        const toggleListPreference = () => {
            const newValue = !showList.value;
            userStore.updatePreference({name : preferenceTypes.SHOW_CALENDAR_LIST_VIEW, value: newValue ? "1":"0"});
            showList.value = newValue;
            if(showList.value){
                sendFathomEvent("USER_PREF_SHOWASLIST");
            }
        }

        const toggleShowCancelledPreference = () => {
            const newValue = !showCancelled.value
            userStore.updatePreference({name : preferenceTypes.SHOW_CALENDAR_CANCELLED, value: newValue ? "1":"0"});
            showCancelled.value = newValue;
        }

        return {
            intervalMinutes,
            selectedDate,
            days,
            displayDate,
            showList,
            toggleListPreference,
            showCancelled,
            toggleShowCancelledPreference
        }
    }
})
</script>

<style scoped>
</style>
