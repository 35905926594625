import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper";
import axios from "axios";
import User from "../modules/user/types/User";

class AuthApiClient {

    // This is needed before attempting to make a call to login
    async getCSRFCookie(): Promise<any> {
        return ApiAsyncWrapper(axios.get(`/sanctum/csrf-cookie`));
    }

    async login(email: string, password: string): Promise<any> {
        return await ApiAsyncWrapper(axios.post(`/login`, {
            email,
            password
        }));
    }

    async logout(): Promise<ApiResponseWrapper<any>> {
        return await ApiAsyncWrapper(axios.post(`/logout`));
    }

    async oauthLogin(provider: string, data: any): Promise<any> {
        return ApiAsyncWrapper(axios.post(`/login/${provider}/oauth`, data));
    }

    async resetPassword(emailAddress: string): Promise<ApiResponseWrapper<User>> {
        return ApiAsyncWrapper(axios.post("/forgot-password", {
            email: emailAddress
        }));
    }
}

export default new AuthApiClient();
