<template>
    <div class="w-full">
        <div v-if="lines && lines.length">

            <div v-if="lines && lines.length"
                 class="font-bold text-gray-600 mt-2 hidden sm:grid"
                 :class="vatRegistered? 'grid-cols-9' : 'grid-cols-7'"
            >
                <div class="col-span-3 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-900 dark:text-gray-100 border">Item</div>
                <div class="col-span-2 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-900 dark:text-gray-100 border">Cost</div>
                <div class="col-span-1 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-900 dark:text-gray-100 border" v-if="vatRegistered">VAT</div>
                <div class="col-span-1 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-900 dark:text-gray-100 border" v-if="vatRegistered">Total</div>
                <div class="col-span-2 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-900 dark:text-gray-100 border"></div>
            </div>
            <div v-for="(line, i) in lines" :key="line" class="grid rounded md:rounded-none mb-4 sm:mb-0" :class="vatRegistered? 'grid-cols-9' : 'grid-cols-7'">
                <div class="col-span-10 sm:col-span-3 p-1 border-y border-x border-b sm:border-t-0 break-all dark:border-gray-900">
                    <div class="font-bold mb-1 sm:hidden">
                        Item
                    </div>
                    <TextInput
                        label=""
                        :id="`line_item_${String(i)}`"
                        :isEditing="isEditing"
                        :value="lines[i].line_item"
                        :errors="errors[`lines[${String(i)}].line_item`]"
                        :expand="true"
                        @update:value="lines[i].line_item = $event"
                    />
                </div>
                <div class="sm:col-span-2 p-1 border-y border-x border-b sm:border-t-0 dark:border-gray-900"
                     :class="vatRegistered ? 'col-span-3': 'col-span-10'">
                    <div class="font-bold mb-1 sm:hidden">
                        Cost
                    </div>
                    <MoneyInput
                        :isEditing="isEditing"
                        :value="lines[i].line_cost"
                        :errors="errors[`lines[${String(i)}].line_cost`]"
                        @update:value="lines[i].line_cost = $event"
                    />
                </div>
                <div v-if="vatRegistered" class="col-span-3 sm:col-span-1 p-1 border-y border-x border-b sm:border-t-0 dark:border-gray-900">
                    <div class="font-bold mb-1 sm:hidden">
                        VAT
                    </div>
                    <MoneyInput
                        :isEditing="isEditing"
                        :value="lines[i].line_vat"
                        :errors="errors[`lines[${String(i)}].line_vat`]"
                        @update:value="lines[i].line_vat = $event"
                    />
                </div>
                <div v-if="vatRegistered" class="col-span-3 sm:col-span-1 p-1 border-y border-x border-b sm:border-t-0 dark:border-gray-900">
                    <div class="font-bold mb-1 sm:hidden">
                        Total
                    </div>
                    <MoneyInput
                        :isEditing="isEditing"
                        :disabled="true"
                        :value="(lines[i].line_cost + (lines[i].line_vat ?? 0)) * lines[i].line_quantity"
                    />
                </div>
                <div v-if="isEditing" class="col-span-10 sm:col-span-2 p-1 sm:border-y sm:border-x sm:border-b sm:border-t-0 dark:border-gray-900 flex justify-end sm:justify-center">
                    <Button @click.prevent="removeInvoiceLine(i)" variety="warning" class="justify-self-end">
                        Remove
                    </Button>
                </div>
                <div v-else class="col-span-10 sm:col-span-2 p-1 sm:border-y sm:border-x sm:border-b sm:border-t-0 dark:border-gray-900"></div>
            </div>
            <div class="mt-2">
                <Button v-if="isEditing" @click.stop="addInvoiceLine" variety="primary">
                    Add New Line
                </Button>
            </div>
        </div>
        <span v-else class="text-gray-500">No invoice items have been added for this invoice.</span>
    </div>
    <div v-if="isEditing && unInvoicedLines && unInvoicedLines.length" class="w-full mt-6 border-t">
        <div class="flex flex-1 flex-inline gap-2 items-center p-1 mt-2">
            <span class="text-lg dark:text-gray-100">Un-Invoiced Lines for {{invoice.customer.name }}</span>
        </div>
        <div>

            <div v-if="lines && lines.length"
                 class="font-bold text-gray-600 mt-2 hidden sm:grid"
                 :class="vatRegistered? 'grid-cols-9' : 'grid-cols-7'"
            >
                <div class="col-span-2 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-900 dark:text-gray-100 border-2 border-b-0">Date</div>
                <div class="col-span-2 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-900 dark:text-gray-100 border-2 border-b-0 border-l-0">Dog</div>
                <div class="col-span-1 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-900 dark:text-gray-100 border-2 border-b-0 border-l-0">Cost</div>
                <div class="col-span-1 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-900 dark:text-gray-100 border-2 border-b-0 border-l-0" v-if="vatRegistered">VAT</div>
                <div class="col-span-1 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-900 dark:text-gray-100 border-2 border-b-0 border-l-0" v-if="vatRegistered">Total</div>
                <div class="col-span-2 p-1 bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-900 dark:text-gray-100 border-2 border-b-0 border-l-0" v-if="isEditing"></div>
            </div>
            <div v-for="(line, i) in unInvoicedLines" :key="line" class="grid border-2 rounded md:rounded-none sm:border-0 mb-4 sm:mb-0 " :class="vatRegistered? 'grid-cols-9' : 'grid-cols-7'">
                <div class="col-span-10 sm:col-span-2 p-1 border-0 sm:border-2 dark:border-gray-900 dark:text-gray-100">
                    <div class="font-bold mb-1 sm:hidden">
                        Date
                    </div>
                    {{ line.date_from.toLocaleString({ ...DateTime.DATE_SHORT, weekday: 'short' }) }}
                </div>
                <div class="col-span-10 sm:col-span-2 p-1 border-0 sm:border-2 dark:border-gray-900 break-all dark:text-gray-100">
                    <div class="font-bold mb-1 sm:hidden">
                        Dog
                    </div>
                    {{ line.dog.name }}
                </div>
                <div class="p-1 border-0 sm:border-2 dark:border-gray-900 dark:text-gray-100">
                    <div class="font-bold mb-1 sm:hidden">
                        Cost
                    </div>
                    {{ MoneyUtils.getDisplayValueFromPence(line.cost) }}
                </div>
                <div v-if="vatRegistered" class="col-span-2 sm:col-span-1 p-1 border-0 dark:border-gray-900 sm:border-2 dark:text-gray-100">
                    <div class="font-bold mb-1 sm:hidden">
                        VAT
                    </div>
                    {{ MoneyUtils.getDisplayValueFromPence(line.vat) }}
                </div>
                <div v-if="vatRegistered" class="col-span-2 sm:col-span-1 p-1 border-0 dark:border-gray-900 sm:border-2 dark:text-gray-100">
                    <div class="font-bold mb-1 sm:hidden">
                        Total
                    </div>
                    {{ MoneyUtils.getDisplayValueFromPence(line.cost + (line.vat ?? 0)) }}
                </div>
                <div class="col-span-10 sm:col-span-2 p-1 border-0 sm:border-2 dark:border-gray-900 flex justify-end sm:justify-center ">
                    <Button v-if="isEditing" variety="primary" @click.stop="addUnInvoicedLine(line)" title="Add this line to the invoice" class="justify-self-end text-xs h-fit">
                        Add To Invoice
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onBeforeMount, onErrorCaptured, PropType, ref, Ref, watch} from 'vue'
import * as yup from "yup";
import { Form, Field, useForm, useField } from "vee-validate";
import Invoice from '../types/Invoice'
import AddIcon from "../../../components/icons/AddIcon.vue";
import BinIcon from "../../../components/icons/BinIcon.vue";
import InvoiceLine from '../types/InvoiceLine';
import SchedulerApiClient from "../../../api/SchedulerApiClient";
import {DateTime} from "luxon";
import MoneyUtils from "../../../utils/MoneyUtils";
import CustomerDog from "../../customer/types/CustomerDog";
import ScheduleBlock from "../../schedule/types/ScheduleBlock";

interface UnInvoicedLine {
    id: number,
    date_from: DateTime,
    cost: number,
    vat: number,
    price: number,
    dog: CustomerDog
}

export default defineComponent({
  components: { VeeForm: Form, Field, AddIcon, BinIcon },
    props: {
        isEditing: {
            type: Boolean,
            required: true
        },
        invoice: {
            type: Object as PropType<Invoice>,
            required: true
        },
        vatRegistered: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'onSubmit'
    ],
    setup(props, context) {
        const allLinesForPeriod = ref([]);
        const unInvoicedLines = ref([]);

        const resetUnInvoicedLines = async function() {
            const blocks = await SchedulerApiClient.listSchedulingBlocksForCustomer(
                props.invoice.customer_id,
                DateTime.utc().startOf('month').toISO(),
                DateTime.utc().endOf('month').toISO(),
                true,
            );

            allLinesForPeriod.value = getLinesForCustomer(blocks.data);
            unInvoicedLines.value = allLinesForPeriod.value.filter(x => !x.invoice);
            sortUnInvoicedLines();
        }

        onBeforeMount(async () => {
            await resetUnInvoicedLines();
        })

        const schema = yup.object({
            lines: yup.array().of(
                yup.object({
                    line_item: yup.string().required("Item is required"),
                    line_cost: yup.number().required("Cost per item is required").min(0, "Cost must be a positive value!"),
                    line_vat: yup.number().required("VAT is required").min(0, "VAT must be a positive value!"),
                    line_quantity: yup.number().required("Quantity required").min(1, "You must have at least 1 of this item.").default(1)
                })
            ).strict()
        });

        const { handleSubmit, resetForm, errors } = useForm({ validationSchema: schema, validateOnMount: false });
        const { value: lines } = useField("lines") as { value: Ref<any> };

        watch(props, () => {
            if (props.isEditing && (!props.invoice.lines || !props.invoice.lines.length)) {
                resetForm({ values: { lines: [{ line_item: "", line_cost: 0, line_vat: 0, line_quantity: 1 } as InvoiceLine]} });
            } else {
                resetForm({ values: { lines: props.invoice.lines } });
            }
        }, { immediate: true });

        function removeInvoiceLine(index: number) {
            const lineToRemove = { ...lines.value[index] };

            // @ts-ignore
            resetForm({ values: { lines: lines.value.filter((_, i) => i !== index) } });

            const originalLine = allLinesForPeriod.value.find(initialLine => initialLine.id === lineToRemove.schedule_line_id);

            if(!originalLine) {
                return;
            }

            unInvoicedLines.value.push(originalLine);
            sortUnInvoicedLines();
        }

        function addInvoiceLine() {
            resetForm({ values: { lines: [...lines.value, { line_cost: 0, line_vat: 0, line_quantity: 1 }] } });
        }

        function addUnInvoicedLine(newLine: UnInvoicedLine) {
            resetForm({ values: { lines: [...lines.value, {
                schedule_line_id: newLine.id,
                line_item: `Walk for ${newLine.dog.name} on ${newLine.date_from.toLocaleString()}`,
                line_cost: MoneyUtils.convertToPounds(newLine.cost),
                line_vat: MoneyUtils.convertToPounds(newLine.vat),
                line_quantity: 1
            }] } });
            unInvoicedLines.value = unInvoicedLines.value.filter(x => x.id !== newLine.id);
        }

        const onSubmit = handleSubmit((values: any) => {
            context.emit("onSubmit", values);
        });

        const sortUnInvoicedLines = function() {
            unInvoicedLines.value?.sort((a ,b) => (a.date_from > b.date_from) ? 1 : ((b.date_from > a.date_from) ? -1 : 0))
        }

        const getLinesForCustomer = function(scheduleBlocks: ScheduleBlock[]) {
            return scheduleBlocks?.flatMap(x => x.lines.map(y => ({...y, date_from: DateTime.fromISO(x.date_from)}))).filter(x => x.customer_id == props.invoice.customer_id) ?? [];
        }

        return {
            DateTime,
            MoneyUtils,
            unInvoicedLines,
            removeInvoiceLine,
            addInvoiceLine,
            addUnInvoicedLine,

            lines,
            errors,

            onSubmit,
            resetUnInvoicedLines
        }
    },
})
</script>
