<template>
    <div v-if="status !== ScheduleBlockStatus.STATUS_SCHEDULED" class="font-extrabold flex-grow"
          :class="{
              'p-4 bg-sky-blue-700 text-white': status === ScheduleBlockStatus.STATUS_SCHEDULED,
              'p-4 bg-sky-orange-600': status === ScheduleBlockStatus.STATUS_IN_PROGRESS,
              'p-4 bg-green-300': status === ScheduleBlockStatus.STATUS_COMPLETE,
              'p-4 bg-red-300 text-white': status === ScheduleBlockStatus.STATUS_CANCELLED
    }">{{ status.replace("_", " ") }}</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import ScheduleBlockStatus from "../types/ScheduleBlockStatus";

export default defineComponent({
    props: {
        status: {
            type: String as PropType < ScheduleBlockStatus >,
            required: true
        },
    },
    name: "ScheduleStatusBanner",
    setup() {
        return {
            ScheduleBlockStatus,
        }
    }
});
</script>

<style scoped>

</style>
