<template>
    <perfect-scrollbar style="height: 100%; width: 100%;" id="scroll-area" class="dark:bg-gray-600">
        <div
            class="flex flex-grow w-100 relative"
            :class="[noPadding ? 'p-0' : 'p-0 md:p-4']"
        >
                <slot></slot>
        </div>
    </perfect-scrollbar>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "PageContentContainer",
    props:{
        noPadding: {
            type: Boolean,
            required: false
        }
    },
    setup(props) {
        return {
            noPadding: props.noPadding
        }
    }
})
</script>

<style scoped>

</style>
