import axios from "axios";
import CustomerDog from "../modules/customer/types/CustomerDog";
import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper";

function listAllDogs(searchTerm?: string): Promise<ApiResponseWrapper<CustomerDog[]>> {
    return ApiAsyncWrapper(axios.get(`/dogs`, {params: {searchTerm}}));
}

function listDogsForCustomer(customerId: number): Promise<ApiResponseWrapper<CustomerDog[]>> {
    return ApiAsyncWrapper(axios.get(`/customers/${customerId}/dogs`));
}

async function createDog(customerId: number, dog: CustomerDog): Promise<ApiResponseWrapper<CustomerDog>> {
    return await ApiAsyncWrapper(axios.post(`/customers/${customerId}/dogs`, dog));
}

async function updateDog(dog: CustomerDog): Promise<ApiResponseWrapper<CustomerDog>> {
    return await ApiAsyncWrapper(axios.put(`/dogs/${dog.id}`, dog));
}

async function deleteDog(dog: CustomerDog): Promise<ApiResponseWrapper<{}>> {
    return await ApiAsyncWrapper(axios.delete(`/dogs/${dog.id}`));
}

async function updateDogImage(dogId: number, file: File): Promise<ApiResponseWrapper<CustomerDog>> {
    const data = new FormData();
    data.append('image', file);
    return await ApiAsyncWrapper(axios.post(`/dogs/${dogId}/image`, data));
}

export default {
    listDogsForCustomer,
    listAllDogs,
    createDog,
    updateDog,
    deleteDog,
    updateDogImage
}
