import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper";
import axios from "axios";
import DashboardMetrics from "../modules/dashboard/types/DashboardMetrics";

function getDashboardMetrics(): Promise<ApiResponseWrapper<DashboardMetrics>> {
    return ApiAsyncWrapper<DashboardMetrics>(axios.get("/dashboard"));
}

export default {
    getDashboardMetrics
}
