<template>
    <div class="flex bg-white dark:bg-gray-800 shadow border-b dark:border-gray-800"
         :class="[dontSetHeight ? '' : 'md:h-16']">
        <div class="flex-1 p-4 flex flex-col justify-between md:flex-row"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "PageTitleContainer",
    props:{
        dontSetHeight: {
            type: Boolean,
            required: false
        }
    },
    setup(props){
        return {
            dontSetHeight: props.dontSetHeight
        }
    }
})
</script>

<style scoped>

</style>
