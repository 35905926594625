<template>
    <div class="bg-yellow-200 p-4">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: "ExampleBox"
})
</script>

<style scoped>
</style>
