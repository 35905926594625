import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper";
import axios from "axios";
import AddressAutocomplete from "../types/AddressAutocomplete";
import AddressLookup from "../types/AddressLookupItem";

async function autocomplete(partialAddress: string) : Promise<ApiResponseWrapper<AddressAutocomplete>> {
    return ApiAsyncWrapper<AddressAutocomplete>(axios.get(`/address-lookup/autocomplete?address=${partialAddress}`));
}

async function lookup(addressLookupId: string) : Promise<ApiResponseWrapper<AddressLookup>> {
    return ApiAsyncWrapper<AddressLookup>(axios.get(`/address-lookup/${addressLookupId}`));
}

export default {
    autocomplete,
    lookup
}
