<template>
    <div class="md:hidden flex border-sky-blue-500 border-2 rounded p-2 md:border-0 dark:text-gray-100"
         :class="{'bg-sky-blue-500 md:bg-transparent': isToggledOn}"
            @click="$emit('onToggleChange', {target: { checked: !isToggledOn }})">
        {{  labelText }}
    </div>
    <div class="hidden md:flex">
        <label :for="id" class="flex items-center cursor-pointer">
            <!-- toggle -->
            <div class="relative">
                <!-- input -->
                <input type="checkbox" :id="id" class="sr-only" v-model="isToggledOn" @change="(ev) => $emit('onToggleChange', ev)">
                <!-- line -->
                <div class="block bg-gray-200 dark:bg-gray-600 w-14 h-8 rounded-full hidden md:block"></div>
                <!-- dot -->
                <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition hidden md:block"></div>
            </div>
            <div class="md:ml-3 font-medium break-all"
                 :class="{'text-white md:text-gray-900 dark:text-gray-50 ': isToggledOn, 'text-gray-900 dark:text-gray-500': !isToggledOn}">
                {{  labelText }}
            </div>
        </label>
    </div>
</template>
<script lang="ts">
export default {
    name: 'ToggleComponent',
    props: {
        id: {
            type: String,
            required: true
        },
        isToggledOn: {
            type: Boolean,
            required: true
        },
        labelText: {
            type: String,
            required: true
        }
    },
    emits: [
        "onToggleChange"
    ]
}
</script>
<style scoped>

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #9DE2ED;
}

</style>
