import DogCategory from "../types/DogCategory";

function apify(dogCategory: DogCategory): DogCategory {
    return { ...dogCategory, price_per_minute: dogCategory.price_per_minute * 100 };
}

function deApify(dogCategory: DogCategory): DogCategory {
    return { 
        ...dogCategory, 
        price_per_minute: dogCategory.price_per_minute > 0 ? dogCategory.price_per_minute / 100 : 0 
    }
}

export default {
    apify,
    deApify
}
