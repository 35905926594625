<template>
    <nav class="top-level-z flex mb-0 md:mb-4 md:hidden bg-sky-blue-500 dark:bg-gray-700 md:bg-transparent z-100" style="width: 100%">
        <div class=" inset-y-0 right-0 left-2 flex items-center md:hidden text-gray-900 pl-4">
            <!-- Mobile menu button-->
            <button
                @click="toggleMenu"
                type="button"
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <svg style="color: black" class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button>
        </div>
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div class="flex items-center justify-between h-16">
                <div class="flex-1 flex items-center justify-center">
                    <div class="flex-shrink-0 flex items-center">
                        <img alt="pugImage" :src="pug" class="h-10 hidden md:block dark:md:hidden">
                        <img alt="pugImageWhite" :src="pugWhite" class="h-10 block md:hidden dark:md:block">
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile menu -->
        <div v-if="isMobileMenuVisible" class="fixed inset-0 flex z-40 bg-white dark:bg-gray-600" role="dialog" aria-modal="true">
            <div class="fixed inset-0 bg-sky-blue-400 dark:bg-gray-800 bg-opacity-75" aria-hidden="true"></div>
            <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-white dark:bg-gray-700">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                    <button @click="toggleMenu" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span class="sr-only">Close sidebar</span>
                        <!-- Heroicon name: outline/x -->
                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div class="flex-shrink-0 flex items-center px-4">
                    <img alt="pugImage" :src="pug">
                </div>
                <div class="text-left ml-1 flex gap-2 ml-1">
                    <DarkModeToggle />
                    <span class="text-xs self-center dark:text-gray-50">Toggle Dark Mode</span>
                </div>
                <div class="mt-5 flex-1 flex-grow h-0 overflow-y-auto">
                    <nav class="px-2 space-y-1" @click="toggleMenu">
                        <SidebarMenuItem v-for="item in menuItems" :link-url="item.url" :label="item.label" :is-current-local="isCurrentRoute(item.url)" />
                    </nav>
                </div>
                <div class="mb-2s text-center p-4">
                    <div class="z-10 relative pl-2 hidden sm:block">
                        <img alt="Help us doggy" :src="help" style="margin:auto">
                    </div>
                    <div class="z-20 relative bg-white dark:bg-gray-600 shadow p-2 -mt-6 rounded border-gray-200 dark:border-gray-500 border-2 dark:text-gray-50">
                        Help us shape the product
                        by voting on the next features
                        you want to see.
                        <div class="mt-2">
                            <button @click="goToFeatureRequests" type="submit"
                                    class="px-4 py-2 border border-transparent rounded-md shadow-sm
                            font-medium text-white dark:text-black bg-sky-blue-500  hover:bg-sky-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2
                            focus:ring-indigo-500 disabled:opacity-50">
                                Suggest Features
                            </button>
                        </div>
                    </div>
                </div>
                <div class="px-4 border-b pb-2" @click="toggleMenu">
                    <UserSidebarItem />
                </div>
                <div class="px-3 py-2"  @click="toggleMenu">
                    <SidebarMenuItem :small="true" v-for="item in walkBuddyMenuItems" :link-url="item.url" :label="item.label" :is-current-local="isCurrentRoute(item.url)" />
                </div>
            </div>

            <div class="flex-shrink-0 w-14" aria-hidden="true">
                <!-- Dummy element to force sidebar to shrink to fit close icon -->
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import pugWhite from "../../assets/logo-coloured-bg.png";
import {useRoute, useRouter} from "vue-router";
import pug from "../../assets/doggo-white-01.png";
import help from "../../assets/help.png";
import {menuItems, walkBuddyMenuItems} from "../utils/menuUtils";
import SidebarMenuItem from "../../components/SidebarMenuItem.vue";
import UserSidebarItem from "../../modules/user/components/UserSidebarItem.vue";
import DarkModeToggle from "../../components/DarkModeToggle.vue";

export default defineComponent({
    name: "Navigation",
    components: {UserSidebarItem, SidebarMenuItem, DarkModeToggle},
    setup(){
        const route = useRoute();
        const router = useRouter();
        const routePath = computed(() =>route.path)
        const isMobileMenuVisible = ref(false);

        const toggleMenu = function() {
            isMobileMenuVisible.value = !isMobileMenuVisible.value
        }

        const isCurrentRoute = function(route : string) {
            return routePath.value === route
        }

        const handleRouteChange = function(route: string) {
            router.push(route);
        }

        const goToFeatureRequests = function(){
            toggleMenu();
            router.push({path : "/features"});
        }

        return {
            pug,
            pugWhite,
            help,
            isMobileMenuVisible,
            goToFeatureRequests,
            isCurrentRoute,
            toggleMenu,
            menuItems,
            walkBuddyMenuItems,
            handleRouteChange
        }
    }

})
</script>

<style scoped>

</style>
