<template>
    <TierCard title="Free" description="Essential tools to get your dog walking business started" monthly-price="£0" :features="freeFeatures" select-button-text="Continue for Free" select-button-variety="secondary" @selected="handleSelected" />
</template>

<script lang="ts">
import {defineComponent} from "vue";
import TierCard from "../components/TierCard.vue";
export default defineComponent({
    name: "ProTierCard",
    components: {
        TierCard
    },
    props:{
        isSelected: {
            type: Boolean,
            required: true
        }
    },
    emits:[
        'selected'
    ],
    setup(props, context){
        const freeFeatures = [
            { display: "Customer management", included: true },
            { display: "Dog management", included: true },
            { display: "Walk scheduling", included: true },
            { display:"Invoicing", included: false }
        ]

        const handleSelected = function() {
            context.emit("selected");
        }

        return {
            freeFeatures,
            handleSelected
        };
    }
})
</script>

<style scoped>

</style>
