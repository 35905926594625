<template>
    <FormComponent v-if="vatRegistered">
        <TextInput
            id=""
            label="Total Cost"
            :value="invoice.total_cost"
            :displayValue="`&pound;${invoice.total_cost.toFixed(2)}`"
        />
    </FormComponent>
    <FormComponent v-if="vatRegistered">
        <TextInput
            id="Total VAT"
            label="Total VAT"
            :value="invoice.total_vat"
            :displayValue="`&pound;${invoice.total_vat.toFixed(2)}`"
        >
        </TextInput>
    </FormComponent>
    <FormComponent>
        <TextInput
            id="total-price"
            label="Total Price"
            :value="invoice.total_price"
            :displayValue="`&pound;${invoice.total_price.toFixed(2)}`"
        >
        </TextInput>
    </FormComponent>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Invoice from '../types/Invoice'

export default defineComponent({
    props: {
        invoice: {
            type: Object as PropType<Invoice>,
            required: true
        },
        vatRegistered: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup() {
    },
})
</script>
