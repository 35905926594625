<template>
    <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
         :class="{
            'bg-red-500': remaining < 1,
            'bg-white': remaining >= 1
        }">
        <dt class="text-sm font-medium text-center"
            :class="{
                'text-white': remaining < 1,
                'text-gray-500 dark:text-gray-100': remaining >= 1
            }">
            Votes Remaining
        </dt>
        <dd class="mt-1 text-3xl font-semibold text-center"
            :class="{
                'text-white': remaining < 1,
                'text-gray-900 dark:text-gray-100': remaining >= 1
            }">
            {{ remaining }}
        </dd>
    </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "UserVotesRemainingCard",
    props: {
        remaining: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        return {
        }
    }
})
</script>

<style scoped>

</style>