<template>
    <div class="bg-white dark:bg-gray-800 shadow px-4 py-4" :class="overflowHidden? 'overflow-hidden' : ''">
        <div class="flex content-center vertical items-center">
            <slot name="icon" class="dark:text-gray-100"></slot>
            <h3 class="ml-2 flex-grow text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                {{ title }} <span v-if="subTitle" class="text-sm font-light ml-2">{{ subTitle }}</span>
            </h3>
            <div class="mt-1 max-w-2xl text-gray-500 flex-grow-1 flex content-end items-end gap-2">
                <slot name="buttons"></slot>
            </div>
        </div>
        <div class="mt-4 mb-2">
            <div v-if="isLoading" class="flex justify-center">
                <LoadingSpinner class="w-32" />
            </div>
            <slot v-else></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
    name: "Card",
    props: {
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        overflowHidden: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    setup(props) {
        return {
            isLoading: computed(() => props.isLoading)
        }
    },
})
</script>
