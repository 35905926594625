<template>
    <div class="flex flex-grow md:items-center" @click="onClickScheduleItem">
        <div class="flex flex-grow md:items-center">
            <CustomerDogAvatar :dog="dog" :index="index" />
            <div class="text-lg ml-4">
                <div class="flex gap-2 items-center">
                    <div class="font-extrabold break-all">
                        {{dog.name}}
                    </div>
                    <div class="text-sm">
                        <a title="View Customer" class="cursor-pointer text-blue-600 font-bold" @click="onViewCustomer">{{ customerName }}</a>
                    </div>
                </div>

                <div>
                    {{ FormattingUtils.toCurrencyString(MoneyUtils.convertToPounds(lineCost)) }}

                    <span v-if="invoiceId" class="cursor-pointer text-blue-600 font-bold text-sm">
                        <a @click="onInvoiceLinkClick">
                            Invoice #{{invoiceId}} ({{displayInvoiceStatus}})
                        </a>
                    </span>
                    <span v-if="walkCategory" class="inline-flex items-center px-2.5 py-0.5 mr-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        {{ walkCategory.name }}
                    </span>

                    <span v-if="surcharges">
                          <span
                              v-for="surcharge in surcharges"
                              class="inline-flex items-center px-2.5 py-0.5 mr-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                              {{ surcharge.name }}
                          </span>

                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue";
import FormattingUtils from "../../../utils/FormattingUtils";
import MoneyUtils from "../../../utils/MoneyUtils";
import ScheduleBlockStatus from "../types/ScheduleBlockStatus";
import CustomerDogAvatar from "../../customer/components/CustomerDogAvatar.vue";
import {useRouter} from "vue-router";
import CustomerDog from "../../customer/types/CustomerDog";
import InvoiceStatus from "../../invoices/types/InvoiceStatus";
import WalkCategory from "../../business/types/WalkCategory";
import Surcharge from "../../business/types/Surcharge";
import useInvoiceStore from "../../invoices/useInvoiceStore";

export default defineComponent({
    components: {
        CustomerDogAvatar
    },
    props:{
        lineId: {
            type: Number,
            required: true,
        },
        index: {
            type: Number,
            required: true
        },
        customerId: {
            type: Number,
            required: true,
        },
        customerName: {
            type: String,
            required: true,
        },
        dog: {
            type: Object as PropType<CustomerDog>,
            required: true,
        },
        lineCost: {
            type: Number,
            required: true
        },
        scheduleBlockStatus: {
            type: String as PropType<ScheduleBlockStatus>,
            required: true
        },
        scheduleBlockId: {
            type: [String, Number],
            required: true
        },
        invoiceId: {
            type: Number,
            required: false
        },
        invoiceStatus: {
            type: String as PropType<InvoiceStatus>,
            required: false
        },
        walkCategory: {
            type: Object as PropType<WalkCategory>,
            required: true
        },
        surcharges: {
            type: Array as PropType<Surcharge[]>,
            required: false,
            default: []
        }
    },
    emits: [
        'onClickScheduledItem'
    ],
    setup: function (props, context) {
        const router = useRouter();
        const invoiceStore = useInvoiceStore();

        const onViewDog = function() {
            router.push(`/customer-dogs/${props.customerId}/${props.dog.id}?scheduleBlockId=${props.scheduleBlockId}`);
        }

        const onViewCustomer = function(e) {
            e.stopPropagation();
            router.replace(`/customers/${props.customerId}?scheduleBlockId=${props.scheduleBlockId}`);
        }

        const onClickScheduleItem = function() {
            context.emit('onClickScheduledItem');
        }

        const onInvoiceLinkClick = function(e) {
            e.stopPropagation();
            router.push(`/invoices/${props.invoiceId}`);
        }

        const getDisplayInvoiceStatus = function(invoiceStatus: InvoiceStatus | undefined) {
            if(!invoiceStatus) {
                return invoiceStatus;
            }

            const matchingStatus = invoiceStore.statuses.find(x => x.value === invoiceStatus);

            if(!matchingStatus) {
                return invoiceStatus;
            }

            return matchingStatus.name;
        }

        const displayInvoiceStatus = computed(() => getDisplayInvoiceStatus(props.invoiceStatus));

        return {
            FormattingUtils,
            MoneyUtils,

            onViewDog,
            onViewCustomer,
            onClickScheduleItem,
            onInvoiceLinkClick,

            displayInvoiceStatus
        }
    }
})
</script>
