import axios, {AxiosError, AxiosPromise, AxiosResponse} from "axios";
import emitter from "../../utils/emitter";


export class ApiResponseWrapper<T>{
    public data?: T | null;
    public success: boolean = false;
    public status?: number | null;
    public errors?: ApiError | null;

    constructor(data : T | null, success : boolean, status?: number | null, errors? : ApiError | null) {
        this.data = data;
        this.success = success;
        this.status = status;
        this.errors = errors;
    }
}

export class ApiError implements ApiError{
    public status: number;
    public message?: string | null;
    public details?: Object | null;

    constructor(status: number, message: string | null, details: Object | null) {
        this.status = status;
        this.message = message;
        this.details = details ?? null;
    }
}

axios.interceptors.request.use(function (config) {
    if(import.meta.env.VITE_APP_ENV == "dev"){
        config.headers['CF-Access-Client-Id'] = import.meta.env.VITE_CF_ACCESS_ID;
        config.headers['CF-Access-Client-Secret'] = import.meta.env.VITE_CF_ACCESS_SECRET;
    }

    return config;
});

axios.interceptors.response.use((response : AxiosResponse) => {
    return response;
}, (error : AxiosError) => {
    throw new ApiError(
        error.response?.status ?? 503,
        error.response?.data.message,
        error.response?.data.errors,
    );

});

export async function ApiAsyncWrapper<T>(axiosRequest : AxiosPromise<any>): Promise<ApiResponseWrapper<T>> {
    try{
        const response = await axiosRequest;
        return new ApiResponseWrapper<T>(
            response.data,
            !!(response && response.status && (response.status === 200 || response.status === 201 || response.status === 204)),
            response.status
        )

    } catch(error){
        // Sorry not sorry
        if(error.status == 404){
            error.message = "Not Found";
            emitter.emit('error-captured', {
                error: error
            });
        }
        else if(error.message != "Unauthenticated.") {
            emitter.emit('error-captured', {
                error: error
            });
        }
        throw new ApiResponseWrapper<any>(
            null,
            false,
            error.status ?? null,
            error
        )
    }
}
