<template>
    <PageTitleContainer>
        <div class="flex flex-grow">
            <h1 class="text-2xl font-bold text-black dark:text-gray-100">About WalkBuddy</h1>
        </div>
    </PageTitleContainer>
    <PageContentContainer>
        <perfect-scrollbar class="md:h-full bg-gray-100 dark:bg-gray-500 dark:text-gray-100">
        <main class="flex-1 relative focus:outline-none ">
            <div class="p-4 mb-8 flex flex-col md:flex-row gap-y-4 md:gap-x-4">
                <div class="flex-grow">
                    <p>WalkBuddy has been Hand Crafted by the team over at <a href="https://www.bosslabs.co.uk" target="_blank" class="underline hover:text-sky-blue-900">BossLabs Ltd.</a></p>
                    <p class="mt-4">
                        <strong>BossLabs Ltd</strong><br />
                        Profolk Bank Chambers,<br />
                        St. Petersgate, <br />
                        Stockport, <br />
                        Cheshire, <br />
                        SK1 1AR
                    </p>
                    <p>
                        Company number : 12614098
                    </p>
                </div>
                <div class="flex flex-col gap-y-4">
                    <p>
                        <strong>Application Policies</strong>
                    </p>
                    <ul>
                        <li><a href="https://app.termly.io/document/privacy-policy/0c7872ac-09a2-4365-bd67-aae14a009b43" target="_blank" class="underline hover:text-sky-blue-900">Privacy Policy</a></li>
                        <li><a target="_blank" href="https://app.termly.io/document/cookie-policy/638b8306-77c1-40fd-b177-ecfe86a91358" class="underline hover:text-sky-blue-900">Cookie Policy</a></li>
                        <li><a target="_blank" href="https://app.termly.io/document/disclaimer/9d388a5b-0db7-4d82-abf7-5c18cd9de105" class="underline hover:text-sky-blue-900">Disclaimer</a></li>
                        <li><a target="_blank" href="https://app.termly.io/document/terms-of-use-for-saas/d40892c0-73b3-40cc-8bad-ce7505e1d199" class="underline hover:text-sky-blue-900">WalkBuddy Terms Of Service</a></li>
                    </ul>
                    <p>
                        <strong>Contact Details</strong><br/>
                        For all enquires please email the team via contact@walkbuddy.net
                    </p>
                </div>
            </div>
        </main>
    </perfect-scrollbar>
    </PageContentContainer>
</template>

<script>
import useTitleManager from "../../../utils/useTitleManager";
import {onMounted} from "vue";
import PageTitleContainer from "../../../components/PageTitleContainer.vue";
import PageContentContainer from "../../../components/PageContentContainer.vue";

export default {
    name: "AboutUsPage",
    components:{
      PageTitleContainer,
      PageContentContainer
    },
    setup(){
        const { setTitlePrefix } = useTitleManager();

        onMounted(async () => {
            setTitlePrefix("About Us");
        });
    }
}
</script>

<style scoped>

</style>
