<template>
    <Form v-if="isEditing" @submit="onSubmit">
        <ErrorBanner
            :show="showFormErrorBanner"
            :message="'Please check the details you have entered for errors.'"
        />
        <FormComponent :hasTopPadding="false">
            <TextInput
                id="contact-name"
                label="Name"
                placeholder="Contact Name"
                :value="name"
                :errors="nameError"
                :isEditing="isEditing"
                :required="true"
                @update:value="(value) => (name = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="contact-email"
                label="Email"
                placeholder="joe@bloggs.com"
                type="email"
                :value="email"
                :errors="emailError"
                :isEditing="isEditing"
                :required="true"
                @update:value="(value) => (email = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="contact-telephone"
                label="Telephone"
                placeholder="01487 523654"
                type="tel"
                :value="telephone"
                :errors="telephoneError"
                :isEditing="isEditing"
                :required="true"
                @update:value="(value) => (telephone = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="contact-mobile"
                label="Mobile"
                placeholder="07541236665"
                type="tel"
                :value="mobile"
                :errors="mobileError"
                :isEditing="isEditing"
                @update:value="(value) => (mobile = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="contact-address-1"
                label="Address Line 1"
                placeholder="32 Long Avenue"
                :value="addressLine1"
                :errors="addressLine1Error"
                :isEditing="isEditing"
                :required="true"
                @update:value="(value) => (addressLine1 = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="contact-address-2"
                label="Address Line 2"
                placeholder=""
                :value="addressLine2"
                :errors="addressLine2Error"
                :isEditing="isEditing"
                @update:value="(value) => (addressLine2 = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="contact-address-3"
                label="Address Line 3"
                placeholder=""
                :value="addressLine3"
                :errors="addressLine3Error"
                :isEditing="isEditing"
                @update:value="(value) => (addressLine3 = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="contact-city"
                label="City"
                placeholder=""
                :value="city"
                :errors="cityError"
                :isEditing="isEditing"
                :required="true"
                @update:value="(value) => (city = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="contact-county"
                label="County"
                placeholder=""
                :value="county"
                :errors="countyError"
                :isEditing="isEditing"
                @update:value="(value) => (county = value)"
            />
        </FormComponent>

        <FormComponent>
            <DropdownSelector
                id="contact-country"
                label="Country"
                placeholder=""
                :value="countryId"
                :errors="countryIdError"
                :isEditing="isEditing"
                :selectors="countries"
                :required="true"
                @update:value="(value) => (countryId = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="contact-post-code"
                label="Post Code"
                placeholder=""
                :value="zipCode"
                :errors="zipCodeError"
                :isEditing="isEditing"
                :required="true"
                @update:value="(value) => (zipCode = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="vat-number"
                label="Vat Number"
                placeholder=""
                :value="vatNumber"
                :errors="vatNumberError"
                :isEditing="isEditing"
                @update:value="(value) => (vatNumber = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="companies-house-id"
                label="Companies House Id"
                placeholder=""
                :value="companiesHouseId"
                :errors="companiesHouseIdError"
                :isEditing="isEditing"
                @update:value="(value) => (companiesHouseId = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="account-name"
                label="Account Name"
                placeholder=""
                :value="accountName"
                :errors="accountNameError"
                :isEditing="isEditing"
                @update:value="(value) => (accountName = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="account-number"
                label="Account Number"
                placeholder=""
                :value="accountNumber"
                :errors="accountNumberError"
                :isEditing="isEditing"
                @update:value="(value) => (accountNumber = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="sort-code"
                label="Sort Code"
                placeholder=""
                :value="sortCode"
                :errors="sortCodeError"
                :isEditing="isEditing"
                @update:value="(value) => (sortCode = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="iban"
                label="Iban"
                placeholder=""
                :value="iban"
                :errors="ibanError"
                :isEditing="isEditing"
                @update:value="(value) => (iban = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="swift"
                label="Swift"
                placeholder=""
                :value="swift"
                :errors="swiftError"
                :isEditing="isEditing"
                @update:value="(value) => (swift = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="bank-name"
                label="Bank Name"
                placeholder=""
                :value="bankName"
                :errors="bankNameError"
                :isEditing="isEditing"
                @update:value="(value) => (bankName = value)"
            />
        </FormComponent>
    </Form>
    <div v-else class="grid gap-y-2 dark:text-gray-100">
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Email :</strong></div>
            <div class="grid-cols-3">{{ email }}</div>
        </div>

        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Telephone :</strong></div>
            <div class="grid-cols-3">{{ telephone }}</div>
        </div>

        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Mobile :</strong></div>
            <div class="grid-cols-3">{{ mobile }}</div>
        </div>

        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Address :</strong></div>
            <div class="grid-cols-3">{{ addressLine1 }} <br />
                <span v-if="addressLine2">{{addressLine2 }} <br /></span>
                <span v-if="addressLine3">{{addressLine3 }} <br /></span>
                <span v-if="city">{{city }} <br /></span>
                <span v-if="county">{{county }} </span><span v-if="countryId">, {{countryId }} </span><br />
                <span v-if="zipCode">{{zipCode }} <br /></span>
            </div>
        </div>

        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>VAT Number :</strong></div>
            <div class="grid-cols-3">{{ vatNumber }}</div>
        </div>

        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Company Number :</strong></div>
            <div class="grid-cols-3">{{ companiesHouseId }}</div>
        </div>

        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Account Name :</strong></div>
            <div class="grid-cols-3">{{ accountName }}</div>
        </div>
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Account Number :</strong></div>
            <div class="grid-cols-3">{{ accountNumber }}</div>
        </div>
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Sort Code :</strong></div>
            <div class="grid-cols-3">{{ sortCode }}</div>
        </div>
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Iban :</strong></div>
            <div class="grid-cols-3">{{ iban }}</div>
        </div>
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Swift :</strong></div>
            <div class="grid-cols-3">{{ swift }}</div>
        </div>
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Bank Name :</strong></div>
            <div class="grid-cols-3">{{ bankName }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, PropType, ref, watch} from "vue";
import Business from "../types/Business";
import * as yup from "yup";
import {useField, useForm} from "vee-validate";
import useMiscInformationStore from "../../../utils/useMiscInformationStore";
import ErrorBanner from "../../../components/ErrorBanner.vue";

export default defineComponent({
    name: "BusinessInformationForm",
    components: {
        ErrorBanner
    },
    props: {
        business: {
            type: Object as PropType<Business>,
            required: true
        },
        isEditing: {
            type: Boolean,
            required: true
        },
        showBottomButtons: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props, context){
        const miscInformationStore = useMiscInformationStore();
        const countries = computed(() => miscInformationStore.countries);
        const isEditing = computed(() => props.isEditing);


        // Form Validation Schema
        const schema = yup.object({
            name: yup.string().required('Business Name is required.').max(255),
            email: yup.string().required('Email is required.').email('Email needs to be a valid email.').max(255),
            telephone: yup.string().required('Telephone is required.').min(6, 'Telephone needs to be a valid number.').max(255),
            mobile:  yup.string().min(6, 'Mobile needs to be a valid number.').max(255).nullable().transform((value, originalValue) => originalValue?.trim() === "" ? null : originalValue),
            address_line_1: yup.string().required('Address line 1 is required.').min(1, 'Address line 1 needs to be a minimum of 1 character.').max(255),
            address_line_2: yup.string().nullable().max(255),
            address_line_3: yup.string().nullable().max(255),
            city: yup.string().required('City is required.').max(255),
            county: yup.string().nullable().max(255),
            country_id: yup.string().required('Country is required.'),
            zip_code: yup.string().required('Post Code is required.').max(255),
            vat_number: yup.string().nullable().max(255),
            companies_house_id: yup.string().nullable().max(255),
            account_number: yup.string().nullable().min(8, 'Account Number must be 8 characters long').max(8, 'Account Number must be 8 characters long').transform((value, originalValue) => originalValue?.trim() === "" ? null : originalValue),
            sort_code: yup.string().nullable().min(6, 'Sort Code must be 6 characters long').max(6, 'Sort Code must be 6 characters long').transform((value, originalValue) => originalValue?.trim() === "" ? null : originalValue),
            iban: yup.string().nullable().min(22, 'IBAN Number must be 22 characters long').max(22, 'IBAN Number must be 22 characters long').transform((value, originalValue) => originalValue?.trim() === "" ? null : originalValue),
            swift: yup.string().nullable().min(8, 'Swift Number must be 8 characters long').max(22, 'Swift Number must be 8 characters long').transform((value, originalValue) => originalValue?.trim() === "" ? null : originalValue),
            account_name: yup.string().nullable().min(4, 'Account name must be at least 4 characters.').transform((value, originalValue) => originalValue?.trim() === "" ? null : originalValue),
            bank_name: yup.string().nullable()
        });

        // Form context
        const {errors, handleSubmit, resetForm} = useForm({
            validationSchema: schema,
        })
        const showFormErrorBanner = computed( () => Object.keys(errors.value).length !== 0);

        // form fields
        // No need to define rules for fields
        const { meta: nameMeta, value: name, errorMessage: nameError } = useField('name');
        const { meta: emailMeta, value: email, errorMessage: emailError } = useField('email');
        const { meta: telephoneMeta, value: telephone, errorMessage: telephoneError } = useField('telephone');
        const { meta: mobileMeta, value: mobile, errorMessage: mobileError } = useField('mobile');
        const { meta: addressLine1Meta, value: addressLine1, errorMessage: addressLine1Error } = useField('address_line_1');
        const { meta: addressLine2Meta, value: addressLine2, errorMessage: addressLine2Error } = useField('address_line_2');
        const { meta: addressLine3Meta, value: addressLine3, errorMessage: addressLine3Error } = useField('address_line_3');
        const { meta: cityMeta, value: city, errorMessage: cityError } = useField('city');
        const { meta: countyMeta, value: county, errorMessage: countyError } = useField('county');
        const { meta: countryMeta, value: countryId, errorMessage: countryIdError } = useField('country_id');
        const { meta: zipCodeMeta, value: zipCode, errorMessage: zipCodeError } = useField('zip_code');
        const { meta: vatNumberMeta, value: vatNumber, errorMessage: vatNumberError } = useField('vat_number');
        const { meta: companiesHouseIdMeta, value: companiesHouseId, errorMessage: companiesHouseIdError } = useField('companies_house_id');


        const { value: accountNumber, errorMessage: accountNumberError } = useField('account_number');
        const { value: sortCode, errorMessage: sortCodeError } = useField('sort_code');
        const { value: iban, errorMessage: ibanError } = useField('iban');
        const { value: swift, errorMessage: swiftError } = useField('swift');
        const { value: accountName, errorMessage: accountNameError } = useField('account_name');
        const { value: bankName, errorMessage: bankNameError } = useField('bank_name');

        watch(
            isEditing,
            () => {
                resetForm();

                name.value = props.business.name;
                email.value = props.business.email;
                telephone.value = props.business.telephone;
                addressLine1.value = props.business.address_line_1;
                city.value = props.business.city;
                countryId.value = props.business.country_id;
                zipCode.value = props.business.zip_code;

                if (props.business.mobile) {
                    mobile.value = props.business.mobile;
                }

                if (props.business.address_line_2) {
                    addressLine2.value = props.business.address_line_2;
                }

                if (props.business.address_line_3) {
                    addressLine3.value = props.business.address_line_3;
                }

                if (props.business.county) {
                    county.value = props.business.county;
                }

                if (props.business.vat_number) {
                    vatNumber.value = props.business.vat_number;
                }

                if (props.business.companies_house_id) {
                    companiesHouseId.value = props.business.companies_house_id;
                }

                if (props.business.account_name) {
                    accountName.value = props.business.account_name;
                }

                if (props.business.account_number) {
                    accountNumber.value = props.business.account_number;
                }

                if (props.business.sort_code) {
                    sortCode.value = props.business.sort_code;
                }

                if (props.business.iban) {
                    iban.value = props.business.iban;
                }

                if (props.business.swift) {
                    swift.value = props.business.swift;
                }

                if (props.business.bank_name) {
                    bankName.value = props.business.bank_name;
                }
            },
            { immediate: true }
        );

        const onSubmit = handleSubmit(values => {
            context.emit('onSubmit', values);
        });

        return {
            showButtomButtons: props.showBottomButtons,
            onSubmit,

            showFormErrorBanner,

            name,
            nameError,

            email,
            emailError,

            telephone,
            telephoneError,

            mobile,
            mobileError,

            addressLine1,
            addressLine1Error,

            addressLine2,
            addressLine2Error,

            addressLine3,
            addressLine3Error,

            city,
            cityError,

            county,
            countyError,

            countryId,
            countryIdError,

            zipCode,
            zipCodeError,

            vatNumber,
            vatNumberError,

            companiesHouseId,
            companiesHouseIdError,

            accountNumber,
            accountNumberError,

            iban,
            ibanError,

            swift,
            swiftError,

            sortCode,
            sortCodeError,

            accountName,
            accountNameError,

            bankName,
            bankNameError,

            countries
        }
    },
});
</script>

<style scoped>

</style>
