<template>
    <Form v-if="isEditing">
        <FormComponent>
            <TextInput
                id="account-name"
                label="Account Name"
                placeholder=""
                :value="accountName"
                :errors="accountNameError"
                :isEditing="isEditing"
                @update:value="(value) => (accountName = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="account-number"
                label="Account Number"
                placeholder=""
                :value="accountNumber"
                :errors="accountNumberError"
                :isEditing="isEditing"
                @update:value="(value) => (accountNumber = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="sort-code"
                label="Sort Code"
                placeholder=""
                :value="sortCode"
                :errors="sortCodeError"
                :isEditing="isEditing"
                @update:value="(value) => (sortCode = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="iban"
                label="Iban"
                placeholder=""
                :value="iban"
                :errors="ibanError"
                :isEditing="isEditing"
                @update:value="(value) => (iban = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="swift"
                label="Swift"
                placeholder=""
                :value="swift"
                :errors="swiftError"
                :isEditing="isEditing"
                @update:value="(value) => (swift = value)"
            />
        </FormComponent>

        <FormComponent>
            <TextInput
                id="bank-name"
                label="Bank Name"
                placeholder=""
                :value="bankName"
                :errors="bankNameError"
                :isEditing="isEditing"
                @update:value="(value) => (bankName = value)"
            />
        </FormComponent>
    </Form>
    <div v-else class="grid gap-y-2">
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Account Name :</strong></div>
            <div class="grid-cols-3">{{ accountName }}</div>
        </div>
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Account Number :</strong></div>
            <div class="grid-cols-3">{{ accountNumber }}</div>
        </div>
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Sort Code :</strong></div>
            <div class="grid-cols-3">{{ sortCode }}</div>
        </div>
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Iban :</strong></div>
            <div class="grid-cols-3">{{ iban }}</div>
        </div>
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Swift :</strong></div>
            <div class="grid-cols-3">{{ swift }}</div>
        </div>
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Bank Name :</strong></div>
            <div class="grid-cols-3">{{ bankName }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, watch} from 'vue'
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import Business from '../types/Business';

export default defineComponent({
    props: {
        isEditing: {
            type: Boolean,
            required: true
        },
        business: {
            type: Object as PropType<Business>,
            required: true
        }
    },
    setup(props, context) {
        const isEditing = computed(() => props.isEditing);
        const schema = yup.object({
            account_number: yup.string().nullable().min(8, 'Account Number must be 8 characters long').max(8, 'Account Number must be 8 characters long').transform((value, originalValue) => originalValue?.trim() === "" ? null : originalValue),
            sort_code: yup.string().nullable().min(6, 'Sort Code must be 6 characters long').max(6, 'Sort Code must be 6 characters long').transform((value, originalValue) => originalValue?.trim() === "" ? null : originalValue),
            iban: yup.string().nullable().min(22, 'IBAN Number must be 22 characters long').max(22, 'IBAN Number must be 22 characters long').transform((value, originalValue) => originalValue?.trim() === "" ? null : originalValue),
            swift: yup.string().nullable().min(8, 'Swift Number must be 8 characters long').max(22, 'Swift Number must be 8 characters long').transform((value, originalValue) => originalValue?.trim() === "" ? null : originalValue),
            account_name: yup.string().nullable().min(4, 'Account name must be at least 4 characters.').transform((value, originalValue) => originalValue?.trim() === "" ? null : originalValue),
            bank_name: yup.string().nullable()
        });

        const {handleSubmit} = useForm({
            validationSchema: schema,
        });

        const onSubmit = handleSubmit(values => {
            context.emit('onSubmit', values);
        });

        const { value: accountNumber, errorMessage: accountNumberError } = useField('account_number');
        const { value: sortCode, errorMessage: sortCodeError } = useField('sort_code');
        const { value: iban, errorMessage: ibanError } = useField('iban');
        const { value: swift, errorMessage: swiftError } = useField('swift');
        const { value: accountName, errorMessage: accountNameError } = useField('account_name');
        const { value: bankName, errorMessage: bankNameError } = useField('bank_name');

        watch(
            isEditing,
            () => {
                accountNumber.value = props.business.account_number;
                sortCode.value = props.business.sort_code;
                iban.value = props.business.iban;
                swift.value = props.business.swift;
                accountName.value = props.business.account_name;
                bankName.value = props.business.bank_name;
            },
            { immediate: true }
        );

        return {
            accountNumber,
            accountNumberError,

            iban,
            ibanError,

            swift,
            swiftError,

            sortCode,
            sortCodeError,

            accountName,
            accountNameError,

            bankName,
            bankNameError,

            onSubmit
        };
    }
})
</script>
