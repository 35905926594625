import {defineStore} from "pinia";
import FaqVideo from "./types/FaqVideo";
import FaqQuestion from "./types/FaqQuestion";
import FaqApiClient from "../../api/FaqApiClient";

const useHelpStore = defineStore({
    id: "help",
    state: () => ({
      videos: [] as FaqVideo[] ,
      questions: [] as FaqQuestion[]
    }),
    actions:{
        async setup(){
          await this.listFaqs();
          await this.listVideos();
        },
        async listFaqs() {
            let response = await FaqApiClient.listFaqs();
            if(response.success) {
                this.questions = response.data as FaqQuestion[];
            }
        },
        async listVideos() {
            let response = await FaqApiClient.listVideos();
            if(response.success) {
                this.videos = response.data as FaqVideo[];
            }
        }
    },
    getters: {
        getVideos: (state) => () : FaqVideo[] => {
            return state.videos;
        },
        getQuestions: (state) => () : FaqQuestion[] => {
            return state.questions;
        }
    }
})

export default useHelpStore;
