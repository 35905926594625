<template>
    <Card :title="title">
        <div class="flex flex-col">
            <div class="my-1 text-sm">{{ description }}</div>
            <div class="my-1"><strong class="text-2xl">{{ monthlyPrice }}</strong><span class="font-light"> /mo</span></div>
            <Button
                :variety="selectButtonVariety"
                @click="handleSelected"
                class="my-4 w-full ml-auto mr-auto"
            >
                {{ selectButtonText }}
            </Button>
            <hr class="my-1" />
            <div class="my-1 text-sm">
                <strong>INCLUDED FEATURES</strong>
            </div>
            <div>
                <div v-for="(feature, index) in features" :key="index">
                    <div class="flex justify-between">
                        <div>
                            {{ feature.display }}
                        </div>
                        <div v-if="feature.included" class="text-green-500">
                            <ThumbsUpIcon />
                        </div>
                        <div v-else class="text-red-500">
                            <ThumbsDownIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Card>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Button from "../../../components/forms/Button.vue";
import ThumbsUpIcon from "../../../components/icons/ThumbsUpIcon.vue";
import ThumbsDownIcon from "../../../components/icons/ThumbsDownIcon.vue";

export default defineComponent({
    name: "TierCard",
    components: {
        ThumbsUpIcon,
        ThumbsDownIcon,
        Button
    },
    props:{
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        monthlyPrice: {
            type: String,
            required: true
        },
        features: {
            type: Array,
            required: true
        },
        selectButtonText: {
            type: String,
            required: true
        },
        selectButtonVariety: {
            type: String,
            required: false,
            default: "primary"
        }
    },
    emits:[
        'selected'
    ],
    setup(props, context){
        const handleSelected = function() {
            context.emit("selected");
        }

        return {
            handleSelected
        }
    }
})
</script>

<style scoped>

</style>
