<template>
    <Modal
        class="z-100"
        :visible="show"
        size="medium"
        title="Future Walk"
    >

        <template #buttons>
            <Button variety="confirm" @click="$emit('onConfirmFutureAction')">Yes</Button>
            <Button variety="warning" @click="$emit('onDeclineFutureAction')">No</Button>
        </template>

        <p>This walk is in the future. Are you sure you wish to perform this action?</p>
    </Modal>
</template>

<script lang="ts">
import FormComponent from "../../../components/forms/FormComponent.vue";
import {defineComponent} from "vue";
import Button from "../../../components/forms/Button.vue";

export default defineComponent({
    components: {
        Button,
        FormComponent
    },
    props:{
      show: {
          type: Boolean,
          required: true,
      }
    },
    emits: [
        'onConfirmFutureAction',
        'onDeclineFutureAction'
    ]
})
</script>
