<template>
    <Form v-if="isEditing" @submit="onSubmit">
        <div class="grid grid-cols-3 col-span-3 space-y-2 items-center mb-2">
            <TextInput
                id="contact-name"
                label="Name"
                placeholder="Contact Name"
                :value="name"
                :errors="nameError"
                :isEditing="isEditing"
                :required="true"
                @update:value="(value) => (name = value)"
            />

            <DropdownSelector
                id="contact-type"
                label="Type"
                :value="type"
                :errors="typeError"
                :isEditing="isEditing"
                :selectors="customerContactTypes"
                :required="true"
                @update:value="(value) => (type = value)"
            />

            <TextInput
                id="contact-email"
                label="Email"
                placeholder="joe@bloggs.com"
                type="email"
                :value="email"
                :errors="emailError"
                :isEditing="isEditing"
                @update:value="(value) => (email = value)"
            />

            <TextInput
                id="contact-telephone"
                label="Telephone"
                placeholder="01487 523654"
                type="tel"
                :value="telephone"
                :errors="telephoneError"
                :isEditing="isEditing"
                @update:value="(value) => (telephone = value)"
            />

            <TextInput
                id="contact-mobile"
                label="Mobile"
                placeholder="07541236665"
                type="tel"
                :value="mobile"
                :errors="mobileError"
                :isEditing="isEditing"
                @update:value="(value) => (mobile = value)"
            />
        </div>

        <AddressLookup
            :hasTopPadding="true"
            id="address-lookup"
            :errors="errors"
            :show-manual-entry-button="false"
            :address="address"
            :general-error-text="undefined"
            @update:value="v => (updateAddress(v))"
        />
    </Form>
    <div v-else class="grid gap-y-2">
        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Name :</strong></div>
            <div class="grid-cols-3">{{ name }}</div>
        </div>

        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Email :</strong></div>
            <div class="grid-cols-3">{{ email }}</div>
        </div>

        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Telephone :</strong></div>
            <div class="grid-cols-3">{{ telephone }}</div>
        </div>

        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Mobile :</strong></div>
            <div class="grid-cols-3">{{ mobile }}</div>
        </div>

        <div class="grid grid-cols-4">
            <div class="grid-cols-1"><strong>Address :</strong></div>
            <div class="grid-cols-3 whitespace-pre">
                {{ addressToString }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { useField, useForm } from "vee-validate";
import {computed, defineComponent, inject, onErrorCaptured, PropType, watch} from "vue";
import * as yup from "yup";
import CustomerContactTypes from "../consts/CustomerContactTypes";
import CustomerContact from "../types/CustomerContact";
import Country from "../../../types/Country";
import AddressLookup from "../../../components/forms/AddressLookup.vue";

export default defineComponent({
    components: {AddressLookup},
    props: {
        countries: {
            type: Array as PropType<Country[]>,
            required: true
        },
        contactModelToEdit: {
            type: Object as PropType<CustomerContact | undefined>,
            required: false
        },
        isEditing: {
            type: Boolean,
            required: true,
        }
    },
    setup(props, context) {
        const isEditing = computed(() => props.isEditing);

        // Form Validation Schema
        const schema = yup.object({
            name: yup.string().required('Name is required.').max(255),
            email: yup.string().nullable().transform((o, c) => o === "" ? null : c).email('Email needs to be a valid email.').max(255),
            telephone: yup.string().nullable().transform((o, c) => o === "" ? null : c).min(6, 'Telephone needs to be a valid number.').max(255),
            mobile:  yup.string().nullable().transform((o, c) => o === "" ? null : c).min(6, 'Mobile needs to be a valid number.').max(255),
            address_line_1: yup.string().nullable().min(5, 'Address line 1 needs to be a minimum of 5 characters.').max(255),
            address_line_2: yup.string().nullable().max(255),
            address_line_3: yup.string().nullable().max(255),
            city: yup.string().nullable().max(255),
            county: yup.string().nullable().max(255),
            country_id: yup.string().required('Country is required.'),
            zip_code: yup.string().nullable().max(255)
        });

        // Form context
        const { handleSubmit, errors, resetForm } = useForm({
            validationSchema: schema,
        })

        // form fields
        // No need to define rules for fields
        const { meta: nameMeta, value: name, errorMessage: nameError } = useField('name');
        const { meta: typeMeta, value: type, errorMessage: typeError } = useField("type");
        const { meta: emailMeta, value: email, errorMessage: emailError } = useField('email');
        const { meta: telephoneMeta, value: telephone, errorMessage: telephoneError } = useField('telephone');
        const { meta: mobileMeta, value: mobile, errorMessage: mobileError } = useField('mobile');
        const { meta: addressLine1Meta, value: addressLine1, errorMessage: addressLine1Error } = useField('address_line_1');
        const { meta: addressLine2Meta, value: addressLine2, errorMessage: addressLine2Error } = useField('address_line_2');
        const { meta: addressLine3Meta, value: addressLine3, errorMessage: addressLine3Error } = useField('address_line_3');
        const { meta: cityMeta, value: city, errorMessage: cityError } = useField('city');
        const { meta: countyMeta, value: county, errorMessage: countyError } = useField('county');
        const { meta: countryMeta, value: countryId, errorMessage: countryIdError } = useField('country_id');
        const { meta: zipCodeMeta, value: zipCode, errorMessage: zipCodeError } = useField('zip_code');

        if (props.contactModelToEdit) {
            name.value = props.contactModelToEdit.name;
            type.value = props.contactModelToEdit.type;
            email.value = props.contactModelToEdit.email;
            telephone.value = props.contactModelToEdit.telephone;
            mobile.value = props.contactModelToEdit.mobile;
            addressLine1.value = props.contactModelToEdit.address_line_1;
            addressLine2.value = props.contactModelToEdit.address_line_2;
            addressLine3.value = props.contactModelToEdit.address_line_3;
            city.value = props.contactModelToEdit.city;
            county.value = props.contactModelToEdit.county;
            countryId.value = props.contactModelToEdit.country_id;
            zipCode.value = props.contactModelToEdit.zip_code;
        } else {
            type.value = "STANDARD";
            countryId.value = "GBR";
        }

        const address = {
            addressLine1: addressLine1.value,
            addressLine2: addressLine2.value,
            addressLine3: addressLine3.value,
            city: city.value,
            county: county.value,
            countryId: countryId.value,
            zipCode: zipCode.value
        }

        const updateAddress = function(value: any) {
            addressLine1.value = value.address_line_1;
            addressLine2.value = value.address_line_2;
            addressLine3.value = value.address_line_3;
            city.value = value.city;
            county.value = value.county;
            zipCode.value = value.zip_code;
            countryId.value = value.country_id;
        }

        const addressToString = computed(() => {
            return [
                addressLine1.value,
                addressLine2.value,
                addressLine3.value,
                city.value,
                county.value,
                countryId.value,
                zipCode.value
            ].filter(e=>e).join(", "+'\n')
        })

        const onSubmit = handleSubmit(values => {
            context.emit("onSubmit", values);
        });

        watch(
            isEditing,
            () => {
                resetForm();

                name.value = props.contactModelToEdit?.name;
                type.value = props.contactModelToEdit?.type;
                email.value = props.contactModelToEdit?.email;
                telephone.value = props.contactModelToEdit?.telephone;
                addressLine1.value = props.contactModelToEdit?.address_line_1;
                city.value = props.contactModelToEdit?.city;
                countryId.value = props.contactModelToEdit?.country_id;
                zipCode.value = props.contactModelToEdit?.zip_code;

                if (props.contactModelToEdit?.mobile) {
                    mobile.value = props.contactModelToEdit?.mobile;
                }

                if (props.contactModelToEdit?.address_line_2) {
                    addressLine2.value = props.contactModelToEdit?.address_line_2;
                }

                if (props.contactModelToEdit?.address_line_3) {
                    addressLine3.value = props.contactModelToEdit?.address_line_3;
                }

                if (props.contactModelToEdit?.county) {
                    county.value = props.contactModelToEdit?.county;
                }
            },
            { immediate: true }
        );

        return {
            customerContactTypes: CustomerContactTypes,
            onSubmit,

            name,
            nameError,

            type,
            typeError,

            email,
            emailError,
            
            telephone,
            telephoneError,

            mobile,
            mobileError,
            
            addressLine1,
            addressLine1Error,

            addressLine2,
            addressLine2Error,

            addressLine3,
            addressLine3Error,

            city,
            cityError,

            county,
            countyError,

            countryId,
            countryIdError,

            zipCode,
            zipCodeError,
            address,
            updateAddress,
            errors,
            addressToString
        };
    },
});
</script>
