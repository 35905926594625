<template>
    <div class="text-lg">
      <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-purple-100 text-purple-800">
            You Voted
      </span>
    </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "UserVotedTag",
    setup(props) {
        return {}
    }
})
</script>

<style scoped>

</style>