import axios from "axios";
import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper";
import SubscriptionPaymentIntent from "../types/SubscriptionPaymentIntent";

async function getPaymentIntent(): Promise<ApiResponseWrapper<SubscriptionPaymentIntent>> {
    return await ApiAsyncWrapper(axios.get("/subscriptions/intent"));
}

async function payment(paymentMethodId: string): Promise<ApiResponseWrapper<SubscriptionPaymentIntent>> {
    return await ApiAsyncWrapper(axios.post("/subscriptions/payment", {
        id: paymentMethodId
    }));
}

async function cancel(): Promise<ApiResponseWrapper<{}>> {
    return await ApiAsyncWrapper(axios.delete("/subscriptions"));
}

async function resume(): Promise<ApiResponseWrapper<{}>> {
    return await ApiAsyncWrapper(axios.post("/subscriptions/resume"));
}

export default {
    getPaymentIntent,
    payment,
    cancel,
    resume
}
