<template>
    <Modal
        title="Generate invoice lines from schedule blocks"
        size="xlarge"
        :visible="true"
    >
        <template #icon>
            <CollectionIcon />
        </template>

        <InvoiceWizardFilters
            :fromDate="fromDate"
            :toDate="toDate"
            @fromDateChanged="fromDateChanged"
            @toDateChanged="toDateChanged"
        />

        <div class="mt-8 grid grid-cols-3 gap-2">
            <InvoiceWizardScheduleBlock
                v-for="scheduleBlock in scheduleBlocks"
                :key="scheduleBlock"
                :scheduleBlock="scheduleBlock"
                :isSelected="scheduleBlockIsSelected(scheduleBlock)"
                @click.stop="scheduleBlockSelected(scheduleBlock)"
            />
        </div>

        <div class="mt-8 border-t flex justify-end pt-4 gap-2">
            <Button
                variety="plain"
                @click.stop="$emit('closed')"
            >
                Close
            </Button>

            <Button
                variety="primary"
                @click.stop="wizardSaved"
            >
                Save
            </Button>
        </div>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, inject, onErrorCaptured, onMounted, PropType, ref} from "vue";
import { DateTime } from "luxon";
import SchedulerApiClient from "../../../api/SchedulerApiClient";
import CollectionIcon from "../../../components/icons/CollectionIcon.vue";
import CheckIcon from "../../../components/icons/CheckIcon.vue";
import ScheduleBlock from "../../schedule/types/ScheduleBlock";
import InvoiceUtils from "../utils/InvoiceUtils";
import InvoiceLine from "../types/InvoiceLine";
import InvoiceWizardScheduleBlock from "./InvoiceWizardScheduleBlock.vue";
import InvoiceWizardFilters from "./InvoiceWizardFilters.vue";
import ScheduleLineToInvoiceLineUtils from "../utils/ScheduleLineToInvoiceLineUtils";

export default defineComponent({
    components: {
        CollectionIcon,
        CheckIcon,
        InvoiceWizardScheduleBlock,
        InvoiceWizardFilters
    },
    props: {
        customerId: {
            type: Number,
            required: true
        },
        invoiceLines: {
            type: Array as PropType<InvoiceLine[]>,
            required: false,
            default: []
        }
    },
    setup(props, context) {
        const scheduleBlocks = ref([] as ScheduleBlock[]);
        const selectedScheduleBlocks = ref([] as ScheduleBlock[]);
        const fromDate = ref(DateTime.utc().minus({ days: 14 }).toISO() as string | undefined);
        const toDate = ref(DateTime.utc().toISO() as string | undefined);

        onMounted(async function() {
            // Retrieve the existing schedule blocks the existing lines are associated with and mark them as being
            // 'selected'.
            if (props.invoiceLines && props.invoiceLines.filter(l => l.schedule_line_id).length) {
                const selectedScheduleBlockResponse = await SchedulerApiClient.listSchedulingBlocksForCustomer(
                    props.customerId,
                    undefined,
                    undefined,
                    true,
                    props.invoiceLines.filter(l => l.schedule_line_id).map(l => l.schedule_line_id!)
                );

                selectedScheduleBlocks.value = selectedScheduleBlockResponse.data!;
            }

            await reloadScheduleBlocks();
        });

        async function fromDateChanged(date: string | undefined) {
            fromDate.value = date ? new Date(date).toISOString() : undefined;
            await reloadScheduleBlocks();
        }

        async function toDateChanged(date: string | undefined) {
            toDate.value = date ? new Date(date).toISOString() : undefined;
            await reloadScheduleBlocks();
        }

        function scheduleBlockIsSelected(scheduleBlock: ScheduleBlock): boolean {
            return selectedScheduleBlocks.value.indexOf(scheduleBlock) > -1;
        }

        function scheduleBlockSelected(scheduleBlock: ScheduleBlock) {
            const indexOfScheduleBlock = selectedScheduleBlocks.value.indexOf(scheduleBlock);

            if (indexOfScheduleBlock === -1) {
                selectedScheduleBlocks.value.push(scheduleBlock);
            } else {
                selectedScheduleBlocks.value.splice(indexOfScheduleBlock, 1);
            }
        }

        async function reloadScheduleBlocks() {
            const scheduleBlockResponse = await SchedulerApiClient.listSchedulingBlocksForCustomer(
                props.customerId,
                fromDate.value!, 
                toDate.value!,
                false
            );

            if (!scheduleBlockResponse.success) {
                return;
            }

            // Schedule blocks should be the selected schedule blocks and then the schedule blocks defined by the
            // filter in an ordered fashion.
            const notSelectedScheduleBlocks = scheduleBlockResponse.data!.filter(
                scheduleBlock => selectedScheduleBlocks.value.findIndex(selectedScheduleBlock => selectedScheduleBlock.id === scheduleBlock.id) === -1
            );

            scheduleBlocks.value = [
                ...selectedScheduleBlocks.value,
                ...notSelectedScheduleBlocks
            ];
        }

        function wizardSaved() {
            if (!selectedScheduleBlocks.value) {
                context.emit("closed");
                return;
            }

            const invoiceLinesToCreate = selectedScheduleBlocks.value
                .flatMap(ScheduleLineToInvoiceLineUtils.convertScheduleLinesToInvoiceLines);

            context.emit(
                "saved", 
                { 
                    lines: [
                        ...props.invoiceLines.filter(invoiceLine => !invoiceLine.schedule_line_id), // They will manage schedule-block related invoices in this modal.
                        ...invoiceLinesToCreate
                    ] 
                }
            );
        }

        return {
            fromDate,
            toDate,
            scheduleBlocks,
            
            fromDateChanged,
            toDateChanged,
            scheduleBlockSelected,
            wizardSaved,

            scheduleBlockIsSelected,
            prettyPrintInvoiceDate: InvoiceUtils.prettyPrintInvoiceDate
        }
    },
})
</script>
