<template>
    <teleport to="body">
        <div class="context-menu"
             id="context-menu"
             v-if="isVisible"
            :style="{'left': contextMenuX, 'top' : contextMenuY}"
             oncontextmenu="return false;"
        >
            <ul class="menu">
                <li v-for="action in listOfActions" :key="action.eventId" @click="handleItemClick(action.eventId)">
                    {{ action.label }}
                </li>
            </ul>
        </div>
    </teleport>
</template>

<script>
import {defineComponent, onMounted, onUnmounted, ref, watch} from "vue";

export default defineComponent({
    name: "ContextMenu",
    props: {
        x: {
            type: String,
            required: false,
            default: 0
        },
        y: {
            type: String,
            required: false,
            default: 0
        },
        isVisible: {
            type: Boolean,
            required: false,
            default: true
        },
        listOfActions: {
            type: Array,
            required: true,
        }
    },
    emits: [
        'menu-item-clicked',
        'menu-closed'
    ],
    setup(props, {emit}){
        const contextMenuY = ref("0px");
        const contextMenuX = ref("0px");

        watch(() => props.x, (first, second) => {
            contextMenuX.value = first;
        });

        watch(() => props.y, (first, second) => {
            contextMenuY.value = first;
        });

        const handleItemClick = (itemId) => {
            emit('menu-item-clicked', itemId);
        }

        const handleWindowClick = (ev) => {
            if(ev.target.id !== "context-menu")
                emit('menu-closed')
        }

        onMounted(() => {
            window.addEventListener('click', handleWindowClick)
        })

        onUnmounted(() => {
            window.removeEventListener('click', handleWindowClick)
        })

        return {
            contextMenuY,
            contextMenuX,
            listOfActions: props.listOfActions,
            handleItemClick
        }
    }
})
</script>

<style scoped>
    .context-menu{
        position: absolute;
    }

    .menu {
        min-width: 120px;
        list-style: none;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border:1px solid #efefef;
        box-shadow: 0 10px 20px rgb(64 64 64 / 5%);
    }
    .menu > li {
        font: inherit;
        border: 0;
        padding: 10px 30px 10px 15px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        text-decoration: unset;
        color: #000;
        font-weight: 500;
    }
    .menu > li:hover {
        background:#f1f3f7;
        color: #4b00ff;
    }
</style>
