<template>
    <router-link
        :to="linkUrl"
        :class="[
            isCurrentLocal ? 'bg-sky-blue-400 text-black font-bold group flex items-center  rounded-md' : 'text-gray-600 dark:text-gray-50 hover:bg-sky-blue-400 hover:text-black dark:hover:text-black hover:font-bold group flex items-center rounded-md hover:font-bold',
            small? 'text-sm px-1 py-1' : 'text-lg px-2 py-2'
        ]">
        <HomeIcon v-if="linkUrl === '/dashboard'" :class="isCurrentLocal ? 'text-black' : 'text-gray-400 dark:text-gray-100'" class="group-hover:text-black mr-3 flex-shrink-0" />
        <CalenderIcon v-if="linkUrl === '/schedule'" :class="isCurrentLocal ? 'text-black' : 'text-gray-400 dark:text-gray-100'" class="group-hover:text-black mr-3 flex-shrink-0" />
        <ChartIcon v-if="linkUrl === '/invoices'" :class="isCurrentLocal ? 'text-black' : 'text-gray-400 dark:text-gray-100'" class="group-hover:text-black mr-3 flex-shrink-0" />
        <PeopleIcon v-if="linkUrl === '/customers'" :class="isCurrentLocal ? 'text-black' : 'text-gray-400 dark:text-gray-100'"  class=" group-hover:text-black mr-3 flex-shrink-0" />
        <QuestionIcon v-if="linkUrl === '/about-us' || linkUrl === '/help'" :class="isCurrentLocal ? 'text-black' : 'text-gray-400 dark:text-gray-100'"  class=" group-hover:text-black mr-3 flex-shrink-0" />
        <BusinessFolderIcon v-if="linkUrl === '/business'" xmlns="http://www.w3.org/2000/svg" :class="isCurrentLocal ? 'text-black' : 'text-gray-400 dark:text-gray-100'" class="group-hover:text-black mr-3 flex-shrink-0" />
        {{ label }}
    </router-link>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ChartIcon from "../components/icons/ChartIcon.vue";
import PeopleIcon from "./icons/PeopleIcon.vue";
import HomeIcon from "./icons/HomeIcon.vue";
import CollectionIcon from "./icons/CollectionIcon.vue";
import BusinessInformationForm from "../modules/business/components/BusinessInformationForm.vue";
import BusinessFolderIcon from "./icons/BusinessFolderIcon.vue";
import CalenderIcon from "./icons/CalenderIcon.vue";
import QuestionIcon from "./icons/QuestionIcon.vue";

export default defineComponent({
    name: "SidebarMenuItem",
    props: {
        linkUrl :{
            type: String,
            required: true,
        },
        label :{
            type: String,
            required: true,
        },
        isCurrentLocal :{
            type: Boolean,
            required: true,
            default: false
        },
        small: {
            type:Boolean,
            required: false
        }
    },
    components:{
        QuestionIcon,
        CalenderIcon,
        BusinessFolderIcon,
        BusinessInformationForm,
        CollectionIcon,
        HomeIcon,
        PeopleIcon,
        ChartIcon
    },
    setup(){

    }
})
</script>

<style scoped>

</style>
