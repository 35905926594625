<template>
    <div>
        <dt class="text-lg bg-sky-blue-400 p-2">
            <!-- Expand/collapse question button -->
            <button @click="toggleClosed" type="button" class="text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0" aria-expanded="false">
                <span class="font-medium text-gray-900"> {{ data.question }} </span>
                <span class="ml-6 h-7 flex items-center text-black">
                    <svg v-if="isClosed" class="rotate-0 h-6 w-6 transform" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                    </svg>
              </span>
            </button>
        </dt>
        <dd class="pr-12 p-2 bg-white dark:bg-gray-500 border-sky-blue-400 dark:border-gray-500 border" id="faq-0" v-if="!isClosed">
            <p class="text-base text-gray-500 dark:text-gray-100" v-html="answer"></p>
        </dd>
    </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import {marked} from 'marked';

export default defineComponent({
    name: "FaqQuestion",
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    setup(props){
        const isClosed = ref(true);

        const toggleClosed = function() {
            isClosed.value = !isClosed.value
        }

        const answer = marked(props.data.answer);

        return {
            answer,
            isClosed,
            toggleClosed
        }
    }
})
</script>

<style scoped>

</style>
