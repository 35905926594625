<template>
    <Form @submit="onSubmit">
        <FormComponent>
            <TextInput
                id="appointment-length"
                label="Appointment Length (minutes)"
                placeholder=""
                :value="appointmentLength"
                :errors="appointmentLengthError"
                :isEditing="isEditing"
                type="number"
                :step="1"
                @update:value="(value) => (appointmentLength = value)"
            />
        </FormComponent>
    </Form>
</template>

<script lang="ts">
import { useField, useForm } from "vee-validate";
import {computed, defineComponent, inject, onErrorCaptured, PropType, watch} from "vue";
import * as yup from "yup";
import Business from "../types/Business";

export default defineComponent({
    props: {
        isEditing: {
            type: Boolean,
            required: true
        },
        business: {
            type: Object as PropType<Business>,
            required: true
        }
    },
    setup(props, context) {
        const isEditing = computed(() => props.isEditing);
        const schema = yup.object({
            default_schedule_block_minutes: yup.number().typeError("Appointment Length must be a number.")
                .required("Appointment Length is required.")
                .min(5, "Appointment Length must be greater than 5 minutes.")
                .max(240, "Appointment Length must be less than 240 minutes."),
        });

        const {handleSubmit} = useForm({
            validationSchema: schema,
        });

        const onSubmit = handleSubmit(values => {
            context.emit('onSubmit', values);
        });

        const { value: appointmentLength, errorMessage: appointmentLengthError } = useField('default_schedule_block_minutes');

        watch(
            isEditing, 
            () => {
                appointmentLength.value = props.business.default_schedule_block_minutes;
            },
            { immediate: true }
        );

        return {
            appointmentLength,
            appointmentLengthError,

            onSubmit
        }
    }
})
</script>
