<template>
    <CopySingleScheduleModal
        :is-visible="copyModalIsVisible"
        :current-schedule-item="scheduledItem"
        :show-failed-message="showCopyModalFailedMessage"
        :show-success-message="showCopyModalSuccessMessage"
        @update:date="updateTargetDate"
        @close="copyModalIsVisible = false; showCopyModalFailedMessage = false; showCopyModalSuccessMessage = false;"
        @save="saveScheduleCopy"
        @view="handleViewNewCopiedScheduleItem"
    />

    <Teleport to="#sticky" v-if="mediaFlags.sm.lessThanOrEq && isMounted">
        <div class="bg-white dark:bg-gray-800 z-20 p-4 text-center flex gap-4 justify-center border-t">
            <ScheduleActionButtons
                :show-as-dropdown-selector="true"
                :current-edit-state="currentEditState"
                :is-deletable="isDeletable"
                :is-editable="isEditable"
                @on-schedule-block-deleted="onScheduleBlockDeleted"
                @on-edit="onEdit"
                @on-schedule-block-update="onScheduleBlockUpdate"
                @on-cancel="onCancel"
                :status="scheduledItem?.status ?? ''"
                :is-disabled="!isEditable"
                :hidden-because-of-edit="currentEditState === ScheduleBlockEditorState.EDITING"
                @on-schedule-block-in-progress="onScheduleBlockInProgress"
                @on-schedule-block-complete="onScheduleBlockComplete"
                @on-schedule-block-cancelled="onScheduleBlockCancelled"
                @on-schedule-block-scheduled="onScheduleBlockScheduled"
                @on-copy="copyModalIsVisible = true"
            />
        </div>
    </Teleport>

    <FutureActionCheckModal
        :show="showFutureActionCheckModal"
        @on-confirm-future-action="onConfirmFutureAction"
        @on-decline-future-action="onDeclineFutureAction"
    />

    <UnauthorizedBlockChangeModal
        :show="showUnauthorizedBlockChangeModal"
        :message="blockChangeModalMessage"
        @on-cancel="showUnauthorizedBlockChangeModal = false"
    />

    <PageTitleContainer :dont-set-height="true">
        <div class="flex flex-grow items-center justify-between">
            <button class="hover:text-sky-blue-400 dark:text-gray-100 pr-2 " @click="goBackToScheduler">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
            </button>
            <div class="flex-grow grid pl-4" v-if="scheduledItem">
                <h1 class="text-xl md:text-2xl font-semibold text-gray-900 dark:text-gray-100 whitespace-nowrap overflow-ellipsis overflow-hidden">{{ title }}</h1>
                <div><span class="text-lg font-normal dark:text-gray-100">{{ startDate }} - {{ endDate }}</span></div>
            </div>
            <div class="items-center gap-4 hidden md:flex">
                <ScheduleActionButtons
                    :current-edit-state="currentEditState"
                    :is-deletable="isDeletable"
                    :is-editable="isEditable"
                    @on-schedule-block-deleted="onScheduleBlockDeleted"
                    @on-edit="onEdit"
                    @on-schedule-block-update="onScheduleBlockUpdate"
                    @on-cancel="onCancel"
                    :status="scheduledItem?.status ?? ''"
                    :is-disabled="!isEditable"
                    :hidden-because-of-edit="currentEditState === ScheduleBlockEditorState.EDITING"
                    @on-schedule-block-in-progress="onScheduleBlockInProgress"
                    @on-schedule-block-complete="onScheduleBlockComplete"
                    @on-schedule-block-cancelled="onScheduleBlockCancelled"
                    @on-schedule-block-scheduled="onScheduleBlockScheduled"
                    @on-copy="copyModalIsVisible = true"
                />
            </div>
        </div>
    </PageTitleContainer>

    <PageContentContainer :no-padding="true">
        <div class="flex flex-col flex-grow relative">
            <ScheduleStatusBanner :status="scheduledItem?.status ?? ''"/>

            <div class="p-4 bg-sky-blue-300" v-if="!isLoading && scheduledItem && SchedulerUtils.getScheduleHasWarning(scheduledItem, intervalMinutes)">
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    This schedule is in the past and has not been completed.
                </div>
            </div>

            <div class="flex" v-if="currentEditState !== ScheduleBlockEditorState.EDITING && currentEditState !== ScheduleBlockEditorState.CREATING">
                <div v-if="isLoading">
                    <h1>Loading</h1>
                </div>
                <div v-if="!isLoading && !scheduledItem">
                    <h1>The schedule item you are looking for is invalid</h1>
                </div>
                <div v-if="!isLoading && scheduledItem" class="p-2" style="width: 100%">
                    <div class="bg-white dark:bg-gray-700 dark:text-gray-100 p-2 shadow mb-2 flex justify-between md:justify-start">
                        <strong>Total Walk Value:</strong>
                        <span class="md:ml-2">{{ FormattingUtils.toCurrencyString(MoneyUtils.convertToPounds(totalWalkValue)) }}</span>
                    </div>
                    <div v-for="(line, index) in scheduledItem.lines" class="shadow bg-white dark:bg-gray-800 dark:text-gray-100 mb-2 flex p-2 flex-col md:flex-row md:items-center">
                        <ScheduleBlockLineTracker
                            :line-id="line.id"
                            :index="index"
                            :customer-id="line.customer?.id"
                            :customer-name="line.customer?.name"
                            :dog="line.dog"
                            :line-cost="line.price"
                            :schedule-block-status="scheduledItem.status"
                            :schedule-block-id="scheduledItem.id"
                            :invoice-id="line.invoice?.id"
                            :invoice-status="line.invoice?.status"
                            :surcharges="line.surcharges ?? []"
                            :walk-category="line.walk_category"
                            @on-click-scheduled-item="onEdit"
                        />
                    </div>
                </div>
            </div>
            <div v-else class="p-2">
                <div v-if="scheduledItem">
                    <ScheduleBlockEditor
                        :schedule-block-to-edit="scheduledItem"
                        :errors="errors"
                        :current-state="currentEditState"
                        :is-deletable="isDeletable"
                        @on-update="(block) => onScheduleBlockUpdated(block)"
                    />
                </div>
            </div>
        </div>

    </PageContentContainer>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onBeforeMount, onErrorCaptured, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import useSchedulerStore from "../useSchedulerStore";
import FormattingUtils from "../../../utils/FormattingUtils";
import Button from "../../../components/forms/Button.vue";
import ScheduleBlockEditor from "../components/ScheduleBlockEditor.vue";
import ScheduleBlockEditorState from "../types/ScheduleBlockEditorState";
import ScheduleBlockStatus from "../types/ScheduleBlockStatus";
import DeleteButtonWithConfirmation from "../../../components/forms/DeleteButtonWithConfirmation.vue";
import * as yup from "yup";
import {useField, useForm} from "vee-validate";
import {DateTime} from "luxon";
import MoneyUtils from "../../../utils/MoneyUtils";
import ScheduleBlock from "../types/ScheduleBlock";
import ScheduleBlockLineTracker from "../components/ScheduleBlockLineTracker.vue";
import {ScheduleBlockLine} from "../types/ScheduleBlockLine";
import FutureActionCheckModal from "../components/FutureActionCheckModal.vue";
import SchedulerUtils from "../utils/scheduler-utils";
import {parseInt} from "lodash";
import UnauthorizedBlockChangeModal from "../components/UnauthorizedBlockChangeModal.vue";
import ScheduleActionButtons from "../components/ScheduleActionButtons.vue";
import {useMediaQuery} from "../../core/useMediaQuery";
import ScheduleStatusBanner from "../components/ScheduleStatusBanner.vue";
import PageTitleContainer from "../../../components/PageTitleContainer.vue";
import PageContentContainer from "../../../components/PageContentContainer.vue";
import useTitleManager from "../../../utils/useTitleManager";
import CopySingleScheduleModal from "../components/copying/CopySingleScheduleModal.vue";
import DropdownSelector from "../../../components/forms/DropdownSelector.vue";

export default defineComponent({
    name: "SingleSchedule",
    components: {
        DropdownSelector,
        CopySingleScheduleModal,
        PageContentContainer,
        PageTitleContainer,
        ScheduleStatusBanner,
        ScheduleActionButtons,
        FutureActionCheckModal,
        Button,
        ScheduleBlockEditor,
        ScheduleBlockLineTracker,
        DeleteButtonWithConfirmation,
        UnauthorizedBlockChangeModal
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const { setTitlePrefix } = useTitleManager();

        const schedulerStore = useSchedulerStore();
        const showNoLinesError = ref(false);
        const showFutureActionCheckModal = ref(false);
        const blockWeAreChanging = ref<ScheduleBlock | null>(null);
        const showUnauthorizedBlockChangeModal = ref<boolean>(false);

        const isLoading = ref(true);
        const scheduledItem = ref();
        const startDate = computed(() => FormattingUtils.formatDate(scheduledItem.value?.date_from, "dd/MM/yy HH:mm"));
        const endDate = computed(() => FormattingUtils.formatDate(scheduledItem.value?.date_to, "dd/MM/yy HH:mm"));
        const currentEditState = ref<ScheduleBlockEditorState>(ScheduleBlockEditorState.NONE);
        const title = computed(() => (currentEditState.value === ScheduleBlockEditorState.CREATING ? "New Schedule Block" : SchedulerUtils.getBlockLabel(scheduledItem.value)));

        const intervalMinutes = ref(30);

        const futureActionCheckPassed = ref(false);
        const futureActionCheckCallback = ref();

        const isDeletable = ref(<Boolean>true);
        const isEditable = ref(<Boolean>true);
        const { mediaFlags } = useMediaQuery("tailwind");

        const isMounted = ref(false);

        const totalWalkValue = computed( () => {
            let total = 0;
            scheduledItem.value.lines?.forEach((line : ScheduleBlockLine) => {
                total += line.price;
            })
            return total;
        })

        let blockChangeModalMessage = ref<String>("You do not have the correct permissions to modify the chosen schedule block(s).");

        yup.addMethod(yup.array, 'uniqueDogs', function(message, mapper = (line: ScheduleBlockLine) => line.dog_id) {
            return this.test('uniqueDogs', message, function(list) {
                return list?.length  === new Set(list?.map(mapper)).size;
            });
        });

        yup.addMethod(yup.array, 'linesTotalIsPositive', function(message) {
            return this.test("total_valid", message, function(list) {
                let total = 0;
                if(!list) return false;
                list.forEach((line) => {
                    total += line.price;
                })
                return total >= 0;
            })
        });

        const scheduleBlockCreationYupSchema = yup.object({
            name: yup.string().nullable().max(255),
            assigned_users : yup.array().min(1, "At least one walker must be assigned to this event"),
            date_from : yup.string().required("A start date and time must be set for this event"),
            date_to : yup.string().required("A end date and time must be set for this event"),
            lines : yup.array().min(1,"At least one dog must be added.").of(
                yup.object({
                    walk_category_id: yup.string().typeError("Walk Type is required.").required("Walk Type is required."),
                    dog_id: yup.string().typeError('Dog is required.').required("Dog is required.")
                })
            )
            .uniqueDogs('Error: a dog can only be added once per schedule block')
            .linesTotalIsPositive("Error: the total cost must be a positive amount.")
        });

        const {handleSubmit, errors} = useForm({
            validationSchema: scheduleBlockCreationYupSchema,
        })


        //Setup initial form.
        const { value: blockName } = useField('name');
        const { value: dateFrom } = useField('date_from');
        const { value: dateTo } = useField('date_to');
        const { value: assignedUsers } = useField('assigned_users');
        const { value: lines } = useField('lines');

        const resetFieldValues = () => {
            blockName.value = scheduledItem.value.name;
            dateFrom.value = scheduledItem.value.date_from;
            dateTo.value = scheduledItem.value.date_to;
            assignedUsers.value = scheduledItem.value.assigned_users;
            lines.value = scheduledItem.value.lines;
        };

        const resetForm = async function() {
            if(route.params.id === "null") {
                let date = DateTime.fromISO(<string>route.params.dateFrom);
                let duration = parseInt(<string>route.params.duration);

                let newScheduleBlock = {
                    date_from: date,
                    date_to: date.plus({minute: duration})
                };

                scheduledItem.value = newScheduleBlock;

                resetFieldValues();

                currentEditState.value = ScheduleBlockEditorState.CREATING;
            } else if (route.params.id) {
                const response = await schedulerStore.getScheduleBlock(parseInt(route.params.id as string));
                if(response){
                    scheduledItem.value = response;

                    resetFieldValues();
                }

                if(route.params.edit === "edit"){
                    currentEditState.value = ScheduleBlockEditorState.EDITING;
                } else {
                    currentEditState.value = ScheduleBlockEditorState.VIEWING;
                    if(Object.keys(scheduledItem.value.invoice_state).filter(x => x !== "draft" && x !=="N/A").length > 0){
                        isDeletable.value = false;
                        isEditable.value = false;
                    }
                    if(scheduledItem.value.invoice_state?.draft > 0){
                        isDeletable.value = false;
                    }
                }
            }

            isLoading.value = false;
        }

        onBeforeMount(async () => {
            resetForm();
        })

        onMounted(() => {
            isMounted.value = true;
            setTitlePrefix("Schedule");
        });

        watch(scheduledItem, s => {
            setTitlePrefix(title.value + " - " + "Schedule")
        });

        watch(route, w => {
            if(route.name == "singleSchedule"){
                resetForm();
            }
        });

        let deleteScheduleBlocks = async (scheduleBlockId: number) => {
            try {
              await schedulerStore.deleteScheduleBlock(scheduleBlockId);
              await goBackToScheduler();
            } catch (error) {
                throw error;
            }
        }

        const goBackToScheduler = () => {
            router.back();
        }

        const onEdit = () => {
            currentEditState.value = ScheduleBlockEditorState.EDITING;
            if(scheduledItem.value){
                router.push({path : `/schedule/${scheduledItem.value.id}/edit`})
            }
        }

        const onCancel = () => {
            isLoading.value = true;
            resetForm();

            if(currentEditState.value === ScheduleBlockEditorState.CREATING) {
                goBackToScheduler();
            } else {
                if(scheduledItem.value){
                    router.push({path : `/schedule/${scheduledItem.value.id}`})
                }
            }
        }

        const onScheduleBlockUpdated = async (updatedBlock) => {
            blockName.value = updatedBlock.name;
            assignedUsers.value = updatedBlock.assigned_users;
            dateFrom.value = updatedBlock.date_from;
            dateTo.value = updatedBlock.date_to;
            lines.value = updatedBlock.lines;
        };

        const onScheduleBlockUpdate = async () => {
            const onSubmit = handleSubmit(async (values) => {
                let updatedScheduleBlock = {
                    ...scheduledItem.value,
                    name: values.name,
                    assigned_users: values.assigned_users,
                    date_from: DateTime.fromISO(values.date_from),
                    date_to: DateTime.fromISO(values.date_to),
                    lines: values.lines
                };

                if(currentEditState.value === ScheduleBlockEditorState.CREATING) {
                    const newBlock = await schedulerStore.createScheduleBlock(updatedScheduleBlock);
                    currentEditState.value = ScheduleBlockEditorState.VIEWING;
                    await router.push(`/schedule/${newBlock!.id}`);
                    scheduledItem.value = newBlock;
                } else {
                    await schedulerStore.updateScheduleBlock(updatedScheduleBlock);
                    scheduledItem.value = updatedScheduleBlock;

                    currentEditState.value = ScheduleBlockEditorState.VIEWING;
                }
            });

            try{
                await onSubmit();
            }
            catch (error){
                throw error;
            }
        };

        const onScheduleBlockDeleted = async () => {
            try {
                await schedulerStore.deleteScheduleBlock(scheduledItem.value.id);
                goBackToScheduler();
            } catch (error) {
                throw error;
            }
        };



        const performActionWithFutureActionCheck = (actionCallback: () => void) => {
            if(scheduledItem.value.date_from.startOf('day') > DateTime.now().startOf('day') && !futureActionCheckPassed.value) {
                futureActionCheckCallback.value = actionCallback;
                showFutureActionCheckModal.value = true;
            } else {
                actionCallback();
            }
        }


        const onStartWalk = async () => {
            try{
                const updatedBlock = await setScheduleBlockStatus(ScheduleBlockStatus.STATUS_IN_PROGRESS);
                if(updatedBlock?.id){
                    await router.push({path : `/schedule/${updatedBlock.id}`})
                    await resetForm();
                }
            }
            catch (error){
                throw error;
            }
        }

        const setScheduleBlockStatus = async (status: string) => {
            try{
                scheduledItem.value.status = status;
                return await schedulerStore.updateScheduleBlock(scheduledItem.value);
            }
            catch (error){
                throw error;
            }
        }

        const onScheduleBlockScheduled = async () => {
            try{
                await setScheduleBlockStatus(ScheduleBlockStatus.STATUS_SCHEDULED);
            }
            catch (error){
                throw error;
            }
        }

        const onScheduleBlockInProgress = async () => {
            try{
                showNoLinesError.value = false;
                performActionWithFutureActionCheck(async () => {
                    await onStartWalk();
                });
            }
            catch (error){
                throw error;
            }
        }

        const onScheduleBlockComplete = async () => {
            try{
                await setScheduleBlockStatus(ScheduleBlockStatus.STATUS_COMPLETE);
            }
            catch (error){
                throw error;
            }
        }

        const onScheduleBlockCancelled = async () => {
            try{
                await setScheduleBlockStatus(ScheduleBlockStatus.STATUS_CANCELLED);
            }
            catch (error){
                throw error;
            }
        }

        const onConfirmFutureAction = async () => {
            showFutureActionCheckModal.value = false;
            futureActionCheckPassed.value = true;

            if(futureActionCheckCallback.value) {
                futureActionCheckCallback.value();
            }
        }

        const onDeclineFutureAction = () => {
            showFutureActionCheckModal.value = false;
            futureActionCheckPassed.value = false
        }

        const copyModalIsVisible = ref(false);
        const scheduleCopyDateTo = ref(DateTime.now().toISO());
        const showCopyModalSuccessMessage = ref(false);
        const showCopyModalFailedMessage = ref(false);
        const newCopiedScheduleId = ref(0);

        const saveScheduleCopy = async() =>{
            const targetDate = DateTime.fromISO(scheduleCopyDateTo.value);
            const newDateFrom = scheduledItem.value.date_from.set({ day: targetDate.day, month: targetDate.month, year: targetDate.year });
            const newDateTo = scheduledItem.value.date_to.set({ day: targetDate.day, month: targetDate.month, year: targetDate.year });

            const newScheduleBlock : ScheduleBlock = {
                assigned_user_ids: scheduledItem.value.assigned_user_ids,
                assigned_users: scheduledItem.value.assigned_users,
                date_from: newDateFrom,
                date_to: newDateTo,
                lines: scheduledItem.value.lines,
                name: scheduledItem.value.name,
                status: ScheduleBlockStatus.STATUS_SCHEDULED
            }
            const newBlock = await schedulerStore.createScheduleBlock(newScheduleBlock);
            if (newBlock) {
                showCopyModalSuccessMessage.value = true;
                newCopiedScheduleId.value = newBlock.id;
            } else {
                showCopyModalFailedMessage.value = true;
            }
        }

        const updateTargetDate = (value) => {
            scheduleCopyDateTo.value = DateTime.fromJSDate(value.value);
        }

        const handleViewNewCopiedScheduleItem = async () => {
            copyModalIsVisible.value = false;
            showCopyModalFailedMessage.value = false;
            showCopyModalSuccessMessage.value = false;
            await router.push(`/schedule/${newCopiedScheduleId.value}`);
        }

        return{
            goBackToScheduler,
            startDate,
            endDate,
            scheduledItem,
            isLoading,
            currentEditState,
            ScheduleBlockEditorState,
            ScheduleBlockStatus,
            errors,
            isDeletable,
            isEditable,
            title,

            blockName,
            dateFrom,
            dateTo,
            assignedUsers,
            lines,

            onEdit,
            onCancel,
            onScheduleBlockDeleted,
            onScheduleBlockUpdated,
            onScheduleBlockUpdate,

            MoneyUtils,
            FormattingUtils,

            showNoLinesError,
            showFutureActionCheckModal,

            showUnauthorizedBlockChangeModal,

            onScheduleBlockScheduled,
            onScheduleBlockInProgress,
            onScheduleBlockComplete,
            onScheduleBlockCancelled,

            onConfirmFutureAction,
            onDeclineFutureAction,

            SchedulerUtils,
            intervalMinutes,
            mediaFlags,
            blockChangeModalMessage,
            isMounted,
            totalWalkValue,

            // Copy Schedule Item
            copyModalIsVisible,
            scheduleCopyDateTo,
            showCopyModalSuccessMessage,
            showCopyModalFailedMessage,
            saveScheduleCopy,
            handleViewNewCopiedScheduleItem,
            updateTargetDate
        }
    }
})
</script>

<style scoped>

</style>
