import axios from 'axios';
import {CustomersListOptions} from "../modules/customer/types/CustomersListOptions";
import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper";
import {Customer} from "../modules/customer/types/Customer";
import PaginatedResponse from '../types/PaginatedResponse';
import SlimCustomer from '../modules/customer/types/SlimCustomer';

async function getCustomerList(options: CustomersListOptions): Promise<ApiResponseWrapper<PaginatedResponse<SlimCustomer[]>>> {
    let endpoint = options.url ? `${options.url}` : `/customers`;
    if (options.searchTerm) endpoint += `?searchTerm=${options.searchTerm}`
    return ApiAsyncWrapper(axios.get(endpoint));
}

async function getCustomer(customerId: number): Promise<ApiResponseWrapper<Customer>> {
    return ApiAsyncWrapper<Customer>(axios.get(`/customers/${customerId}`));
}

async function updateCustomer(customerId: number, customer: Customer): Promise<ApiResponseWrapper<Customer>> {
    return await ApiAsyncWrapper<Customer>(axios.put(`/customers/${customerId}`, customer));
}

async function createCustomer(customer: Customer): Promise<ApiResponseWrapper<Customer>> {
    return await ApiAsyncWrapper<Customer>(axios.post(`/customers`, customer));
}

async function getCountryList(): Promise<any> {
    return ApiAsyncWrapper(axios.get(`/countries`));
}

async function deleteCustomer(customerId: number): Promise<ApiResponseWrapper<{}>> {
    return await ApiAsyncWrapper(axios.delete(`/customers/${customerId}`));
}

export default {
    getCustomerList,
    getCustomer,
    updateCustomer,
    createCustomer,
    getCountryList,
    deleteCustomer
};
