import { defineStore } from "pinia";
import CustomerNotesApiClient from "../../api/CustomerNotesApiClient";
import InvoiceApiClient from "../../api/InvoiceApiClient";
import { InvoicePdfCreatedEvent } from "../../types/events/InvoicePdfCreatedEvent";
import PaginatedResponse from "../../types/PaginatedResponse";
import CreatedInvoice from "./types/CreatedInvoice";
import Invoice from "./types/Invoice";
import InvoiceLine from "./types/InvoiceLine";
import InvoiceStatus from "./types/InvoiceStatus";
import SlimlineInvoice from "./types/SlimlineInvoice";

const useInvoiceStore = defineStore({
    id: "invoices",
    state: () => ({
        invoicesLoading: true,
        invoices: undefined as PaginatedResponse<SlimlineInvoice[]> | undefined,

        invoiceLoading: true,
        invoice: undefined as Invoice | undefined,

        statuses: [
            { value: "draft", name: "Draft" }, 
            { value: "sent", name: "Sent", }, 
            { value: "manually_sent", name: "Manually Sent" }, 
            { value: "paid", name: "Paid" }, 
            { value: "cancelled", name: "Cancelled" }
        ],

        sortOptions: [
            { value: "createdDesc", name: "Created - New to Old"},
            { value: "createdAsc", name: "Created - Old to New"},
        ]
    }),
    actions: {
        async loadInvoices(
            status?: InvoiceStatus | undefined,
            customer?: number | undefined,
            paginationLink?: string | undefined,
            sortToUse?: string | undefined,
            showDeleted: number = 0
        ) {
            this.invoicesLoading = true;

            const invoiceListResponse = await InvoiceApiClient.listInvoices([status], customer, paginationLink, sortToUse, showDeleted);
            if (invoiceListResponse.success) {
                this.invoices = invoiceListResponse.data!;
            }

            this.invoicesLoading = false;
        },

        async invoiceCreated(invoice: CreatedInvoice) {
            this.invoiceLoading = true;

            const createdInvoiceResponse = await InvoiceApiClient.createInvoice(invoice);
            if (createdInvoiceResponse.success) {
                this.invoice = createdInvoiceResponse.data!;
            }

            this.invoiceLoading = false;
            return this.invoice!.id;
        },

        async activeInvoiceChanged(invoiceId: number) {
            this.invoiceLoading = true;

            const invoiceResponse = await InvoiceApiClient.getInvoice(invoiceId);
            if (invoiceResponse.success) {
                this.invoice = invoiceResponse.data!;
            }

            this.invoiceLoading = false;
        },

        async invoiceUpdated(invoiceId: number, invoice: Invoice) {
            this.invoiceLoading = true;

            const invoiceResponse = await InvoiceApiClient.updateInvoice(invoiceId, invoice);
            if (invoiceResponse.success) {
                this.invoice = invoiceResponse.data!;
            }

            this.invoiceLoading = false;
        },

        async sendReminder(invoiceId: number) {
            this.invoiceLoading = true;

            const invoiceResponse = await InvoiceApiClient.sendReminder(invoiceId);
            if (invoiceResponse.success) {
                this.invoice = invoiceResponse.data!;
            }

            this.invoiceLoading = false;
        },

        async invoiceDeleted(invoiceId: number) {
            this.invoiceLoading = true;

            const invoiceResponse = await InvoiceApiClient.deleteInvoice(invoiceId);
            if (invoiceResponse.success) {
                const indexOfAvailableInvoice = this.invoices?.data!.findIndex(c => c.id === invoiceId);
                if(indexOfAvailableInvoice !== -1){
                    this.invoices?.data!.splice(indexOfAvailableInvoice, 1);
                }
            }

            this.invoiceLoading = false;
        },

        async invoiceUnDeleted(invoiceId: number) {
            this.invoiceLoading = true;

            const invoiceResponse = await InvoiceApiClient.unDeleteInvoice(invoiceId);
            if (invoiceResponse.success) {
                const indexOfAvailableInvoice = this.invoices?.data!.findIndex(c => c.id === invoiceId);
                if(indexOfAvailableInvoice !== -1){
                    this.invoices?.data!.splice(indexOfAvailableInvoice, 1);
                    this.invoices?.data!.push(invoiceResponse.data!);
                }
            }

            this.invoiceLoading = false;
        },

        async invoiceLinesUpdated(invoiceId: number, lines: InvoiceLine[]) {
            this.invoiceLoading = true;

            const invoiceUpdateResponse = await InvoiceApiClient.updateInvoice(invoiceId, { ...this.invoice!, lines });
            if (invoiceUpdateResponse.success) {
                this.invoice = invoiceUpdateResponse.data!;
            }

            this.invoiceLoading = false;
        },

        async invoiceStatusChanged(invoiceId: number, newStatus: InvoiceStatus) {
            this.invoicesLoading = true;
            this.invoiceLoading = true;

            const invoiceResponse = await InvoiceApiClient.getInvoice(invoiceId);
            invoiceResponse.data!.status = newStatus;
            
            await InvoiceApiClient.updateInvoice(invoiceId, invoiceResponse.data!);
            
            const relatedInvoiceInList = this.invoices?.data?.find(invoice => invoice.id === invoiceId);
            if (relatedInvoiceInList) {
                relatedInvoiceInList.status = newStatus;
            }

            this.invoicesLoading = false;
            this.invoiceLoading = false;
        },

        invoicePdfDownloadedInBackground(message: InvoicePdfCreatedEvent) {
            if (!this.invoice || this.invoice.id !== message.invoice_id) {
                return;
            }
            
            this.invoice.pdf_url = message.pdf_url;
        }
    }
});

export default useInvoiceStore;
