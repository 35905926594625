<template>
    <div>
        <div class="block font-medium text-gray-700">
            {{ label }}{{ required && isEditing ? "*" : "" }}
        </div>
        <div class="flex flex-row items-center">
            <v-date-picker class="inline-block h-full" v-model="internalDate">
                <template v-slot="{ inputValue, togglePopover }">
                    <input
                        v-if="isEditing"
                        style="height: 38px; max-width: 135px"
                        :value="inputValue"
                        class="bg-white
                         rounded-r-none
                         rounded-l-md
                         dark:bg-gray-500
                         text-gray-700 dark:text-gray-50
                         w-full py-1 px-2
                         appearance-none
                         border border-gray-300 dark:border-gray-800
                         focus:outline-none
                         focus:border-sky-blue-500 border-r-0"
                        readonly
                        @click="togglePopover()"
                    />
                    <div class="mr-2" v-else>{{inputValue}}</div>
                </template>
            </v-date-picker>
            <div class="left-time-selector">
                <DropdownSelector
                    key="selector1"
                    id="time-start"
                    placeholder=""
                    :value="internalTimeStart"
                    :isEditing="isEditing"
                    :selectors="selectorsTimeStart"
                    @update:value="updateInternalTimeStart"
                />
            </div>
            <div v-if="isEditing" class="bg-gray-300 dark:bg-gray-800 font-bold text-2xl pl-2 pr-2 dark:text-gray-50" style="height: 38px">
                -
            </div>
            <div class="m-1" v-else> - </div>
            <div class="right-time-selector">
                <DropdownSelector
                    key="selector2"
                    id="time-end"
                    placeholder=""
                    :value="internalTimeEnd"
                    :isEditing="isEditing"
                    :selectors="selectorsTimeEnd"
                    @update:value="updateInternalTimeEnd"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent, ref, watch} from "vue";
import {DateTime, Duration} from "luxon";
import DropdownSelector from "./DropdownSelector.vue"
import SchedulerUtils from "../../modules/schedule/utils/scheduler-utils";

export default defineComponent({
    name: "DateTimeRangePicker",
    components: {DropdownSelector},
    props:{
        label:{
            required: true,
            type: String,
        },
        timeInterval: {
            required: false,
            type: Number,
            default: 30
        },
        fromValue: {
            required: true,
            type: String,
            default: ''
        },
        toValue:{
            required: true,
            type: String,
            default: ''
        },
        isEditing: {
            required: false,
            type: Boolean,
            default: false,
        },
        id:{
            required: true,
            type: String
        },
        type: {
            required: false,
            type: String,
            default: 'text'
        },
        displayValue: {
            required: false,
            type: String
        },
        required: {
            required: true,
            type: Boolean,
            default: false
        }
    },
    emits:[
        'update:valueTo',
        'update:valueFrom',
    ],
    setup(props, context){

        const toTotalMinutes = (dt) => {
            return dt.minute + (dt.hour * 60);
        }

        const durationToTotalMinutes = (dt) => {
            return dt.minutes + (dt.hours * 60);
        }

        const toMinsAndHoursString = (mins) => {
            if(mins < 60) return `${mins}m`
            if(getMinsFromMins(mins) > 0) return `${getHoursFromMins(mins)}hrs ${getMinsFromMins(mins)}m`;
            return `${getHoursFromMins(mins)}hrs`;
        }

        const getHoursFromMins = (mins) => {
            if(!mins) return 0;
            return Math.floor(mins / 60);
        }

        const getMinsFromMins = (mins) => {
            if(!mins) return 0;
            return mins % 60;
        }

        const internalValue = computed({
            get: () =>{
                return {
                    dateFrom: props.fromValue,
                    dateTo: props.toValue,
                }
            },
            set: (newValue) => {
                context.emit('update:valueFrom', newValue.dateFrom);
                context.emit('update:valueTo', newValue.dateTo);
            }
        });

        const internalDate = ref();
        const internalTimeStart = ref();
        const internalTimeEnd = ref();

        watch(props, c => {
            internalTimeStart.value = toTotalMinutes(DateTime.fromISO(props.fromValue));
            internalTimeEnd.value = toTotalMinutes(DateTime.fromISO(props.toValue));
            internalDate.value = DateTime.fromISO(props.fromValue).toJSDate();
        }, { immediate: true });

        watch(internalDate, c => {
            const dateTimeDay = DateTime.fromJSDate(internalDate.value);
            context.emit('update:valueFrom', DateTime.fromObject(
                {
                    year: dateTimeDay.year,
                    month: dateTimeDay.month,
                    day: dateTimeDay.day,
                    hour: getHoursFromMins(internalTimeStart.value),
                    minute: getMinsFromMins(internalTimeStart.value)
                }
            ).toISO());
            context.emit('update:valueTo', DateTime.fromObject(
                {
                    year: dateTimeDay.year,
                    month: dateTimeDay.month,
                    day: dateTimeDay.day,
                    hour: getHoursFromMins(internalTimeEnd.value),
                    minute: getMinsFromMins(internalTimeEnd.value)
                }
            ).toISO());
        });

        const selectorsTimeStart = computed({
            get: () =>{
                return SchedulerUtils.getDailyHours(0, 24, props.timeInterval)
                    .slice(0, -1) // remove 1 intervals behind 24hrs (i.e. 5min intervals it goes to 23:50, skipping the 00:00 problem)
                    .map((d) => { return {
                        value: durationToTotalMinutes(d),
                        name: d.toFormat("hh:mm")
                }});
            },
        });

        const selectorsTimeEnd = computed({
            get: () =>{
                return SchedulerUtils.getDailyHours(getHoursFromMins(internalTimeStart.value), 24, props.timeInterval)
                    .map((d) => {
                    if((durationToTotalMinutes(d) - internalTimeStart.value) > 0)
                    {
                        return {
                            value: durationToTotalMinutes(d),
                            name: d.toFormat("hh:mm") + ` (${toMinsAndHoursString(durationToTotalMinutes(d) - internalTimeStart.value)})`
                        }
                    }
                }).filter(x => x);
            },
        });

        const updateInternalTimeStart = (value) => {
            const currDifference = internalTimeEnd.value - internalTimeStart.value;
            internalTimeStart.value = value;

            let lastTime = (24 * 60) - props.timeInterval;
            if(internalTimeStart.value + currDifference >= lastTime) {
                internalTimeEnd.value = lastTime;
            } else {
                internalTimeEnd.value = internalTimeStart.value + currDifference;
            }

            const currentBlockLength = internalTimeEnd.value - internalTimeStart.value;

            //Adjust the new start time
            const currDateTimeStart = DateTime.fromISO(props.fromValue);
            const newStartTime = DateTime.fromObject(
                {
                    year: currDateTimeStart.year,
                    month: currDateTimeStart.month,
                    day: currDateTimeStart.day,
                    hour: getHoursFromMins(internalTimeStart.value),
                    minute: getMinsFromMins(internalTimeStart.value)
                }
            ).toISO();

            //Adjust end time to be the new start time plus the original block length.
            const newEndTime = DateTime.fromObject(
                {
                    year: currDateTimeStart.year,
                    month: currDateTimeStart.month,
                    day: currDateTimeStart.day,
                    hour: getHoursFromMins(internalTimeStart.value + currentBlockLength),
                    minute: getMinsFromMins(internalTimeStart.value + currentBlockLength)
                }
            ).toISO();

            internalValue.value = {
                dateFrom: newStartTime,
                dateTo: newEndTime
            };
        }

        const updateInternalTimeEnd = (value) => {
            if(value < internalTimeStart.value) {
                internalTimeStart.value = value;
            }

            internalTimeEnd.value = value;

            const currDateTimeEnd = DateTime.fromISO(props.toValue);

            const newStartTime = DateTime.fromObject(
                {
                    year: currDateTimeEnd.year,
                    month: currDateTimeEnd.month,
                    day: currDateTimeEnd.day,
                    hour: getHoursFromMins(internalTimeStart.value),
                    minute: getMinsFromMins(internalTimeStart.value)
                }
            ).toISO();

            const newEndTime = DateTime.fromObject(
                {
                    year: currDateTimeEnd.year,
                    month: currDateTimeEnd.month,
                    day: currDateTimeEnd.day,
                    hour: getHoursFromMins(internalTimeEnd.value),
                    minute: getMinsFromMins(internalTimeEnd.value)
                }
            ).toISO()

            internalValue.value = {
                dateFrom: newStartTime,
                dateTo: newEndTime
            };
        }

        return{
            SchedulerUtils,
            updateInternalTimeStart,
            updateInternalTimeEnd,
            selectorsTimeStart,
            selectorsTimeEnd,
            internalTimeStart,
            internalTimeEnd,
            internalDate,
            internalValue,
        }
    }


})
</script>

<style>
.left-time-selector .dropdown-selector{
    border-radius: 0;
    min-width: 90px
}

.right-time-selector .dropdown-selector{
    border-left: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
</style>
