<template>
    <Modal
        :visible="addingNewCustomer"
        size="xlarge"
        title="Create a Customer"
    >
        <PersonalInformationForm
            :isEditing="true"
            @onSubmit="newCustomerCreated"
            ref="personalInformationForm"
        />

        <template #footer-buttons>
            <Button variety="warning" @click="addingNewCustomer = false">
                Cancel
            </Button>
            <Button variety="confirm" @click="$refs.personalInformationForm.onSubmit()">
                Save
            </Button>
        </template>
    </Modal>

    <!-- Title -->
    <PageTitleContainer>

        <div class="flex items-center flex-grow">
                <div class="flex-grow">
                    <h1 class="text-2xl font-semibold text-black dark:text-gray-100">Customer Management</h1>
                </div>
                <div class="flex flex-1 justify-end">
                    <Button v-if="userHasManageCustomerPermission" @click="handleAddNewButton" variety="primary">
                        Add
                    </Button>
                </div>
        </div>

    </PageTitleContainer>

    <!-- Content -->
    <PageContentContainer>
        <div class="flex flex-col flex-grow p-2 md:p-0 bg-gray-50 dark:bg-gray-700 rounded-lg">

            <div class="flex items-center flex-grow shadow">
                <!-- Heroicon name: solid/search -->
                <svg class="h-5 w-5 dark:text-gray-100 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                </svg>
                <input
                    id="search_field"
                    class="bg-gray-50 dark:bg-gray-700 block flex-grow w-100 pr-3 py-2 border-transparent rounded-lg text-gray-900 dark:text-gray-100 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent"
                    placeholder="Search"
                    @keyup="getPaginatedDataWithDebounce"
                    v-model="searchTerm"
                    type="search"
                    name="search"
                >
            </div>
            <div v-if="customers && customers.data" class="shadow overflow-hidden border-b border-gray-200 dark:border-gray-800">
                <table class="hidden md:table min-w-full divide-y divide-gray-200 dark:divide-gray-800">
                    <thead class="bg-gray-50 dark:bg-gray-800">
                    <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider">
                            Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider">
                            Email
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider">
                            Phone
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider">
                            Dogs
                        </th>
                    </tr>
                    </thead>
                    <tbody class="bg-white dark:bg-gray-700 divide-y divide-gray-200 dark:divide-gray-800">

                    <tr v-if="loadingCustomers">
                        <th scope="col" colspan="4" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider text-center">
                            Loading...
                        </th>
                    </tr>
                    <CustomerTableRow
                        v-else
                        v-for="(customer, index) in customers.data"
                        :key="index"
                        :customer="customer"
                    />

                    <!-- More people... -->
                    </tbody>
                </table>

                <div class="block md:hidden bg-white dark:bg-gray-800 mb-2 p-2 shadow"  v-for="(customer, index) in customers.data" :key="index" @click="viewCustomer(customer.id)">
                    <div class="flex items-center">
                        <div class="flex-shrink-0 flex items-center justify-center w-8 h-8 bg-gray-600 text-white font-medium rounded-full">
                            {{customer.name.split(' ')[0]?.charAt(0)}}{{customer.name.split(' ')[1]?.charAt(0)}}
                        </div>
                        <div class="ml-4">
                            <div class="font-medium text-gray-900 dark:text-gray-100">
                                <strong>{{ customer.name }}</strong><br/>
                                {{ customer.email }}<br />
                                {{ customer.telephone ?? customer.mobile }}
                            </div>
                        </div>
                    </div>
                </div>

                <Pagination
                    :paging="customers"
                    @linkClicked="getNewPaginatedData"
                />
            </div>

        </div>
    </PageContentContainer>

</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, onMounted, ref} from "vue";
import CustomerTableRow from "../components/CustomerTableRow.vue";
import {useRouter} from "vue-router";
import {PermissionGroup} from "../../user/enums/PermissionGroup";
import useCustomerStore from "../useCustomerStore";
import useUserStore from "../../user/useUserStore";
import PersonalInformationForm from "../components/PersonalInformationForm.vue";
import { Customer } from "../types/Customer";
import { CreatingCustomer } from "../types/CreatingCustomer";
import { ContactType } from "../types/ContactType";
import useTitleManager from "../../../utils/useTitleManager";
import Pagination from "../../../components/Pagination.vue";
import _ from "lodash";
import {FathomEvent, sendFathomEvent} from "../../fathom/fathomUtils";
import PageTitleContainer from "../../../components/PageTitleContainer.vue";
import PageContentContainer from "../../../components/PageContentContainer.vue";

export default defineComponent({
    name: "CustomerDashboard",
    components: {PageContentContainer, PageTitleContainer, CustomerTableRow, PersonalInformationForm, Pagination},
    setup(){
        const userStore = useUserStore();
        const customerStore = useCustomerStore();
        const { setTitlePrefix } = useTitleManager();
        const router = useRouter();
        const customers = computed(() => customerStore.customers);
        const addingNewCustomer = ref(false);
        const loadingCustomers = ref(false);
        const searchTerm = ref('');
        const userHasManageCustomerPermission = computed(() => userStore.userHasPermission(PermissionGroup.MANAGE_CUSTOMERS));

        onMounted(() =>{
            setTitlePrefix("Customers");
            getNewPaginatedData();
        })

        async function getPaginatedDataBasedOnSearchQuery(){
            loadingCustomers.value = true;

            await customerStore.listingCustomers({ searchTerm: searchTerm.value });

            loadingCustomers.value = false;
        }

        async function getNewPaginatedData(apiUrl: string | undefined = undefined){
            loadingCustomers.value = true;

            await customerStore.listingCustomers({ url: apiUrl });

            loadingCustomers.value = false;
        }

        function handleAddNewButton(){
            addingNewCustomer.value = true;
        }

        async function newCustomerCreated(customer: CreatingCustomer) {
            try{
                customer.create_contact_for_customer = true;
                customer.contact_type = ContactType.STANDARD;

                const createdCustomerId = await customerStore.customerCreated(customer);
                sendFathomEvent("CREATED_A_CUSTOMER");
                addingNewCustomer.value = false;
                router.push(`/customers/${createdCustomerId}`);
            }
            catch (error){}
        }

        const getPaginatedDataWithDebounce = _.debounce(getPaginatedDataBasedOnSearchQuery, 500);

        function viewCustomer(id : string){
            router.push(`/customers/${id}`);
        }

        return {
            loadingCustomers,
            customers,
            getNewPaginatedData,
            searchTerm,
            getPaginatedDataWithDebounce,
            handleAddNewButton,
            userHasManageCustomerPermission,
            addingNewCustomer,
            newCustomerCreated,
            viewCustomer
        }
    }
})
</script>

