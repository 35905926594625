<template>
    <div class="divide-y divide-gray-50">
        <div class="bg-white py-8 px-4 shadow sm:px-10">
            <h2 class="text-center text-3xl font-extrabold text-gray-900 mb-6">
                Registered
            </h2>
            <div class="flex flex-col justify-center items-center gap-8">
                <div>
                    Thank you for registering.
                </div>
                <div>
                    Please check your email to verify your account.
                </div>
                <div v-if="emailResent" class="text-sky-blue-900 italic">
                    Email resent.
                </div>
            </div>

            <div class="flex justify-center mt-4">
                <Button variety="primary" @click="resendEmail">
                    Resend Email
                </Button>
            </div>
            <div class="flex">
                <div class="text-center mt-8 flex-grow">
                    <a href="#" @click="returnToLogin" class="italic" style="border: 0; border-bottom: 1px solid #efefef">
                        Did you mean to <span class="font-bold">login</span>?
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import UserApiClient from "../../../api/UserApiClient";
import User from "../../user/types/User";
import router from "../../../router";

export default defineComponent({
    name: "VerificationRequired",
    props:{
        userDetails: {
            type: Object as PropType<User>,
            required: false
        }
    },
    setup(props){
        let emailResent = ref(false);

        const resendEmail = async function(){
            const resendEmailResponse = await UserApiClient.resendEmail(<string>props.userDetails?.email);

            if (resendEmailResponse.success) {
                emailResent.value = true;
            }
        }


        const returnToLogin = async function(){
            await router.push({path : "/"});
        }

        return {
            emailResent,
            resendEmail,
            returnToLogin
        }
    }
})
</script>

<style scoped>

</style>
