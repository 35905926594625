<template>
    <!-- Default card -->
    <Card title="Unpaid Invoices" :isLoading="customerInvoicesLoading">
        <template #icon>
            <ChartIcon />
        </template>

        <template #buttons>
            <Button
                variety="primary"
                @click.stop="viewAllInvoicesForCustomerClicked"
            >
                View All
            </Button>
        </template>

        <InformationList v-if="customerInvoices && customerInvoices.length">
            <InformationListItem
                v-for="invoice in customerInvoices"
                :key="invoice"
                class="cursor-pointer"
                @click="invoiceClicked(invoice)"
            >
                <div class="grid grid-cols-3 w-full">
                    <span class="dark:text-gray-100">{{ `#${invoice.id}` }}</span>
                    <div class="flex flex-col flex-1">
                        <span class="text-center dark:text-gray-100">&pound;{{ prettyPrice(invoice) }}</span>
                        <span class="text-gray-500 dark:text-gray-100 text-center">{{ prettyInvoiceCreatedDate(invoice.created_at) }}</span>
                    </div>
                    <div class="text-red-500 text-center">
                        {{ invoiceOverdue(invoice) ? "OVERDUE" : "" }}
                    </div>
                </div>
            </InformationListItem>
        </InformationList>

        <div v-else class="text-gray-500">
            No unpaid invoices exist for this customer.
        </div>
    </Card>

</template>

<script lang="ts">
import { DateTime } from "luxon";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import ChartIcon from "../../../components/icons/ChartIcon.vue";
import SlimlineInvoice from "../../invoices/types/SlimlineInvoice";
import InvoiceUtils from "../../invoices/utils/InvoiceUtils";
import useCustomerStore from "../useCustomerStore";

export default defineComponent({
    components: { ChartIcon },
    setup() {
        const router = useRouter();
        const customerStore = useCustomerStore();

        const activeCustomer = computed(() => customerStore.activeCustomer);
        const customerInvoicesLoading = computed(() => customerStore.unpaidInvoicesLoading);
        const customerInvoices = computed(() => customerStore.unpaidInvoices);

        function invoiceClicked(invoice: SlimlineInvoice): void {
            router.push(`/invoices/${invoice.id}`);
        }

        function viewAllInvoicesForCustomerClicked() {
            router.push(`/invoices?customerId=${activeCustomer.value!.id}`);
        }

        function invoiceOverdue(invoice: SlimlineInvoice) {
            if (!invoice.expected_payment_date) {
                return false;
            }

            const now = DateTime.now().toUTC();
            const expected = DateTime.fromISO(invoice.expected_payment_date!).toUTC();

            return expected < now;
        }

        return {
            customerInvoicesLoading,
            customerInvoices,
            prettyInvoiceCreatedDate: InvoiceUtils.prettyPrintInvoiceDate,
            prettyPrice: InvoiceUtils.prettyPrintSlimlineInvoicePrice,

            invoiceOverdue,
            invoiceClicked,
            viewAllInvoicesForCustomerClicked
        }
    },
})
</script>
