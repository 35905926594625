<template>

</template>

<script>
import {inject} from 'vue'
import NotificationType from "../types/NotificationType";
import * as Sentry from "@sentry/vue";
import {ApiError} from "../api/utils/ApiAsyncWrapper";
import {objectMap} from "../utils/ObjectUtils";

export default {
    setup() {
        const emitter = inject("emitter");

        emitter.on('error-captured', event => {
            try{
                if (!event.error) {
                    // This shouldn't happen, the caller has omitted the `error` attribute.
                    let err = "Unknown error captured in ErrorHandler: "+JSON.stringify(event);
                    console.error(err);
                    Sentry.captureException(new Error(err));
                } else {
                    switch (event.error.constructor) {
                        case ApiError:
                            let logToSentry = true;
                            switch (event.error.status){
                                case 401:
                                case 403:
                                case 404:
                                case 422:
                                    logToSentry = false;
                                    break;
                            }
                            emitter.emit('show-notification', {
                                type: NotificationType.WARNING,
                                title: "Error",
                                body: event.error.message ?? "There was an error completing your request, please try again later",
                                debug: event.error.details ? objectMap(event.error.details, function (x){
                                    return x instanceof Array ? x.join(", ") : x.toString()
                                }) : null
                            });
                            if(logToSentry){
                                Sentry.captureException(new Error(JSON.stringify(event.error)));
                            }
                            break;

                        default:
                            emitter.emit('show-notification', {
                                type: NotificationType.ERROR,
                                title: "Critical Error",
                                body: event.error.message ?? "There was an error completing your request, please try again later",
                                debug: event.error
                            });
                            Sentry.captureException(new Error(JSON.stringify(event.error)));
                            break;
                    }
                }
            }
            catch (e){
                console.error("Unhandled Error", e);
                Sentry.captureException(e);
            }
        });

        return {}
    },
}
</script>
