<template>
    <div class="min-h-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto  w-auto" :src="pug" alt="PugWalk Logo">
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                404
            </h2>
        </div>
    </div>
</template>

<script>
import pug from '../assets/pug-confused-3.jpg';

export default {
    name: "FourZeroFour",
    data: function() {
        return {
            pug: pug
        }
    }
}
</script>

<style scoped>

</style>