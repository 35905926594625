<template>
    <Card :title="cardTitle ?? 'Information'">
        <template #icon>
            <PersonIcon />
        </template>

        <template #buttons>
            <Button
                v-if="!isEditing && !disableEditing"
                variety="primary"
                @click="toggleEditing"
            >
                Edit
            </Button>
            <Button
                v-if="isEditing && !disableEditing"
                variety="plain"
                @click="toggleEditing"
            >
                Cancel
            </Button>
            <Button
                v-if="isEditing && !disableEditing"
                variety="primary"
                @click="$refs.form.onSubmit()"
            >
                Save
            </Button>
            <Button
                v-if="!isEditing && rendersWithViewButton"
                variety="primary"
                @click="viewRecord"
            >
                View
            </Button>
        </template>

        <PersonalInformationForm
            :isEditing="isEditing && !disableEditing"
            :customer="activeCustomer"
            ref="form"
            @onSubmit="$emit('onCustomerDetailsChange', $event)"
        />
    </Card>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, onMounted, PropType, ref} from "vue";
import PersonIcon from "../../../components/icons/PersonIcon.vue";
import useMiscInformationStore from "../../../utils/useMiscInformationStore";
import { Customer } from "../types/Customer";
import PersonalInformationForm from "./PersonalInformationForm.vue";
import router from "../../../router";

export default defineComponent({
    name: "PersonalInformation",
    components: {
        PersonalInformationForm,
        PersonIcon
    },
    props:{
        activeCustomer: {
            type: Object as PropType<Customer>,
            required: false,
        },
        isEditing:{
            type: Boolean,
            required: false,
            default: false
        },
        rendersWithEditEnabled:{
            required: false,
            default: false
        },
        rendersWithViewButton:{
            required: false,
            default: false
        },
        cardTitle: {
            type: String,
            required: false,
            default: null
        },
        disableEditing: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits:[
        'onCustomerDetailsChange',
        'onToggleEditing'
    ],
    setup(props, context){
        const miscInformationStore = useMiscInformationStore();

        const countries = computed(() => miscInformationStore.countries)
        const isEditing = computed(() => props.isEditing)

        const toggleEditing = () => {
            context.emit('onToggleEditing', !props.isEditing);
        }

        const viewRecord = () => {
            router.push(`/customers/${props.activeCustomer?.id}`)
        }

        return {
            activeCustomer: computed(() => props.activeCustomer),
            isEditing,
            countries,
            toggleEditing,
            viewRecord,
            rendersWithEditEnabled: props.rendersWithEditEnabled,
        }
    }
})
</script>

<style scoped>

</style>
