<template>
    <div class="min-h-full bg-sky-blue-400 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md p-4 pb-0">
            <img class="w-60" style="margin-left: auto; margin-right: auto;" alt="loading Image of a dog" :src="(showError && !resendSentSuccessfully) ? failDog : logo">
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div v-if="!checkingToken">
                <div v-if="showError">

                    <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
                        <div v-if="!checkingResendDetails && !resendSentSuccessfully" class="m-2 md:m-0 bg-white py-8 px-4 shadow sm:px-10">

                            <div class="border border-red-400 rounded bg-red-100 px-4 py-3 text-red-700">
                                <p>We couldn't verify your email address.</p>
                            </div>
                            <h2 class="text-center text-3xl font-extrabold text-gray-900 mb-6 mt-4">
                                <span>Resend your verification email</span>
                            </h2>
                            <form v-if="!checkingResendDetails" @submit="handleResendEmail">
                                <div class="mb-4">
                                    To request your verification email to be resent, please input your email address below.
                                    <br />
                                    If you've previously signed up for an account, you'll receive an email.
                                </div>
                                <div>
                                    <label for="email" class="block font-medium text-gray-700">
                                        Email address
                                    </label>
                                    <div class="mt-1">
                                        <input id="email"
                                               :disabled="checkingResendDetails"
                                               name="email"
                                               type="email"
                                               v-model="email"
                                               autocomplete="email"
                                               required
                                               class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                                    </div>

                                    <div class="flex mt-4">
                                        <Button type="submit"
                                                variety="primary"
                                                :disabled="checkingResendDetails"
                                                @click="handleResendEmail"
                                                class="w-full flex justify-center py-2 px-4">
                                            Resend Verification email
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div v-if="loading" class="m-2 md:m-0 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <LoadingSpinner class="m-auto"/>
                        </div>
                        <div v-if="resendSentSuccessfully" class="m-2 md:m-0 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 flex inline">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="green">
                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                            <span class="ml-2">Please check your email for a verification link.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="m-2 md:m-0 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <LoadingSpinner class="m-auto"/>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import logo from "../../../assets/logo-coloured-bg.png";
import {defineComponent, inject, onMounted, ref} from "vue";
import useUserStore from "../useUserStore";
import {useRoute, useRouter} from "vue-router";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import Button from "../../../components/forms/Button.vue";
import UserApiClient from "../../../api/UserApiClient";
import NotificationType from "../../../types/NotificationType";
import failDog from "../../../assets/gone-wrong.png";
import AuthApiClient from "../../../api/AuthApiClient";
import {OnboardingLevel} from "../../onboarding/types/OnboardingLevel";

export default defineComponent({
    name: "VerifyEmail",
    components: {
        Button,
        LoadingSpinner
    },
    setup() {
        const emitter = inject('emitter');

        const route = useRoute();
        const userStore = useUserStore();
        const router = useRouter();

        const showError = ref(false);

        // Login Checking
        let checkingToken = ref(false);
        let checkingResendDetails = ref(false);
        let resendSentSuccessfully = ref(false);
        let loading = ref(false);


        // Form
        let email = ref('');

        onMounted(async () => {
            loading.value = true;
            try{
                await UserApiClient.verifyEmail(route.params.token);
                checkingToken.value = false;

                emitter.emit('show-notification', {
                    type: NotificationType.SUCCESS,
                    title: "Email verified",
                    body: "Your email address has been verified.",
                    debug: null
                });

                await userStore.userHasAuthenticated();
                router.go(0);
            }
            catch (e){
                showError.value = true;
            }
            finally {
                loading.value = false;
            }
        })

        const handleResendEmail = async function(){

            loading.value = true;
            if (email.value.length <= 0) {
                return;
            }

            if (checkingResendDetails.value) {
                return;
            }

            checkingResendDetails.value = true;
            try{
                await UserApiClient.resendEmail(email.value);
                resendSentSuccessfully.value = true;
            }
            catch (e){}
            finally {
                checkingResendDetails.value = false;
                loading.value = false;
            }
        }

        return {
            logo,
            failDog,
            emitter,
            checkingToken,
            checkingResendDetails,
            resendSentSuccessfully,
            showError,
            email,
            loading,
            handleResendEmail
        }
    }
})
</script>

<style scoped>

</style>
